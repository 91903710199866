import { Note } from "../../../store/token/api/types";
import { getTimeDifference } from "../../../helpers/getTimeDifference";
import { ExternalImage } from "../../ui/ExternalImage";

const NoteInfo = ({ note }: { note: Note }) => {
  const { message, user, createdAt } = note;

  const { first_name, photo_url } = user!;
  return (
    <div className=" w-full flex  rounded-xl gap-3">
      <div className="flex-shrink-0 w-[48px] h-[48px] min-w-[48px] min-h-[48px] max-w-[48px] max-h-[48px] bg-lightPurple rounded-[12px] ">
        <ExternalImage
          src={photo_url}
          alt={"user-photo"}
          width={48}
          height={48}
          className="rounded-[12px] size-full bg-cover"
        />
      </div>

      <div className="pt-[10px] flex-1 ">
        <div className=" w-full gap-2">
          <div className="flex flex-col gap-[6px]">
            <div className="flex justify-between">
              <p className="font-bold">@{first_name}</p>{" "}
              <p className="font-medium text-lavenderGray text-[10px]">{getTimeDifference(createdAt)}</p>
            </div>
            <p className="text-[12px] leading-4 font-medium text-lavenderGray">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteInfo;
