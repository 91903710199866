import ExchangeBlock from "../ExchangeBlock";
import UserBalanceBlock from "../UserBalanceBlock";

const ClickerHeader = () => {
  return (
    <div className="pb-2 pt-[12px] flex gap-1">
      <UserBalanceBlock />
      <ExchangeBlock />
    </div>
  );
};

export default ClickerHeader;
