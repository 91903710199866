const TopStepsBlock = ({ currentStep }: { currentStep: number }) => {
  return (
    <div className="flex items-center justify-center gap-2 pt-[3vh]">
      {[...Array(5).keys()].map((index) => (
        <div
          key={index}
          className={`relative w-[15vw] h-[4px] rounded-lg ${index === currentStep - 1 ? "bg-[#C3ACD1]" : "bg-[#694B7C]"}`}
        ></div>
      ))}
    </div>
  );
};

export default TopStepsBlock;
