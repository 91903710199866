import { axiosAdInstance } from "../axiosAdInstance";

export async function registerAdClick(userId: number, offerId: number): Promise<void> {
    const apiKey = import.meta.env.VITE_TAP_AD_API_KEY;
    const userAgent = navigator.userAgent;
    await axiosAdInstance.get<void>("/v1/click", {
      params: {
        user_id: userId,
        offer_id: offerId,
        apikey: apiKey,
        ua: userAgent,
      },
    });
}
