import { useSelector } from "react-redux";
import CurrencyDisplay from "../../Common/CurrencyDisplay";
import GradientBorderBox from "../../ui/GradientBorderBox";
import {
  dailyComboSelector,
  lastComboRewardTakenAtSelector,
  rewardForCurrentCombo,
} from "../../../store/cards/cardsReducer";
import { PurchasedCards } from "../../../store/cards/api/types";
import { useAppDispatch } from "../../../store/hooks";
import { fetchCombosRewardAction } from "../../../store/account/actions/fetchCombosRewardAction";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Timer } from "../../ui/Timer";
import { ClickerGameTimerRef } from "../../Clicker/GameTimer";
import { fetchComboDataAction } from "../../../store/cards/actions/fetchComboDataAction";
import { profitPerHourSelector } from "../../../store/account/account";

const DailyComboStatus = React.memo(({ comboList }: { comboList: PurchasedCards[] }) => {
  const dailyCombo = useSelector(dailyComboSelector);
  const lastComboRewardTakenAt = useSelector(lastComboRewardTakenAtSelector);
  const profitPerHour = useSelector(profitPerHourSelector);

  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const reward = profitPerHour * 10;
  console.log("reward", reward);

  const timerRef = useRef<ClickerGameTimerRef>(null);
  const [timeRemaining, setTimeRemaining] = useState<number>(() =>
    dailyCombo ? calculateTimeRemaining(new Date(dailyCombo.activeDate)) : -1,
  );

  useEffect(() => {
    if (dailyCombo) {
      const updatedEndDate = new Date(new Date(dailyCombo.activeDate).getTime() + 24 * 60 * 60 * 1000);
      setTimeRemaining(calculateTimeRemaining(updatedEndDate));
    }
  }, [dailyCombo]);

  const handleGetRewardForCombo = () => {
    if (isButtonActive) {
      dispatch(fetchCombosRewardAction());
    }
  };

  useEffect(() => {
    setIsButtonActive(
      lastComboRewardTakenAt
        ? !rewardForCurrentCombo(lastComboRewardTakenAt, dailyCombo?.activeDate || null) && comboList.length === 3
        : comboList.length === 3,
    );
  }, [lastComboRewardTakenAt, dailyCombo, comboList]);

  return (
    <>
      <GradientBorderBox className="h-full" border={true}>
        <div
          className="flex justify-between pl-4 pr-[5px] items-center py-3 rounded-xl "
          style={{ background: "rgba(255, 255, 255, 0.08)" }}
        >
          <p className="font-bold">Daily combo</p>
          <div className="flex w-1/3 items-center justify-center gap-[6px]">
            <Timer
              ref={timerRef}
              remainingTime={timeRemaining || 0}
              onEnd={() => {
                dispatch(fetchComboDataAction());
              }}
            />
          </div>
          <button
            className="px-3 py-[10px] rounded-lg"
            onClick={handleGetRewardForCombo}
            style={{
              background: isButtonActive
                ? "radial-gradient(181.58% 181.58% at 0% -20.31%, rgb(229, 67, 255) 0%, rgb(131, 45, 191) 100%)"
                : "grey",
            }}
          >
            <CurrencyDisplay value={reward} />
          </button>
        </div>
      </GradientBorderBox>
    </>
  );
});

export default DailyComboStatus;

export function calculateTimeRemaining(endDate: Date | string): number {
  if (typeof endDate === 'string' || typeof endDate === 'number') {
    endDate = new Date(endDate);
  }

  if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
    console.error('Invalid date passed to calculateTimeRemaining:', endDate);
    return 0;
  }

  const currentTime = new Date().getTime();
  const endTime = endDate.getTime();
  const difference = endTime - currentTime;
  return difference >= 0 ? difference : 0;
}
