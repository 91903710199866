import { createSlice } from "@reduxjs/toolkit";
import { Exchange } from "./types.ts";
import { RootState } from "../store.ts";
import { fetchExchangesListAction } from "./actions/fetchExchangesListAction.ts";

export interface ExchangesState {
  pending: boolean;
  error: string | null;
  exchanges: Exchange[];
}

const initialState: ExchangesState = {
  exchanges: [],
  pending: false,
  error: null,
};

export const exchangesSlice = createSlice({
  name: "exchanges",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchExchangesListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchExchangesListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.exchanges = action.payload.stokes;
      })
      .addCase(fetchExchangesListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const {} = exchangesSlice.actions;

export default exchangesSlice.reducer;
export const exchangesSelector = (state: RootState) => state.exchanges.exchanges;
