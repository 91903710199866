import balanceIcon from "../../assets/img/balance-shadow.png";
import ticketIcon from "../../assets/icons/ticket.svg";
import { moneyFormat, moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";
import { useSelector } from "react-redux";
import Button from "../../components/ui/Button";
import UserProfileHeader from "../../components/Pumpadur/ConnectWallet/UserProfileHeader";
import {
  cryptoProfileBalanceSelector,
  isActiveWalletSelector,
  isRewardReceivedSelector,
  pendingSelector,
} from "../../store/cryptoProfile/cryptoProfileReducer";
import { redirectToWalletManagement } from "../../helpers/redirectToWalletManagement";
import { useAppDispatch } from "../../store/hooks";
import { fetchRewardForWalletAction } from "../../store/cryptoProfile/actions/fetchRewardForWalletAction";
import { useToastNotifications } from "../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer";
import { changeRefTickets } from "../../store/cards/cardsReducer";
import MintAndManageNFT from "../../components/Pumpadur/MintAndManageNFT";
// import { PreloaderCircle } from "../../components/ui/PreloaderCircle";

const ConnectWalletPage = () => {
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const phantomWalletBalance = useSelector(cryptoProfileBalanceSelector);
  const isPending = useSelector(pendingSelector);
  const isRewardReceived = useSelector(isRewardReceivedSelector);

  const dispatch = useAppDispatch();

  const { showNotification } = useToastNotifications();

  const getRewardForConnectedWalletHandle = async () => {
    const result = await dispatch(fetchRewardForWalletAction());
    if (!fetchRewardForWalletAction.fulfilled.match(result)) {
      throw new Error("Failed to get reward");
    } else {
      dispatch(changeRefTickets(result.payload.ticket));
      showNotification({
        title: `You have successfully received ${result.payload.ticket} tickets!`,
        type: ToastNotificationType.Success,
        timeoutMs: 5000,
      });
    }
  };

  return (
    <div className="pb-[100px]">
      <UserProfileHeader />
      {!isPending ? (
        !isRewardReceived || !isActiveWallet ? (
          <div className="flex flex-col items-center text-center pt-[5vh]">
            <img src={balanceIcon} alt="balanceIcon" className="w-[60vw] object-contain" />
            <p className="text-[32px] text-yellow font-bold pb-[3.5vh]">Pumpadur task</p>
            <p className="text-[20px] pb-[4.6vh] px-[3vw] leading-6">
              Get ready for the NFT Launch! Tasks will be listed below. Complete them to join the RetroDrop event.
            </p>
            {!isActiveWallet ? (
              <>
                <div className="text-center pb-[4.6vh] text-[16px]">
                  <p>Connect Phantom Wallet</p>
                  <div className="flex items-center justify-center gap-1">
                    <p>and Get</p>
                    <div className="flex gap-1 text-lightPink text-[16px] items-center justify-center ">
                      <span className="tracking-[1px] text-[16px] font-bold">+{moneyFormat(5 || 0)}</span>
                      <img src={ticketIcon} className="w-[14px] h-auto" />
                    </div>
                    <p>Tickets</p>
                  </div>
                </div>
                <Button
                  handleClick={redirectToWalletManagement}
                  style="radial-gradient(406.24% 212.5% at 0% -44.64%, rgb(229, 67, 255) 0%, rgb(131, 45, 191) 100%)"
                  text="COnnect your PHANTOM wallet"
                />
              </>
            ) : (
              <>
                <p className="text-[20px] font-bold pb-[2vh]">
                  Your Balance: {moneyFormatWithTwoDigits(+phantomWalletBalance)} SOL
                </p>

                <Button handleClick={getRewardForConnectedWalletHandle} text="Claim Your Reward" />
              </>
            )}
          </div>
        ) : (
          <MintAndManageNFT />
        )
      ) : (
        // <div className="w-full flex justify-center text-[6px] items-center text-orange mt-[6vh]">
        //   <PreloaderCircle />
        //   <p className="text-[16px]">Loading data</p>
        // </div>
        <div className="app-loading-screen__header absolute bottom-[50vh] w-full ">
          <div className="animated-logo__wrapper">
            <div className="animated-logo">
              <img loading="lazy" className="animated-logo__spinner" src="/loader.png" alt=" " />
            </div>
            <img src="/rocket-logo-white.png" className="animated-logo__rocket" alt="Rocket" loading="lazy" />
          </div>
          <p className="animated-logo__name">Loading data</p>
        </div>
      )}
    </div>
  );
};

export default ConnectWalletPage;
