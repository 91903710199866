import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { buyPurchasedCardResponse } from "./types.ts";

export async function useTicket(key: string) {
  return (
    await axiosInstance.put<buyPurchasedCardResponse>(apiRoutes.cards.useTicket, {
      key,
    })
  ).data.data;
}
