import { BottomSheet } from "../../ui/BottomSheet";
import ticketIcon from "../../../assets/icons/ticket-white.svg";
import arrowIcon from "../../../assets/icons/arrow.svg";
import { Card } from "../../../store/cards/api/types";
import { ExternalImage } from "../../ui/ExternalImage";
import Button from "../../ui/Button";
import { useAppDispatch } from "../../../store/hooks";
import { putUseTicketAction } from "../../../store/cards/actions/putUseTicketAction";
import { useState } from "react";

interface UseTicketPopupProps {
  card: Card;
  isOpened: boolean;
  close: () => void;
}
const UseTicketPopup = ({ card, close, isOpened }: UseTicketPopupProps) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const { name, img, key } = card;

  const dispatch = useAppDispatch();

  const handleUseTicket = async () => {
    setIsPending(true);

    try {
      const result = await dispatch(putUseTicketAction(key));

      if (putUseTicketAction.fulfilled.match(result)) {
        close();
      } else {
        console.error(result.payload);
      }
    } catch (error) {
      console.error("Error using ticket:", error);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className="absolute z-[3000] top-0 flex flex-col justify-center">
      <BottomSheet
        className="bottom-[35vh] w-[85vw] rounded-[24px]"
        isOpened={isOpened}
        onClose={() => close()}
        closeButton={false}
        backdropBackgroundColorVisible="rgba(0,0,0,0.4)"
      >
        <div className="absolute top-[-6vh]  left-1/2 -translate-x-1/2 ">
          <div className="w-[24vw] aspect-square relative  border-solid border-[1px] border-[#4E335E] rounded-xl">
            <ExternalImage src={img} className="size-full object-cover  rounded-xl" alt={name} />
          </div>
        </div>
        <div className="text-center pt-[2vh] ">
          <div className=" px-[5vw] flex flex-col justify-center items-center">
            <h1 className="text-[18px] font-bold mb-[22px] ">Do you want to use your Ticket to unlock this card?</h1>
            <div className="px-4 w-full">
              <Button
                text={`<div class='flex gap-[6px]'> <img class='w-[14px] h-full' loading="lazy" src=${ticketIcon} alt="ticket" /> <p>Use</p></div>`}
                handleClick={handleUseTicket}
                isPending={isPending}
                className="text-[14px]"
                style="#AE3DFF"
              />
            </div>
            <button onClick={() => close()} className="flex justify-center items-center gap-4 mt-4 mb-2">
              <img src={arrowIcon} className="size-[16px]" alt="back" />
              <span className="block text-[14px] font-bold uppercase">Back</span>
            </button>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default UseTicketPopup;
