import { useNavigate } from "react-router-dom";
import ClickerHeader from "../../components/Clicker/ClickerHeader";
import LeagueBlock from "../../components/Clicker/LeagueBlock";
import { ProfitPerHourBlock } from "../../components/Clicker/ProfitPerHourBlock";
import { StakedRewardProgress } from "../../components/Clicker/StakedRewardProgress";
import StakingComponent from "../../components/Clicker/StakingComponent";
import { useAppDispatch } from "../../store/hooks";
import { setOnboardingStep } from "../../store/onboarding/onboardingReducer";
import { OnboardingTour } from "../../components/Clicker/OnboardingTour";
import { useSelector } from "react-redux";
import { isDemoSelector } from "../../store/clicker/selectors";
import OnboardingSteps, { OnboardingStep } from "../../components/Onboarding/OnboardingSteps";

const OnboardingStakingFinishPage = () => {
  const isDemo = useSelector(isDemoSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="flex-1 flex flex-col pb-[96px] -mt-3 select-none">
        <div className="shrink-0 ">
          <ClickerHeader />
          <LeagueBlock />
          <div className="pb-[15px]  border-0 relative">
            <div className="grid grid-cols-2 gap-x-1 [&>*]:col-span-1 mt-[-1px]">
              <StakedRewardProgress />
              <ProfitPerHourBlock />
            </div>
          </div>
        </div>
        <StakingComponent />
      </div>
      {isDemo ? (
        <OnboardingTour
          steps={[
            {
              target: "#skills",
              content: <OnboardingSteps step={OnboardingStep.Skills} />,
              placement: "top-start",
            },
          ]}
          onEnd={() => {
            navigate("/cards");
            dispatch(setOnboardingStep(2));
          }}
        />
      ) : null}
    </>
  );
};

export default OnboardingStakingFinishPage;
