import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { LoginResponse } from "./types.ts";
import { WebAppInitData } from "@twa-dev/types";

export async function login(tgChatId: number, webAppInitData?: WebAppInitData) {
  const response = await axiosInstance.post<LoginResponse>(apiRoutes.auth.login, {
    tgChatId,
    webAppInitData: webAppInitData || {},
    user: webAppInitData?.user || {},
  });
  return {
    data: response.data.data,
    status: response.status,
  };
}
