import { SetCoinsProgress } from "../api/clicker/types.ts";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";

type LocalStorageData = {
  data: SetCoinsProgress;
  writeTime: number;
};

export function saveLocally(data: SetCoinsProgress) {
  try {
    const previousData = localStorage.getItem("clicker:progress");
    let parsedPreviousData: LocalStorageData | null = null;

    if (previousData) {
      parsedPreviousData = JSON.parse(previousData) as LocalStorageData;
    }

    localStorage.setItem(
      "clicker:progress",
      JSON.stringify({
        data: {
          ...data,
          coins: [
            ...data.coins,
            ...(parsedPreviousData
              ? parsedPreviousData.data.coins.filter(({ id }) => {
                  return !data.coins.some((coin) => coin.id === id);
                })
              : []),
          ],
        },
        writeTime: new Date().getTime(),
      } satisfies LocalStorageData),
    );
  } catch (err) {
    Sentry.captureException({
      message: "Failed to save clicker progress to local storage",
      err,
    });
  }
}

export function getFromLocalStorage() {
  try {
    const data = localStorage.getItem("clicker:progress");

    if (!data) return null;

    const parsedData = JSON.parse(data) as LocalStorageData;
    const isProgressInTodayDailyQuest = dayjs(parsedData.writeTime).isSame(new Date(), "day");

    if (!isProgressInTodayDailyQuest) {
      const allCoinsAreCompleted = parsedData.data.coins.every((coin) => coin.progress >= 100);

      if (allCoinsAreCompleted) {
        Sentry.setContext("Progress", parsedData.data);
        Sentry.setContext("Write time", {
          utc: new Date(parsedData.writeTime).toUTCString(),
        });

        Sentry.captureException(
          `
            All coins are completed, but not saved to the server in his daily quest. 
            Should have been saved on (${dayjs(parsedData.writeTime, "DD-MM-YYYY")}
          `,
        );
      }

      clearLocalStorage();
      return null;
    }

    return parsedData.data;
  } catch (err) {
    Sentry.captureException({
      message: "Failed to get clicker progress from local storage",
      err,
    });
  }
}

export function clearLocalStorage() {
  localStorage.removeItem("clicker:progress");
}
