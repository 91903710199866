import { useEffect } from "react";
import RoundList from "../components/Round/RoundList";
import RoundsFilter from "../components/Round/RoundsFilter";
import RoundsSubTitle from "../components/Round/RoundsSubTitle";
import TopPanel from "../components/TopPanel";
// import OverlayBlock from "../components/ui/OverlayBlock";
import { isVisibleOverlay } from "../store/global/globalReducer";
import { useAppDispatch } from "../store/hooks";

const RoundsOverviewPage = () => {
  const dispatch = useAppDispatch();
  const handleSelectLimit = (limit: string) => {
    console.log("Selected limit:", limit);
  };

  useEffect(() => {
    dispatch(isVisibleOverlay(true));

    return () => {
      dispatch(isVisibleOverlay(false));
    };
  }, []);

  return (
    <div>
      {/* <OverlayBlock> */}
      <TopPanel page="lobby" />
      <div className="mt-[25px]">
        <RoundsFilter onSelectLimit={handleSelectLimit} />
        <RoundsSubTitle />
        <RoundList />
      </div>
      {/* </OverlayBlock> */}
    </div>
  );
};
export default RoundsOverviewPage;
