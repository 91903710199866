import { forwardRef, useImperativeHandle } from "react";
import { useCountDown } from "../../hooks/useCountDown.ts";
import { clamp } from "lodash-es";

type TimeFormat = "hh:mm:ss" | "mm:ss";

interface TimerProps {
  remainingTime: number;
  immediate?: boolean;
  onStart?: () => void;
  onEnd?: () => void;
  style?: string;
  format?: TimeFormat;
  autoFontSize?: boolean;
}

export interface TimerRef {
  start: () => void;
  clear: () => void;
  isPlaying: () => boolean;
}

export const Timer = forwardRef<TimerRef, TimerProps>(
  ({ onStart, onEnd, remainingTime, immediate, style, format, autoFontSize = true }, ref) => {
    const {
      start,
      time: timeRemaining,
      isPlaying,
      clear,
    } = useCountDown({
      duration: remainingTime,
      onEnd,
    });

    useImperativeHandle(ref, () => ({
      start: () => {
        start();
        onStart && onStart();
      },
      clear: () => {
        clear();
      },
      isPlaying: () => isPlaying,
    }));

    if (immediate) {
      clear();
      start();
      onStart && onStart();
    }

    const fontSize = clamp(window.innerHeight * 0.035, 12, 16);

    return isPlaying ? (
      <span
        className={`block text-center  ${style}`}
        style={
          autoFontSize
            ? {
                fontSize: fontSize,
              }
            : {}
        }
      >
        {formatTime(timeRemaining, format)}
      </span>
    ) : null;
  },
);

export function formatTime(milliseconds: number, format: TimeFormat = "hh:mm:ss") {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (format === "mm:ss") {
    const totalMinutes = Math.floor(totalSeconds / 60);
    return `${String(totalMinutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}
