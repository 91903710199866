import React from "react";

interface GradientBorderBoxProps {
  children: React.ReactNode;
  className?: string;
  roundedClass?: string;
  border?: boolean;
}

const GradientBorderBox: React.FC<GradientBorderBoxProps> = ({
  children,
  className = "",
  roundedClass = "rounded-2xl before:rounded-[16px]",
  border = true,
}) => {
  if (!border) {
    return <div className={`${className} ${roundedClass}`}>{children}</div>;
  }

  return (
    <div
      className={`relative w-full text-white before:absolute before:inset-0 gradient-border-container bg-transparent before:p-[1px] before:[-webkit-mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)] before:[mask-composite:exclude] before:pointer-events-none ${className} ${roundedClass}`}
    >
      <div className={`h-full w-full p-[1px] ${roundedClass} pointer-events-auto`}>{children}</div>
    </div>
  );
};

export default GradientBorderBox;
