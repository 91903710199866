export function getTimeDifference(createdAt: string): string {
  const dataCreatedToken = new Date(createdAt);
  const currentDate = new Date();

  const diffInMs = currentDate.getTime() - dataCreatedToken.getTime();
  const diffInMinutes = Math.floor(diffInMs / 60000);

  if (diffInMinutes < 60) {
    return `${diffInMinutes >= 0 ? diffInMinutes : 0}m ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays}d ago`;
}
