interface PayoutsTable {
  min: number;
  max: number;
  numberOFPrizes: number;
}

export const payoutsTable: PayoutsTable[] = [
  {
    min: 1,
    max: 9,
    numberOFPrizes: 1,
  },
  {
    min: 10,
    max: 18,
    numberOFPrizes: 2,
  },
  {
    min: 19,
    max: 24,
    numberOFPrizes: 3,
  },
  {
    min: 25,
    max: 31,
    numberOFPrizes: 4,
  },
  {
    min: 32,
    max: 45,
    numberOFPrizes: 5,
  },
  {
    min: 46,
    max: 52,
    numberOFPrizes: 6,
  },
  {
    min: 53,
    max: 59,
    numberOFPrizes: 7,
  },
];
