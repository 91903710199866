import ticketIcon from "../../../assets/icons/ticket.svg";
import balanceIcon from "../../../assets/icons/balance.svg";
import { DailyReward } from "../../../store/cards/api/types";

interface YellowDotProps {
  index: number;
  fill?: string;
  reward: DailyReward;
}

export default function YellowDot({ index, fill = "#FFBA42", reward}: YellowDotProps) {
  return (
    <div className={`w-[16px] h-[16px] rounded-full relative`} style={{ backgroundColor: fill }}>
      {index === 2 && (
        <div className="absolute -top-[50px] left-1/2 transform -translate-x-1/2 flex flex-col items-center">
          <div className="flex items-center justify-center mb-1">
            <img src={ticketIcon} alt="Ticket Icon" className="w-[20px] h-[17px] mr-1 translate-y-[3px]" />
            <span className="text-white font-bold text-[24px] leading-[24px]" style={{ width: '16px', height: '17px' }}>{reward.rewardTicket}</span>
          </div>
          <span className="text-[#FFBA42] font-bold text-[10px] leading-[24px]" style={{ height: '7px', whiteSpace: 'nowrap' }}>
            DAY {index + 1}
          </span>
        </div>
      )}

      {index === 6 && (
        <div className="absolute -top-[55px] left-1/2 transform -translate-x-1/2 flex flex-col items-center">
          <div className="flex items-center mb-[4px]">
            <img src={balanceIcon} alt="Balance Icon" className="w-[8px] h-[8px] inline-block" />
            <span className="text-[#FFD600] font-bold text-[12px] leading-none ml-[4px] inline-block" style={{ height: '12px' }}>{reward.rewardAmount}</span>
          </div>
          <span className="text-white font-bold text-[12px] leading-none inline-block" style={{ width: '84px', height: '9px', textAlign: 'center', fontFamily: 'Inter, sans-serif', fontStyle: 'normal', fontWeight: 700, whiteSpace: 'nowrap'}}>+ Special card</span>
          <span className="text-[#FFBA42] font-bold text-[10px] leading-none mt-[10px] inline-block" style={{ height: '10px', whiteSpace: 'nowrap', textAlign: 'center' }}>DAY {index + 1}</span>
        </div>
      )}
    </div>
  );
}
