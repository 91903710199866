import Title from "../components/ui/Title";
import balanceIcon from "../assets/icons/balance.svg";
import bagMoneyIcon from "../assets/icons/bag-coins.svg";
import flyingSaucerIcon from "../assets/icons/flying-saucer.svg";
import plantIcon from "../assets/icons/plant.svg";
import { useSelector } from "react-redux";
import {
  currentLeagueSelector,
  exchangeSelector,
  profitPerHourSelector,
  profitPerHourToStakingSelector,
  totalBonusForPurchasedCardsToStakingSelector,
} from "../store/account/account";
import { moneyFormat } from "../helpers/moneyFormat";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../helpers/capitalizeFirstLetter";
import { ExternalImage } from "../components/ui/ExternalImage";
// import { isGameActiveSelector, profitPerHourSelector } from "../store/clicker/selectors";
import { isGameActiveSelector } from "../store/clicker/selectors";
import { useToastNotifications } from "../hooks/useToastNotifications";
import { ToastNotificationType } from "../store/toastNotifications/toastNotificationsReducer";
import { totalBonusForPurchasedCardsSelector } from "../store/cards/cardsReducer";

export default function ProfitPerHour() {
  const profitPerHour = useSelector(profitPerHourSelector);
  const profitPerHourToStaking = useSelector(profitPerHourToStakingSelector);
  const isGameActive = useSelector(isGameActiveSelector);
  return (
    <div className="pb-[100px]">
      <Title text="Profit Per Hour" />
      <p className="text-[16px] leading-[24px] text-center ">
        Complete Portfolio Quest to activate
        <br /> 4-hour passive income
      </p>
      <div className="pt-8">
        <p className="text-[16px] font-bold  text-center  pb-4">Staking Setup:</p>
        <StakingSetup />
      </div>
      <div className="flex flex-col justify-center items-center font-bold pt-[8vh]">
        <h3>Your Current Profit Per Hour:</h3>
        <strong className="flex justify-center items-center gap-x-[0.286em] text-[24px] text-[#FFD600]">
          +
          <span className="size-[21px]">
            <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
          </span>
          {moneyFormat(isGameActive ? profitPerHour : profitPerHourToStaking || 0)} / hour
        </strong>
      </div>
    </div>
  );
}

const StakingSetup = () => {
  const exchange = useSelector(exchangeSelector);
  const totalBonusForPurchasedCards = useSelector(totalBonusForPurchasedCardsSelector);
  const totalBonusForPurchasedCardsToStaking = useSelector(totalBonusForPurchasedCardsToStakingSelector) || 0;
  const currentLeague = useSelector(currentLeagueSelector);
  const isGameActive = useSelector(isGameActiveSelector);

  const { showNotification } = useToastNotifications();

  const navigate = useNavigate();

  const navigateToSelectionEchange = () => {
    if (!isGameActive) {
      showNotification({
        title: "You can't change the exchange while staking is in progress!",
        type: ToastNotificationType.Info,
      });
    } else navigate("/selection-exchange");
  };

  const handleUpgratesRatio = () => {
    navigate("/cards");
  };

  const baseRatio = ((exchange?.ratio || 0) * (currentLeague?.rewardPortfolio || 0)) / 100;

  const totalStakingRatio = isGameActive
    ? +baseRatio + +totalBonusForPurchasedCards
    : +baseRatio + +totalBonusForPurchasedCardsToStaking;

  const items = [
    {
      icon: flyingSaucerIcon,
      title: "Staked Amount:",
      value: `${currentLeague?.rewardPortfolio}`,
      explanation: `${capitalizeFirstLetter(currentLeague?.title || "")} League Portfolio Reward`,
    },
    {
      icon: flyingSaucerIcon,
      title: "Base Ratio:",
      value: `+${exchange?.ratio}% / hour`,
      button: {
        icon: exchange?.iconUrl2,
        disabled: !isGameActive,
        handle: navigateToSelectionEchange,
      },
    },
    {
      icon: plantIcon,
      title: "Upgrades Ratio:",
      value: `+${moneyFormat(isGameActive ? totalBonusForPurchasedCards : totalBonusForPurchasedCardsToStaking)} / hour`,
      button: {
        text: "Upgrade",
        disabled: false,
        handle: handleUpgratesRatio,
      },
    },
    {
      icon: bagMoneyIcon,
      title: "Total Staking Ratio:",
      value: `+${moneyFormat(totalStakingRatio)} / hour`,
    },
  ] satisfies StakingSetupItemProps[];

  return (
    <div className="flex flex-col gap-4">
      {items.map((item, index) => (
        <StakingSetupItem item={item} key={index} />
      ))}
    </div>
  );
};

interface StakingSetupItemProps {
  icon: string;
  title: string;
  value: string;
  explanation?: string;
  button?: {
    icon?: string | null;
    text?: string;
    handle?: () => void;
    disabled?: boolean;
  };
}

const StakingSetupItem = ({ item }: { item: StakingSetupItemProps }) => {
  const { icon, title, value, explanation, button } = item;

  return (
    <div className="flex justify-between pl-6 pr-3 bg-white/15 rounded-xl py-3">
      <div className="flex items-center gap-[10px] ">
        <img src={icon} alt={title} className="size-6" />
        <div>
          <p className="font-bold">{title}</p>
          <p>
            <span className="font-bold text-[#FFD600] mr-1">{value}</span>
            {explanation && <span className="text-[12px]">({explanation})</span>}
          </p>
        </div>
      </div>{" "}
      {button && (
        <div className="w-[115px] h-[37px]">
          <button
            className="size-full flex justify-center items-center uppercase rounded-[32px]"
            style={{
              background: button.disabled ? "#808080" : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)",
            }}
            onClick={button.handle}
          >
            {button.icon && <ExternalImage src={button.icon} className="mr-[2px] h-[12px] " alt={button.text} />}
            {button.text && <p className="text-[12px] font-bold">{button.text}</p>}
          </button>
        </div>
      )}
    </div>
  );
};
