import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_COMBO_ENCRYPTION_KEY);

export function decryptComboKeys(encryptedComboKeys: string[]): string[] {
  return encryptedComboKeys.map((encryptedKey) => {
    const [encryptedStr, ivHex] = encryptedKey.split(":");
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encrypted = CryptoJS.enc.Base64.parse(encryptedStr);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: encrypted,
    });

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  });
}
