import { useSelector } from "react-redux";
import { userIdSelector } from "../../store/account/account";
import { useAppSelector } from "../../store/hooks";
import { Passenger } from "../../store/rocket/api/types";
import Chip from "../ui/Chip";

const PassengerItem: React.FC<{ passenger: Passenger }> = ({ passenger }) => {
  const { id, photo, name, deposite } = passenger;

  const rocket = useAppSelector((state) => state.rocket.rocket);

  const currentUserId = useSelector(userIdSelector);

  const userStatus: string =
    id === currentUserId && id === rocket?.ownerId
      ? "You Owner"
      : id === currentUserId
        ? "You"
        : id === rocket?.ownerId
          ? "Owner"
          : "";

  const chipBgColor: string =
    id === rocket?.ownerId ? "bg-purple-gradient" : id === currentUserId ? "bg-green-gradient" : "";

  const backgroundImage: string =
    id === rocket?.ownerId
      ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(72, 0, 143, 0.25) 0%, rgba(235, 0, 255, 0) 100%)"
      : id === currentUserId
        ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(1, 166, 97, 0.25) 0%, rgba(1, 166, 97, 0) 100%)"
        : "";

  return (
    <div
      className={
        "flex gap-1 items-center  rounded-[16px] block_info  hover:bg-[#4A315A] ease-in duration-300 border-item text-[14px] font-bold justify-between pr-3 bg-green-gradient-full"
      }
      style={{
        backgroundImage: backgroundImage,
      }}
    >
      <div className="flex items-center gap-[10px]">
        <div className="relative bg-lightPurple rounded-[12px] m-[3px]">
          <img src={photo} className=" rounded-[12px] w-[48px] h-[48px] bg-cover" />
        </div>
        <p>{name}</p>
      </div>
      <div className="flex items-center text-[16px]  w-[120px] justify-between">
        {userStatus.length > 0 ? <Chip text={userStatus} bgColor={chipBgColor} /> : <div></div>}
        <p>${deposite}</p>
      </div>
    </div>
  );
};

export default PassengerItem;
