import Decimal from "decimal.js";

export function calculatePercent(part: number, total: number, fractionDigits?: number): number {
  if (total === 0) {
    throw new Error("Total cannot be zero.");
  }

  const percentage = new Decimal(part).dividedBy(total).times(100);

  if (fractionDigits === undefined) {
    return percentage.toNumber();
  }

  const factor = new Decimal(10).pow(fractionDigits);
  return percentage.times(factor).floor().dividedBy(factor).toNumber();
}
