import { motion } from "framer-motion";
import arrowIcon from "../../assets/icons/switch-arrow.svg";
import { League } from "../../store/leagues/api/types";
import { useSelector } from "react-redux";
import { leaguesSelector } from "../../store/leagues/leaguesReducer";
import { useMemo, useCallback } from "react";

interface LeagueHeaderProps {
  league: League;
  handleChangeLeague: (id: number) => void;
}

const LeagueHeader: React.FC<LeagueHeaderProps> = ({ league, handleChangeLeague }) => {
  const leagues = useSelector(leaguesSelector);

  const handlePreviousLeague = useCallback(() => {
    handleChangeLeague(league.id - 1);
  }, [handleChangeLeague, league.id]);

  const handleNextLeague = useCallback(() => {
    handleChangeLeague(league.id + 1);
  }, [handleChangeLeague, league.id]);

  const isPreviousDisabled = useMemo(() => league.id <= 1, [league.id]);
  const isNextDisabled = useMemo(() => league.id >= leagues.length, [league.id, leagues.length]);

  return (
    <>
      <button onClick={handlePreviousLeague} disabled={isPreviousDisabled}>
        <img src={arrowIcon} alt="arrowIcon" className="rotate-180 w-6 h-6" />
      </button>

      <motion.img
        className="w-[200px] h-[200px] rounded-full object-cover object-center"
        alt={league.title}
        key={league.id}
        src={`${import.meta.env.VITE_API_BASE_URL}/${league.iconUrl}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        loading="eager"
      />

      <button onClick={handleNextLeague} disabled={isNextDisabled}>
        <img src={arrowIcon} alt="arrowIcon" className="w-6 h-6" />
      </button>
    </>
  );
};

export default LeagueHeader;
