import filterIcon from "../../assets/icons/filter.svg";

const SearchBar = ({}) => {
  return (
    <div className="h-[31px] flex justify-between mb-[15px]">
      <input
        type="text"
        placeholder="Search by name and friends..."
        className="h-full w-[90%] bg-transparent placeholder:italic placeholder:text-[#FFFFFF80] placeholder:tracking-wide placeholder:font-normal pb-[17px] border-solid border-b-[1px] border-[#735C80] outline-none"
      />
      <img src={filterIcon} className="w-5 h-5" />
    </div>
  );
};
export default SearchBar;
