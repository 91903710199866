import { Nft } from "../../store/cryptoProfile/types";
import { axiosInstance } from "../axios";
import { apiRoutes } from "../routes";
import { GetClaimRewardForTicketResponse } from "./types";

export async function claimRewardForNft(mintAddress: Nft["mintAddress"][]) {
  const response = await axiosInstance.post<GetClaimRewardForTicketResponse>(
    apiRoutes.nft.claimRewardForNft,
    mintAddress,
  );

  return response.data.data;
}
