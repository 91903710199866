import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Task } from "./api/types.ts";
import { fetchTasksListAction } from "./actions/fetchTasksListAction.ts";
import { Ad } from "../../api/tasks/types.ts";
import { fetchAdFeedAction } from "./actions/fatchAdTapListAction.ts";
import { registerAdClickAction } from "./actions/registerAdClickAction.ts";

export interface TasksState {
  pending: boolean;
  error: string | null;
  tasks: Task[];
  primeExpiryTime: number | null;
  okxDepositeExpiryTime: number | null;
  tapAds: Ad[];
  AdsTapClickloading: boolean;
}

const initialState: TasksState = {
  tasks: [],
  pending: false,
  error: null,
  primeExpiryTime: null,
  okxDepositeExpiryTime: null,
  tapAds: [],
  AdsTapClickloading: false,
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    changePrimeExpiryTime(state, action: PayloadAction<number | null>) {
      state.primeExpiryTime = action.payload;
    },
    changeOkxDepositeExpiryTime(state, action: PayloadAction<number | null>) {
      state.okxDepositeExpiryTime = action.payload;
    },
    claimReward(_, action: PayloadAction<Pick<Task, "id">>) {
      console.log("claimReward", action.payload.id);
    },
    startTask(_, action: PayloadAction<Pick<Task, "id">>) {
      console.log("startTask", action.payload.id);
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchTasksListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchTasksListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.tasks = action.payload.tasks;
      })
      .addCase(fetchTasksListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchAdFeedAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchAdFeedAction.fulfilled, (state, action) => {
        state.pending = false;
        state.tapAds = action.payload.ads;
        state.error = null;
      })
      .addCase(fetchAdFeedAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(registerAdClickAction.pending, (state) => {
        state.AdsTapClickloading = true;
        state.error = null;
      })
      .addCase(registerAdClickAction.fulfilled, (state) => {
        state.AdsTapClickloading = false;
      })
      .addCase(registerAdClickAction.rejected, (state, action) => {
        state.AdsTapClickloading = false;
        state.error = action.error.message || "Error registering ad click";
      });
  },
});

export const { claimReward, startTask, changePrimeExpiryTime, changeOkxDepositeExpiryTime } = tasksSlice.actions;

export const tasksSelector = (state: RootState) => state.tasks.tasks;
export const primeExpiryTimeSelector = (state: RootState) => state.tasks.primeExpiryTime;
export const OkxDepositeExpiryTimeSelector = (state: RootState) => state.tasks.okxDepositeExpiryTime;
export const tapAdsSelector = (state: RootState) => state.tasks.tapAds;

export default tasksSlice.reducer;
