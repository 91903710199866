import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsersInLeague } from "../../../api/user/getUsersInLeague";

interface FetchUsersInLeaguePayload {
  count: number;
  offset: number;
  league?: number;
}

export const fetchUsersInLeagueAction = createAsyncThunk(
  "leagues/fetchUsersInLeague",
  async (payload: FetchUsersInLeaguePayload) => {
    const { count, offset, league } = payload;
    return getUsersInLeague(count, offset, league);
  },
);
