import React from "react";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../assets/icons/arrow.svg";

interface ButtonBackProps {
  text: string;
  url: string;
}

const ButtonBack: React.FC<ButtonBackProps> = ({ text, url }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    console.log(url);
    navigate(url);
  };

  return (
    <button
      onClick={handleBack}
      className="w-[86px] h-[38px] rounded-[18px] bg-[#19141B] flex justify-center items-center gap-2"
    >
      <img src={arrowIcon} alt="back" />
      <span className="block text-[12px]">{text}</span>
    </button>
  );
};
export default ButtonBack;
