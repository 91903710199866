import { Note } from "../../../store/token/api/types";
import { GradientCard } from "../../ui/GradientCard";

import NoteActions from "./NoteActions";
import NoteInfo from "./NoteInfo";

const NotesItem = ({ note }: { note: Note }) => {
  const { disLike, like, id } = note;
  return (
    <GradientCard backgroundColor="#28202C">
      <div className={"flex flex-col relative pt-1 min-h-[88px] pb-3 pr-3 pl-1 rounded-[16px]  leading-none"}>
        <NoteInfo note={note} />
        <div className="pl-2 pt-[5px]">
          <hr className="border-solid border-[1px] border-white/10" />
          <NoteActions dislikes={disLike} likes={like} noteId={id} />
        </div>
      </div>
    </GradientCard>
  );
};

export default NotesItem;
