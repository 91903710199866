import { ReactNode } from "react";
import warningIcon from "../../assets/icons/warning.svg";
import warningBackground from "../../assets/img/gradiend-warning.png";

interface WarningBlockProps {
  children: ReactNode;
}
const WarningBlock: React.FC<WarningBlockProps> = ({ children }) => {
  return (
    <div className=" relative flex h-[56px]  rounded-2xl">
      <img src={warningBackground} alt="warningIcon" className="absolute inset-0 rounded-2xl" />
      <div className="z-10 ml-4 flex items-center justify-between w-full gap-2">
        <img src={warningIcon} alt="warningIcon" className="size-6" />
        {children}
      </div>
    </div>
  );
};

export default WarningBlock;
