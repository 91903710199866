import { useState } from "react";
import { BottomSheet } from "../ui/BottomSheet.tsx";
import Title from "../ui/Title.tsx";
import Button from "../ui/Button.tsx";

export function RegistrationRules() {
  const [isOpened, setIsOpened] = useState(false);
  const rules = [
    // {
    //   text: "Once you register and complete the KYC process, you will receive the $600 welcome voucher in your  app in real time.",
    // },
    {
      text: "<span class='font-bold text-[#ff5858]'>Attention!</span> Only new users who register during the initial task step 1 are eligible to input their UID in step 2. ",
    },
    {
      text: "<span class='underline'>OrbitonX does not access to any exchange data!</span>",
    },
    {
      text: "After the completion of the mission, all data will be checked for validity. If it is not valid, Shares reward will be removed.  ",
    },
  ];
  return (
    <div className="flex justify-center ">
      <button type="button" onClick={() => setIsOpened(true)}>
        <p className="text-[#A98DBD] underline text-center pt-4 text-[16px] leading-6 text-white/75 mb-6">
          Registration Rules
        </p>
      </button>

      <BottomSheet isOpened={isOpened} onClose={() => setIsOpened(false)}>
        <div className="mt-3">
          <Title text="Registration Rules" />
          <div className="px-[10px] flex flex-col gap-7 text-white/75 pb-6">
            {rules.map((rule, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: rule.text }} />
            ))}
          </div>
          <Button
            text="ok"
            handleClick={() => setIsOpened(false)}
            style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          />
        </div>
      </BottomSheet>
    </div>
  );
}
