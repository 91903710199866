import { createAsyncThunk } from "@reduxjs/toolkit";
import { claimRewardForNft } from "../../../api/nft/claimRewardForNft.ts";
import { Nft } from "../types.ts";

export const fetchRewardForNftAction = createAsyncThunk(
  "account/fetchRewardForNft",
  async (mintAddress: Nft["mintAddress"][]) => {
    return claimRewardForNft(mintAddress);
  },
);
