import { useSelector } from "react-redux";
import {
  adNextAvailableTimeSelector,
  adNextAvailableTimeTwentySelecor,
  adTwentyCountSelecor,
  adTwentyRewardTakenSelector,
} from "../../store/account/account";
import { useEffect, useRef, useState } from "react";
import { ClickerGameTimerRef } from "../Clicker/GameTimer";
import { calculateTimeRemaining } from "../../helpers/calculateTimeRemaining";
import { fetchRewardForTaskAdsAction } from "../../store/account/actions/fetchRewardForTaskAdsAction";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer";
import { GradientCard } from "../ui/GradientCard";
import playIcon from "../../assets/img/play.png";
import { Timer } from "../ui/Timer";
import { useToastNotifications } from "../../hooks/useToastNotifications";
import { useAppDispatch } from "../../store/hooks";
import React from "react";
import TadsWidgetTask from "./TadsWidgetTask";
import { fetchAdTicketRewardAction } from "../../store/account/actions/fetchAdTicketReward";
import { referralsTicketSelector } from "../../store/cards/cardsReducer";

const ADSGRAM_BLOCK_ID = import.meta.env.VITE_TADSWIDGET_BLOCK_ID;
const ADSGRAM_BLOCK_ID2 = import.meta.env.VITE_TADSWIDGET_BLOCK_ID2;

export interface ShowPromiseResult {
  done: boolean; // true if user watch till the end, otherwise false
  description: string; // event description
  state: "load" | "render" | "playing" | "destroy"; // banner state
  error: boolean; // true if event was emitted due to error, otherwise false
}

const AdsTasks = React.memo(() => {
  const adNextAvailable = useSelector(adNextAvailableTimeSelector);
  const adNextAvailableTimeTwenty = useSelector(adNextAvailableTimeTwentySelecor);
  const adTwentyCount = useSelector(adTwentyCountSelecor);
  const adTwentyRewardTaken = useSelector(adTwentyRewardTakenSelector);
  const reftickets = useSelector(referralsTicketSelector);

  const [isAdsActive, setIsAdsActive] = useState(adNextAvailable === null || new Date(adNextAvailable) < new Date());
  const [AdController, setAdController] = useState<{
    show: () => Promise<ShowPromiseResult>;
    load: () => boolean;
  } | null>(null);
  const [isBannerAdsgram, setIsBannerAdsgram] = useState<boolean>(false);
  const [isClaimTicketAvailable, setIsClaimTicketAvailable] = useState(false);

  const { showNotification } = useToastNotifications();
  const dispatch = useAppDispatch();

  const timerRef = useRef<ClickerGameTimerRef>(null);
  const [timeRemainingTwenty, setTimeRemainingTwenty] = useState(() =>
    adNextAvailableTimeTwenty ? calculateTimeRemaining(adNextAvailableTimeTwenty) : -1,
  );

  useEffect(() => {
    setTimeRemainingTwenty(adNextAvailableTimeTwenty ? calculateTimeRemaining(adNextAvailableTimeTwenty) : -1);
    setIsAdsActive(adNextAvailable === null || new Date(adNextAvailable) < new Date());
  }, [adNextAvailable, adNextAvailableTimeTwenty]);

  useEffect(() => {
    const checkBannerAvailability = async () => {
      try {
        const blockId = import.meta.env.VITE_ADSGRAM_BLOCK_ID;
        const controller = window.Adsgram.init({ blockId: blockId });
        setAdController(controller);

        if (controller) {
          const loadPromise = await controller.load();
          setIsBannerAdsgram(loadPromise);
        } else {
          console.error("AdController is not initialized.");
        }
      } catch (error) {
        console.error("Error checking banner availability:", error);
      }
    };

    checkBannerAvailability();
  }, []);

  useEffect(() => {
    if (adTwentyCount > 0 && adTwentyCount % 10 === 0 && !adTwentyRewardTaken) {
      setIsClaimTicketAvailable(true);
    } else {
      setIsClaimTicketAvailable(false);
    }
  }, [adTwentyCount, adTwentyRewardTaken, reftickets]);

  const adsgramShow = () => {
    if (isClaimTicketAvailable) {
      showNotification({
        title: "Claim your reward",
        type: ToastNotificationType.Error,
      });
      return;
    }

    if (timeRemainingTwenty > 0) {
      console.log("Ads are on cooldown, skipping ad display.");
      return;
    }

    if (isAdsActive) {
      if (AdController) {
        AdController.show()
          .then((result: ShowPromiseResult) => {
            if (result.done) {
              dispatch(fetchRewardForTaskAdsAction("special-ads"));
              showNotification({
                title: "You've been watched video",
                type: ToastNotificationType.Success,
                timeoutMs: 2000,
              });
            }
          })
          .catch((result: ShowPromiseResult) => {
            console.error("Error or user skipped the ad", result);
          });
      } else {
        console.error("AdController is not initialized");
      }
    } else {
      console.log("Ads are not active");
    }
  };
  const handleClaimTicket = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isClaimTicketAvailable && !adTwentyRewardTaken) {
      dispatch(fetchAdTicketRewardAction());
    } else {
      console.log("Claim ticket button is not active");
    }
  };

  const displayAdTwentyCount = (() => {
    if (adTwentyCount === 0) {
      return "0/10";
    }
    if (adTwentyCount === 10 && adTwentyRewardTaken) {
      return "0/10";
    }
    if (adTwentyCount === 10 || adTwentyCount === 20) {
      return "10/10";
    }
    return `${adTwentyCount % 10}/10`;
  })();

  return (
    <div className="pb-[14px]">
      <h2 className="text-[16px] font-semibold opacity-75 text-center">Ads Rewards</h2>
      <div className="mt-[10px] flex flex-col gap-2">
        <TadsWidgetTask pageName="tasks" blockId={ADSGRAM_BLOCK_ID2} reward={false} />
        {isAdsActive && <TadsWidgetTask pageName="tasks" blockId={ADSGRAM_BLOCK_ID} reward={true} />}
        {isBannerAdsgram && (
          <GradientCard backgroundColor="#28202C">
            <button
              type="button"
              className="flex-1 p-1 w-full flex gap-x-3 items-center pr-3"
              onClick={() => adsgramShow()}
            >
              <span className="flex grow gap-x-3 items-center">
                <span className="h-[56px] w-[56px] relative bg-white/25 rounded-xl overflow-hidden flex justify-center items-center">
                  <img
                    loading="lazy"
                    className="size-[28px] object-cover object-center"
                    src={playIcon}
                    alt="Task icon"
                  />
                </span>

                <span className="flex-1">
                  <span className="text-left text-[16px] font-medium block break-all capitalize">
                    Watch and Earn $RCT
                  </span>
                  <span
                    className="text-left text-sm block"
                    style={{
                      width: "73px",
                      height: "10px",
                      left: "72px",
                      top: "180px",
                      fontFamily: "Inter, sans-serif",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: isClaimTicketAvailable ? "#00D254" : "#FFFFFF",
                    }}
                  >
                    seen {displayAdTwentyCount}
                  </span>
                </span>
              </span>

              {timeRemainingTwenty > 0 ? (
                <div>
                  <Timer
                    ref={timerRef}
                    remainingTime={timeRemainingTwenty}
                    style="text-white text-[16px] font-semibold"
                    onEnd={() => {
                      setIsAdsActive(true);
                    }}
                  />
                  <span className="block text-white text-[12px]">Next videos</span>
                </div>
              ) : (
                <button
                  type="button"
                  className={`text-white ${isClaimTicketAvailable ? "" : "cursor-not-allowed"}`}
                  style={{
                    width: "84px",
                    height: "24px",
                    left: "265px",
                    top: "164px",
                    backgroundColor: isClaimTicketAvailable && !adTwentyRewardTaken ? "#00D254" : "#5C565F",
                    borderRadius: "16px",
                    padding: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Inter, sans-serif",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "12px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                  }}
                  onClick={handleClaimTicket}
                  disabled={!isClaimTicketAvailable || adTwentyRewardTaken}
                >
                  Claim ticket
                </button>
              )}
            </button>
          </GradientCard>
        )}
      </div>
    </div>
  );
});

export default AdsTasks;
