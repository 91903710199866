import React, { ReactNode, useMemo, useRef } from "react";
import { ClickAllowedStatus } from "../../store/clicker/types.ts";
import { CircleCount, CounterRef } from "./CircleCounter.tsx";

export interface CoinClickProps {
  children: ReactNode;
  isLocked?: boolean;
  className?: string;
  cost: number;

  onPointerDown: (event: React.PointerEvent<HTMLElement>) => ClickAllowedStatus;
  onPointerUp: (event: React.PointerEvent<HTMLElement>) => void;
  onPointerCancel: (event: React.PointerEvent<HTMLElement>) => void;
}

const randomOffset = () => Math.random() * 40 - 2;

export function CoinClickArea({
  isLocked,
  children,
  cost,
  onPointerCancel,
  onPointerUp,
  onPointerDown,
  className = "",
}: CoinClickProps) {
  const counterRef = useRef<HTMLSpanElement>(null);
  const circlesCounterRef = useRef<CounterRef>(null);

  function appendNumber() {
    if (!counterRef.current) return;

    const el = document.createElement("span");

    el.innerHTML = `+${cost}`;
    el.style.animation = "numberScale 1s linear forwards";
    el.style.position = "absolute";
    el.style.letterSpacing = "0";
    el.style.top = "0";
    el.style.top = `-10px`;

    el.style.left = `${randomOffset()}%`;

    const handleAnimationEnd = () => {
      el.removeEventListener("animationend", handleAnimationEnd);
      el.remove();
    };

    el.addEventListener("animationend", handleAnimationEnd);

    counterRef.current.appendChild(el);
  }

  const handleCoinClick = useMemo(() => {
    if (isLocked) {
      return {};
    }

    return {
      onPointerDown: (event: React.PointerEvent<HTMLElement>) => {
        const { isAllowed } = onPointerDown(event);
        if (isAllowed) {
          appendNumber();
        }

        if (!isAllowed) {
          circlesCounterRef.current?.applyCircle("fail");
        } else {
          circlesCounterRef.current?.applyCircle("success");
        }
      },
      onPointerUp,
      onPointerCancel,
    };
  }, [isLocked]);

  return (
    <button className={`${className} relative`} {...handleCoinClick}>
      <CircleCount ref={circlesCounterRef} />
      <span ref={counterRef} className="absolute z-20 left-0 top-0 size-full text-[60px] font-extrabold" />
      <span className={`${className} relative z-10`}>{children}</span>
    </button>
  );
}
