import { moneyFormat } from "../../../../helpers/moneyFormat";
import { TradeType } from "./FilterTrade";

const TradeInfo = ({ activeTradeType }: { activeTradeType: TradeType }) => {
  return <> {activeTradeType === TradeType.BUY ? <BuyInfo /> : <SellInfo />}</>;
};

const BuyInfo = () => {
  return (
    <div>
      <div className="flex gap-2 w-full">
        <Block
          value={moneyFormat(1000000000)}
          text="Max Supply"
          className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
        />
        <Block
          value={moneyFormat(432944548)}
          text="Available to buy"
          className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
        />
      </div>
    </div>
  );
};

const SellInfo = () => {
  const percentageChanges = [
    { period: "5m", percentage: 0.0 },
    { period: "15m", percentage: 3.18 },
    { period: "1h", percentage: -5.79 },
    { period: "24h", percentage: -2.75 },
  ];
  return (
    <div>
      <div className=" w-full rounded-2xl bg-black/25 uppercase px-3 py-3">
        <div className="flex justify-between pb-[14px]  border-solid border-b-[2px] border-white/10">
          <Block
            value={moneyFormat(1000000000)}
            text="Max Supply"
            className="w-1/2 border-solid border-r-[2px] border-white/10"
          />
          <Block value={moneyFormat(432944548)} text="Available to buy" className="w-1/2 pl-3" />
        </div>
        <div className="flex justify-between pt-[14px]">
          <Block value="$11.6K" text="MKT KAP" className="border-solid border-r-[2px] border-white/10 pr-[5vw]" />
          <Block
            value="$5.9K"
            text="Liquidity"
            className="border-solid border-r-[2px] border-white/10 pr-[5vw] pl-[5vw]"
          />
          <Block value="521/1000" text="TON Collected" className="pl-[5vw] " />
        </div>
      </div>

      <PercentageChangeBlock changes={percentageChanges} />
    </div>
  );
};

interface PercentageChange {
  period: string;
  percentage: number;
}

interface PercentageChangeBlockProps {
  changes: PercentageChange[];
}

const PercentageChangeBlock: React.FC<PercentageChangeBlockProps> = ({ changes }) => {
  const getColor = (percentage: number) => {
    if (percentage >= 0) return "text-coralRed";
    if (percentage < 0) return "text-seaGreen";
  };

  return (
    <div className="w-full rounded-2xl bg-black/25 uppercase mt-3 py-3 leading-none">
      <ul className="flex justify-center items-center text-center">
        {changes.map(({ period, percentage }, index) => (
          <li
            key={period}
            className={`flex flex-col justify-between gap-[14px] px-[4.5vw] ${index < changes.length - 1 ? "border-solid border-r-[2px] border-white/10 " : ""}`}
          >
            <span className="font-semibold text-[12px]">{period}</span>
            <span className={`${getColor(percentage)} font-bold`}>{percentage.toFixed(2)}%</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Block = ({ text, value, className }: { text: string; value: string; className?: string }) => {
  return (
    <div className={`flex flex-col gap-[14px]    leading-none  ${className}`}>
      <p className="font-semibold text-lavenderGray">{text}</p>
      <p className="font-bold">{value}</p>
    </div>
  );
};

export default TradeInfo;
