import { Note } from "../../store/token/api/types";
import { axiosInstance } from "../axios";
import { apiRoutes } from "../routes";
import { ApiResponse } from "../types";
import { CreateNote } from "./types";

export async function createNote(data: CreateNote, first_name: string, photo_url: string): Promise<Note> {
  try {
    const response = await axiosInstance.post<ApiResponse<Note>>(apiRoutes.communityNotes.createNote, data);

    const note: Note = { user: { first_name, photo_url }, ...response.data.data };
    return note;
  } catch (error) {
    console.error("Error creating note:", error);
    throw error;
  }
}
