import { Holder } from "../../../api/holders/types";
import Title from "../../ui/Title";
import HoldersItem from "./HoldersItem";

const HoldersList = ({ holders }: { holders: Holder[] }) => {
  return (
    <>
      {holders && (
        <div className="pt-6">
          <Title text="Holders" />
          <div className="flex flex-col gap-2">
            {holders.map((holder, index) => (
              <HoldersItem key={index} holder={holder} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default HoldersList;
