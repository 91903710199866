import StatusRound from "../Round/StatusRound";
import BlockInfo from "../ui/BlockInfo";
import Title from "../ui/Title";
import ProfileRocketImages from "./ProfileRocketImages";
import RocketMetrics from "./RocketMetrics";
import { useAppSelector } from "../../store/hooks";

import passengersIcon from "../../assets/icons/passengers.svg";
import leaderIcon from "../../assets/icons/leader.svg";
import performanceIcon from "../../assets/icons/performance.svg";
import upIcon from "../../assets/icons/up.svg";
import { useSelector } from "react-redux";
// import { userIdSelector } from "../../store/account/account";
import { RootState } from "../../store/store";
import { otherInvestStore, ownerInvestStore, yourInvestStore } from "../../store/rocket/rocketReducer";

const MainRocketBlock: React.FC = () => {
  const rocket = useAppSelector((state) => state.rocket.rocket);
  const round = useAppSelector((state) => state.round.round);

  // const currentUserId = useSelector(userIdSelector);

  const yourInvest = useSelector((state: RootState) => yourInvestStore(state, "jjjj"));
  const ownerInvest = useSelector((state: RootState) => ownerInvestStore(state, "jjjj"));
  const otherInvest = useSelector((state: RootState) => otherInvestStore(state, "jjjj"));

  const percentOfYourInvest = (100 * yourInvest) / (round?.buyIn || 0);
  const percentOfOtherInvest = (100 * otherInvest) / (round?.buyIn || 0);
  const percentOfOwnerInvest = (100 * ownerInvest) / (round?.buyIn || 0);
  const fullnessValue = percentOfYourInvest + percentOfOtherInvest + percentOfOwnerInvest;
  return (
    <div className="w-full">
      <div className="w-full flex justify-center items-center gap-[23px] mb-[17px]">
        <ProfileRocketImages />
      </div>
      <Title text={rocket?.name || ""} />
      {round?.status === "running" ? (
        <div className="grid grid-cols-2 grid-rows-1 gap-1 mb-[15px]">
          <div className="row-span-1 col-span-1">
            <BlockInfo
              icon={performanceIcon}
              text={
                <div className="flex gap-[6px]">
                  <p>Performance</p>
                  <div className="flex gap-1 text-green font-bold">
                    <img src={upIcon} className={"w-[7px] inline"} />
                    <span>+{rocket?.percentage_profit}</span>
                  </div>
                </div>
              }
              textSize="16"
              value={
                <div className="flex gap-[12px] text-green font-bold">
                  <p>+{rocket?.profit}</p>
                </div>
              }
            />
          </div>
          <div className="row-span-1 col-span-1">
            <StatusRound time={round?.time || ""} status="playing" width="100%" textSize="20" />
          </div>
          <div className="row-span-1 col-span-1">
            <BlockInfo icon={leaderIcon} text="Place in the rating" value={12} textSize="16" />
          </div>
          <div className="row-span-1 col-span-1">
            <BlockInfo icon={passengersIcon} text="Passengers" value={rocket?.passengers?.length} textSize="16" />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 grid-rows-1 gap-1 mb-[15px]">
          <div className="row-span-1 col-span-1">
            <BlockInfo icon={passengersIcon} text="Passengers" value={rocket?.passengers?.length} textSize="16" />
          </div>
          <div className="row-span-1 col-span-1">
            <StatusRound
              time={round?.time || ""}
              status={fullnessValue === 100 ? "ready" : round?.status || "planned"}
              width="100%"
              textSize="20"
            />
          </div>
        </div>
      )}

      <RocketMetrics
        min_deposite={rocket?.min_deposite || 0}
        buyIn={round?.buyIn || 0}
        yourInvest={yourInvest}
        otherInvest={otherInvest}
        ownerInvest={ownerInvest}
      />
    </div>
  );
};

export default MainRocketBlock;
