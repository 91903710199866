import { Note } from "../../store/token/api/types.ts";
import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";

export async function getNotesForToken(id: string | number) {
  const response = await axiosInstance.get<ApiResponse<Note[]>>(
    `${apiRoutes.communityNotes.getNotesForToken}?id=${id}`,
  );
  return response.data.data;
}
