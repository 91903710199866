import { forwardRef, useImperativeHandle, useRef } from "react";

interface CircleCounterProps {
  className?: string;
}

export interface CounterRef {
  applyCircle: (type?: "success" | "fail") => void;
}

export const CircleCount = forwardRef<CounterRef, CircleCounterProps>(({ className }, ref) => {
  const circlesRootRef = useRef<HTMLSpanElement>(null);
  const count = useRef(0);

  useImperativeHandle(ref, () => ({
    applyCircle(type = "success") {
      if (circlesRootRef.current) {
        count.current += 1;

        const el = document.createElement("span");

        el.className = `absolute top-1/2 left-1/2 rounded-full bg-opacity-10 z-0 ${type === "success" ? "bg-white" : "bg-red"}`;
        el.style.animation = "bounceCircle 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards";
        el.style.width = `${80 + count.current * 4}%`;
        el.style.height = `${80 + count.current * 4}%`;
        el.style.transformOrigin = "left top";

        el.addEventListener("animationend", () => {
          count.current -= 1;
          el.remove();
        });

        circlesRootRef.current.appendChild(el);
      }
    },
  }));

  return <span ref={circlesRootRef} className={className} />;
});
