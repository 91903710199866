import { GradientCard } from "../ui/GradientCard";
import playIcon from "../../assets/img/play.png";
import { useAppDispatch } from "../../store/hooks";
import React, { useEffect, useRef, useState } from "react";
import { fetchAdFeedAction } from "../../store/tasks/actions/fatchAdTapListAction";
import { useSelector } from "react-redux";
import { adTapDateSelector, tgIdSelector, userTappAdsrSelector } from "../../store/account/account";
import { fetchTapTaskRewardAction } from "../../store/cards/actions/fetchTapTaskReward";
import { calculateTimeRemaining } from "../../helpers/calculateTimeRemaining";
import { Timer } from "../ui/Timer";
import { ClickerGameTimerRef } from "../Clicker/GameTimer";
import { fetchUserTappAdsAction } from "../../store/account/actions/fetchUserTappAdsAction";
import { UserTappAds } from "../../api/user/types";

interface TapAddsTasksProps {
  onOpenPopup: () => void;
}

const TapAddsTasks: React.FC<TapAddsTasksProps> = React.memo(({ onOpenPopup }) => {
  const userId = useSelector(tgIdSelector);
  const adTapDate = useSelector(adTapDateSelector);
  const userTappAds = useSelector(userTappAdsrSelector);

  const [timeRemaining, setTimeRemaining] = useState(() =>
    adTapDate ? calculateTimeRemaining(adTapDate) : -1
  );
  const [tapTaskCounter, setTapTaskCounter] = useState<number>(0);
  
  const dispatch = useAppDispatch();
  const [isClaimTicketAvailable, setIsClaimTicketAvailable] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchUserTappAdsAction());
  }, []);
  
  useEffect(() => {
    setTimeRemaining(adTapDate ? calculateTimeRemaining(adTapDate) : -1);
  }, [adTapDate]);

  useEffect(() => {
    if (!userTappAds) {
        console.log("userTappads is undefined or not yet loaded");
        return;
    }
    const tapTaskCounter = userTappAds.filter((ad: UserTappAds) => ad.status === 'completed').length;
    setTapTaskCounter(tapTaskCounter);
  }, [userTappAds]);


  const timerRef = useRef<ClickerGameTimerRef>(null);

  const handleWatchTapAdds = () => {
    if (userId !== null) {
      const numericUserId = typeof userId === 'string' ? parseInt(userId, 10) : userId;
      dispatch(fetchAdFeedAction({ userId: numericUserId }));
      if(tapTaskCounter >= 3){
        return
      }
      onOpenPopup();
    } else {
      console.error("User ID is null, cannot dispatch action.");
    }
  };

  useEffect(() => {
    if (tapTaskCounter > 0 && tapTaskCounter % 3 === 0) {
      setIsClaimTicketAvailable(true);
    } else {
      setIsClaimTicketAvailable(false);
    }
  }, [tapTaskCounter]);

  const handleClaimTicket = (event: React.MouseEvent) => {
    event.stopPropagation();
    if ( tapTaskCounter=== 3) {
      dispatch(fetchTapTaskRewardAction());
    } else {
      console.log("Claim ticket button is not active");
    }
  };

  return (
    <div className="pb-[14px]">
      <h2 className="text-[16px] font-semibold opacity-75 text-center">TappAds Rewards</h2>
      <div className="mt-[10px] flex flex-col gap-2">
          <GradientCard backgroundColor="#28202C">
            <button
              type="button"
              className="flex-1 p-1 w-full flex gap-x-3 items-center pr-3"
              onClick={handleWatchTapAdds}
              disabled={timeRemaining > 0 ? true : false}
            >
              <span className="flex grow gap-x-3 items-center">
                <span className="h-[56px] w-[56px] relative bg-white/25 rounded-xl overflow-hidden flex justify-center items-center">
                  <img
                    loading="lazy"
                    className="size-[28px] object-cover object-center"
                    src={playIcon}
                    alt="Task icon"
                  />
                </span>

                <span className="flex-1">
                  <span className="text-left text-[16px] font-medium block break-all capitalize">
                  Watch And Earn
                  </span>
                  <span 
                    className="text-left text-sm block"
                    style={{
                      width: '73px',
                      height: '10px',
                      left: '72px',
                      top: '180px',
                      fontFamily: 'Inter, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '16px',
                      color: isClaimTicketAvailable ? '#00D254' : '#FFFFFF',
                    }}
                  >
                    seen {`${tapTaskCounter}/3`}
                  </span>
                </span>
              </span>
              {timeRemaining > 0 ? (
                <div>
                  <Timer
                    ref={timerRef}
                    remainingTime={timeRemaining}
                    style="text-white text-[16px] font-semibold"
                  />
                  <span className="block text-white text-[12px]">Next tasks</span>
                </div>
              ) : (
                <button
                  type="button"
                  className={`text-white ${isClaimTicketAvailable ? '' : 'cursor-not-allowed'}`}
                  style={{
                    width: '84px',
                    height: '24px',
                    left: '265px',
                    top: '164px',
                    backgroundColor: isClaimTicketAvailable ? '#00D254' : '#5C565F',
                    borderRadius: '16px',
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Inter, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '24px',
                    color: '#FFFFFF',
                  }}
                  onClick={handleClaimTicket}
                  disabled={!isClaimTicketAvailable}
                >
                  Claim ticket
                </button>
              )}
            </button>
          </GradientCard>
      </div>
    </div>
  );
});

export default TapAddsTasks;