import { useSelector, useStore } from "react-redux";
import { RootState } from "../store/store.ts";
import { track, identify, Identify } from "@amplitude/analytics-browser";
import { absoluteBalanceSelector, balanceSelector } from "../store/account/account.tsx";

export const enum DataLayerEventNames {
  launchFirstTime = "launch_first_time",
  sessionStart = "session_start",
  onboardingStarted = "onboarding_start",
  onboardingPassed = "onboarding_passed",
  demoPortfolioCompleted = "demo_portfolio_completed",
  portfolioCompleted = "portfolio_completed",
  questCompleted = "quest_completed",
  boostPurchased = "boost_purchased",
  refLinkSharedClick = "ref_link_shared",
  cardPurchased = "card_purchased",
  tapTipPassed = "tap_tip_passed",
  skillsTipPassed = "skills_tip_passed",
  cardsTipPassed = "cards_tip_passed",
}

export function useDataLayer() {
  const store = useStore<RootState>();
  const balance = useSelector(balanceSelector);
  const absoluteBalance = useSelector(absoluteBalanceSelector);
  return {
    trackEvent<Properties extends object>(eventName: DataLayerEventNames, properties?: Properties) {
      const userAnalyticsData: Record<string, any> = store.getState().account.analyticsData || {};
      const allProperties: Record<string, any> = {
        ...userAnalyticsData,
        ...(properties || {}),
        rct_current_balance: balance,
        rct_overall_balance: absoluteBalance,
      };

      // Track event in Google Analytics
      gtag("event", eventName, allProperties);

      // Identify user properties in Amplitude
      const identifyObj = new Identify();
      for (const key in allProperties) {
        if (allProperties.hasOwnProperty(key)) {
          identifyObj.set(key, allProperties[key]);
        }
      }
      identify(identifyObj);

      // Track event in Amplitude with event properties
      track(eventName);
    },
  };
}
