import { useParams } from "react-router-dom";
import ButtonBack from "../../components/ui/ButtonBack";
import RocketsFilter from "../../components/Rocket/RocketsFilter";
import CoinList from "../../components/Coin/CoinList";
import { useEffect, useState } from "react";
import { setStatusGetInRocket } from "../../store/menu/bottomMenuReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setRocket, isPassenger } from "../../store/rocket/rocketReducer";

import friendsIcon from "../../assets/icons/users-white.svg";
import PassengersList from "../../components/Passenger/PassengersList";
import MainRocketBlock from "../../components/Rocket/MainRocketBlock";
import { setRound } from "../../store/round/roundReducer";
import ModalGetInRocket from "../../components/Rocket/GetInRocket";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
// import { userIdSelector } from "../../store/account/account";

const RocketPage = ({}) => {
  const { roundId, rocketId } = useParams();
  const round = useAppSelector((state) => state.round.round);
  // const rocket = useAppSelector((state) => state.rocket.rocket);
  const [activeTab, setActiveTab] = useState<string>("portfolio");

  const handleSelectLimit = (tab: string) => {
    console.log("Selected limit:", tab);
    setActiveTab(tab);
  };

  const dispatch = useAppDispatch();

  // const currentUserId = useSelector(userIdSelector);

  const modalRocketVisible = useAppSelector((state) => state.rocket.modalRocket);
  const isUserPassenger = useSelector((state: RootState) => isPassenger(state, "jjjj"));

  useEffect(() => {
    if (!round) {
      dispatch(setRound(roundId));
    }
  }, []);

  useEffect(() => {
    if (round?.status === "registering") dispatch(setStatusGetInRocket(true));
    const rocket = round?.rockets.find((rocket) => rocket.id == rocketId);
    dispatch(setRocket(rocket));

    return () => {
      dispatch(setStatusGetInRocket(false));
    };
  }, [round]);

  return (
    <div className="w-full">
      <div className="bg-[#00000040] mx-[-16px]">
        <div className="px-4">
          <div className="relative">
            <div className="absolute top-[25px]">
              <ButtonBack text="Round" url={`/rounds/${round?.id}`} />
            </div>

            <MainRocketBlock />
          </div>
          {isUserPassenger && round?.status != "running" && (
            <div className="text-center flex justify-center w-full mb-[10px]">
              <button
                className="uppercase font-bold px-4 py-[9px] rounded-[32px] text-[12px] tracking-[0.02em] flex gap-2 items-center"
                style={{
                  background: "linear-gradient(89.51deg, #FFAE34 -9.17%, #F37E3B 98.77%)",
                }}
              >
                <img src={friendsIcon} className="w-[16px] h-[16px]" alt="friends" />
                <span>Invite Friends</span>
              </button>{" "}
            </div>
          )}

          <RocketsFilter onSelectLimit={handleSelectLimit} activeTab={activeTab} />
        </div>
      </div>
      <div className="mb-[160px]">
        {activeTab === "portfolio" && <CoinList />}
        {activeTab === "passengers" && <PassengersList />}
      </div>
      {/* {modalRocketVisible && <Modal />} */}
      {modalRocketVisible && <ModalGetInRocket />}
    </div>
  );
};
export default RocketPage;
