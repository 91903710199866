import { useSelector } from "react-redux";
import Title from "../../components/ui/Title";
import balanceIcon from "../../assets/icons/balance.svg";
import ExchangeTasksBlock from "../../components/Exchange/ExchangeTasksBlock";
import { RegistrationRules } from "../../components/Exchange/RegistrationRules";
import { GradientCard } from "../../components/ui/GradientCard";
import { moneyFormat } from "../../helpers/moneyFormat";
import ExchangeRegistrationCheck from "../../components/Exchange/ExchangeRegistrationCheck";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import { exchangesSelector } from "../../store/exchanges/exchangesReducer";

const ExchangeRegistrationPage = () => {
  const dispatch = useAppDispatch();
  const exchanges = useSelector(exchangesSelector);

  const exchange = exchanges.find((exchange) => exchange.title === "OKX");

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));

    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    exchange && (
      <div className={` relative ${isIOS ? "pb-[300px]" : ""}`}>
        <div className="pb-4">
          <Title text={`Create Your <br/> ${exchange?.title} Account`} style="leading-[24px]" />
        </div>
        <ExchangeTasksBlock exchange={exchange} />
        <RegistrationRules />
        <GradientCard backgroundColor="#28202C">
          <div className="flex-1 py-6 w-full flex gap-y-1 justify-between items-center leading-none px-3">
            <p className="text-[16px] font-medium">Registration Reward</p>
            <strong className="flex justify-center items-center gap-x-[0.286em] text-[22px] text-[#FFD600]">
              <span className="size-[17px]">
                <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
              </span>
              {moneyFormat(3000)}
            </strong>
          </div>
        </GradientCard>

        <ExchangeRegistrationCheck exchange={exchange} />
      </div>
    )
  );
};

export default ExchangeRegistrationPage;
