import { Task } from "../../store/tasks/api/types";
import { axiosInstance } from "../axios";
import { apiRoutes } from "../routes";

export async function startTask(taskId: Task["id"]) {
  return (
    await axiosInstance.post(apiRoutes.tasks.getTasksList, {
      taskId,
    })
  ).data.data;
}
