import { useEffect, useState } from "react";
import { BottomSheet } from "../ui/BottomSheet.tsx";
import { useSelector } from "react-redux";
import Button from "../ui/Button.tsx";
import balanceIcon from "../../assets/icons/balance.svg";
import purchaseCoinsIcon from "../../assets/icons/purchase-coins.png";
import { moneyFormat } from "../../helpers/moneyFormat.ts";
import { useAppDispatch } from "../../store/hooks.ts";
import { serviceBonusSelector } from "../../store/account/account.tsx";
import { fetchServiceBonusAction } from "../../store/account/actions/fetchServiceBonusAction.ts";

export function PopupCompensation() {
  const serviceBonus = useSelector(serviceBonusSelector);
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState(false);

  const handleGetServiceBonus = () => {
    dispatch(fetchServiceBonusAction()).then(() => {
      setIsOpened(false);
    });
  };

  useEffect(() => {
    console.log("serviceBonus", serviceBonus);

    if (serviceBonus > 0) {
      setIsOpened(true);
    }
  }, [serviceBonus]);

  return (
    <div className="flex flex-col justify-center leading-none">
      <BottomSheet isOpened={isOpened} onClose={() => setIsOpened(false)}>
        <div className="absolute top-[-100px]  left-1/2 -translate-x-1/2 ">
          <img
            className="size-[200px] object-contain  z-10"
            loading="lazy"
            src={purchaseCoinsIcon}
            alt="purchaseCoinsIcon"
          />
        </div>
        <div className="text-center pt-3 leading-none ">
          <div className="mb-6">
            <h1 className="text-[24px] font-bold mb-6 leading-none">System Reward</h1>
            <p className="text-[16px] leading-none mb-5">Thank you for your patience!</p>
            <strong className="flex justify-center items-center gap-x-[0.286em] text-[36px] text-[#FFD600]">
              <span className="size-[26px]">
                <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
              </span>
              {moneyFormat(serviceBonus || 0)}
            </strong>
          </div>
          <Button
            text={"OK"}
            handleClick={handleGetServiceBonus}
            style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          />
        </div>
      </BottomSheet>
    </div>
  );
}
