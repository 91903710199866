import { useSelector } from "react-redux";
import SearchIcon from "../../assets/icons/сhevron.svg?react";
import { absoluteBalanceSelector, currentLeagueSelector } from "../../store/account/account";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { clamp } from "lodash-es";
import { NavLink } from "react-router-dom";

const LeagueBlock = () => {
  const absoluteBalance = useSelector(absoluteBalanceSelector);
  const currentLeague = useSelector(currentLeagueSelector);

  const currentLeagueFillPercentage = clamp(
    currentLeague && currentLeague.threshold ? (absoluteBalance * 100) / currentLeague.threshold : 0,
    0,
    100,
  );

  return (
    <NavLink className="flex flex-col gap-[5px] w-full mt-[5px] mb-[22px]" to={"/leagues-rewards-page"}>
      <div className="flex justify-between">
        <div className="pl-[5px] font-bold flex items-center">
          <p>{capitalizeFirstLetter(currentLeague?.title || "")}</p> <SearchIcon className={` stroke-white size-4 `} />
        </div>
      </div>
      <div className="rounded h-2 w-full bg-white/25 p-[1px]">
        <div
          className=" h-full rounded-[3px]"
          style={{
            width: `${currentLeagueFillPercentage}%`,
            background: "linear-gradient(94.55deg, #AF38D9 -1.4%, #8B18E5 100.83%)",
          }}
        ></div>
      </div>
    </NavLink>
  );
};

export default LeagueBlock;
