import { Note } from "../../../store/token/api/types";
import NotesItem from "./NotesItem";

const NotesList = ({ communityNotes }: { communityNotes: Note[] }) => {
  return (
    <div className="flex flex-col gap-2">
      {communityNotes?.map((item, index) => <NotesItem note={item} key={index} />)}
    </div>
  );
};

export default NotesList;
