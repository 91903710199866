import balanceIcon from "../../assets/icons/balance.svg";
import Button from "../../components/ui/Button.tsx";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks.ts";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer.ts";
import { useEffect } from "react";
import { useRouterLock } from "../../hooks/useRouterLock.ts";

export default function PayAndEarnPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useRouterLock();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
  }, []);

  return (
    <div className="flex flex-1 flex-col pb-[17px]">
      <div className="grow flex flex-col items-center pt-[13vh] pb-[100px]">
        <h1 className="text-[24px] font-bold leading-none flex flex-col items-center text-center gap-y-[7px]">
          <span>Play and earn</span>

          <span className="text-[1.34em] flex items-center gap-x-[0.22em]">
            <span className="size-[0.8125em]">
              <img src={balanceIcon} alt="Rocket" className="size-full object-cover" />
            </span>

            <span className="leading-none font-black text-orangeLight">RCT</span>
          </span>
        </h1>

        <p className="text-center mt-[26px] leading-[1.5]">
          We will convert your rewards to real <br />
          balance (TON/USDT). The exchange rate <br />
          will be announced later.
        </p>

        <div className="relative max-w-[300px] mx-auto">
          <img className="w-full h-auto" loading="lazy" src="/pay-and-earn.png" alt="Pay and earn" />
        </div>
      </div>

      <div className="fixed bottom-0 right-0 left-0 shrink-0 px-4 pb-4 ">
        <Button
          text="NEXT"
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          className="text-[14px] py-[1.14em] disabled:!bg-lightGrey"
          handleClick={() => navigate("/onboarding/how-to-earn")}
        />
      </div>
    </div>
  );
}
