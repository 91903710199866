import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CryptoAssetDistribution } from "../../../store/rocket/api/types";
import {
  calculateTotalsPercentagePortfolio,
  changeCryptoPercentage,
  changeCryptoPosition,
} from "../../../store/rocket/rocketReducer";
import CoinNameBlock from "../../Coin/CoinNameBlock";
import CounterField from "../../ui/CounterField";
import GradientBorderBox from "../../ui/GradientBorderBox";
import Switch from "../../ui/Switch";

interface TokenAllocationItemProps {
  item: CryptoAssetDistribution;
}

const TokenAllocationItem: React.FC<TokenAllocationItemProps> = ({ item }) => {
  const { coin, percentage, position } = item;

  const [maxValue, setMaxValue] = useState<number>(100);
  const [positionValue, setPositionValue] = useState<string>(position);
  const [percentageValue, setPercentageValue] = useState<number>(percentage);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(positionValue == "short");

  const dispatch = useAppDispatch();
  const portfolio = useAppSelector((state) => state.rocket.rocket?.portfolio);

  const handleChangePercentageValue = (percentageValue: number) => {
    setPercentageValue(percentageValue);
    setMaxValue(100 - calculateTotalsPercentagePortfolio(portfolio) + percentageValue);
    dispatch(changeCryptoPercentage({ coin, percentageValue }));
  };

  const toggleSwitchPosition = (newValue: boolean) => {
    let positionValue: "long" | "short";
    if (newValue) {
      positionValue = "short";
      setPositionValue(positionValue);
      dispatch(changeCryptoPosition({ coin, positionValue }));
    } else {
      positionValue = "long";
      setPositionValue(positionValue);
      dispatch(changeCryptoPosition({ coin, positionValue }));
    }
    setIsSwitchOn(newValue);
  };

  useEffect(() => {
    setPercentageValue(percentage);
  }, [percentage]);

  useEffect(() => {
    setPositionValue(position);
    setIsSwitchOn(position === "short");
  }, [position]);

  useEffect(() => {
    setPercentageValue(percentage);
  }, []);

  useEffect(() => {
    setMaxValue(100 - calculateTotalsPercentagePortfolio(portfolio) + percentage);
  }, [portfolio]);

  return (
    <GradientBorderBox className="h-[72px]" border={true}>
      <div className="flex justify-between items-center w-full py-1 px-[12px] h-full bg-mediumGrey/50 rounded-2xl">
        <CoinNameBlock symbol={coin.symbol} />
        <div className="flex items-center gap-[29px]">
          <div className="w-[169px] h-[64px]">
            <CounterField
              value={percentageValue}
              step={1}
              displayText={percentageValue + "%"}
              handleChange={handleChangePercentageValue}
              paddingY="py-4"
              textStyle={` ${percentageValue > 0 ? "text-[#FC9F37]" : "text-[#A19AA4]"} tracking-[0.02em]`}
              background="bg-[#1A161C]"
              max={maxValue}
              border={false}
            />
          </div>
          <div className="mr-[17px] flex flex-col gap-[10px] leading-[10px]">
            <p
              className={`text-[10px] font-bold text-center  ${
                positionValue === "long" ? "text-[#3EFF74]" : "text-[#FF2C5F]"
              }`}
            >
              {positionValue.charAt(0).toUpperCase() + positionValue.slice(1)}
            </p>
            <Switch
              isOn={isSwitchOn}
              bgColor="linear-gradient(0deg, rgba(217, 217, 217, 0.12), rgba(217, 217, 217, 0.12)),radial-gradient(517.11% 893.18% at -417.11% 50%, #3EFF74 0%, rgba(62, 255, 116, 0) 100%)"
              bgActiveColor="linear-gradient(0deg, rgba(217, 217, 217, 0.12), rgba(217, 217, 217, 0.12)),radial-gradient(544.74% 940.91% at 544.74% 50%, #FF002E 0%, rgba(255, 0, 46, 0) 100%)"
              circleColor={positionValue === "long" ? "#3EFF74" : "#FF2C5F"}
              toggleSwitch={toggleSwitchPosition}
              width="38px"
              height="22px"
              circleSize="16px"
            />
          </div>
        </div>
      </div>
    </GradientBorderBox>
  );
};

export default TokenAllocationItem;
