import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateNote } from "../../../api/communityNotes/types";
import { updateNoteForToken } from "../../../api/communityNotes/updateNoteForToken";

export const patchLastNoteForTokenAction = createAsyncThunk(
  "tokens/patchLastNoteForToken",
  async (data: UpdateNote) => {
    return updateNoteForToken(data);
  },
);
