import { TradeType, setBackgroundTradeType } from "../FilterTrade";

interface TransactionData {
  totalTransactions: number;
  buys: number;
  sells: number;
}

const TransactionSummary = () => {
  const transactionDataArray: TransactionData[] = [
    {
      totalTransactions: 299,
      buys: 171,
      sells: 171,
    },
    {
      totalTransactions: 125,
      buys: 65,
      sells: 60,
    },
    {
      totalTransactions: 100,
      buys: 40,
      sells: 60,
    },
  ];
  return (
    <div className="pt-[14px]">
      {transactionDataArray.map((item, index) => (
        <Item item={item} key={index} />
      ))}
    </div>
  );
};

const Item = ({ item }: { item: TransactionData }) => {
  const { totalTransactions, buys, sells } = item;
  return (
    <div className="flex gap-[7vw] leading-none text-[12px] py-4 border-solid border-b-[2px] border-white/10">
      <div className="flex flex-col gap-[6px]">
        <p className="text-lavenderGray font-semibold">TXNS</p>
        <p className="font-bold">{totalTransactions}</p>
      </div>
      <div className="w-full flex flex-col items-stretch">
        <div className="flex justify-between mb-2">
          <div>
            <span className="text-lavenderGray font-semibold pr-1">Buys</span>
            <span className="font-bold">{buys}</span>
          </div>
          <div>
            <span className="text-lavenderGray font-semibold pr-1">Sells</span>
            <span className="font-bold">{sells}</span>
          </div>
        </div>
        <div>
          <ProgressLine countBuys={buys} countSells={sells} />
        </div>
      </div>
    </div>
  );
};

const ProgressLine = ({ countBuys, countSells }: { countBuys: number; countSells: number }) => {
  const amount = countBuys + countSells;
  const buysPercentage = (countBuys * 100) / amount;
  const sellsPercentage = (countSells * 100) / amount;
  return (
    <div className={`flex gap-[1px] h-[8px] rounded-full w-full   p-[2px] bg-[#FFFFFF40]  items-center`}>
      <div className={`relative h-[6px] w-full rounded-full  flex`}>
        <div
          className="rounded-l-full h-[6px]"
          style={{
            width: `${buysPercentage}%`,
            background: setBackgroundTradeType(TradeType.BUY),
          }}
        ></div>
        <div
          className="rounded-r-full h-[6px]"
          style={{
            width: `${sellsPercentage}%`,
            background: setBackgroundTradeType(TradeType.SELL),
          }}
        ></div>
      </div>
    </div>
  );
};

export default TransactionSummary;
