import user1 from "../../../assets/icons/user.svg";
const LastTransaction = () => {
  return (
    <div className={"flex gap-1 items-start  rounded-[16px]  text-[14px]  p-1 justify-between pr-3 bg-mediumGrey "}>
      <div className="flex items-center gap-[10px]">
        <img src={user1} className="relative bg-lightPurple rounded-[12px]  w-[40px] h-[40px] bg-contain" />

        <div>
          <p className="font-semibold">@cassye2</p>
          <div className="flex text-[12px] font-medium items-center gap-1">
            <p className="text-[#b5a4bf]">Bought 0.99 SOL of</p>
            <div className="flex items-center gap-1">
              <div className="rounded-[2px] bg-white w-4 h-4 min-w-4 min-h-4 ">
                <img src="/image-token.png" className="w-full object-cover " />
              </div>
              <p className="font-semibold text-[#f99239]">1HPT</p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-[10px] font-medium text-[#95879e]">13m ago</p>
    </div>
  );
};

export default LastTransaction;
