import { useAppSelector } from "../../store/hooks";
import PassengerItem from "./PassengerItem";

const PassengersList: React.FC = () => {
  const passengers = useAppSelector((state) => state.rocket.rocket?.passengers);

  return (
    <div>
      {passengers?.map((passenger, index) => (
        <div className={`${index == 0 ? "" : "mt-[8px]"}`} key={index}>
          <PassengerItem passenger={passenger} />
        </div>
      ))}
    </div>
  );
};
export default PassengersList;
