import { useEffect, useState } from "react";
import FilterCards from "./FilterCards";
import { useSelector } from "react-redux";
import {
  cardsSelector,
  openPopUpForCard,
  popUpForCardSelector,
  purchasedCardsSelector,
} from "../../../store/cards/cardsReducer";
import { useLocation } from "react-router-dom";
import CardItem, { checkRules } from "./CardItem";
import { Card, TypeCardsEnum } from "../../../store/cards/api/types";
import { isGameActiveSelector } from "../../../store/clicker/selectors";
import PurchaseCard from "./PurchaseCard";
import { userInvitesSelector } from "../../../store/friends/friendsReducer";
import { useAppDispatch } from "../../../store/hooks";
import AdTicketReward from "../adTicketReward/adTicketReward";

const CardsList = () => {
  const isGameActive = useSelector(isGameActiveSelector);
  // const isDemo = useSelector(isDemoSelector);
  const popUpForCard = useSelector(popUpForCardSelector);
  const userInvites = useSelector(userInvitesSelector);

  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<string>("Risk");
  const [visibleCards, setVisibleCards] = useState<Card[]>([]);
  const [filterItems, setFilterItems] = useState<TypeCardsEnum[]>([]);

  const cards = useSelector(cardsSelector);
  const purchasedCards = useSelector(purchasedCardsSelector);
  const location = useLocation(); 
  const handleSelectLimit = (tab: string) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const typeParam = params.get('type');
    if (typeParam) {
      setActiveTab(typeParam);
    }
  }, [location.search]);

  useEffect(() => {
    let filteredCards;

    if (activeTab === "Special") {
      const purchasedCardKeys = new Set(purchasedCards.map((card) => card.key));
      filteredCards = cards.filter((card) => card.type === activeTab && purchasedCardKeys.has(card.key));
    } else {
      filteredCards = cards.filter((card) => card.type === activeTab);
    }
    setVisibleCards(filteredCards);
  }, [activeTab, cards, purchasedCards]);

  useEffect(() => {
    setFilterItems([...new Set(cards.map((card) => card.type))]);
  }, [cards]);

  useEffect(() => {
    if (popUpForCard.isOpened) {
      const { unlockRequirement, referalsPerLevel, referalsToUnlock } = popUpForCard.card!;
      const { executedRule, typeOfRule } = checkRules(
        unlockRequirement,
        referalsToUnlock,
        popUpForCard.card!,
        referalsPerLevel,
        purchasedCards,
        userInvites,
        cards,
      );
      dispatch(
        openPopUpForCard({
          isOpened: true,
          card: popUpForCard.card,
          isActiveCard: true,
          typeOfRule: typeOfRule,
          executedRule: executedRule,
        }),
      );
    }
  }, [purchasedCards]);

  return (
    <>
      <FilterCards filterItems={filterItems} onSelectLimit={handleSelectLimit} activeTab={activeTab} />
      <AdTicketReward/>
      <div className="grid grid-cols-2 gap-3 mb-3 relative z-[1000]" id="cards">
        {visibleCards.slice(0, 2).map((card, index) => (
          <div key={card.key} id={index < 2 ? `card-${index}` : ""} className="flex flex-col h-full">
            <CardItem
              card={card}
              level={purchasedCards.find((purchasedCard) => purchasedCard.key === card.key)?.level || 0}
              isGameActive={isGameActive}
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 gap-3">
        {visibleCards.slice(2).map((card, index) => (
          <div key={card.key} id={index < 2 ? `card-${index}` : ""} className="flex flex-col h-full">
            <CardItem
              card={card}
              level={purchasedCards.find((purchasedCard) => purchasedCard.key === card.key)?.level || 0}
              isGameActive={isGameActive}
            />
          </div>
        ))}
      </div>
      <PurchaseCard />
    </>
  );
};

export default CardsList;
