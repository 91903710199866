import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import randomColor from "../../helpers/randomColor";
import { generateRandomPortfolio } from "../../helpers/randomGeneratePortfolio";
import { RootState } from "../store";
import { Coin, CryptoAssetDistribution, Passenger, Rocket } from "./api/types";

export interface RocketInitialState {
  rocket: Rocket | undefined;
  modalRocket: boolean;
}
const initialState: RocketInitialState = {
  rocket: {
    id: "",
    name: "",
    portfolio: [],
    passengers: [],
  },
  modalRocket: false,
};

export const rocketSlice = createSlice({
  name: "rocket",
  initialState,
  reducers: {
    setRocket: (state, action: PayloadAction<Rocket | undefined>) => {
      state.rocket = action.payload;
    },
    investedInThisRocket: (state, action: PayloadAction<Passenger>) => {
      state.rocket?.passengers?.push(action.payload);
    },
    changeStatusModalRocket: (state, action: PayloadAction<boolean>) => {
      state.modalRocket = action.payload;
    },
    changeRocketPortfolio: (state: RocketInitialState, action: PayloadAction<Coin>) => {
      if (state.rocket) {
        if (!state.rocket.portfolio) {
          state.rocket.portfolio = [];
        }
        const index = state.rocket.portfolio.findIndex((asset) => asset.coin.symbol === action.payload.symbol);
        if (index === -1) {
          state.rocket.portfolio.push({
            coin: action.payload,
            percentage: 0,
            position: "long",
            color: randomColor(),
          });
        } else {
          state.rocket.portfolio.splice(index, 1);
        }
      }
    },
    changeCryptoPercentage: (
      state: RocketInitialState,
      action: PayloadAction<{ coin: Coin; percentageValue: number }>,
    ) => {
      if (state.rocket && state.rocket.portfolio) {
        const index = state.rocket.portfolio.findIndex((asset) => asset.coin.symbol === action.payload.coin.symbol);
        if (index !== -1) {
          state.rocket.portfolio[index].percentage = action.payload.percentageValue;
        }
      }
    },
    changeCryptoPosition: (
      state: RocketInitialState,
      action: PayloadAction<{ coin: Coin; positionValue: "long" | "short" }>,
    ) => {
      if (state.rocket && state.rocket.portfolio) {
        const index = state.rocket.portfolio.findIndex((asset) => asset.coin.symbol === action.payload.coin.symbol);
        if (index !== -1) {
          state.rocket.portfolio[index].position = action.payload.positionValue;
        }
      }
    },
    resetPortfolioSettings: (state: RocketInitialState) => {
      if (state.rocket?.portfolio) {
        state.rocket.portfolio = state.rocket.portfolio.map((asset) => ({
          ...asset,
          percentage: 0,
          position: "long",
        }));
      }
    },
    randomPortfolio: (state) => {
      if (state.rocket && state.rocket.portfolio) {
        state.rocket.portfolio = state.rocket.portfolio.map((asset) => ({
          ...asset,
          percentage: 0,
        }));
        const shuffledPortfolio = generateRandomPortfolio(state.rocket.portfolio);

        state.rocket.portfolio = shuffledPortfolio;
      }
    },
    clearPercentageInPortfolio: (state: RocketInitialState) => {
      if (state.rocket?.portfolio) {
        state.rocket.portfolio = state.rocket?.portfolio?.map((asset) => ({
          ...asset,
          percentage: 0,
        }));
      }
    },
  },
});

export const yourInvestStore = (state: RootState, currentUserId: string) =>
  state.rocket.rocket?.passengers?.find(
    (passenger) => passenger.id === currentUserId && passenger.id !== state.rocket.rocket?.ownerId,
  )?.deposite || 0;

export const ownerInvestStore = (state: RootState, currentUserId: string) =>
  state.rocket.rocket?.passengers?.find(
    (passenger) =>
      passenger.id === state.rocket.rocket?.ownerId ||
      (passenger.id === currentUserId && passenger.id === state.rocket.rocket?.ownerId),
  )?.deposite || 0;

export const otherInvestStore = (state: RootState, currentUserId: string) =>
  state.rocket.rocket?.passengers?.reduce(
    (acc, passenger) =>
      passenger.id !== currentUserId && passenger.id != state.rocket.rocket?.ownerId ? acc + passenger.deposite : acc,
    0,
  ) || 0;
export const fullness = (state: RootState) =>
  state.rocket.rocket?.passengers?.reduce((acc, passenger) => acc + passenger.deposite, 0) || 0;

export const isPassenger = (state: RootState, currentUserId: string): boolean => {
  const rocket = state.rocket.rocket;
  if (!rocket || !rocket.passengers) {
    return false;
  }

  return rocket.passengers.findIndex((passenger) => passenger.id === currentUserId) >= 0;
};

export const {
  setRocket,
  investedInThisRocket,
  changeStatusModalRocket,
  changeRocketPortfolio,
  changeCryptoPercentage,
  changeCryptoPosition,
  resetPortfolioSettings,
  randomPortfolio,
  clearPercentageInPortfolio,
} = rocketSlice.actions;
export default rocketSlice.reducer;

export const isTokenInPortfolio = (rocket: Rocket | undefined, coin: Coin): boolean => {
  if (rocket && rocket.portfolio) {
    return rocket.portfolio.some((asset) => asset.coin.symbol === coin.symbol);
  }
  return false;
};

export const calculateTotalsPercentagePortfolio = (portfolio: CryptoAssetDistribution[] | undefined): number => {
  return portfolio ? portfolio.reduce((sum, cur) => sum + cur.percentage, 0) : 0;
};
