interface IconWithTextProps {
  icon: string;
  text: string | number;
  style?: string;
}

const IconWithText: React.FC<IconWithTextProps> = ({ icon, text, style }) => {
  return (
    <span className="flex items-center gap-1 justify-center">
      <img src={icon} alt={icon} className={"w-[15px]"} />
      <span className={"text-xs " + style}>{text}</span>
    </span>
  );
};

export default IconWithText;
