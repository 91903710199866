import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Card, DailyCombo, DailyRewards, PurchasedCards, RuleCardsEnum } from "./api/types";
import { fetchCardsListAction } from "./actions/fetchCardsListAction";
import { RootState } from "../store";
import { fetchPurchasedCardsListAction } from "./actions/fetchPurchasedCardsListAction";
import { putPurchasedCardAction } from "./actions/putPurchasedCardAction";
import { fetchComboDataAction } from "./actions/fetchComboDataAction";
import { fetchCombosRewardAction } from "../account/actions/fetchCombosRewardAction";
import { decryptComboKeys } from "../../helpers/decryptComboKeys";
import { putUseTicketAction } from "./actions/putUseTicketAction";
import { fetchDailyRewardsAction } from "./actions/fetchDailyRewards";
import { fetchAdTicketRewardAction } from "../account/actions/fetchAdTicketReward";
import { fetchDailyRewardAction } from "../account/actions/fetchDailyReward";
import { fetchTapTaskRewardAction } from "./actions/fetchTapTaskReward";
import { fetchRewardForNftAction } from "../cryptoProfile/actions/fetchRewardForNftAction";

interface PopupState {
  isOpened: boolean;
  card?: Card;
  isActiveCard?: boolean;
  typeOfRule?: RuleCardsEnum;
  executedRule?: boolean;
}
export interface CardsState {
  pending: boolean;
  error: string | null;
  cards: Card[];
  purchasedCards: PurchasedCards[];
  popUpForCard: PopupState;
  totalBonus: number;
  refTickets: number | null;
  dailyCombo: DailyCombo | null;
  lastComboRewardTakenAt: Date | null;
  dailyRewards: DailyRewards | null;
  dailyRewardCardKey: string;
}

const initialState: CardsState = {
  cards: [],
  purchasedCards: [],
  refTickets: 0,
  totalBonus: 0,
  pending: false,
  popUpForCard: { isOpened: false },
  error: null,
  dailyCombo: null,
  lastComboRewardTakenAt: null,
  dailyRewards: [],
  dailyRewardCardKey: "",
};

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    openPopUpForCard(state, action: PayloadAction<PopupState>) {
      state.popUpForCard = action.payload;
    },
    closePopUpForCard(state) {
      state.popUpForCard = { isOpened: false };
    },
    changeRefTickets(state, action: PayloadAction<number>) {
      state.refTickets = state.refTickets! + action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchCardsListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchCardsListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.cards = action.payload;
      })
      .addCase(fetchCardsListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchPurchasedCardsListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchPurchasedCardsListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.purchasedCards = action.payload ? action.payload.boostCards : [];
        state.refTickets = action.payload ? action.payload.refTickets : 0;
        state.totalBonus = state.purchasedCards.reduce((sum, card) => sum + card.bonus, 0);
        state.lastComboRewardTakenAt = action.payload.lastComboRewardTakenAt;
      })
      .addCase(fetchPurchasedCardsListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(putPurchasedCardAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(putPurchasedCardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.purchasedCards = action.payload.userBoostCards.boostCards;
        state.refTickets = action.payload.userBoostCards.refTickets;
        state.totalBonus = action.payload.userBoostCards.totalBonus;
        state.lastComboRewardTakenAt = action.payload.userBoostCards.lastComboRewardTakenAt;
      })
      .addCase(putPurchasedCardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      //USE TICKET
      .addCase(putUseTicketAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(putUseTicketAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.purchasedCards = action.payload.userBoostCards.boostCards;
        state.refTickets = action.payload.userBoostCards.refTickets;
        state.totalBonus = action.payload.userBoostCards.totalBonus;
        state.lastComboRewardTakenAt = action.payload.userBoostCards.lastComboRewardTakenAt;
      })
      .addCase(putUseTicketAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      //COMBO
      .addCase(fetchComboDataAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchComboDataAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;

        state.dailyCombo = {
          activeDate: action.payload.activeDate,
          reward: action.payload.reward,
          comboKeys: decryptComboKeys(action.payload.comboKeys),
        };
      })
      .addCase(fetchComboDataAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(fetchTapTaskRewardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.refTickets = action.payload.refTickets;
      })

      .addCase(fetchTapTaskRewardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchCombosRewardAction.fulfilled, (state) => {
        state.pending = false;
        state.error = null;
        state.lastComboRewardTakenAt = new Date();
      })
      .addCase(fetchDailyRewardsAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchDailyRewardsAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.dailyRewards = action.payload;
      })
      .addCase(fetchDailyRewardsAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      //Ad Reward
      .addCase(fetchAdTicketRewardAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchAdTicketRewardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.refTickets = action.payload.refTickets;
        state.error = null;
      })
      .addCase(fetchDailyRewardAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchDailyRewardAction.fulfilled, (state, action) => {
        state.pending = false;
        if (action.payload.updatedRefTickets && action.payload.updatedRefTickets !== 0) {
          state.refTickets = action.payload.updatedRefTickets;
        }
        state.dailyRewardCardKey = action.payload.newBoostCardKey;
        if (action.payload.boostCards && action.payload.boostCards.length > 0) {
          state.purchasedCards = action.payload.boostCards;
        }
        state.error = null;
      });

    //claim reward for nft
    builder
      .addCase(fetchRewardForNftAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchRewardForNftAction.fulfilled, (state, action) => {
        state.pending = false;

        const { ticket } = action.payload;
        state.refTickets = state.refTickets! + ticket;
      });
  },
});

export const { openPopUpForCard, closePopUpForCard, changeRefTickets } = cardsSlice.actions;

export const cardsSelector = (state: RootState) => state.cards.cards;
export const popUpForCardSelector = (state: RootState) => state.cards.popUpForCard;
export const purchasedCardsSelector = (state: RootState) => state.cards.purchasedCards;
export const totalBonusForPurchasedCardsSelector = (state: RootState) => state.cards.totalBonus;
export const referralsTicketSelector = (state: RootState) => state.cards.refTickets;
export const dailyComboSelector = (state: RootState) => state.cards.dailyCombo;
export const lastComboRewardTakenAtSelector = (state: RootState) => state.cards.lastComboRewardTakenAt;
export const dailyRewardsSelector = (state: RootState) => state.cards.dailyRewards;
export const dailyRewardCardKeySelector = (state: RootState) => state.cards.dailyRewardCardKey;

export default cardsSlice.reducer;

export function isCardInPurchasedCards(purchasedCards: PurchasedCards[], currentCardKey: string) {
  return purchasedCards.find((purchasedCard) => purchasedCard.key === currentCardKey);
}

export function isCardInCombo(purchasedCard: PurchasedCards, dailyCombo: DailyCombo): boolean {
  return (
    dailyCombo.comboKeys.includes(purchasedCard.key) &&
    new Date(purchasedCard.lastUpdated) >= new Date(dailyCombo.activeDate) &&
    new Date(purchasedCard.lastUpdated) <= new Date(new Date(dailyCombo.activeDate).getTime() + 24 * 60 * 60 * 1000)
  );
}

export function rewardForCurrentCombo(lastComboRewardTakenAt: Date | null, comboActiveData: Date | null): boolean {
  return lastComboRewardTakenAt && comboActiveData
    ? new Date(lastComboRewardTakenAt) >= new Date(comboActiveData) &&
        new Date(lastComboRewardTakenAt) <= new Date(new Date(comboActiveData).getTime() + 24 * 60 * 60 * 1000)
    : true;
}
