import userPhoto1 from "../assets/icons/user.svg";
import userPhoto2 from "../assets/icons/user2.svg";
import userPhoto3 from "../assets/icons/user3.svg";
import { Round } from "../store/round/api/types";

const roundData: Round[] = [
  {
    id: "_7pu1k0yh5",
    time: "15:23",
    status: "running",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [
      {
        id: "_7pu1k0yh2",
        name: "#2 Name of the game/rocket",
        min_deposite: 10,
        profit: "$250.00",
        percentage_profit: "20.00%",
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f414",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 250,
          },
          {
            id: "800a7e56-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 145,
          },
          {
            id: "00917256-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 200,
          },
        ],
      },
      {
        id: "_7pu1k0yh3",
        name: "#3 Name of the game/rocket",
        min_deposite: 10,
        profit: "$345.49",
        percentage_profit: "28.99%",
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 250,
          },
          {
            id: "800a7e56-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 145,
          },
          {
            id: "00917256-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 200,
          },
        ],
      },
      {
        id: "_7pu1k0yh4",
        name: "#4 Name of the game/rocket",
        min_deposite: 10,
        profit: "$250.00",
        percentage_profit: "20.00%",
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 250,
          },
          {
            id: "800a7e56-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 145,
          },
          {
            id: "00917256-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 200,
          },
        ],
      },
      {
        id: "_7pu1k0yh1",
        name: "#1 Name of the game/rocket",
        min_deposite: 10,
        profit: "$345.49",
        percentage_profit: "28.99%",
        ownerId: "38c49087-6757-43e8-8b63-1eaaad98f414",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f414",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 50,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 300,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 150,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 250,
          },
          {
            id: "00917242-a3a2-42d7-8445-e8778605o963",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
        ],
      },
      {
        id: "_7pu1k0ypl",
        name: "#5 Name of the game/rocket",
        min_deposite: 10,
        profit: "$250.00",
        percentage_profit: "20.00%",
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 250,
          },
          {
            id: "800a7e56-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 145,
          },
          {
            id: "00917256-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 200,
          },
        ],
      },
    ],
  },
  {
    id: "_8fu3mkrsj",
    time: "15:23",
    status: "registering",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [
      {
        id: "_7pu1k0yh5",
        name: "#1 Name of the game/rocketttt",
        min_deposite: 10,
        ownerId: "38c49087-6757-43e8-8b63-1eaaad98f414",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f414",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0yh6",
        name: "#2 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 500,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 200,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 300,
          },
        ],
      },
      {
        id: "_7pu1k0yh7",
        name: "#3 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 50,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
        ],
      },
      {
        id: "_7pu1k0yh8",
        name: "#4 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y10",
        name: "#5 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
        ],
      },
      {
        id: "_7pu1k0y11",
        name: "#6 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
    ],
  },
  {
    id: "_8fu3mghsj",
    time: "15:23",
    status: "registering",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [
      {
        id: "_7pu1k0y12",
        name: "#1 Name of the game/rocket",
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        min_deposite: 10,
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 50,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 20,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y13",
        name: "#2 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 30,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 50,
          },
        ],
      },
      {
        id: "_7pu1k0y14",
        name: "#3 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y15",
        name: "#4 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 50,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 150,
          },
        ],
      },
      {
        id: "_7pu1k0y16",
        name: "#5 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y17",
        name: "#6 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y18",
        name: "#7 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y19",
        name: "#8 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y20",
        name: "#9 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y21",
        name: "#8 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
      {
        id: "_7pu1k0y22",
        name: "#9 Name of the game/rocket",
        min_deposite: 15,
        ownerId: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
        passengers: [
          {
            id: "38c49087-6757-43e8-8b63-1eaaad98f424",
            photo: userPhoto1,
            name: "Jacob Mitchell",
            deposite: 60,
          },
          {
            id: "3347162e-f4ab-4e91-b2c2-79a651a78c9e",
            photo: userPhoto3,
            name: "James Reynolds",
            deposite: 150,
          },
          {
            id: "92aeeb21-1f7a-47a9-a05b-3a387fb489ae",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 100,
          },
          {
            id: "800a7e71-ca06-4fbb-9703-ea01f30485c0",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 95,
          },
          {
            id: "00917242-a3a2-42d7-8445-e87786052d63",
            photo: userPhoto2,
            name: "Emily Watson",
            deposite: 35,
          },
        ],
      },
    ],
  },
  {
    id: "_5i8o0cuj3",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_tgz37qykd",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_tgz37qy9d",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_tgz37qy8d",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_tgz57qykd",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_tg137qykd",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_lgz37qykd",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_tgz39qykd",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_fkp9uiqfa",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_g5zoq711b",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
  {
    id: "_g7zoq711b",
    time: "10.04.24",
    status: "planned",
    title: "Sunday $100k Guarantee",
    buyIn: 1000,
    prize: "$87.000",
    rockets: [],
  },
];

export default roundData;
