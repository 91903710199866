import { forwardRef, useImperativeHandle } from "react";
import { useCountDown } from "../../hooks/useCountDown.ts";
import { clamp } from "lodash-es";

interface StakingTimerProps {
  remainingTime: number;
  immediate?: boolean;
  onStart?: () => void;
  onEnd?: () => void;
}

export interface StakingTimerRef {
  start: () => void;
  clear: () => void;
  isPlaying: () => boolean;
}

export const StakingTimer = forwardRef<StakingTimerRef, StakingTimerProps>(
  ({ onStart, onEnd, remainingTime, immediate }, ref) => {
    const {
      start,
      time: timeRemaining,
      isPlaying,
      clear,
    } = useCountDown({
      duration: remainingTime,
      onEnd,
    });

    useImperativeHandle(ref, () => ({
      start: () => {
        start();
        onStart && onStart();
      },
      clear: () => {
        clear();
      },
      isPlaying: () => isPlaying,
    }));

    if (immediate) {
      clear();
      start();
      onStart && onStart();
    }

    const fontSize = clamp(window.innerHeight * 0.035, 12, 16);

    return isPlaying ? (
      <span
        className="block text-center font-medium opacity-50"
        style={{
          fontSize: fontSize,
        }}
      >
        {formatTime(timeRemaining)}
      </span>
    ) : null;
  },
);

export function formatTime(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (totalSeconds < 60) {
    return `${String(seconds).padStart(2, "0")}s`;
  } else {
    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(2, "0")}m`;
  }
}
