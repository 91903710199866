import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDailyQuest } from "../../../api/clicker/getDailyQuest";
import { getCoins } from "../../../api/clicker/getCoins";
import { clearLocalStorage, getFromLocalStorage } from "../../../helpers/saveCoinsProgressLocally";
import { patchClickProgress } from "../../../api/clicker/patchClickProgress";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchInitialClickerDataAction = createAsyncThunk("clicker/fetchInitialClickerData", async () => {
  try {
    const previousNotSavedProgress = getFromLocalStorage();

    if (previousNotSavedProgress) {
      await patchClickProgress(previousNotSavedProgress);
      clearLocalStorage();
    }

    let dailyQuest;
    let attempts = 0;
    const maxAttempts = 5;

    while (attempts < maxAttempts) {
      dailyQuest = await getDailyQuest();
      if (dailyQuest?.quest?.portfolios?.[0]?.coins?.length === 5) break;
      attempts++;
      await delay(1000);
    }

    const coins = await getCoins();

    return {
      dailyQuest,
      coins,
    };
  } catch (error) {
    console.error("Error fetching initial clicker data:", error);
    throw error;
  }
});
