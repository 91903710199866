import { useCallback, useEffect, useRef } from "react";

type InitialStyles = Record<"body" | "html" | "scrollableEl", Record<string, string>>;

export function useLockAppSwipe() {
  const overflow = 100;

  const ts = useRef(0);
  const scrollableEl = useRef(document.getElementById("root")!);

  const initialStyles = useRef<InitialStyles>();

  const handleTouchStart = useCallback((event: TouchEvent) => {
    ts.current = event.touches[0].clientY;
  }, []);

  const handleTouchMove = useCallback((event: TouchEvent) => {
    if (scrollableEl.current) {
      const scroll = scrollableEl.current.scrollTop;
      const te = event.changedTouches[0].clientY;

      if (scroll <= 0 && ts.current! < te) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY < overflow) {
      window.scrollTo(0, overflow);
      if (scrollableEl.current) {
        scrollableEl.current.scrollTo(0, 0);
      }
    }
  }, [overflow]);

  function lock() {
    console.log("lock swipe **3");

    const scrollPosition = window.scrollY;
    // HTML
    document.documentElement.style.height = "100%";
    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.minHeight = "100vh";

    document.body.style.marginTop = `${overflow}px`;
    document.body.style.paddingBottom = `${overflow}px`;

    document.body.style.overflow = "hidden";
    document.body.style.minHeight = `100vh`;
    document.body.style.height = `${window.innerHeight + overflow * 2}px`;
    document.body.style.overflow = "hidden";
    document.body.style.isolation = "isolate";

    // Відновлення початкового положення прокрутки
    window.scrollTo(0, scrollPosition + overflow);

    // #ROOT
    if (scrollableEl.current) {
      scrollableEl.current.style.maxHeight = "100%";
      scrollableEl.current.style.overflow = "auto";
    }

    document.addEventListener("touchstart", handleTouchStart, { passive: false });
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("scroll", handleScroll, { passive: true });
  }

  function unlock() {
    console.log("unlock swipe");
    if (initialStyles.current) {
      // HTML
      document.documentElement.style.height = initialStyles.current.html.height;
      document.documentElement.style.overflow = initialStyles.current.html.overflow;
      document.documentElement.style.minHeight = initialStyles.current.html.minHeight;

      // BODY
      document.body.style.minHeight = initialStyles.current.body.minHeight;
      document.body.style.height = initialStyles.current.body.height;
      document.body.style.overflow = initialStyles.current.body.overflow;
      document.body.style.marginTop = initialStyles.current.body.marginTop;
      document.body.style.paddingBottom = initialStyles.current.body.paddingBottom;
      document.body.style.isolation = initialStyles.current.body.isolation;

      // #ROOT
      if (scrollableEl.current) {
        scrollableEl.current.style.maxHeight = initialStyles.current.scrollableEl.maxHeight;
        scrollableEl.current.style.overflow = initialStyles.current.scrollableEl.overflow;
      }
    }

    document.removeEventListener("touchstart", handleTouchStart);
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("scroll", handleScroll);
  }

  useEffect(() => {
    initialStyles.current = {
      html: {
        height: document.documentElement.style.height,
        overflow: document.documentElement.style.overflow,
        minHeight: document.documentElement.style.minHeight,
      },
      body: {
        minHeight: document.body.style.minHeight,
        height: document.body.style.height,
        overflow: document.body.style.overflow,
        paddingBottom: document.body.style.paddingBottom,
        marginTop: document.body.style.marginTop,
        isolation: document.body.style.isolation,
      },
      scrollableEl: {
        maxHeight: scrollableEl.current?.style.maxHeight,
        overflow: scrollableEl.current?.style.overflow,
      },
    };

    return unlock;
  }, []);

  return {
    lock,
    unlock,
  };
}
