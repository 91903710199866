import RoundItem from "./RoundItem";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

const RoundList = () => {
  const rounds = useAppSelector((state) => state.round.rounds);
  return (
    <div className=" mb-[100px] ">
      {rounds.map((round, index) => (
        <div key={index} className={`${index == 0 ? "mt-[6px]" : "mt-[8px]"}`}>
          <Link to={`/rounds/${round.id}`}>
            <RoundItem
              time={round.time}
              status={round.status}
              title={round.title}
              buyIn={round.buyIn}
              rockets={round.rockets}
              prize={round.prize}
            />
          </Link>
        </div>
      ))}
    </div>
  );
};
export default RoundList;
