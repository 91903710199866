import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { putPurchasedCardAction } from "../../../store/cards/actions/putPurchasedCardAction";
import { changeProfitPerHour } from "../../../store/account/account";
import { useToastNotifications } from "../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../store/toastNotifications/toastNotificationsReducer";
import { Card, RuleCardsEnum } from "../../../store/cards/api/types";
import Button from "../../ui/Button";

import ticketIcon from "../../../assets/icons/ticket-white.svg";
import balanceIcon from "../../../assets/icons/balance.svg";
import lockIcon from "../../../assets/icons/lock-white.svg";

import { useSelector } from "react-redux";
import { userInvitesSelector } from "../../../store/friends/friendsReducer";
import {
  cardsSelector,
  closePopUpForCard,
  isCardInPurchasedCards,
  openPopUpForCard,
  purchasedCardsSelector,
  referralsTicketSelector,
} from "../../../store/cards/cardsReducer";
import { DataLayerEventNames, useDataLayer } from "../../../hooks/useDataLayer";
import { moneyFormat } from "../../../helpers/moneyFormat";
import UseTicketPopup from "./UseTicketPopup";
import { checkRules } from "./CardItem";
import { useNavigate } from "react-router-dom";

interface PurchaseButtonGroupProps {
  card: Card;
  price: number;
  incomePerHour: number;
  isActiveCard: boolean;
  typeOfRule: RuleCardsEnum;
  executedRule: boolean;
}

const PurchaseButtonGroup = ({
  incomePerHour,
  card,
  executedRule,
  isActiveCard,
  price,
  typeOfRule,
}: PurchaseButtonGroupProps) => {
  const dispatch = useAppDispatch();
  const [isStartPending, setIsStartPending] = useState(() => false);
  const [isUseTicketPopupOpen, setUseTicketPopupOpen] = useState(() => false);

  const referralsTicket = useSelector(referralsTicketSelector);
  const userInvites = useSelector(userInvitesSelector);
  const cards = useSelector(cardsSelector);
  const purchasedCards = useSelector(purchasedCardsSelector);

  const { trackEvent } = useDataLayer();

  const navigate = useNavigate();

  const { showNotification } = useToastNotifications();

  const isCurrentCard = isCardInPurchasedCards(purchasedCards, card.key);

  const bonusForCurrentCard =
    isCurrentCard && isCurrentCard.level > 0 ? incomePerHour - isCurrentCard.bonus : incomePerHour;
  const { key, unlockRequirement } = card;

  const unlockRequirementKey = unlockRequirement?.key;
  const unlockRequirementLevel = unlockRequirement?.level;

  const rule = `Reach lvl ${unlockRequirementLevel} on ${cards.find((item) => item.key === unlockRequirementKey)?.name} first  `;

  const handleBuyCard = async () => {
    try {
      setIsStartPending(true);
      const result = await dispatch(putPurchasedCardAction(key));
      if (!putPurchasedCardAction.fulfilled.match(result)) {
        throw new Error("Failed to purchase card");
      } else {
        dispatch(changeProfitPerHour(bonusForCurrentCard));
        if (purchasedCards.length === 0) trackEvent(DataLayerEventNames.cardPurchased);

        showNotification({
          title: `Upgrade is yours! ${card.name} ${isCurrentCard ? isCurrentCard.level + 1 : 1} lvl`,
          type: ToastNotificationType.Success,
          timeoutMs: 1000,
        });
      }
    } catch (error) {
      showNotification({
        title: "Something went wrong. Please try again later.",
        type: ToastNotificationType.Error,
        timeoutMs: 5000,
      });
    } finally {
      setIsStartPending(false);
      dispatch(closePopUpForCard());
    }
  };

  const nextCard = cards.find((item) => item.key === unlockRequirementKey);

  const { executedRule: executedRuleForNextCard, typeOfRule: typeOfRuleForNextCard } = checkRules(
    nextCard?.unlockRequirement,
    nextCard?.referalsToUnlock!,
    nextCard!,
    nextCard?.referalsPerLevel!,
    purchasedCards,
    userInvites,
    cards,
  );

  const handleUseTicket = () => {
    setUseTicketPopupOpen(true);
  };

  const handleOpenPopUpForCard = () => {
    dispatch(closePopUpForCard());
    setTimeout(() => {
      dispatch(
        openPopUpForCard({
          isOpened: true,
          card: nextCard,
          isActiveCard: true,
          typeOfRule: typeOfRuleForNextCard,
          executedRule: executedRuleForNextCard,
        }),
      );
    }, 0);
  };

  return (
    <>
      {" "}
      {executedRule ? (
        <Button
          text={`<div class='flex gap-[6px]'><p>UPGRADE for</p>  <img class='size-4' loading="lazy" src=${balanceIcon} alt="currency" /> <p>${moneyFormat(price)}</p></div>`}
          isPending={isStartPending}
          handleClick={handleBuyCard}
          className={`${!isActiveCard ? "opacity-50" : ""} text-[14px]`}
          attributes={{
            disabled: !isActiveCard,
          }}
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
        />
      ) : typeOfRule === RuleCardsEnum.NEW_FRIENDS ? (
        <div className="flex gap-4">
          <div className="w-full">
            <Button
              text={`<div class='flex gap-[6px] '> <img class='size-4' loading="lazy" src=${ticketIcon} alt="ticket" /> <p>Use Ticket to Unlock</p></div>`}
              isPending={isStartPending}
              handleClick={handleUseTicket}
              className={`${referralsTicket! <= 0 ? "opacity-50" : ""} text-[14px] px-5`}
              attributes={{
                disabled: referralsTicket! <= 0,
              }}
              style="#AE3DFF"
            />
            {referralsTicket! <= 0 && (
              <button
                onClick={() => {
                  navigate(`/how-to-get-ticket?closePath=/cards`);
                  dispatch(closePopUpForCard());
                }}
                className="pt-2 text-base leading-none"
              >
                How to get tickets?
              </button>
            )}
          </div>
        </div>
      ) : typeOfRule === RuleCardsEnum.LEVEL ? (
        <Button
          text={`<div class='flex gap-[6px] items-center justify-center' > <img class='w-[18px] h-[19px]' loading="lazy" src=${lockIcon} alt="currency" /> <p class='text-center '>${rule}</p></div>`}
          isPending={isStartPending}
          handleClick={handleOpenPopUpForCard}
          className={`opacity-50 text-[14px] px-5`}
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
        />
      ) : (
        <></>
      )}
      <UseTicketPopup card={card} isOpened={isUseTicketPopupOpen} close={() => setUseTicketPopupOpen(false)} />
    </>
  );
};

export default PurchaseButtonGroup;
