export interface CreateNote {
  tokenId: number | string;
  message: string;
}

export interface UpdateNote {
  tokenId: number | string;
  noteId: number | string;
  like: NoteAction;
}

export enum NoteAction {
  LIKE = "Like",
  DISLIKE = "disLike",
}
