import { useSelector } from "react-redux";
import { exchangesSelector } from "../../store/exchanges/exchangesReducer";
import ExchangeItem from "./ExchangeItem";

const ExchangeList = () => {
  const exchanges = useSelector(exchangesSelector);

  return (
    <div className="flex flex-col gap-4 pb-[100px]">
      {exchanges.map((exchange) => (
        <ExchangeItem exchange={exchange} key={exchange.id} />
      ))}
    </div>
  );
};

export default ExchangeList;
