import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { AppProvider } from "./components/Onboarding/context";
import "./index.css";
import router from "./router";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { pick } from "lodash-es";

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
const appBaseUrl = import.meta.env.VITE_APP_BASE_URL;
const SENTRY_FILTERED_DOMAINS = [/https:\/\/([a-zA-Z0-9-]+\.)?google-analytics\.com\//];

Sentry.init({
  debug: import.meta.env.VITE_SENTRY_DEBUG === "true",
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.VITE_ENVIRONMENT !== "local" && Boolean(import.meta.env.VITE_SENTRY_DSN),
  environment: import.meta.env.VITE_ENVIRONMENT,

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  profilesSampleRate: 0.01,
  tracesSampleRate: 0.01,
  normalizeDepth: 5,

  tracePropagationTargets: [new RegExp(`^${appBaseUrl}`), new RegExp(`^${apiBaseUrl}`), /^\//],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: import.meta.env.VITE_ENVIRONMENT === "production" ? 0.01 : 0,
  beforeSend(event) {
    if (SENTRY_FILTERED_DOMAINS.some((domain) => domain.test(event.request?.url || ""))) {
      return null;
    }

    event.user = {
      ...(event.user || {}),
      ...pick(window.Telegram.WebApp.initDataUnsafe.user, ["id", "username", "is_bot"]),
      ...pick(window.Telegram.WebApp.initDataUnsafe, [
        "query_id",
        "version",
        "platform",
        "viewportHeight",
        "viewportStableHeight",
        "start_param",
      ]),
      chat: pick(window.Telegram.WebApp.initDataUnsafe.chat, ["id"]),
      tgVersion: window.Telegram.WebApp.version || "unknown",
    };

    return event;
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </Provider>
  </React.StrictMode>,
);
