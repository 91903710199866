import { useEffect } from "react";
import LastTransaction from "../../components/Pumpadur/Main/LastTransaction";
import TokenList from "../../components/Pumpadur/Main/TokenList";
import TopTokenBanner from "../../components/Pumpadur/Main/TopTokenBanner";
import UserProfileHeader from "../../components/Pumpadur/UserProfileHeader";
import { useAppDispatch } from "../../store/hooks";
import { TokensBuyStatus } from "../../store/token/api/types";
import { fetchHotTokensListAction } from "../../store/token/actions/fetchHotTokensListAction";

const MainPage = () => {
  const topToken = {
    id: 1222,
    logo: "ffff",
    name: "Rough Dog Club",
    ticker: "RDCLB",
    createdBy: 123,
    userName: "sshmidtit",
    progress: "20",
    marcetCap: "12523.97",
    description: "",
    tgChatLink: "",
    tgChannelLink: "",
    xTwitter: "",
    status: TokensBuyStatus.BUEYD,
    createdAt: "",
    updatedAt: "",
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchHotTokensListAction());
  }, []);

  return (
    <div>
      <UserProfileHeader />
      <div className="pt-4">
        <LastTransaction />
      </div>
      <div className="pt-2">
        <TopTokenBanner token={topToken} />
      </div>
      <TokenList />
    </div>
  );
};

export default MainPage;
