import { CryptoAssetDistribution } from "../store/rocket/api/types";
import randomColor from "./randomColor";

const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const distributePercentages = (numTokens: number): number[] => {
  const percentages = [];
  let remainingPercentage = 100;

  for (let i = 0; i < numTokens - 1; i++) {
    const percentage = getRandomInt(1, remainingPercentage - (numTokens - 1 - i));
    percentages.push(percentage);
    remainingPercentage -= percentage;
  }

  percentages.push(remainingPercentage);
  return percentages;
};

export const generateRandomPortfolio = (portfolio: CryptoAssetDistribution[]): CryptoAssetDistribution[] => {
  const numTokens = getRandomInt(1, portfolio.length);
  console.log(portfolio);

  const indices = new Set<number>();
  while (indices.size < numTokens) {
    indices.add(getRandomInt(0, portfolio.length - 1));
  }

  const percentages = distributePercentages(numTokens);

  const updatedPortfolio = portfolio.map((asset, index) => {
    if (indices.has(index)) {
      const percentageIndex = [...indices].indexOf(index);
      return {
        ...asset,
        percentage: percentages[percentageIndex],
        color: randomColor(),
        position: "long" as "long",
      };
    }
    return asset;
  });

  return updatedPortfolio;
};
