import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import closeIcon from "../../assets/icons/close.svg";
import { useEffect, useRef } from "react";

interface BottomSheetProps {
  title?: string;
  children: React.ReactNode;
  isOpened: boolean;
  onClose: () => void;
  closeButton?: boolean;
  canBeClosed?: boolean;
  classNameForChildrenContainer?: string;
  canBeClosedOnTouchMove?: boolean;
  canBeClosedOnClickInside?: boolean;
  className?: string;
  backdropBackgroundColorVisible?: string;
}

export function BottomSheet({
  title,
  children,
  closeButton = true,
  canBeClosed,
  canBeClosedOnTouchMove = false,
  canBeClosedOnClickInside = false,
  isOpened,
  onClose,
  className = "w-full rounded-tl-[24px] rounded-tr-[24px]",
  classNameForChildrenContainer = "px-4 py-[25px]",
  backdropBackgroundColorVisible = "rgba(0,0,0,0.9)",
}: BottomSheetProps) {
  const backdrop = {
    hidden: { backgroundColor: "rgba(0,0,0,0)" },
    visible: { backgroundColor: backdropBackgroundColorVisible },
  };

  const sheet = {
    hidden: { y: "100vh" },
    visible: { y: 0 },
  };

  const root = useRef(document.getElementById("root")!);

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("touchmove", handleTouchMove);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  });

  function handleClose() {
    if (canBeClosed || canBeClosed === undefined) {
      onClose();
    }
  }

  function handleEscapeKey(event: KeyboardEvent) {
    if (event.key === "Escape") {
      handleClose();
    }
  }
  function handleTouchMove() {
    if (canBeClosedOnTouchMove) handleClose();
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpened && (
        <div onClick={handleClose} className="popup">
          <motion.div
            className={`fixed inset-0 flex items-end justify-center z-[1000]`}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={backdrop}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              className={` bg-darkenPurple relative ${className} `}
              variants={sheet}
              transition={{ type: "tween", duration: 0.5 }}
              onClick={(e) => !canBeClosedOnClickInside && e.stopPropagation()}
            >
              <header className={` ${title ? "bg-black/25" : ""} py-[9px] relative px-4`}>
                {title && <h2 className="text-[20px] font-bold text-center">{title}</h2>}
                {closeButton && (
                  <button
                    type="button"
                    aria-label="Close"
                    className="p-[3px] absolute top-[15px] right-3 w-5 h-5"
                    onClick={handleClose}
                  >
                    <img src={closeIcon} className="w-full h-full object-cover" alt="Close" />
                  </button>
                )}
              </header>

              <div className={classNameForChildrenContainer}>{children}</div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    root.current,
  );
}
