interface CoinNameBlockProps {
  symbol: string;
  name?: string;
}

const CoinNameBlock: React.FC<CoinNameBlockProps> = ({ symbol, name }) => {
  return (
    <div className="flex gap-2 text-left items-center text-xs max-h-[25px]">
      <img src={`/${symbol}.svg`} className="w-[24px] h-[24px]" alt="coin" />
      <div>
        <p className="tracking-[0.02em] font-bold">{symbol}</p>
        <p className="text-[#B5A4BF] font-medium ">{name}</p>
      </div>
    </div>
  );
};
export default CoinNameBlock;
