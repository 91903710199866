import balanceIcon from "../../../assets/icons/balance.svg";
import ticketIcon from "../../../assets/icons/ticket.svg";

interface DayCardItemProps {
  day: number;
  rewardAmount?: string | null;
  rewardTicket?: number | null;
  rewardBoostCard?: boolean;
  isLarge: boolean;
  currentDay: number;
}

export default function DayCardItem({
  day,
  rewardAmount,
  rewardTicket,
  rewardBoostCard,
  isLarge,
  currentDay,
}: DayCardItemProps) {
  const isToday = day === currentDay;
  const isTomorrow = day === currentDay + 1;
  const isPast = day < currentDay;
  const isFuture = day > currentDay + 1;

  const getHeaderText = () => {
    if (isToday) return "TODAY";
    if (isTomorrow) return "TOMORROW";
    if (isPast) return `DAY ${day} ✅`;
    return `DAY ${day}`;
  };
  return (
    <div
      className={`relative bg-[rgba(255, 255, 255, 0.11)] text-white text-center ${
        isLarge ? "col-span-3 w-full" : "w-[101px]"
      }`}
      style={{
        height: "101px",
        borderRadius: "16px",
        background: isPast
          ? "rgba(82, 255, 0, 0.18)"
          : "rgba(206, 206, 206, 0.28)",
        opacity: isFuture ? 0.5 : 1,
        border: isToday ? "2px solid #02ff24" : "none",
      }}
    >
      <div
        className="flex justify-center items-center absolute left-0 right-0"
        style={{
          background: isToday ? "#02ff24" : "rgba(206, 206, 206, 0.28)",
          borderRadius: isToday? "12px 12px 0 0":"16px 16px 0 0",
          width: "100%",
          height: isToday? "14px" :"16px",
          fontFamily: "Inter, sans-serif",
          fontStyle: "normal",
          fontWeight: 900,
          fontSize: "10px",
          lineHeight: "24px",
          textAlign: "center",
          color: isToday ? "#4e345f" : "#FFFFFF",
          paddingTop: "5.5px",
          paddingBottom: "3.5px",
          top: "0px",
        }}
      >
        {getHeaderText()}
      </div>

      <div className="flex justify-center items-center h-full">
        <div
          className={`flex flex-col justify-center items-center`}
          style={{ height: "auto", marginTop: "16px" }}
        >
          {isLarge && (
            <div
              className="text-white font-[var(--font-family)] font-bold text-[18px] leading-[133%] text-center mb-2"
              style={{
                width: "95px",
                height: "24px",
                left: "calc(50% - 47.5px)",
                top: "32px",
                fontFamily: "Inter, sans-serif",
                fontStyle: "normal",
                fontWeight: 900,
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "center",
                letterSpacing: "-0.03em",
                textTransform: "uppercase",
                background: "linear-gradient(90deg, #92CBFF 0%, #FFFFFF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }}
            >
              Big Day
            </div>
          )}

          <div
            className={`flex ${isLarge ? "flex-row gap-3" : "flex-col"} justify-center items-center`}
            style={{ height: "auto" }}
          >
            {rewardAmount && (
              <div className="text-lg font-bold flex items-center gap-1">
                <img src={balanceIcon} className="w-[14px] h-auto" alt="Balance Icon" />
                <span
                  className="font-[var(--font-family)] font-bold text-[18px] leading-[133%] text-center text-[#ffd501]"
                >
                  {rewardAmount}
                </span>
              </div>
            )}
            {rewardBoostCard && (
              <div className="text-lg font-bold flex items-center gap-1">
                <span
                  className="font-[var(--font-family)] font-bold text-[18px] leading-[133%] text-center text-[#ffd501]"
                  style={{
                    fontFamily: "Inter, sans-serif",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                >
                 + Special Card
                </span>
              </div>
            )}
            {rewardTicket && (
              <div className="text-lg font-bold flex items-center gap-1">
                {isLarge && <span className="text-[18px] leading-[133%] text-center text-[var(--hamstercard.pro-white-mine-shaft)]">+</span>}
                <img src={ticketIcon} className="w-[20px] h-auto" alt="Ticket Icon" />
                <span className="font-[var(--font-family)] font-bold text-[18px] leading-[133%] text-center text-[var(--hamstercard.pro-white-mine-shaft)]">
                  {rewardTicket}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
