import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useRouterLock() {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      navigate(0);
    };

    window.addEventListener("popstate", handlePopState);

    window.history.pushState(null, "", window.location.pathname);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
}
