import { RuleCardsEnum } from "../../../store/cards/api/types";

//icons
import balanceIcon from "../../../assets/icons/balance.svg";
import ticketIcon from "../../../assets/icons/ticket.svg";
import { nFormatter } from "../../../helpers/moneyFormat";

interface CardRuleProps {
  executedRule: boolean;
  typeOfRule: RuleCardsEnum;
  rule: string;
  cardPrice: number;
  isMaxLevel: boolean;
}

const CardRule = ({ executedRule, cardPrice, rule, typeOfRule, isMaxLevel }: CardRuleProps) => {
  return (
    <div className="flex flex-1  justify-center items-center  w-full">
      {!executedRule ? (
        typeOfRule !== RuleCardsEnum.NEW_FRIENDS ? (
          <span className="h-full flex items-center  rounded-b-2xl p-1 w-full py-[2.14vh] bg-[#FFFFFF26] px-[3vw]">
            {rule}
          </span>
        ) : (
          <div className=" h-full w-full flex gap-[1px] ">
            <div className="w-full flex gap-1 font-bold justify-center py-[2.14vh] items-center  bg-[#FFFFFF26] rounded-b-2xl ">
              <img className=" w-4 h-4  object-cover" loading="lazy" src={ticketIcon} alt="currency" />
              <span>Use ticket</span>
            </div>
          </div>
        )
      ) : (
        <strong className="h-full rounded-b-2xl p-1 w-full py-[2.14vh] bg-[#FFFFFF26] flex-1 flex justify-center gap-1 items-center px-[7vw]">
          {isMaxLevel ? (
            <span>Max</span>
          ) : (
            <>
              <p className="size-3">
                <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
              </p>
              <p>{nFormatter(cardPrice || 0)}</p>
            </>
          )}
        </strong>
      )}
    </div>
  );
};

export default CardRule;
