import { useSelector } from "react-redux";
import { ProgressbarBox } from "../ui/ProgressbarBox";
import { currentLeagueSelector } from "../../store/account/account";

const StakingConditions = () => {
  const currentLeague = useSelector(currentLeagueSelector);

  return (
    <ProgressbarBox progressPercent={0}>
      <small className="text-[12px] text-center flex flex-col w-full">
        <span>
          <span className="text-white font-medium">Staking Duration</span>
        </span>

        <strong className="flex justify-center items-center gap-x-[0.286em] text-[14px] text-yellow">
          {currentLeague?.stakingTime} hours
        </strong>
      </small>
    </ProgressbarBox>
  );
};

export default StakingConditions;
