import { useSelector } from "react-redux";
import Input from "../../../ui/Input";
import { cryptoProfileBalanceSelector } from "../../../../store/cryptoProfile/cryptoProfileReducer";

interface SolanaAmountFieldProps {
  solanaAmount?: string | number;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SolanaAmountField = ({ handleInputChange, solanaAmount }: SolanaAmountFieldProps) => {
  const phantomWalletSolanaBalance = useSelector(cryptoProfileBalanceSelector);
  return (
    <div className="relative">
      <Input
        bgColor="rgba(0, 0, 0, 0.25)"
        value={solanaAmount}
        handleChange={handleInputChange}
        className="font-bold text-[20px] pr-[85px]"
        containerClassname="h-[56px]"
        type="number"
      />
      <div className="absolute bottom-3 right-[15px] z-10 leading-none text-right">
        <p className="uppercase font-semibold text-[16px]">SOL</p>
        <p className="text-xs font-medium text-[#95879e]">Balance: {phantomWalletSolanaBalance}</p>
      </div>
    </div>
  );
};

export default SolanaAmountField;
