import React from "react";

interface RocketsFilterProps {
  onSelectLimit: (limit: string) => void;
  activeTab: string;
}

const RocketsFilter: React.FC<RocketsFilterProps> = ({ onSelectLimit, activeTab }) => {
  const handleTabClick = (tab: string) => {
    onSelectLimit(tab);
  };

  return (
    <div className="flex justify-between font-semibold px-1 pt-1 h-[40px] mb-3">
      {["portfolio", "passengers"].map((status) => (
        <button
          key={status}
          className={`text-[16px] w-1/2 pb-[10px]  border-b border-solid ${
            activeTab === status ? "text-[#FEA836]  border-[#FEA836]" : "text-[#FFFFFF] opacity-75  border-[#46364D]"
          }`}
          onClick={() => handleTabClick(status)}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}{" "}
        </button>
      ))}
    </div>
  );
};

export default RocketsFilter;
