import { ReactNode } from "react";

interface TopBlockProps {
  children: ReactNode;
}

const TopBlock: React.FC<TopBlockProps> = ({ children }) => {
  return <div className="bg-[#00000040] px-4 border-solid border-t-[1px] border-white/10 pt-4">{children}</div>;
};
export default TopBlock;
