import balanceIcon from "../../assets/icons/balance.svg";
import Button from "../../components/ui/Button.tsx";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks.ts";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer.ts";
import { useEffect } from "react";
import cosmonautImage from "../../assets/img/claim-cosmonaut.png";
import { useRouterLock } from "../../hooks/useRouterLock.ts";

export default function HowToEarnPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useRouterLock();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
  }, []);

  const steps = ["Collect Daily Portfolios", "Invite Friends", "Complete Tasks"];

  return (
    <div className="flex flex-1 flex-col pb-[17px]">
      <div className="grow flex flex-col items-center pt-[13vh] pb-[100px]">
        <h1 className="text-[24px] font-bold leading-none flex items-center text-center gap-x-[9px]">
          <span>How to earn</span>

          <span className="text-[1.34em] flex items-center gap-x-[0.22em]">
            <span className="size-[0.8125em]">
              <img src={balanceIcon} alt="Rocket" className="size-full object-cover" />
            </span>

            <span className="leading-none font-black text-orangeLight">RCT</span>
          </span>
        </h1>

        <ul className="mt-[42px] space-y-6">
          {steps.map((step) => (
            <li key={step} className="flex text-base items-center gap-x-2 leading-[1.5]">
              <div className="shrink-0">
                <img className="w-[1.375em] h-auto" src="/feature-checkmark.png" loading="lazy" alt="Checkmar" />
              </div>
              <span>{step}</span>
            </li>
          ))}
        </ul>

        <div className="grow relative w-full mt-[30px] max-w-[259px] min-w-[180px] min-h-[160px] max-h-[262px] mx-auto">
          <img
            rel="preload"
            className="h-full w-full object-contain block object-top mx-auto absolute top-0 left-1/2 -translate-x-1/2"
            src={cosmonautImage}
            alt="Cosmonaut"
            loading="lazy"
          />
        </div>
      </div>

      <div className="fixed bottom-0 right-0 left-0 shrink-0 px-4 pb-4">
        <Button
          text="NEXT"
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          className="text-[14px] py-[1.14em] disabled:!bg-lightGrey"
          handleClick={() => {
            dispatch(changeStatusBottomMenu(true));
            navigate("/");
          }}
        />
      </div>
    </div>
  );
}
