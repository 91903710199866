const ProgressLine = ({ progress }: { progress: number }) => {
  return (
    <div className=" flex gap-[1px] rounded-full w-full border-solid border-[1px] border-[#FFFFFF40]  items-center  mt-[10px]">
      <div
        className={`relative h-2  rounded-full`}
        style={{
          width: `${progress}%`,
          background: "linear-gradient(90deg, #F37E3B 0%, #FFAE34 100%)",
        }}
      ></div>
    </div>
  );
};

export default ProgressLine;
