import { useSelector, useStore } from "react-redux";
import {
  generateGameCoins,
  isCoinCanBeClicked,
  registerCoinClick,
  setActiveGame,
} from "../../store/clicker/clickerReducer";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { debounce, isEqual } from "lodash-es";
import { useAppDispatch } from "../../store/hooks";
import { RANDOMIZE_COINS_INTERVAL_MS } from "../../constants/clicker.ts";
import { RootState } from "../../store/store.ts";
import { ClickerGameCanvas } from "./ClickerGameCanvas.tsx";
import { Coin, Portfolio, PortfolioCoin } from "../../store/clicker/types.ts";
import {
  activeGameCoinsSelector,
  activePortfolioIdSelector,
  clickCostSelector,
  coinsSelector,
  dailyQuestSelector,
  isGameActiveSelector,
} from "../../store/clicker/selectors.ts";
import { useTelegramApp } from "../../hooks/useTelegramApp.ts";
import { saveClickProgressAction } from "../../store/clicker/actions/saveClickProgressAction.ts";
import { isOnboardingFinishedSelector } from "../../store/onboarding/selectors.ts";
import { activeGameSelector } from "../../store/clicker/selectors";
import { ClickerGameTimer, ClickerGameTimerRef } from "./GameTimer.tsx";
import { useWatch } from "../../hooks/usePreviousValue.ts";
import * as Sentry from "@sentry/react";
import { SetCoinsProgress } from "../../api/clicker/types.ts";
import { clearLocalStorage, saveLocally } from "../../helpers/saveCoinsProgressLocally.ts";
// import { fetchInitialClickerDataAction } from "../../store/clicker/actions/fetchInitialClickerDataAction.ts";

export interface ClickerGameProps {
  allCoins: Coin[];
  isGameActive: boolean;
  activePortfolioId?: Portfolio["id"];
}

interface ClickerGameRef {
  generateNewGame: () => void;
}

export const MemoizedGame = React.memo(
  forwardRef<ClickerGameRef, ClickerGameProps>(({ allCoins, isGameActive, activePortfolioId }, ref) => {
    const store = useStore<RootState>();

    // const getIsActivePortfolioNotExpired = useCallback(() => {
    //   const activePortfolio = store.getState().clicker.activePortfolio?.portfolio;
    //   if (!activePortfolio) return false;
    //   return activePortfolio?.activeFrom < new Date() && activePortfolio?.activeTo > new Date();
    // }, []);

    const dispatch = useAppDispatch();
    const telegramApp = useTelegramApp();

    const activeGame = useSelector(activeGameSelector);
    const clickCost = useSelector(clickCostSelector);
    const isOnboardingFinished = useSelector(isOnboardingFinishedSelector);

    const gameTimeRemaining = useMemo(() => {
      if (!activeGame?.untilDate) {
        return RANDOMIZE_COINS_INTERVAL_MS;
      }

      const remainingTime = activeGame?.untilDate?.getTime() - new Date().getTime();
      return remainingTime > 0 ? remainingTime : RANDOMIZE_COINS_INTERVAL_MS;
    }, [activeGame]);

    const timerRef = useRef<ClickerGameTimerRef>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const isInitialized = useRef(true);

    const generateNewGame = () => {
      // Set active game and await for user start the game
      dispatch(
        setActiveGame({
          portfolioId: activePortfolioId!,
          untilDate: null, // Set untilDate to null to prevent immediate start
        }),
      );

      // Generate coins
      dispatch(generateGameCoins(isOnboardingFinished));
    };

    useImperativeHandle(ref, () => ({
      generateNewGame: () => {
        timerRef.current?.clear();
        generateNewGame();
        timerRef.current?.start();
      },
    }));

    const newGame = (immediateStart?: boolean) => {
      generateNewGame();

      if (immediateStart) {
        startNewGame();
      }
    };

    useWatch(isPlaying, (current, prev) => {
      if (prev && !current) {
        newGame(true);
      }
    });

    useWatch(activePortfolioId, (current, prev) => {
      if (prev !== undefined && current !== prev) {
        timerRef.current?.clear();
        newGame(true);
      }
    });

    const startNewGame = () => {
      dispatch(
        setActiveGame({
          portfolioId: activePortfolioId!,
          untilDate: new Date(Date.now() + RANDOMIZE_COINS_INTERVAL_MS),
        }),
      );

      timerRef.current?.start();
    };

    useEffect(() => {
      timerRef.current?.clear();
      const gameHasBaseDependencies = allCoins.length;

      if (!gameHasBaseDependencies) return;

      const isGameAlreadyGenerated = Boolean(activeGame);

      // Generate new game if onboarding finished and game not generated yet
      if (!isGameAlreadyGenerated) {
        return newGame();
      }

      const isGameStarted = Boolean(activeGame!.untilDate);

      // If the game already generated, but not started - nothing to do
      if (!isGameStarted) return;
      // const state = store.getState();
      const isGameStillActive = Boolean(activeGame!.untilDate! > new Date());

      // const isPortfolioNotExpired = getIsActivePortfolioNotExpired();

      // If the game already started and still active - just start the game with remaining time
      if (isGameStillActive) {
        return timerRef.current?.start();
      }

      // if (isPortfolioNotExpired) {
      newGame(true);
      // }
    }, []);

    useWatch(isGameActive, (isGameActive) => {
      if (!isGameActive) {
        timerRef.current?.clear();
      }
    });

    const handleCoinClick = (id: PortfolioCoin["id"]) => {
      if (!timerRef.current?.isPlaying()) {
        startNewGame();
      }

      isInitialized.current = false;

      const state = store.getState();
      const activePortfolio = state.clicker.dailyQuest?.portfolios.find(({ id }) => id === activePortfolioId);

      if (!activePortfolio) {
        Sentry.setContext("Active portfolio id", { id });
        Sentry.captureException("GAME: Active portfolio not found");
      }

      const isCanBeClicked = isCoinCanBeClicked(id, activePortfolio ?? null);

      try {
        telegramApp.HapticFeedback.impactOccurred(isCanBeClicked.isAllowed ? "light" : "heavy");
      } catch (err) {
        Sentry.setTags({
          telegramError: "HapticFeedback.impactOccurred error",
        });

        Sentry.captureException(err);
      }

      dispatch(registerCoinClick({ id }));

      return isCanBeClicked;
    };

    return (
      <div className="relative grow flex">
        <ClickerGameCanvas clickCost={clickCost || 0} handleCoinClick={handleCoinClick} isPlaying={isPlaying} />

        <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
          <ClickerGameTimer
            ref={timerRef}
            remainingTime={gameTimeRemaining}
            onStart={() => {
              setIsPlaying(true);
            }}
            onEnd={() => {
              setIsPlaying(false);
            }}
          />
        </div>
      </div>
    );
  }),
  isEqual,
);

const MIN_DIFF_TO_SAVE = 2;

export const ClickerGame = () => {
  const store = useStore<RootState>();
  const dispatch = useAppDispatch();
  const dailyQuest = useSelector(dailyQuestSelector);
  const activePortfolioId = useSelector(activePortfolioIdSelector);
  const activeGameCoins = useSelector(activeGameCoinsSelector);
  const isGameActive = useSelector(isGameActiveSelector);

  const gameRef = useRef<ClickerGameRef>(null);

  const activePortfolio = useMemo(() => {
    return dailyQuest?.portfolios.find(({ id }) => id === activePortfolioId);
  }, [activePortfolioId, dailyQuest]);

  const activeGameCoinsWithProgress = useMemo(() => {
    return activeGameCoins
      .filter(({ id }) => {
        return activePortfolio?.coins.some((coin) => coin.id === id);
      })
      .map((coin) => {
        return {
          ...coin,
          progress: activePortfolio?.coins.find(({ id }) => id === coin.id)?.progress || 0,
        };
      });
  }, [activeGameCoins, activePortfolio]);

  const allCoins = useSelector(coinsSelector);

  const lastSavedProgress = useRef<null | SetCoinsProgress["coins"]>(null);
  const debouncedSave = useRef(
    debounce(
      async (activePortfolio: Portfolio | null, force?: boolean) => {
        if (!activePortfolio) {
          return;
        }

        const progress = activePortfolio.coins.map(({ id, progress }) => ({
          id,
          progress,
        }));

        const calculateTotalProgress = (coins: SetCoinsProgress["coins"]) => {
          return coins.reduce((acc, { progress }) => (acc += progress), 0);
        };

        const currentTotalProgress = calculateTotalProgress(progress);
        const totalLastSavedProgress = lastSavedProgress.current
          ? calculateTotalProgress(lastSavedProgress.current)
          : 0;

        const diff = currentTotalProgress - totalLastSavedProgress;

        const allCompleted = activePortfolio.progress === 100;

        if (!allCompleted && ((force && diff <= 0) || (!force && diff <= MIN_DIFF_TO_SAVE))) {
          return;
        }

        const result = await dispatch(
          saveClickProgressAction({
            coins: progress,
            energy: 0,
          }),
        );

        if (saveClickProgressAction.fulfilled.match(result)) {
          lastSavedProgress.current = progress;
          clearLocalStorage();
        } else {
          saveLocally({ coins: progress, energy: 0 });
        }
      },
      500,
      { maxWait: 25000 },
    ),
  );

  useEffect(() => {
    const { activePortfolio, dailyQuest } = store.getState().clicker;
    if (!dailyQuest || !activePortfolio) return;
    lastSavedProgress.current = activePortfolio.portfolio.coins.map(({ id, progress }) => ({ id, progress }));

    return () => {
      const { activePortfolio, dailyQuest } = store.getState().clicker;
      if (!dailyQuest || !activePortfolio) return;

      debouncedSave.current(activePortfolio.portfolio, true);
      debouncedSave.current.flush();
    };
  }, []);

  useWatch(activeGameCoinsWithProgress, (current, prev) => {
    if (!current.length || !prev.length) {
      return;
    }
    const leftCoinsToCompleteInCurrentGame = current.filter(({ progress }) => progress < 100);

    if (!leftCoinsToCompleteInCurrentGame.length) {
      gameRef.current?.generateNewGame();
    }
  });

  // Save progress on each activePortfolio progress change
  useWatch(activePortfolio, (current, prev) => {
    if (current?.id === prev?.id && current?.progress !== prev?.progress) {
      debouncedSave.current(current ?? null);
    }
  });

  return activePortfolio !== null ? (
    <MemoizedGame ref={gameRef} allCoins={allCoins} isGameActive={isGameActive} activePortfolioId={activePortfolioId} />
  ) : null;
};
