import { useParams } from "react-router-dom";
import Title from "../components/ui/Title";
import BlockInfo from "../components/ui/BlockInfo";
import IconWithText from "../components/ui/IconWithText";
import StatusRound from "../components/Round/StatusRound";

import rocketIcon from "../assets/icons/rocket.svg";
import rocketWhiteIcon from "../assets/icons/rocket-white.svg";
import coinsIcon from "../assets/icons/coins.svg";
import bankIcon from "../assets/icons/bank.svg";
import dollarIcon from "../assets/icons/dollar.svg";
import RocketsStatusFilter from "../components/Rocket/RocketsStatusFilter";
import TopPanel from "../components/TopPanel";
import SearchBar from "../components/Rocket/SearchBar";
import RocketList from "../components/Rocket/RocketList";
import { useEffect, useState } from "react";
import { boostRocket } from "../store/menu/bottomMenuReducer";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setRound } from "../store/round/roundReducer";
import ModalWindow from "../components/ModalWindow";
import BottomBlock from "../components/ModalWindow/BottomBlock";
import RocketAdded from "../components/Rocket/Custom/RocketAdded/ModalContent";
import PayoutsTable from "../components/Round/PayoutsTable";
import { Rocket } from "../store/rocket/api/types";

export default function RoundPage() {
  const { roundId } = useParams();
  const dispatch = useAppDispatch();

  const round = useAppSelector((state) => state.round.round);

  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<string>("pending");
  const [payoutsTableModal, setPayoutsTableModal] = useState<boolean>(false);

  const modalRocketVisible = useAppSelector((state) => state.rocket.modalRocket);

  function handleScrollChange() {
    // setIsHidden(window.scrollY !== 0);

    const targetElement = document.getElementById("list");

    if (targetElement) {
      const { top } = targetElement.getBoundingClientRect();
      if (top <= 80) {
        setIsHidden(window.scrollY !== 0);
        console.log("Користувач дійшов до певного блоку!");
      } else {
        setIsHidden(false);
      }
    }
  }

  const readyRockets = round?.rockets.filter((rocket) => getFullnessForRocket(rocket, round.buyIn) == 100);
  const pendingRockets = round?.rockets.filter((rocket) => getFullnessForRocket(rocket, round.buyIn) !== 100);
  console.log(readyRockets);

  const handleSelectLimit = (status: string) => {
    console.log("Selected status:", status);
    setActiveStatus(status);
  };

  const handlePayoutsModal = () => {
    setPayoutsTableModal(!payoutsTableModal);
  };

  useEffect(() => {
    dispatch(setRound(roundId));
  }, []);

  useEffect(() => {
    if (round?.status === "registering") dispatch(boostRocket(true));
    return () => {
      dispatch(boostRocket(false));
    };
  }, [round]);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollChange);
    return () => {
      window.removeEventListener("scroll", handleScrollChange);
    };
  }, []);
  return (
    <div>
      <div className="bg-[#00000040] mx-[-16px] px-4">
        <TopPanel page={round ? round.status : ""} />
        <div className="relative mt-[25px]">
          <div>
            <Title text={round?.title || ""} />
            <div className="grid grid-cols-2 grid-rows-2 gap-1">
              <div className="row-span-1 col-span-1">
                <BlockInfo icon={coinsIcon} text="Rocket Buy-in" value={round?.buyIn} />
              </div>
              <div className="row-span-1 col-span-1">
                <BlockInfo icon={bankIcon} text="Prize pool" value={round?.prize} />
              </div>
              <div className="row-span-1 col-span-1">
                <BlockInfo icon={rocketIcon} text="Rockets in play" value={round?.rockets.length} />
              </div>
              <div className="row-span-1 col-span-1">
                <StatusRound time={round?.time || ""} status={round?.status || "planned"} width="100%" textSize="20" />
              </div>
            </div>
            <div className="flex justify-center">
              <button className="mt-[12px] pb-[12px]" onClick={handlePayoutsModal}>
                <IconWithText icon={dollarIcon} text="Payouts table" style="text-[#A98DBD] underline text-center" />
              </button>
            </div>
          </div>
          <div
            className={`fixed top-0 right-0 left-0 flex justify-between items-center w-full z-50 mb-[22px] bg-black p-[14px] ${
              isHidden
                ? "transition-top duration-1000 opacity-100 top-0"
                : "transition-top duration-1000 opacity-100  top-[-100px]"
            }`}
          >
            <div className="h-[45px] flex flex-col gap-[6px]">
              <h1 className="text-center text-xl font-bold ">{round?.title}</h1>
              <div className="flex text-[#989898] text-xs gap-3">
                <div>
                  <span className="mr-1"> Buy-in:</span>
                  <span className="text-white font-bold">{round?.buyIn}</span>
                </div>
                <div>
                  <span className="mr-1">Prize:</span>
                  <span className="text-white font-bold">{round?.prize}</span>
                </div>
                <IconWithText icon={rocketWhiteIcon} text={round?.rockets?.length || 0} style="font-bold text-white" />
              </div>
            </div>
            <div className="w-[88px]">
              <StatusRound time={round?.time || ""} status={round?.status || "planned"} />
            </div>
          </div>
          {round?.status === "registering" && (
            <RocketsStatusFilter onSelectStatus={handleSelectLimit} activeStatus={activeStatus} />
          )}
        </div>
      </div>
      <div className="mt-[18px]">
        <SearchBar />
        <div id="list" className=" text-center">
          {round?.status == "running" ? (
            <div className={`${isHidden ? "mb-[300px]" : ""} scroll-smooth`}>
              <RocketList rockets={round?.rockets} status={round?.status || ""} />
            </div>
          ) : (
            <div className={`${isHidden ? "mb-[280px]" : ""} `}>
              {activeStatus === "pending" && <RocketList rockets={pendingRockets} status={round?.status || ""} />}
              {activeStatus === "ready" && <RocketList rockets={readyRockets} status={round?.status || ""} />}
            </div>
          )}
        </div>
      </div>
      {modalRocketVisible && (
        <ModalWindow headerText={`Congratulations!`}>
          <BottomBlock>
            <RocketAdded />
          </BottomBlock>
        </ModalWindow>
      )}
      {payoutsTableModal && (
        <ModalWindow headerText={`Payouts table`} closeModal={handlePayoutsModal}>
          <BottomBlock>
            <PayoutsTable round={round} />
          </BottomBlock>
        </ModalWindow>
      )}
    </div>
  );
}

export const getFullnessForRocket = (rocket: Rocket, buyIn: number) => {
  const fullness = rocket?.passengers?.reduce((acc, passenger) => acc + passenger.deposite, 0) || 0;
  console.log((100 * fullness) / buyIn);

  return (100 * fullness) / buyIn;
};
