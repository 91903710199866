import { ReactNode } from "react";
import { LEFT_TO_RIGHT_LIGHT_GRAY_RADIAL_GRADIENT } from "../../constants/style/graditents.ts";
import { GradientBorder } from "./GradientBorder.tsx";

interface GradientCardProps {
  children: ReactNode;
  backgroundColor?: string;
}

export function GradientCard({ children, backgroundColor = "#4C3A57" }: GradientCardProps) {
  return (
    <GradientBorder
      roundedClass="rounded-2xl"
      backgroundColor={backgroundColor}
      className="flex"
      gradient={LEFT_TO_RIGHT_LIGHT_GRAY_RADIAL_GRADIENT}
    >
      {children}
    </GradientBorder>
  );
}
