import style from "./Round.module.css";

const CoinSubTitle = () => {
  interface Item {
    title: string;
    width: string;
  }

  const items: Item[] = [
    { title: "Coin", width: "140px" },
    { title: "Cost", width: "166px" },
    { title: "24h", width: "60px" },
  ];

  return (
    <div className="h-[11px] relative w-full ml-[-5px]  justify-between flex text-[#FFFFFF61] text-[11px]">
      {items.map(({ title, width }) => (
        <div key={title} className={`flex w-[${width}]`} style={{ width: width, minWidth: width }}>
          <div className={`relative top-[9px] w-4 h-[1px] ${style.borderSubTitle}`}></div>
          <button key={title}>{title.charAt(0).toUpperCase() + title.slice(1)} </button>
        </div>
      ))}
    </div>
  );
};
export default CoinSubTitle;
