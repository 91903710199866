import { AppBg } from "../ui/AppBg";
import { ScreenLayout } from "../ui/ScreenLayout";
import balanceIcon from "../../assets/icons/balance.svg";

import { moneyFormat } from "../../helpers/moneyFormat";
import Button from "../ui/Button";
import { Exchange } from "../../store/exchanges/types";
import { ExternalImage } from "../ui/ExternalImage";
// import { calculateRewards } from "../../helpers/calculateRewards.ts";

interface ClaimRewardScreenProps {
  isOpened: boolean;
  isPending: boolean;
  exchange: Exchange;
  onClaim: () => void;
}

export function ClaimRewardScreen({ isOpened, isPending, onClaim, exchange }: ClaimRewardScreenProps) {
  function handleClaim() {
    onClaim();
  }

  return isOpened ? (
    <>
      <ScreenLayout className="fixed top-0 left-0 z-[999] w-full h-full flex flex-col">
        <AppBg position="absolute" />
        <div className="pt-[15.2vh]">
          {exchange.iconUrl2 && (
            <ExternalImage src={exchange.iconUrl2} alt={exchange.title} className="h-[28px] w-full" />
          )}
        </div>
        <p className="text-[24px] font-bold leading-8 text-center pt-[5vh]">
          Your {exchange.title} Account
          <br /> is Verified!
        </p>
        <div className=" relative grow flex flex-col pb-[17px]">
          <div className=" grow flex flex-col">
            <div className="grow flex flex-col items-center">
              <img
                rel="preload"
                className="  h-[150px] object-contain block object-top mx-auto mt-[1vh] mb-[1vh] "
                src="/coin-reward.png"
                alt="Cosmonaut"
                loading="lazy"
              />
              <strong className="flex justify-center items-center gap-x-[0.286em] text-[36px] text-yellow font-bold">
                + {moneyFormat(3000)}
                <span className="size-[26px]">
                  <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
                </span>
              </strong>{" "}
              <p className="text-center text-[16px] pt-[2.4vh]">
                Thank you for registering.
                <br /> We appreciate your activity.
                <br /> Claim your reward!
              </p>
            </div>

            <div className="shrink-0 pt-8">
              <Button
                text="Ok"
                isPending={isPending}
                style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
                className={`text-[14px] py-[1.14em] disabled:!bg-lightGrey}`}
                handleClick={handleClaim}
              />
            </div>
          </div>
        </div>
      </ScreenLayout>
    </>
  ) : null;
}
