// useFocusHandler.ts
import { useRef } from "react";

const useFocusHandler = <T extends HTMLTextAreaElement | HTMLInputElement>(paddingBottom: number) => {
  const inputRef = useRef<T>(null);

  const handleFocus = (e: React.FocusEvent<T>) => {
    setTimeout(() => {
      // Зупиняємо стандартну поведінку прокрутки
      e.preventDefault();

      const container = document.querySelector(".main-container") as HTMLElement;
      if (container) {
        container.style.paddingBottom = `${paddingBottom}px`;
      }

      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        console.log("rect", rect);

        console.log("Координати елемента відносно вікна браузера:");
        console.log("Top:", rect.top);
        inputRef.current.scrollIntoView({ behavior: "instant", block: "start" });
      }
    }, 100);
  };

  const handleBlur = () => {
    const container = document.querySelector(".main-container") as HTMLElement;
    if (container) {
      container.style.paddingBottom = "";
    }
  };

  return { inputRef, handleFocus, handleBlur };
};

export default useFocusHandler;
