import { useSelector } from "react-redux";
import { exchangeSelector } from "../store/account/account";
import { ExternalImage } from "./ui/ExternalImage";

const ExchangeSummary = ({ imgSize = 13 }: { imgSize?: number }) => {
  const exchange = useSelector(exchangeSelector);
  return (
    <div className="flex gap-2 items-center justify-center font-bold tracking-[0.02em]">
      {exchange?.iconUrl ? (
        <ExternalImage src={exchange.iconUrl} alt={exchange.title} width={imgSize} height={imgSize} />
      ) : (
        <img src={`/${exchange?.title}.svg`} alt={exchange?.title} className="size-[13px]" />
      )}
      <span className="block font-bold">{exchange?.title}</span>
    </div>
  );
};

export default ExchangeSummary;
