import React from "react";
import { GradientBorder } from "./GradientBorder.tsx";
import { LEFT_TO_RIGHT_LIGHT_GRAY_RADIAL_GRADIENT } from "../../constants/style/graditents.ts";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  bgColor?: string;
  containerClassname?: string;
}

const Input: React.FC<InputProps> = ({
  value,
  className = "",
  handleChange,
  containerClassname = "h-[48px]",
  bgColor = "#1F1F1F",
  ...attrs
}) => {
  return (
    <GradientBorder
      gradient={LEFT_TO_RIGHT_LIGHT_GRAY_RADIAL_GRADIENT}
      className={`relative w-full ${containerClassname}`}
      roundedClass="rounded-2xl"
      backgroundColor={bgColor}
    >
      <input
        type="text"
        value={value}
        {...attrs}
        onChange={handleChange}
        className={`appearance-none relative z-10 w-full h-full px-4 outline-none rounded-2xl truncate bg-mediumGrey/50 ${className}`}
      />
    </GradientBorder>
  );
};

export default Input;
