import { useState } from "react";
import SolanaAmountField from "./SolanaAmountField";
import Chip from "../../../ui/Chip";
import Button from "../../../ui/Button";
import { TradeType, setBackgroundTradeType } from "./FilterTrade";
import ConfirmPurchase from "./ConfirmPurchase";

const AmountOption = ({
  amount,
  onClick,
  selectedAmountOption,
}: {
  amount: number;
  selectedAmountOption: number;
  onClick: (amount: number) => void;
}) => {
  const isActiveAmount = selectedAmountOption === amount;
  return (
    <div className="flex-shrink-0" onClick={() => onClick(amount)}>
      <Chip
        text={` ${amount} SOL`}
        bgColor={`${isActiveAmount ? "bg-white/30" : "bg-white/15"} `}
        textSize={`text-[14px] ${isActiveAmount ? "text-white" : "text-white/50"}`}
        paddingX="px-[17px]"
        height="h-[32px]"
      />
    </div>
  );
};

const TransactionControl = ({ activeTradeType }: { activeTradeType: TradeType }) => {
  const [isOpened, setIsOpened] = useState(false);

  const amountOptions = [3, 50, 100, 200];

  const [solanaAmount, setSolanaAmount] = useState<number>(amountOptions[0]);
  const [selectedAmountOption, setSelectedAmountOption] = useState<number>(amountOptions[0]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSolanaAmount(Number(e.target.value));
  };

  const handleAmountClick = (amount: number) => {
    setSolanaAmount(amount);
    setSelectedAmountOption(amount);
  };

  const handleTransaction = () => {
    setIsOpened(true);
  };
  return (
    <div>
      <SolanaAmountField handleInputChange={handleInputChange} solanaAmount={solanaAmount} />
      <div className="flex justify-start space-x-[6px] overflow-x-auto overflow-y-hidden  scrollbar-hide pt-2">
        {amountOptions.map((amount) => (
          <AmountOption
            key={amount}
            amount={amount}
            selectedAmountOption={selectedAmountOption}
            onClick={handleAmountClick}
          />
        ))}
      </div>
      <p className="pt-4 pb-3 text-center text-[12px] text-lavenderGray">You will give ≈ 27.756.487.45$EPMP</p>
      <Button handleClick={handleTransaction} text={activeTradeType} style={setBackgroundTradeType(activeTradeType)} />
      <ConfirmPurchase isOpened={isOpened} setIsOpened={setIsOpened} solanaAmount={solanaAmount} />
    </div>
  );
};

export default TransactionControl;
