import { useSelector } from "react-redux";
import TokenItem from "../Token/TokenItem/TokenItem";
import { myTokensSelector } from "../../../store/token/tokensReducer";
import { useEffect, useState } from "react";
import { Token, TokensBuyStatus } from "../../../store/token/api/types";
import Title from "../../ui/Title";

const CreatedTokensList = () => {
  const [createdTokens, setCreatedTokens] = useState<Token[] | null>(null);
  const myTokens = useSelector(myTokensSelector);

  useEffect(() => {
    setCreatedTokens(myTokens?.filter((token) => token.status === TokensBuyStatus.CREATED));
  }, [myTokens]);

  return (
    <>
      {createdTokens && (
        <div className="pt-8">
          <Title text="Created Tokens" />
          <div className="flex flex-col gap-2">
            {createdTokens?.map((token) => <TokenItem key={token.id} token={token} />)}
          </div>
        </div>
      )}
    </>
  );
};

export default CreatedTokensList;
