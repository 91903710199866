// import CoinDailyPriceChangeBlock from "./CoinDailyPriceChangeBlock";
// import CoinNameBlock from "./CoinNameBlock";
// import CoinPriceBlock from "./CoinPriceBlock";

import { Coin } from "../../store/rocket/api/types";

const CoinItem: React.FC<{
  coin: Coin;
}> = ({ coin }) => {
  const { symbol, name, cost, priceChange, priceChangePercentage } = coin;

  return (
    <div className="flex items-center justify-between text-xs pt-[15px] pb-[16px] border-solid border-b-[1px] border-[#FFFFFF15] font-bold ml-[3px]">
      <div className="max-h-[25px] flex gap-2 w-[140px] min-w-[140px]">
        <img src={`/${symbol}.svg`} className="w-[24px] h-[24px]" alt="coin" />
        <div>
          <p className="tracking-[0.02em]">{symbol}</p>
          <p className="text-[#B5A4BF] font-medium ">{name}</p>
        </div>
      </div>
      <div className="w-[166px] min-w-[166px]">{cost}</div>
      <div className={`${+priceChange < 0 ? "text-[#FF005C]" : "text-[#45D700]"} font-bold max-h-[25px]`}>
        <p>{priceChange}</p>
        <p>{priceChangePercentage}</p>
      </div>
    </div>
  );
};
export default CoinItem;
