import { useSelector } from "react-redux";
import { League } from "../../store/leagues/api/types";
import LeaderItem from "./LeaderItem";
import { currentLeagueSelector } from "../../store/account/account";
// import { PreloaderCircle } from "../ui/PreloaderCircle";
import {
  currentUserSelector,
  // isPendingGetMoreUsersSelector,
  // totalUsersSelector,
  usersInLeagueSelector,
} from "../../store/leagues/leaguesReducer";
// import { useAppDispatch } from "../../store/hooks";
// import { addUsersInLeagueAction } from "../../store/leagues/actions/addUsersInLeagueAction";

const LeadersList = ({ league }: { league: League }) => {
  const currentLeague = useSelector(currentLeagueSelector);
  // const totalUsers = useSelector(totalUsersSelector);
  // const isPending = useSelector(isPendingGetMoreUsersSelector);
  const users = useSelector(usersInLeagueSelector);
  const currentUser = useSelector(currentUserSelector);

  // const dispatch = useAppDispatch();

  // const getMoreUsers = () => {
  //   dispatch(addUsersInLeagueAction({ count: 20, offset: users.length, league: league?.id }));
  // };

  return (
    <div className="pt-4 pb-[100px]">
      {currentUser && currentLeague?.id === league.id && (
        <div
          key={league.title}
          className="rounded-[16px] rounded-tl-[20px] rounded-tr-[20px] mb-[6px] px-[1px] pb-[1px]"
          style={{
            background: "linear-gradient(93.06deg, #FF34F7 -7.81%, #A23BF3 111.82%)",
          }}
        >
          <p className="text-center py-[4px] font-extrabold leading-3 text-[12px] italic">Your Position</p>
          <div className={` rounded-[16px] bg-black/45`}>
            <LeaderItem leader={currentUser} rankingPosition={currentUser.userPosition} />
          </div>
        </div>
      )}
      <ul className="space-y-[6px]">
        {users.map((user, index) => (
          <li key={user.id}>
            <LeaderItem leader={user} rankingPosition={index + 1} />
          </li>
        ))}
      </ul>
      {/* {totalUsers > 0 && totalUsers > users.length ? (
        <button
          disabled={isPending}
          className="font-semibold flex justify-center items-center  gap-x-5 text-base h-[64px] text-orange w-full text-center py-5"
          onClick={getMoreUsers}
        >
          <span className="relative">
            Load more friends
            {isPending ? (
              <span className="flex absolute right-0 translate-x-[calc(100%+12px)] top-1/2 -translate-y-1/2 justify-center text-[6px] items-center text-orange">
                <PreloaderCircle />
              </span>
            ) : null}
          </span>
        </button>
      ) : null} */}
    </div>
  );
};

export default LeadersList;
