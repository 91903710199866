interface StatusRocketProps {
  status: string;
  time?: string;
}

const StatusRocket: React.FC<StatusRocketProps> = ({ status, time }) => {
  let bgColor: string = "";
  let statusTextColor: string = "";
  let textBeforeTime: string = "";

  const formattedTime = time;

  switch (status) {
    case "Playing":
      bgColor = "bg-green";
      statusTextColor = "text-white";
      textBeforeTime = "End at";
      break;
    case "Ready to Go":
      bgColor = "bg-lime";
      statusTextColor = "text-black";
      textBeforeTime = "Close at";
      break;
    case "Registering":
      bgColor = "bg-orange";
      statusTextColor = "text-black";
      textBeforeTime = "Close at";
      break;
    case "Unpublished":
      bgColor = "bg-[#45384D]";
      statusTextColor = "text-pink";
      break;

    default:
      break;
  }
  return (
    <div>
      {status != "Unpublished" ? (
        <div className={`h-[39px] w-full ${bgColor} text-xs font-bold rounded-[12px]`}>
          <span className={`${statusTextColor}`}>{status}</span>
          <div className="h-[22px] bg-darkGrey  rounded-[11px] mx-[1px] flex justify-center items-center">
            <p>
              <span className="mr-1">{textBeforeTime}</span>
              <span>{formattedTime}</span>
            </p>
          </div>
        </div>
      ) : (
        <div className={`h-[39px] w-full ${bgColor} text-xs font-bold rounded-[12px] flex justify-center items-center`}>
          <p className={`${statusTextColor}`}>{status}</p>
        </div>
      )}
    </div>
  );
};

export default StatusRocket;
