import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store.ts";
import { League } from "./api/types.ts";
import { LeaguesMock } from "./mock/leagues.ts";

export interface LeaguesState {
  pending: boolean;
  error: string | null;
  leagues: League[];
}

const initialState: LeaguesState = {
  leagues: [...LeaguesMock],
  pending: false,
  error: null,
};

const leaguesSlice = createSlice({
  name: "leagues",
  initialState,
  reducers: {},

  // extraReducers(builder) {},
});

export const {} = leaguesSlice.actions;

export const leaguesSelector = (state: RootState) => state.leagues.leagues;

export default leaguesSlice.reducer;
