import { useEffect, useRef, useState } from "react";

interface UseCountDownProps {
  duration: number;
  onEnd?: () => void; // Добавим опциональный колбэк
}

export function useCountDown({ duration, onEnd }: UseCountDownProps) {
  const [time, setTime] = useState<number>(duration);
  const startTimeRef = useRef<number | null>(null);
  const remainingTimeRef = useRef<number>(duration);
  const animationFrameRef = useRef<number | null>(null);
  const lastUpdateTimeRef = useRef<number | null>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    start(duration);
  }, [duration]);

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  function start(newDuration?: number) {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    // Update duration if new value passed
    if (newDuration !== undefined) {
      setTime(newDuration);
      remainingTimeRef.current = newDuration;
    }

    if (startTimeRef.current === null) {
      startTimeRef.current = Date.now();
      lastUpdateTimeRef.current = startTimeRef.current;
    }

    setIsPlaying(true);

    const tick = () => {
      const now = Date.now();
      const elapsed = now - (startTimeRef.current as number);
      const remainingTime = Math.max(0, remainingTimeRef.current - Math.floor(elapsed / 1000) * 1000);
      const timeSinceLastUpdate = now - (lastUpdateTimeRef.current as number);

      if (timeSinceLastUpdate >= 1000) {
        setTime(remainingTime);
        lastUpdateTimeRef.current = now;
      }

      if (remainingTime <= 0) {
        reset();

        if (onEnd) {
          onEnd();
        }

        cancelAnimationFrame(animationFrameRef.current!);
      } else {
        animationFrameRef.current = requestAnimationFrame(tick);
      }
    };

    animationFrameRef.current = requestAnimationFrame(tick);
  }

  function clear() {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    reset();
  }

  function reset() {
    setTime(duration);
    remainingTimeRef.current = duration;
    startTimeRef.current = null;
    lastUpdateTimeRef.current = null;
    setIsPlaying(false);
  }

  return {
    time,
    start,
    clear,
    isPlaying,
  };
}
