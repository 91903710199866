import Button from "../../components/ui/Button.tsx";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks.ts";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer.ts";
import { useEffect } from "react";
import { useRouterLock } from "../../hooks/useRouterLock.ts";
import TopStepsBlock from "../../components/Onboarding/TopStepsBlock.tsx";
import { moneyFormat } from "../../helpers/moneyFormat.ts";

import balanceIcon from "../../assets/icons/balance.svg";
import { DataLayerEventNames, useDataLayer } from "../../hooks/useDataLayer.ts";
export default function ClaimYourAirdropPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { trackEvent } = useDataLayer();

  useRouterLock();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
  }, []);
  const leagues = [
    {
      id: 2,
      title: "Diamond",
      rewardPortfolio: 600,
      threshold: "50k",
      iconUrl: "diamond",
    },
    {
      id: 3,
      title: "Master",
      rewardPortfolio: 700,
      threshold: "100k",
      iconUrl: "master",
    },
    {
      id: 4,
      title: "Legendary",
      rewardPortfolio: 800,
      threshold: 5000,
      iconUrl: "legendary",
    },
  ];

  return (
    <div className="flex flex-1 flex-col pb-[17px]">
      <TopStepsBlock currentStep={5} />
      <div className="grow flex flex-col items-center pt-[6.5vh] pb-[100px] ">
        <div className="px-4">
          <h1 className="text-[32px] font-bold leading-10  text-center gap-x-[9px]">
            Stay active and
            <br /> claim your Airdrop
          </h1>

          <p className="mt-[2vh] text-center text-[20px] leading-6 font-normal">
            Advance through leagues to earn Airdrops—reach the top league for maximum rewards
          </p>
        </div>
        <div className="overflow-x-auto w-full text-center pt-[5vh] px-2">
          <div className="min-w-full">
            <div className="w-full text-[16px] flex leagues-rewards-border-gradient pb-[10px] justify-center items-center font-bold ">
              <div className=" w-1/4">League</div>
              <div className=" w-2/4">
                Required
                <br /> Points
              </div>
              <div className="w-1/4">Portfolio Reward</div>
            </div>
            {leagues?.map((league, index) => {
              const isCurrentLeague = 4 === league.id;

              return (
                <div key={league.id} className="w-full leagues-rewards-border-gradient flex py-[10px] items-center ">
                  {!isCurrentLeague ? (
                    <div className={`w-full flex flex-col`}>
                      <div className="w-full flex items-center ">
                        <div className="w-1/4 flex flex-col justify-center items-center ">
                          <img
                            src={`/${league.iconUrl}.svg`}
                            className="object-contain object-center"
                            alt={league.title}
                            width={60}
                            height={60}
                          />
                          <p className="font-medium text-[12px]">{league.title}</p>
                        </div>
                        <div className="w-2/4 text-[16px] font-bold text-yellow flex gap-1 justify-center items-center">
                          <p>from {leagues[index].threshold}</p>
                          <img className="size-[14px] object-cover" loading="lazy" src={balanceIcon} alt="currency" />
                        </div>
                        <div className="w-1/4 text-[16px] font-bold text-yellow flex gap-1 justify-center items-center">
                          <p>{moneyFormat(league.rewardPortfolio)}</p>
                          <img className="size-[14px] object-cover" loading="lazy" src={balanceIcon} alt="currency" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={league.id}
                      className="w-full rounded-[16px] rounded-tl-[20px] rounded-tr-[20px] mb-[6px] px-[1px] pb-[1px] mx-1"
                      style={{
                        background: "#e736f6",
                      }}
                    >
                      <p className="text-center py-[4px] font-extrabold leading-3 text-[12px] italic">Your League</p>
                      <div
                        className={` rounded-[16px] px-2`}
                        style={{
                          background: "linear-gradient(99deg, #4c345c 12.68%, #3d294a 85.22%)",
                        }}
                      >
                        <div className={`w-full flex flex-col `}>
                          <div className="w-full flex py-[10px] items-center ">
                            <div className="w-1/4 flex flex-col justify-center items-center ">
                              <img
                                src={`/${league.iconUrl}.svg`}
                                className="object-contain object-center"
                                alt={league.title}
                                width={60}
                                height={60}
                              />
                              <p className="font-medium text-[12px]">{league.title}</p>
                            </div>
                            <div className="w-2/4 text-[16px] font-bold text-yellow  gap-1 justify-center items-center flex flex-col">
                              <div className="flex justify-center items-center gap-1">
                                <p>499k/ 500k</p>
                                <img
                                  className="size-[14px] object-cover"
                                  loading="lazy"
                                  src={balanceIcon}
                                  alt="currency"
                                />
                              </div>
                              <div className="rounded h-2 w-full max-w-[131px] bg-white/25 p-[1px]">
                                <div
                                  className=" h-full rounded-[3px]"
                                  style={{
                                    width: `${95}%`,
                                    background: "linear-gradient(94.55deg, #AF38D9 -1.4%, #8B18E5 100.83%)",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="w-1/4 text-[16px] font-bold text-yellow flex gap-1 justify-end items-center">
                              <p>{moneyFormat(league.rewardPortfolio)}</p>
                              <img
                                className="size-[14px] object-cover"
                                loading="lazy"
                                src={balanceIcon}
                                alt="currency"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="fixed z-[2000] bottom-0 right-0 left-0 shrink-0 px-4 pb-4">
        <Button
          text="NEXT"
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          className="text-[14px] py-[1.14em] z-[2000] disabled:!bg-lightGrey"
          handleClick={() => {
            dispatch(changeStatusBottomMenu(true));
            navigate("/");
            trackEvent(DataLayerEventNames.onboardingStarted);
          }}
        />
      </div>
    </div>
  );
}
