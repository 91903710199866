import ProfileRocketImages from "../../components/Rocket/ProfileRocketImages";
import BlockInfo from "../../components/ui/BlockInfo";
import Title from "../../components/ui/Title";
import DarkBackground from "../../components/ui/DarkBackground";
import Input from "../../components/ui/Input";
import Label from "../../components/ui/Label";
import CounterField from "../../components/ui/CounterField";
import ExplanationText from "../../components/ui/ExplanationText";
import Chip from "../../components/ui/Chip";

import userPhoto1 from "../../assets/icons/user.svg";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeStatusBottomMenu, setStatusBuildPortfolio } from "../../store/menu/bottomMenuReducer";

import coinsIcon from "../../assets/icons/coins.svg";
import ModalWindow from "../../components/ModalWindow/index";
import BottomBlock from "../../components/ModalWindow/BottomBlock";
import ModalContent from "../../components/Rocket/Custom/ChooseToken/ModalContent";
import { changeStatusModalRocket, setRocket } from "../../store/rocket/rocketReducer";
import { MAX_TOKENS_IN_PORTFOLIO, MIN_TOKENS_IN_PORTFOLIO } from "../../constants/token";
import BottomButton from "../../components/ui/BottomButton";
import { Rocket } from "../../store/rocket/api/types";
// import { useSelector } from "react-redux";
// import { userIdSelector } from "../../store/account/account";

const CustomRocketPage = () => {
  const rocketLimits: string[] = ["micro", "low", "medium"];

  const dispatch = useAppDispatch();

  const [rocketData, setRocketData] = useState<Rocket>({
    id: "_7pu1k564h1",
    name: "Crazy Everest Rocket",
    min_deposite: 10,
  });
  const [minBetValue, setMinBetValue] = useState<number>(10);
  const [adminBidValue, setAdminBidValue] = useState<number>(200);
  const [rocketName, setRocketName] = useState<string>("Crazy Everest Rocket");

  // const currentUserId = useSelector(userIdSelector);

  const modalRocketVisible = useAppSelector((state) => state.rocket.modalRocket);

  const handleChangeMinBetValue = (count: number) => {
    setMinBetValue(count);
  };
  const handleChangeAdminBidValue = (count: number) => {
    setAdminBidValue(count);
  };

  const handleChangeRocketName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRocketName(e.target.value);
  };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(setStatusBuildPortfolio(true));
    return () => {
      dispatch(changeStatusBottomMenu(true));
      dispatch(setStatusBuildPortfolio(false));
    };
  }, []);

  const handleBuildPortfolio = () => {
    dispatch(changeStatusModalRocket(true));

    const rocket: Rocket = {
      ...rocketData,
      name: rocketName,
      min_deposite: minBetValue,
      ownerId: "jjjj",
      passengers: [
        {
          id: "jjjj",
          photo: userPhoto1,
          name: "Jacob Mitchell",
          deposite: adminBidValue,
        },
      ],
    };
    setRocketData(rocket);

    dispatch(setRocket(rocket));
  };

  return (
    <div>
      <DarkBackground>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="mb-[17px]">
            <ProfileRocketImages />
          </div>
          <Title text="Rocket customization" />
          <div className="w-full grid grid-cols-2 grid-rows-1 gap-1 mb-[15px]">
            <div className="row-span-1 col-span-1">
              <BlockInfo icon={coinsIcon} text="Rocket Buy-in" value="$500" />
            </div>
            <div className="row-span-1 col-span-1">
              <BlockInfo sing="%" text="Launch Commission Earnings" value="1,5%" textSize="14px" />
            </div>
          </div>
        </div>
      </DarkBackground>
      <div className="mt-[15px]">
        <div className="mb-[23px]">
          <Label text="Name of the rocket" />
          <Input value={rocketName} handleChange={handleChangeRocketName} />
        </div>
        <div className="mb-[23px]">
          <Label text="Available rocket limits" />
          <div className="flex gap-2 pt-2">
            {rocketLimits.map((limit) => (
              <Chip
                text={limit.charAt(0).toUpperCase() + limit.slice(1)}
                bgColor="bg-[#FFFFFF1A]"
                textSize="text-[14px]"
                paddingX="px-4"
                height="h-[32px]"
                border="border-[1px] border-[#7D6B88]"
                key={limit}
              />
            ))}
          </div>
        </div>
        <div className="mb-[23px]">
          <Label text="Minimum bet for passengers" />
          <div className="h-[48px]">
            <CounterField
              value={minBetValue}
              displayText={"$" + minBetValue}
              handleChange={handleChangeMinBetValue}
              max={1000}
            />
          </div>
        </div>
        <div>
          <div className="mb-2 flex justify-between items-center">
            <label className="block text-xs text-white opacity-50 ml-2 font-bold">Admin bid</label>
            <div className="flex gap-[6px] items-center">
              <div className="relative p-[2px] bg-[#00000080] h-[10px] rounded-[5px] w-[84px]">
                <div className=" absolute bg-orange h-[6px] w-[20%] rounded-[3px] z-10"></div>
                <div className=" absolute bg-[#5F4D6A] h-[6px] w-[80%] rounded-[3px] z-0"></div>
              </div>
              <p className="text-xs font-bold">20%</p>
            </div>
          </div>
          <div className="h-[48px]">
            <CounterField
              value={adminBidValue}
              displayText={"$" + adminBidValue}
              handleChange={handleChangeAdminBidValue}
              max={1000}
            />
          </div>
          <ExplanationText text="The Admin's bid caps at 80% of the fuel tank cost. Invite other Passengers to Get a Full Tank." />
        </div>
      </div>
      <BottomButton
        text="Build a portfolio"
        handler={handleBuildPortfolio}
        bgColor="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
      />
      {modalRocketVisible && (
        <ModalWindow
          height="100%"
          headerText={`Choose ${MIN_TOKENS_IN_PORTFOLIO} to ${MAX_TOKENS_IN_PORTFOLIO} tokens`}
        >
          <BottomBlock>
            <ModalContent />
          </BottomBlock>
        </ModalWindow>
      )}
    </div>
  );
};

export default CustomRocketPage;
