interface FilterCardsProps {
  onSelectLimit: (limit: string) => void;
  activeTab: string;
  filterItems: string[];
}

const FilterCards = ({ onSelectLimit, activeTab, filterItems }: FilterCardsProps) => {
  const handleTabClick = (limit: string) => {
    onSelectLimit(limit);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between font-semibold bg-[#FFFFFF26] rounded-lg py-[1vh] px-[5.4vw] mb-[13px]">
        {filterItems.map((limit) => (
          <button
            key={limit}
            className={`w-1/4 py-[1vh] leading-none ${activeTab === limit ? " rounded-lg bg-black/20" : "bg-transparent"}`}
            onClick={() => handleTabClick(limit)}
          >
            {limit.charAt(0).toUpperCase() + limit.slice(1)}{" "}
          </button>
        ))}
      </div>
    </div>
  );
};
export default FilterCards;
