import { createSlice } from "@reduxjs/toolkit";
import { Note, Token, TokenInfo, Transaction } from "./api/types";
import { fetchMyTokensListAction } from "./actions/fetchMyTokensListAction";
import { RootState } from "../store";
import { fetchMyTransactionListAction } from "./actions/fetchMyTransactionListAction";
import { fetchHotTokensListAction } from "./actions/fetchHotTokensListAction";
import { fetchAllNewTokensListAction } from "./actions/fetchAllNewTokensListAction";
import { fetchTopCapTokensListAction } from "./actions/fetchTopCapTokensListAction";
import { sendTokenAction } from "./actions/sendTokenAction";
import { fetchSearchTokensListAction } from "./actions/fetchSearchTokensListAction";
import { fetchTokenInfoAction } from "./actions/fetchTokenInfoAction";
import { fetchNotesForTokenAction } from "./actions/fetchNotesForTokenAction";
import { createNoteAction } from "./actions/createNoteAction";
import { patchNoteForTokenAction } from "./actions/patchNoteForTokenAction";
import { fetchLastNoteForTokenAction } from "./actions/fetchLastNoteForTokenAction";

export interface TokensState {
  myTokens: Token[];
  filteredTokens: Token[];
  communityNotes: Note[];
  lastNote: Note | null;
  myTransaction: Transaction[];
  pending: boolean;
  error: string | null;
  currentToken: TokenInfo | null;
}

const initialState: TokensState = {
  myTokens: [],
  filteredTokens: [],
  communityNotes: [],
  myTransaction: [],
  lastNote: null,
  pending: false,
  error: null,
  currentToken: null,
};

const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchMyTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.myTokens = action.payload;
        state.filteredTokens = action.payload;
      })
      .addCase(fetchMyTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });

    //MY TRANSACTION
    builder
      .addCase(fetchMyTransactionListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchMyTransactionListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.myTransaction = action.payload;
      })
      .addCase(fetchMyTransactionListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //HOT TOKENS
    builder
      .addCase(fetchHotTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchHotTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = action.payload;
      })
      .addCase(fetchHotTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //NEW TOKENS
    builder
      .addCase(fetchAllNewTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchAllNewTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = action.payload;
      })
      .addCase(fetchAllNewTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //TOP TOKENS
    builder
      .addCase(fetchTopCapTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchTopCapTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = action.payload;
      })
      .addCase(fetchTopCapTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CREATE TOKEN
    builder
      .addCase(sendTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(sendTokenAction.fulfilled, (state) => {
        state.pending = false;
        state.error = null;
      })
      .addCase(sendTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //SEARCH TOKENS
    builder
      .addCase(fetchSearchTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchSearchTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = action.payload;
      })
      .addCase(fetchSearchTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CURRENT TOKEN
    builder
      .addCase(fetchTokenInfoAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchTokenInfoAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.currentToken = action.payload;
      })
      .addCase(fetchTokenInfoAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //NOTES FOR CURRENT TOKEN
    builder
      .addCase(fetchNotesForTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchNotesForTokenAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.communityNotes = action.payload;
      })
      .addCase(fetchNotesForTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CREATE NEW NOTE FOR CURRENT TOKEN
    builder
      .addCase(createNoteAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createNoteAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        if (state.communityNotes?.length > 0) {
          state.communityNotes.unshift(action.payload);
        } else {
          state.communityNotes = [action.payload];
        }
      })
      .addCase(createNoteAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //LAST NOTE FOR CURRENT TOKEN
    builder
      .addCase(fetchLastNoteForTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchLastNoteForTokenAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.lastNote = action.payload;
      })
      .addCase(fetchLastNoteForTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CHANGE NOTE
    builder
      .addCase(patchNoteForTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(patchNoteForTokenAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        const updatedNote = action.payload;
        state.communityNotes = state.communityNotes.map((note) =>
          note.id === updatedNote.id ? { ...note, like: updatedNote.like, disLike: updatedNote.disLike } : note,
        );
      })
      .addCase(patchNoteForTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const {} = tokensSlice.actions;

export default tokensSlice.reducer;

export const myTokensSelector = (state: RootState) => state.tokens.myTokens;
export const myTransactionSelector = (state: RootState) => state.tokens.myTransaction;
export const communityNotesSelector = (state: RootState) => state.tokens.communityNotes;
export const lastNoteSelector = (state: RootState) => state.tokens.lastNote;
export const filteredTokensSelector = (state: RootState) => state.tokens.filteredTokens;
export const currentTokenSelector = (state: RootState) => state.tokens.currentToken;
export const isPendingSelector = (state: RootState) => state.tokens.pending;
