import { useSelector } from "react-redux";
import { getTimeDifference } from "../../../../../helpers/getTimeDifference";
import { moneyFormatWithTwoDigits } from "../../../../../helpers/moneyFormat";
import { TokenInfo, Transaction, TransactionsStatus } from "../../../../../store/token/api/types";
import { forStatusTransaction } from "../../../Profile/Transaction/TransactionItem";
import { currentTokenSelector } from "../../../../../store/token/tokensReducer";

import buyIcon from "../../../../../assets/icons/arrow-buy.svg";
import sellIcon from "../../../../../assets/icons/arrow-sell.svg";

const TransactionList = () => {
  const token = useSelector(currentTokenSelector);

  const { transactions } = token!;
  return (
    <>
      {transactions?.length > 0 && (
        <div className="pt-[33px]">
          <h3 className="text-[20px] font-bold text-center">Latest transactions</h3>
          <div className="pt-4">
            {transactions.map((transaction) => (
              <TransactionItem transaction={transaction} token={token!} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const TransactionItem = ({ transaction, token }: { transaction: Transaction; token: TokenInfo }) => {
  const { coinQuantity, createdAt, status, user } = transaction;
  const { ticker } = token;
  const { first_name } = user!;

  const colorForStatusTransaction = forStatusTransaction(status);
  return (
    <div className="flex justify-between items-center font-semibold leading-none  text-[12px] border-solid border-b-[2px] border-white/10 py-[15px]">
      <div className="flex gap-3 justify-between items-center">
        <div className="rounded-2xl bg-white size-6  ">
          <img src="/image-token.png" className="w-full object-cover" />
        </div>
        <div className="flex flex-col gap-[7px]">
          <p>@{first_name}</p>
          <div className="flex gap-2 ">
            <p className={`font-bold ${colorForStatusTransaction} flex items-center gap-[3px]`}>
              <img src={`${status === TransactionsStatus.BUY ? buyIcon : sellIcon}`} className="inline-block size-2" />{" "}
              {status}
            </p>
            <p className="font-medium text-paleGrey">{getTimeDifference(createdAt)}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[7px] text-right">
        <p>{moneyFormatWithTwoDigits(+coinQuantity)} SOL</p>
        <div className=" text-paleGrey">
          <span>8.7M </span>
          <span className="uppercase">${ticker} </span>
        </div>
      </div>
    </div>
  );
};

export default TransactionList;
