import React from "react";

interface SwitchProps {
  isOn: boolean;
  toggleSwitch: (newValue: boolean) => void;
  bgColor: string;
  bgActiveColor?: string;
  circleColor: string;
  width?: string;
  height?: string;
  circleSize?: string;
}

const Switch: React.FC<SwitchProps> = ({
  isOn,
  toggleSwitch,
  bgColor,
  bgActiveColor,
  circleColor,
  width = "42px",
  height = "24px",
  circleSize = "18px",
}) => {
  const handleClick = () => {
    toggleSwitch(!isOn);
  };

  return (
    <div
      className={`
       rounded-[25px] p-[3px] relative transition-all duration-300`}
      style={{
        background: isOn ? (bgActiveColor ? bgActiveColor : bgColor) : bgColor,
        height: height,
        width: width,
      }}
      onClick={handleClick}
    >
      <div
        className={` rounded-[50%] ${isOn ? "translate-x-[100%]" : "translate-x-[0%]"} transition-all duration-300`}
        style={{
          background: circleColor,
          width: circleSize,
          height: circleSize,
        }}
      ></div>
    </div>
  );
};

export default Switch;
