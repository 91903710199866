import Button from "../../components/ui/Button.tsx";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks.ts";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer.ts";
import { useEffect } from "react";
import twoCosmonautsImage from "../../assets/img/two-cosmonauts.png";
import { useRouterLock } from "../../hooks/useRouterLock.ts";
import TopStepsBlock from "../../components/Onboarding/TopStepsBlock.tsx";

export default function EarnMoreWithFriendsPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useRouterLock();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
  }, []);

  return (
    <div className="flex flex-1 flex-col pb-[17px]">
      <TopStepsBlock currentStep={4} />
      <div className="grow flex flex-col items-center pt-[6.5vh] pb-[100px] ">
        <div className="px-4">
          <h1 className="text-[32px] font-bold leading-10  text-center gap-x-[9px]">
            Earn More
            <br />
            with Friends
          </h1>

          <p className="mt-[2vh] text-center text-[20px] leading-6 font-normal">
            Invite friends and receive <span className="font-bold">10%</span> of their earnings. Both you and your
            friends get rewarded!
          </p>
        </div>
        <p className="text-[24px] font-bold text-yellow pt-[5vh] -rotate-12 text-center leading-7">
          +10% for direct referrals
          <br /> daily!
        </p>
        <div className="grow relative w-full  max-w-[283px] min-w-[180px] min-h-[160px] max-h-[243.08px] mx-auto">
          <img
            rel="preload"
            className="h-full w-full object-contain block object-top mx-auto absolute  top-0 left-1/2 -translate-x-1/2"
            src={twoCosmonautsImage}
            alt="tapEarnCoins"
            loading="lazy"
          />
        </div>
      </div>

      <div className="fixed z-[2000] bottom-0 right-0 left-0 shrink-0 px-4 pb-4">
        <Button
          text="NEXT"
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          className="text-[14px] py-[1.14em] z-[2000] disabled:!bg-lightGrey"
          handleClick={() => {
            navigate("/onboarding/claim-airdrop");
          }}
        />
      </div>
    </div>
  );
}
