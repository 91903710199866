interface FilterTradeProps {
  onSelectTab: (limit: TradeType) => void;
  activeTab: TradeType;
}
export enum TradeType {
  BUY = "buy",
  SELL = "sell",
}

const FilterTrade = ({ activeTab, onSelectTab }: FilterTradeProps) => {
  return (
    <div className="flex justify-between font-semibold bg-[#FFFFFF26] rounded-[12px] p-1 h-[40px] mb-[13px] mt-4">
      {[TradeType.BUY, TradeType.SELL].map((type) => (
        <button
          key={type}
          className={` px-[17px] w-1/2  ${activeTab === type ? " rounded-lg" : "bg-transparent"}`}
          style={{
            background: activeTab === type ? setBackgroundTradeType(activeTab) : "transparent",
          }}
          onClick={() => onSelectTab(type)}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}{" "}
        </button>
      ))}
    </div>
  );
};

export const setBackgroundTradeType = (type: TradeType) => {
  let background;

  switch (type) {
    case TradeType.BUY: {
      background = "radial-gradient(405.69% 506.69% at 28.13% -143.75%, rgb(63, 193, 139) 0%, rgb(29, 117, 80) 100%)";
      break;
    }
    case TradeType.SELL: {
      background = "radial-gradient(335.03% 335.03% at 19.07% -101.56%, rgb(245, 70, 96) 0%, rgb(195, 35, 59) 100%)";
      break;
    }
  }
  return background;
};

export default FilterTrade;
