import { createSlice } from "@reduxjs/toolkit";
import { InvitesData } from "./api/types.ts";
import { fetchInvitesDataAction } from "./actions/fetchInvitesDataAction.ts";

export type FriendsState = InvitesData & {
  error: string | null;
  pending: boolean;
};

const initialState: FriendsState = {
  error: null,
  pending: false,

  inviteId: null,
  inviteUrl: null,
  userInviteBonuce: 0,
  premiumUserInviteBonuce: 0,
  userInvites: 0,
  userInvitesTier2: 0,
  userInviteTicketBonuce: 0,
  premiumUserInviteTicketBonuce: 0,
  dateReferralsUpdate: null,
};

const friendsSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {
    // nextPage(state) {
    //   state.pagination.page++;
    // },
    // resetPagination(state) {
    //   state.pagination.page = 1;
    //   state.pagination.perPage = 10;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvitesDataAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchInvitesDataAction.fulfilled, (state, action) => {
        state.pending = false;
        // state.invitedFriends =
        //   state.pagination.page > 1
        //     ? [...state.invitedFriends, ...action.payload.invitedFriends]
        //     : action.payload.invitedFriends;

        // state.pagination.total = action.payload.pagination.total;
        state.inviteId = action.payload.inviteId;
        state.inviteUrl = action.payload.inviteUrl;
        state.userInviteBonuce = action.payload.userInviteBonuce;
        state.premiumUserInviteBonuce = action.payload.premiumUserInviteBonuce;
        state.userInvites = action.payload.userInvites;
        state.userInvitesTier2 = action.payload.userInvitesTier2;
        state.dateReferralsUpdate = action.payload.dateReferralsUpdate;
        state.userInviteTicketBonuce = action.payload.userInviteTicketBonuce;
        state.premiumUserInviteTicketBonuce = action.payload.premiumUserInviteTicketBonuce;
      })
      .addCase(fetchInvitesDataAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const inviteIdSelector = (state: { friends: FriendsState }) => state.friends.inviteId;
export const inviteUrlSelector = (state: { friends: FriendsState }) => state.friends.inviteUrl;
export const userInvitesTier2Selector = (state: { friends: FriendsState }) => state.friends.userInvitesTier2;
export const userInvitesSelector = (state: { friends: FriendsState }) => state.friends.userInvites;
export const userInviteBonuceSelector = (state: { friends: FriendsState }) => state.friends.userInviteBonuce;
export const premiumUserInviteBonuceSelector = (state: { friends: FriendsState }) =>
  state.friends.premiumUserInviteBonuce;
export const userInviteTicketBonuceSelector = (state: { friends: FriendsState }) =>
  state.friends.userInviteTicketBonuce;
export const premiumUserInviteTicketBonuceSelector = (state: { friends: FriendsState }) =>
  state.friends.premiumUserInviteTicketBonuce;
export const dateReferralsUpdateSelector = (state: { friends: FriendsState }) => state.friends.dateReferralsUpdate;

// export const { nextPage, resetPagination } = friendsSlice.actions;
export default friendsSlice.reducer;
