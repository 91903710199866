import { useSelector } from "react-redux";
import TokenImage from "../TokenImage";
import CreatedBy from "./CreatedBy";
import TokenMetrics from "./TokenMetrics";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import { NavLink } from "react-router-dom";

const TokenHeader = () => {
  const token = useSelector(currentTokenSelector);

  const { name, ticker, marcetCap, progress, logo } = token!;
  return (
    <div className="pt-4">
      <NavLink to={`/pumpadur/tokens/token-info/${token?.id}`} className="flex gap-4 leading-none">
        <TokenImage imageSrc={logo} />
        <div className="flex flex-col gap-[10px]">
          <p className="font-semibold text-[20px]  uppercase">{name}</p>
          <p className="font-semibold text-[16px] uppercase text-paleGrey">${ticker}</p>
          <CreatedBy />
        </div>
      </NavLink>
      <TokenMetrics progress={progress} marcetCap={marcetCap} />
    </div>
  );
};

export default TokenHeader;
