import GrayDot from "./GrayDot.tsx";
import YellowDot from "./YellowDot.tsx";
import UserIcon from "./UserIcon.tsx";
import { DailyRewards } from "../../../store/cards/api/types.ts";

interface ProgressBarProps { dailyRewards: DailyRewards; claimedRewards: number }

export default function ProgressBar({ dailyRewards, claimedRewards }: ProgressBarProps) {
  let currentDay = claimedRewards;
  if (currentDay >= 7) {
    currentDay = 0;
  }

  return (
    <div
      className="relative w-full"
      style={{
        height: '107px',
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '24px',
        margin: '0 auto',
      }}
    >
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-[12px] h-[28px] bg-white bg-opacity-10 rounded-full flex justify-between items-center"
        style={{
          width: '280px', // Фиксированная ширина для сохранения расположения точек
        }}
      >
        {Array.from({ length: 7 }).map((_, index) => {
          const isPastDay = index < currentDay;
          const reward = dailyRewards[index];

          return (
            <div
              key={index}
              className={`relative flex justify-center items-center ${index === 0 ? 'ml-[10px]' : ''} ${index === 6 ? 'mr-[6px]' : ''}`}
              style={{ width: '40px' }} // Устанавливаем ширину каждой точки
            >
              {index === 2 || index === 6 ? (
                <YellowDot index={index} fill={isPastDay ? "#c869ff" : undefined} reward={reward} />
              ) : (
                <GrayDot fill={isPastDay ? "#c869ff" : undefined} />
              )}
              {index === currentDay && <UserIcon />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
