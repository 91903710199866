import { useEffect, useState } from "react";
import { BottomSheet } from "../ui/BottomSheet.tsx";
import { useSelector } from "react-redux";
import { absoluteBalanceSelector, currentLeagueSelector } from "../../store/account/account.tsx";
import Button from "../ui/Button.tsx";
import balanceIcon from "../../assets/icons/balance.svg";
import { moneyFormat } from "../../helpers/moneyFormat.ts";
import { ExternalImage } from "../ui/ExternalImage.tsx";
import { useAppDispatch } from "../../store/hooks.ts";
import { fetchLeagueTransitionAction } from "../../store/leagues/actions/fetchLeagueTransitionAction.ts";
import { fetchUserInfoAction } from "../../store/account/actions/fetchUserInfoAction.ts";
import ReactDOM from "react-dom";
import { leaguesSelector } from "../../store/leagues/leaguesReducer.ts";

export function LeagueTransition() {
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState(false);

  const currentLeague = useSelector(currentLeagueSelector);
  const absoluteBalance = useSelector(absoluteBalanceSelector);
  const leagues = useSelector(leaguesSelector);
  // const leagueTransition = useSelector(leagueTransitionSelector);

  useEffect(() => {
    if (
      absoluteBalance > (currentLeague?.threshold || 0) &&
      (currentLeague?.threshold || 0) < leagues[leagues.length - 1]?.threshold
    ) {
      dispatch(fetchLeagueTransitionAction()).then(({ payload }) => {
        // Type assertion or guard to inform TypeScript about the shape of payload
        if ((payload as { isTransition: boolean }).isTransition) {
          dispatch(fetchUserInfoAction());
          setIsOpened(true);
        }
      });
    }
  }, [absoluteBalance, dispatch, leagues]);

  return ReactDOM.createPortal(
    <div className="flex flex-col relative z-[2000] justify-center leading-none">
      <BottomSheet isOpened={isOpened} onClose={() => setIsOpened(false)}>
        <div className="absolute top-[-70px]  left-1/2 -translate-x-1/2 ">
          <ExternalImage
            src={currentLeague?.iconUrl}
            className="rounded-[12px]"
            alt={currentLeague?.title}
            width={200}
            height={200}
          />
        </div>
        <div className="text-center pt-[75px] leading-none ">
          <div className="mb-6">
            <h1 className="text-[24px] font-bold mb-6 leading-none">Welcome to {currentLeague?.title} League</h1>
            <p className="text-[16px] leading-none mb-5">Now Your Portfolio Reward is</p>
            <strong className="flex justify-center items-center gap-x-[0.286em] text-[36px] text-[#FFD600]">
              <span className="size-[26px]">
                <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
              </span>
              {moneyFormat(currentLeague?.rewardPortfolio || 0)}
            </strong>
          </div>
          <Button
            text={"OK"}
            handleClick={() => setIsOpened(false)}
            style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          />
        </div>
      </BottomSheet>
    </div>,
    document.getElementById("root")!,
  );
}
