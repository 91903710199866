import { useSelector } from "react-redux";
import arrowIcon from "../assets/icons/сhevron.svg";
import { exchangeSelector } from "../store/account/account";
import { NavLink } from "react-router-dom";
import ExchangeSummary from "./ExchangesSummery";
import { isGameActiveSelector } from "../store/clicker/selectors";

export default function ExchangeBlock() {
  const exchange = useSelector(exchangeSelector);
  const isGameActive = useSelector(isGameActiveSelector);

  return (
    <NavLink
      to={`${isGameActive ? "/selection-exchange" : "/"}`}
      className="container p-1 flex justify-center items-center pr-3 rounded-[16px] bg-[#1F1F1F] w-full"
    >
      <div className="flex flex-col items-center gap-1 leading-none">
        <div className="font-bold tracking-[0.02em]">
          {exchange ? <ExchangeSummary imgSize={24} /> : <p>Not selected</p>}
        </div>
      </div>

      {isGameActive ? (
        <div className="absolute right-2 size-6">
          <img src={arrowIcon} alt="back" />
        </div>
      ) : null}
    </NavLink>
  );
}
