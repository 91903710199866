import { ButtonHTMLAttributes, ElementType, createElement } from "react";

enum ChipButtonVariants {
  Primary = "primary",
  // TODO: Implement other types
}

export interface ChipButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant?: ChipButtonVariants;
  as?: ElementType;
}

const variantsClasses = {
  [ChipButtonVariants.Primary]: "bg-orange text-white",
};

export function ChipButton({ as = "button", text, variant = ChipButtonVariants.Primary, ...attrs }: ChipButtonProps) {
  return createElement(
    as,
    {
      ...attrs,
      className: `
                ${!attrs.disabled ? variantsClasses[variant] : "bg-[#5C565F]"}
                rounded-full
                text-[12px]
                font-semibold
                leading-[2em]
                px-3
                py-0
                disabled:bg-[#5C565F]
                ${attrs.className || ""}
            `,
    },
    text,
  );
}
