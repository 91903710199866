import { useSelector } from "react-redux";
import ComboCards from "./ComboCards";
import DailyComboStatus from "./DailyComboStatus";
import { dailyComboSelector, isCardInCombo, purchasedCardsSelector } from "../../../store/cards/cardsReducer";
import { useEffect, useState } from "react";
import { PurchasedCards } from "../../../store/cards/api/types";

const DailyComboBlock = () => {
  const dailyCombo = useSelector(dailyComboSelector);
  const purchasedCards = useSelector(purchasedCardsSelector);

  const [comboList, setComboList] = useState<PurchasedCards[]>([]);

  useEffect(() => {
    if (dailyCombo) {
      const purchasedComboCards = purchasedCards.filter((purchasedCard) => isCardInCombo(purchasedCard, dailyCombo));
      setComboList(purchasedComboCards);
    }
  }, [purchasedCards, dailyCombo]);

  return (
    <div className="pt-4">
      {/* <h2 className="text-center text-[20px] leading-6 font-bold py-4">Daily combo</h2> */}

      <DailyComboStatus comboList={comboList} />

      <ComboCards comboList={comboList} />
    </div>
  );
};

export default DailyComboBlock;
