import { ReactNode } from "react";

interface BottomBlockProps {
  children: ReactNode;
}

const BottomBlock: React.FC<BottomBlockProps> = ({ children }) => {
  return <div className="px-4 py-[14px] mx-auto">{children}</div>;
};

export default BottomBlock;
