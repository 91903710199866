import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { investedInThisRocket, isPassenger } from "../../../store/rocket/rocketReducer";
import ModalWindow from "../../ModalWindow";
import BottomBlock from "../../ModalWindow/BottomBlock";
import TopBlock from "../../ModalWindow/TopBlock";
import Button from "../../ui/Button";
import MainRocketBlock from "../MainRocketBlock";
import RocketMetrics from "../RocketMetrics";

import userPhoto1 from "../../../assets/icons/user.svg";
import copyIcon from "../../../assets/icons/copy.svg";
import { useSelector } from "react-redux";
// import { userIdSelector } from "../../../store/account/account";
import { RootState } from "../../../store/store";
import { investedInRocket } from "../../../store/round/roundReducer";
import { useEffect, useState } from "react";
import CounterField from "../../ui/CounterField";

const ModalGetInRocket = () => {
  const dispatch = useAppDispatch();

  const rocket = useAppSelector((state) => state.rocket.rocket);
  // const currentUserId = useSelector(userIdSelector);
  const round = useAppSelector((state) => state.round.round);

  const [investValue, setInvestValue] = useState<number>(180);
  const [passengersInvest, setPassengersInvest] = useState<number>(
    rocket?.passengers?.reduce((acc, passenger) => acc + passenger.deposite, 0) || 0,
  );

  const isUserPassenger = useSelector((state: RootState) => isPassenger(state, "jjjj"));

  const handleChangeValue = (count: number) => {
    setInvestValue(count);
    console.log(count);
  };

  useEffect(() => {
    console.log(investValue);
    const investOfPassenger =
      (rocket?.passengers?.reduce((acc, passenger) => acc + passenger.deposite, 0) || 0) + investValue;
    console.log(investOfPassenger);

    setPassengersInvest(investOfPassenger);
  }, [investValue]);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleConfirm = () => {
    dispatch(
      investedInThisRocket({
        id: "jjjj",
        photo: userPhoto1,
        name: "Jacob Mitchell",
        deposite: investValue,
      }),
    );
    dispatch(
      investedInRocket({
        passenger: {
          id: "jjjj",
          photo: userPhoto1,
          name: "Jacob Mitchell",
          deposite: investValue,
        },
        rocketId: rocket?.id,
      }),
    );
  };

  return (
    <ModalWindow
      headerText={isUserPassenger ? `You have been successfully added to the rocket` : `Take a Seat in the Rocket`}
    >
      <TopBlock>
        {isUserPassenger ? (
          <div className="pt-4">
            <MainRocketBlock />
          </div>
        ) : (
          <RocketMetrics buyIn={round?.buyIn || 0} yourInvest={0} ownerInvest={0} otherInvest={passengersInvest} />
        )}
      </TopBlock>
      <BottomBlock>
        <div>
          <div>
            {isUserPassenger ? (
              <div>
                <div className="text-xs text-[#FFFFFF80] pb-4 text-center">The link to this rocket</div>
                <div className="h-[48px] w-full bg-[#36283E] rounded-2xl border-solid border-[1px] border-[#66586E] flex gap-3 justify-between items-center p-1">
                  <div className="w-full px-[12px] flex justify-between items-center">
                    <div>jskdfii3293/fjq09h3/98h2cnyxyaw4/8qx...</div>
                    <button
                      className="relative w-[33px] h-[32px] flex items-center justify-end border-solid border-l-[1px] greyBorder"
                      onClick={() => copyTextToClipboard("jskdfii3293/fjq09h3/98h2cnyxyaw4/8qx...")}
                    >
                      <img src={copyIcon} alt="copy" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-between text-xs mx-2">
                  <div className=" text-[#FFFFFF80] pb-2 text-left ">
                    How much do you want to invest in this rocket?
                  </div>
                  <p className="font-bold">20%</p>
                </div>
                <CounterField
                  value={investValue}
                  handleChange={handleChangeValue}
                  displayText={`$${investValue}`}
                  max={1000}
                  min={rocket?.min_deposite ?? 0}
                />
              </div>
            )}
          </div>
          <div className="pb-2 pt-4 w-full">
            {!isUserPassenger ? (
              <Button
                text="Confirm"
                style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
                handleClick={handleConfirm}
              />
            ) : (
              <Button
                text="Invite Friend"
                style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
                handleClick={handleConfirm}
              />
            )}
          </div>
        </div>
      </BottomBlock>
    </ModalWindow>
  );
};

export default ModalGetInRocket;
