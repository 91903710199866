import React, { useState } from "react";
import SearchIcon from "../../../assets/icons/search-icon.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";

interface SearchBarProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ value = "", setValue }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleReset = () => {
    setValue("");
  };

  return (
    <div className="h-[40px] w-full py-[10px] px-4 flex justify-between bg-[#0D0D0D40] rounded-xl italic relative">
      <input
        type="text"
        placeholder="Search by tokens"
        className={`h-full w-full italic bg-transparent placeholder:italic placeholder:text-[14px] placeholder:text-[${
          isFocused ? "white" : "#958E9A"
        }] placeholder:font-normal pl-[26px] outline-none pr-1`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        onChange={(e) => handleChange(e)}
      />
      <SearchIcon className={`absolute w-5 h-5 ${isFocused || value.length > 0 ? "fill-white" : "fill-[#958E9A]"}`} />
      {value.length > 0 && (
        <div
          className="w-5 min-w-5 h-5 bg-white/50 rounded-[50%] flex justify-center items-center"
          onClick={handleReset}
        >
          <CloseIcon className={`absolute w-[14px] h-[14px] fill-[#2C1E35]`} />{" "}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
