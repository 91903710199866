import { ExternalImage } from "../../ui/ExternalImage";

interface TokenImageProps {
  classesName?: string;
  imageSrc: string;
}
const TokenImage = ({
  classesName = "rounded-2xl  w-[88px] h-[88px] min-w-[88px] min-h-[88px]",
  imageSrc,
}: TokenImageProps) => {
  return (
    <ExternalImage
      loading="lazy"
      src={imageSrc}
      alt="token-image"
      className={`  size-full object-cover  ${classesName}`}
    />
  );
};

export default TokenImage;
