// import rocketImage from "../../../assets/img/rocket.png";
import rocketImage from "../../../assets/icons/big-rocket.svg";
import infoIcon from "../../../assets/icons/info.svg";
import StatusRocket from "./StatusRocket";

interface Rocket {
  name: string;
  rarity: string;
  status: string;
  time?: string;
  active?: boolean;
}
export const RocketCard: React.FC<{ rocket: Rocket }> = ({ rocket }) => {
  const active = rocket.active != undefined ? rocket.active : true;
  return (
    <div
      className={`relative w-full rounded-2xl h-[256px] max-h-[256px] border-solid border-[1px] border-[#493E50] z-0 ${
        !active ? "bg-[#261a2e] opacity-50" : ""
      } `}
    >
      <div className={`relative w-full h-full z-10 flex flex-col ${!active ? "mix-blend-luminosity" : ""} `}>
        <img src={infoIcon} alt="info" className="w-4 h-4 absolute right-[14px] top-[14px]" />
        <div className="w-full h-[161px] flex justify-center  py-4 ">
          <img src={rocketImage} alt="rocket" className="w-[128px] h-[128px] " />
        </div>
        <div className=" relative w-full flex-grow rounded-bl-2xl rounded-br-2xl mx-auto h-[95px]">
          <div className="absolute top-0 w-full h-full rounded-bl-2xl rounded-br-2xl  bg-black opacity-25 z-0"></div>
          <div className="relative pt-2 pb-2 mx-auto text-center z-10 font-medium">
            <p className="leading-[14px] mb-[7px]">{rocket.name}</p>
            <p className="text-xs leading-3 mb-2">
              <span className="text-[#989898] mr-1">Rarity:</span>
              <span className="font-bold ">{rocket.rarity}</span>
            </p>
            <div className="px-2">
              <StatusRocket status={rocket.status} time={rocket?.time} />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full h-full opacity-50 rounded-2xl bg-[#1F1F1F] z-0"></div>
    </div>
  );
};
