import IconWithText from "./ui/IconWithText";
import UserBalanceBlock from "./UserBalanceBlock";
import usersIcon from "../assets/icons/users.svg";
import { Link, useParams } from "react-router-dom";

interface TopPanelProps {
  page: string;
}

export default function TopPanel({ page }: TopPanelProps) {
  const { roundId } = useParams<{ roundId: string }>();

  return (
    <div className="flex justify-between items-center">
      <UserBalanceBlock />
      {page === "lobby" && <IconWithText icon={usersIcon} text="1,274" style="font-semibold" />}
      {page === "registering" && (
        <Link to={`/rounds/${roundId}/addRocket`}>
          <div className="w-[122px] h-[42px] flex justify-center relative">
            <button
              className="absolute w-[114px] h-[38px] rounded-[32px] flex justify-center items-center z-10 text-xs font-bold   hover:scale-105 ease-in duration-300"
              style={{
                backgroundImage: "linear-gradient(89.51deg, #FFAE34 -9.17%, #F37E3B 98.77%)",
              }}
            >
              <span className="uppercase tracking-wide">Add Rocket</span>
            </button>
            <div
              className="absolute bottom-0 w-[122px] h-[38px] rounded-[32px]"
              style={{
                backgroundImage: "linear-gradient(89.51deg, #FFAE34 -9.17%, #F37E3B 98.77%)",
                filter: "blur(40px)",
              }}
            ></div>
          </div>
        </Link>
      )}
      {page === "buy" && (
        <Link to={`/rounds/${roundId}/addRocket`}>
          <div className="w-[122px] h-[42px] flex justify-center relative">
            <button
              className="absolute w-[114px] h-[38px] rounded-[32px] flex justify-center items-center z-10 text-xs font-bold   hover:scale-105 ease-in duration-300"
              style={{
                backgroundImage: "linear-gradient(89.51deg, #FFAE34 -9.17%, #F37E3B 98.77%)",
              }}
            >
              <span className="uppercase tracking-wide">Buy Rocket</span>
            </button>
            <div
              className="absolute bottom-0 w-[122px] h-[38px] rounded-[32px]"
              style={{
                backgroundImage: "linear-gradient(89.51deg, #FFAE34 -9.17%, #F37E3B 98.77%)",
                filter: "blur(40px)",
              }}
            ></div>
          </div>
        </Link>
      )}
    </div>
  );
}
