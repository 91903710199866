import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonsBox from "../../components/Portfolio/build/ButtonsBox";
import PortfolioAllocationOverview from "../../components/Portfolio/build/PortfolioAllocationOverview";
import TokenAllocationItem from "../../components/Portfolio/build/TokenAllocationItem";
import BottomButton from "../../components/ui/BottomButton";
import DarkBackground from "../../components/ui/DarkBackground";
import Title from "../../components/ui/Title";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeStatusBottomMenu, setStatusBuildPortfolio } from "../../store/menu/bottomMenuReducer";
import { calculateTotalsPercentagePortfolio, changeStatusModalRocket } from "../../store/rocket/rocketReducer";
import { addRocketToRound } from "../../store/round/roundReducer";

const BuildPortfolioPage = () => {
  const dispatch = useAppDispatch();

  const portfolio = useAppSelector((state) => state.rocket.rocket?.portfolio);
  const rocket = useAppSelector((state) => state.rocket.rocket);

  const [allocationPortfolio, setAllocationPortfolio] = useState<number>(0);

  const { roundId } = useParams<{ roundId: string }>();

  const navigate = useNavigate();

  const handleRegisterToRound = () => {
    dispatch(addRocketToRound(rocket));
    const newPath = `/rounds/${roundId}`;
    navigate(newPath);
    dispatch(changeStatusModalRocket(true));
  };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(setStatusBuildPortfolio(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  useEffect(() => {
    setAllocationPortfolio(calculateTotalsPercentagePortfolio(portfolio));
  }, [portfolio]);

  return (
    <div>
      <DarkBackground>
        <div className="pb-4">
          <Title text="Build a portfolio" />
          <PortfolioAllocationOverview allocationPortfolio={allocationPortfolio} portfolio={portfolio} />
          <div className="pt-[20px]">
            <ButtonsBox />
          </div>
        </div>
      </DarkBackground>
      <div className="flex flex-col gap-2 mt-4 mb-[100px]">
        {portfolio?.map((item, index) => <TokenAllocationItem item={item} key={index} />)}
      </div>
      <BottomButton
        text="Register to round"
        handler={handleRegisterToRound}
        bgColor={allocationPortfolio !== 100 ? "#563C66" : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"}
        className="disabled:text-white/50"
        attributes={{
          disabled: allocationPortfolio !== 100,
        }}
      />
    </div>
  );
};

export default BuildPortfolioPage;
