import { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Exchange } from "../../store/exchanges/types";
import { ExternalImage } from "../ui/ExternalImage";
import { GradientCard } from "../ui/GradientCard";
import { Timer } from "../ui/Timer";
import { ClickerGameTimerRef } from "../Clicker/GameTimer";
import { ClaimRewardScreen } from "./ClaimRewardScreen";
import { useSelector } from "react-redux";
import { tasksSelector } from "../../store/tasks/tasksReducer";
import { TaskActionEnum } from "../../store/tasks/api/types";
import { useTelegramApp } from "../../hooks/useTelegramApp";
import { getRewardOKX } from "../../api/user/getRewardOKX";
import { useNavigate } from "react-router-dom";
import { getExpiryTimeFromLocalStorage, saveExpiryTimeToLocalStorage } from "../../pages/TasksPage";
import { useAppDispatch } from "../../store/hooks";
import { fetchBalanceAction } from "../../store/account/actions/fetchBalanceAction";

const ExchangeRegistrationCheck = ({ exchange }: { exchange: Exchange | null }) => {
  const [inputUIDValue, setInputUIDtValue] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [response, setResponse] = useState<boolean | null>(null);

  const tgApp = useTelegramApp();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tasks = useSelector(tasksSelector);

  const currentTask = tasks.find(({ action_name }) => action_name === TaskActionEnum.REDIRECT_TO_OKX);

  const timerRef = useRef<ClickerGameTimerRef>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [timeRemaining, setTimeRemaining] = useState(getExpiryTimeFromLocalStorage("okx registration"));

  let isButtonDisabled = inputUIDValue.trim().length == 0;
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log("event.target.value", event.target.value);

    setInputUIDtValue(event.target.value);
    isButtonDisabled = inputUIDValue.trim().length == 0;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("send id");
  };
  const handleRegisterAccountOKX = () => {
    tgApp.openLink(currentTask?.action_url || "");
  };

  const handleCheckIsRegistered = async () => {
    const result = await getRewardOKX(inputUIDValue);
    if (result.data.message) {
      setResponse(false);
      setErrorMessage(result.data.message);
      saveExpiryTimeToLocalStorage("okx registration", 600000);
      setTimeRemaining(getExpiryTimeFromLocalStorage("okx registration"));
      console.log("resuktOKX", result.data.message);
    } else {
      setResponse(true);
      dispatch(fetchBalanceAction());
    }
  };

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "instant", block: "start" });
    }
  };

  const handleInputBlur = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div className=" pt-6">
      <h2 className="text-[16px] font-semibold text-center leading-6 pb-4">Your tasks: </h2>
      <div className="flex flex-col gap-2">
        <GradientCard backgroundColor="#28202C">
          <div className="flex justify-between pl-1 pr-3 items-center">
            <div className="flex items-center ">
              <div className="size-[56px] bg-white/25 rounded-xl flex justify-center items-center">
                {exchange?.iconUrl2 && <ExternalImage src={exchange?.iconUrl2} alt={exchange?.title} height={11} />}
              </div>
              <div className="flex-1 py-6 w-full flex gap-y-1 justify-between items-center leading-none px-3">
                <p className="text-[16px] font-medium">Join {exchange?.title} Today</p>
              </div>
            </div>
            <button
              className="h-[24px] w-[57px] rounded-3xl"
              style={{ background: "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)" }}
              onClick={handleRegisterAccountOKX}
            >
              Go
            </button>
          </div>
        </GradientCard>
        <GradientCard backgroundColor="#28202C">
          <div className="flex flex-col w-full  gap-4 justify-between items-center leading-none py-[27px] px-3">
            <p className="text-[16px] font-medium">Enter your {exchange?.title} ID</p>
            <form className="w-full flex justify-between items-center gap-2 " onSubmit={handleSubmit}>
              <input
                ref={inputRef}
                className="px-2 py-5 rounded-xl bg-white/15 flex-1 appearance-none outline-none text-[16px]"
                name="UID"
                value={inputUIDValue}
                onChange={handleInputChange}
                placeholder="Enter your ID here"
                onFocus={handleInputFocus}
                onBlur={() => window.scrollTo(0, 0)}
              />
              {response === null && timeRemaining === null ? (
                <button
                  className={`h-[24px] w-[57px] rounded-3xl ${isButtonDisabled ? "cursor-not-allowed" : ""}`}
                  style={{
                    background: isButtonDisabled
                      ? "#5c565f"
                      : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)",
                  }}
                  disabled={isButtonDisabled}
                  onClick={handleCheckIsRegistered}
                  onBlur={handleInputBlur}
                >
                  Go
                </button>
              ) : (
                <div className="space-y-2">
                  <Timer
                    ref={timerRef}
                    remainingTime={timeRemaining || 0}
                    format="mm:ss"
                    onEnd={() => setTimeRemaining(null)}
                  />
                  <p className="text-[12px]">Update</p>
                </div>
              )}
            </form>
            {(response === false || timeRemaining) && <p className="text-[16px] text-[#FF5858]"> {errorMessage}</p>}
          </div>
        </GradientCard>
      </div>
      <ClaimRewardScreen
        isOpened={!!response}
        onClaim={() => {
          navigate("/");
        }}
        isPending={false}
        exchange={exchange!!}
      />
    </div>
  );
};

export default ExchangeRegistrationCheck;
