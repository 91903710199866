import plusIcon from "../../assets/icons/plus.svg";
import minusIcon from "../../assets/icons/minus.svg";
import GradientBorderBox from "./GradientBorderBox";

interface CounterFieldProps {
  value: number;
  handleChange: (newValue: number) => void;
  displayText: string;
  step?: number;
  background?: string;
  border?: boolean;
  paddingY?: string;
  textStyle?: string;
  min?: number;
  max: number;
}

const CounterField: React.FC<CounterFieldProps> = ({
  value,
  handleChange,
  displayText,
  step = 10,
  border,
  background = "bg-mediumGrey/50",
  paddingY = "py-2",
  textStyle = "white",
  min = 0,
  max,
}) => {
  const onChange = (counter: number) => {
    console.log(value);

    if (counter >= min && max && counter <= max) handleChange(counter);
  };
  return (
    <GradientBorderBox className="h-full" border={border}>
      <div className={`relative flex w-full h-full  items-center rounded-2xl px-2 gap-2   ${paddingY}  ${background}`}>
        <div className="w-full h-full flex z-10 justify-between items-center">
          <div
            className="w-8 h-8 bg-[#80549A] rounded-[50%] flex justify-center items-center"
            onClick={() => onChange(value - step)}
          >
            <img src={minusIcon} alt="plus" />
          </div>
          <div className={`text-[14px] font-bold ${textStyle}`}>{displayText}</div>
          <div
            className="w-8 h-8 bg-[#80549A] rounded-[50%] flex justify-center items-center"
            onClick={() => onChange(value + step)}
          >
            <img src={plusIcon} alt="plus" />
          </div>
        </div>
      </div>
    </GradientBorderBox>
  );
};

export default CounterField;
