import { notificationsSelector } from "../store/toastNotifications/selectors.ts";
import { useSelector } from "react-redux";
import {
  hideNotification,
  showNotification,
  ToastNotification,
} from "../store/toastNotifications/toastNotificationsReducer.ts";
import { useAppDispatch } from "../store/hooks.ts";

export function useToastNotifications() {
  const notifications = useSelector(notificationsSelector);
  const dispatch = useAppDispatch();

  return {
    notifications,

    /**
     *
     * @returns A function that hides the notification
     */
    showNotification(notification: Omit<ToastNotification, "id">) {
      const id = Math.max(...notifications.map(({ id }) => id)) + 1;

      dispatch(showNotification({ id, ...notification }));

      if (notification.timeoutMs) {
        setTimeout(() => {
          dispatch(hideNotification(id));
        }, notification.timeoutMs);
      }

      /**
       * Hide the notification
       */
      return () => dispatch(hideNotification(id));
    },
  };
}
