import { useEffect, useRef } from "react";

export function useWatch<T>(value: T, onChange: (current: T, prev: T) => void) {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (ref.current) {
      onChange(value, ref.current);
    }

    ref.current = value;
  }, [value]);
}
