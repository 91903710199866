import IconWithText from "../../ui/IconWithText";

import usersIcon from "../../../assets/icons/users-white.svg";
import photoIcon from "../../../assets/icons/user2.svg";
import ownerBadgeIcon from "../../../assets/icons/owner-badge.svg";
import investedBadgeIcon from "../../../assets/icons/invested-badge.svg";

import style from "../Rocket.module.css";
import StatisticsBlock from "./StatisticsBlock";
import { Link, useParams } from "react-router-dom";
import { Rocket } from "../../../store/rocket/api/types";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../../store/account/account";
import { useAppSelector } from "../../../store/hooks";
import { addLeadingZero } from "../../../helpers/addLeadingZero";

const RocketItem: React.FC<{
  rocket: Rocket;
}> = ({ rocket }) => {
  const { id, name, min_deposite, profit, percentage_profit, ownerId, passengers } = rocket;
  const players = rocket.passengers?.length;

  const { roundId } = useParams<{ roundId: string }>();
  const round = useAppSelector((state) => state.round.round);
  const allRockets = round?.rockets;

  const rocketIndex = allRockets?.findIndex((item) => item.id === id);

  const currentUserId = useSelector(userIdSelector);
  const isCurrentUserOwner = currentUserId === ownerId;
  const isCurrentInvestedInRocket =
    passengers !== undefined && passengers?.findIndex((passenger) => passenger.id === currentUserId) >= 0;

  const yourInvest =
    rocket?.passengers?.find((passenger) => passenger.id === currentUserId && passenger.id !== rocket.ownerId)
      ?.deposite || 0;
  const ownerInvest =
    rocket?.passengers?.find(
      (passenger) =>
        passenger.id === rocket.ownerId || (passenger.id === currentUserId && passenger.id === rocket.ownerId),
    )?.deposite || 0;
  const otherInvest =
    rocket?.passengers?.reduce(
      (acc, passenger) =>
        passenger.id !== currentUserId && passenger.id != rocket.ownerId ? acc + passenger.deposite : acc,
      0,
    ) || 0;

  const percentOfYourInvest = (100 * yourInvest) / (round?.buyIn || 0);
  const percentOfOtherInvest = (100 * otherInvest) / (round?.buyIn || 0);
  const percentOfOwnerInvest = (100 * ownerInvest) / (round?.buyIn || 0);
  const fullnessValue = percentOfYourInvest + percentOfOtherInvest + percentOfOwnerInvest;

  return (
    <Link to={`/rounds/${roundId}/rockets/${id}`}>
      <div
        onClick={() => console.log(roundId)}
        className={
          "relative flex gap-[6px] max-h-[64px] items-center  rounded-[16px] font-medium block_info  hover:bg-[#4A315A] ease-in duration-300 " +
          style["rocket-item "]
        }
        style={{
          backgroundImage: isCurrentUserOwner
            ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(1, 133, 255, 0.2) 0%, rgba(1, 133, 255, 0) 100%)"
            : isCurrentInvestedInRocket
              ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(1, 166, 97, 0.15) 0%, rgba(1, 166, 97, 0) 100%)"
              : "",
        }}
      >
        <div className="relative bg-lightPurple rounded-[12px] m-[3px]">
          <img src={photoIcon} className=" rounded-[12px] w-[56px] h-[56px] bg-cover" />
          <div className="absolute top-[2px] left-[2px] rounded-2xl w-[20px] h-[20px] bg-[#2F2038] text-[10px]">
            {addLeadingZero((rocketIndex ?? 0) + 1)}
          </div>
        </div>
        <div className="pb-[8px] pt-[14px] text-xs flex items-end flex-grow justify-between mr-3">
          <div>
            <p className="pb-[15px] text-justify tracking-[0.02em] text-[14px] leading-[14px]">{name}</p>
            <StatisticsBlock
              fullness={fullnessValue}
              min_deposite={min_deposite}
              profit={profit}
              percentage_profit={percentage_profit}
            />
          </div>

          <IconWithText icon={usersIcon} text={players || 0} style="font-bold" />
        </div>
        {isCurrentUserOwner || isCurrentInvestedInRocket ? (
          <img
            src={isCurrentUserOwner ? ownerBadgeIcon : investedBadgeIcon}
            alt="owner"
            className="w-6 h-8 absolute top-[-1px] right-[19px]"
          />
        ) : (
          ""
        )}
      </div>
    </Link>
  );
};
export default RocketItem;
