import { useSelector } from "react-redux";
import Title from "../../../ui/Title";
import NftItem from "./NftItem";

import nftIcon from "../../../../assets/icons/nft.svg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { clamp } from "lodash-es";
import { useTelegramApp } from "../../../../hooks/useTelegramApp";
import { nftSelector } from "../../../../store/cryptoProfile/cryptoProfileReducer";
import { fetchRewardForNftAction } from "../../../../store/cryptoProfile/actions/fetchRewardForNftAction";
import { useAppDispatch } from "../../../../store/hooks";
import { useToastNotifications } from "../../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../../store/toastNotifications/toastNotificationsReducer";
import { moneyFormat, nFormatter } from "../../../../helpers/moneyFormat";
import { useEffect, useState } from "react";

const NftList = () => {
  const myNfts = useSelector(nftSelector);
  const [isReceivedReward, setIsReceivedReward] = useState<Boolean>(true);

  useEffect(() => {
    setIsReceivedReward(myNfts.some((nft) => nft.reward));
  }, [myNfts]);

  const dispatch = useAppDispatch();

  const { showNotification } = useToastNotifications();

  const handleGetReward = async () => {
    const mintAddressArray = myNfts.map((nft) => nft.mintAddress);

    const result = await dispatch(fetchRewardForNftAction(mintAddressArray));
    if (!fetchRewardForNftAction.fulfilled.match(result)) {
      showNotification({
        title: `Something went wrong!`,
        type: ToastNotificationType.Error,
        timeoutMs: 5000,
      });
    } else {
      showNotification({
        title: `You successfully received ${nFormatter(result.payload.coins)} $RCT and ${moneyFormat(result.payload.ticket)} tickets!`,
        type: ToastNotificationType.Success,
        timeoutMs: 5000,
      });
    }
  };

  return (
    <div className="pt-[3vh]">
      <Title text="Your OrbitonX NFTs" style="text-left !text-[16px] pb-4" />
      <Swiper
        slidesPerView={3.5}
        spaceBetween={10}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        className="mySwiper"
      >
        {myNfts?.map((nft, index) => (
          <SwiperSlide key={index}>
            <NftItem nft={nft} showClaimButton={index === 0 && !isReceivedReward} handleGetReward={handleGetReward} />
          </SwiperSlide>
        ))}
        <SwiperSlide key="magic-eden-block">
          <MagicEdenBlock />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default NftList;

const MagicEdenBlock = () => {
  const tgApp = useTelegramApp();

  const handleOpenSite = () => {
    tgApp.openLink("https://magiceden.io/launchpad/orbitonx?preview=");
  };

  return (
    <div
      onClick={handleOpenSite}
      className="rounded-2xl w-full p-2 leading-none flex flex-col gap-[6px] justify-center items-center font-semibold aspect-square border-solid border-[1px] border-[#6c4e7e] bg-[rgba(255,255,255,0.16)] text-center"
      style={{
        fontSize: clamp(window.innerWidth * 0.027, 6, 12),
      }}
    >
      <p>Mint</p>
      <img src={nftIcon} alt="nftIcon" className="w-[6vw]" />
      <p>on MagicEden</p>
    </div>
  );
};
