import { KeyframeOptions, animate, useInView, useIsomorphicLayoutEffect } from "framer-motion";
import { useRef } from "react";
import { moneyFormat } from "../../helpers/moneyFormat";

type AnimatedCounterProps = {
  from: number;
  to: number;
  duration: number;
  animationOptions?: KeyframeOptions;
  toFixed?: number;
};

const AnimatedCounter = ({ from, to, duration, animationOptions, toFixed = 0 }: AnimatedCounterProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const inView = useInView(ref, { once: true });

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;

    if (!element) return;
    if (!inView) return;

    // Set initial value
    element.textContent = moneyFormat(from);

    // If reduced motion is enabled in system's preferences
    if (window.matchMedia("(prefers-reduced-motion)").matches) {
      element.textContent = moneyFormat(to);
      return;
    }

    const controls = animate(from, to, {
      duration: duration,
      ease: "easeOut",
      ...animationOptions,
      onUpdate(value) {
        element.textContent = moneyFormat(Number(value.toFixed(toFixed)));
      },
    });

    // Cancel on unmount
    return () => {
      controls.stop();
    };
  }, [ref, inView, from, to]);

  return <span ref={ref} />;
};

export default AnimatedCounter;
