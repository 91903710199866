import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store.ts";
import { League, LeagueTransition } from "./api/types.ts";
import { fetchLeaguesListAction } from "./actions/fetchLeaguesListAction.ts";
import { fetchLeagueTransitionAction } from "./actions/fetchLeagueTransitionAction.ts";
import { CurrentUser, UserInLeague } from "../../api/user/types.ts";
import { fetchUsersInLeagueAction } from "./actions/fetchUsersInLeagueAction.ts";
import { addUsersInLeagueAction } from "./actions/addUsersInLeagueAction.ts";

export interface LeaguesState {
  pending: boolean;
  pendingGetMoreUsers: boolean;
  error: string | null;
  leagues: League[];
  leagueTransition: LeagueTransition | null;
  users: UserInLeague[];
  totalUsers: number;
  currentUser: CurrentUser | null;
}

const initialState: LeaguesState = {
  leagues: [],
  leagueTransition: null,
  users: [],
  totalUsers: 0,
  pending: false,
  pendingGetMoreUsers: false,
  error: null,
  currentUser: null,
};

const leaguesSlice = createSlice({
  name: "leagues",
  initialState,
  reducers: {
    clearUsers(state) {
      state.users = [];
      state.totalUsers = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeaguesListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchLeaguesListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.leagues = action.payload.leagues;
      })
      .addCase(fetchLeaguesListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchLeagueTransitionAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.leagueTransition = action.payload;
      })
      .addCase(fetchLeagueTransitionAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });

    //  FETCH LIST OF USERS IN LEAGUE
    builder
      .addCase(fetchUsersInLeagueAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchUsersInLeagueAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.users = action.payload.users;
        state.totalUsers = action.payload.total;
        if (action.payload.currentUser) state.currentUser = action.payload.currentUser;
      })
      .addCase(fetchUsersInLeagueAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(addUsersInLeagueAction.pending, (state) => {
        state.pendingGetMoreUsers = true;
        state.error = null;
      })
      .addCase(addUsersInLeagueAction.fulfilled, (state, action) => {
        state.pendingGetMoreUsers = false;
        state.error = null;
        state.users = [...state.users, ...action.payload.users];
        state.totalUsers = action.payload.total;
        if (action.payload.currentUser) state.currentUser = action.payload.currentUser;
      })
      .addCase(addUsersInLeagueAction.rejected, (state, action) => {
        state.pendingGetMoreUsers = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const { clearUsers } = leaguesSlice.actions;

export const leaguesSelector = (state: RootState) => state.leagues.leagues;
export const leagueTransitionSelector = (state: RootState) => state.leagues.leagueTransition;
export const usersInLeagueSelector = (state: RootState) => state.leagues.users;
export const currentUserSelector = (state: RootState) => state.leagues.currentUser;
export const isPendingSelector = (state: RootState) => state.leagues.pending;
export const isPendingGetMoreUsersSelector = (state: RootState) => state.leagues.pendingGetMoreUsers;
export const totalUsersSelector = (state: RootState) => state.leagues.totalUsers;

export default leaguesSlice.reducer;
