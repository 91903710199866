import { Coin } from "../../../store/rocket/api/types";

const coins: Coin[] = [
  {
    symbol: "BTC",
    name: "Bitcoin",
    cost: "$63,240.00",
    priceChange: "-0.0154",
    priceChangePercentage: "-4.40%",
  },
  {
    symbol: "ETH",
    name: "Ethereum",
    cost: "$3,150.31",
    priceChange: "+0.0154",
    priceChangePercentage: "+4.40%",
  },
  {
    symbol: "USDT",
    name: "AppCoins",
    cost: "$1,00",
    priceChange: "-0.0154",
    priceChangePercentage: "-4.40%",
  },
  {
    symbol: "APPC",
    name: "AppCoins",
    cost: "$0,003469235235",
    priceChange: "+0.0154",
    priceChangePercentage: "+4.40%",
  },
  {
    symbol: "ARDR",
    name: "AppCoins",
    cost: "$0,000432",
    priceChange: "+0.0154",
    priceChangePercentage: "+4.40%",
  },
];

export default coins;
