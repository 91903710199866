import closeIcon from "../../assets/icons/close.svg";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusModalRocket } from "../../store/rocket/rocketReducer";

interface HeaderProps {
  text: string;
  closeModal?: (state: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ text, closeModal }) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(changeStatusModalRocket(false));
    if (closeModal) closeModal(false);
  };
  return (
    <div className="relative py-[17px] font-bold text-[20px] leading-[20px] flex  justify-center items-center ">
      <div className="max-w-[270px] text-center">{text}</div>
      <button className="w-5 h-5 absolute right-0 " onClick={handleCloseModal}>
        <img src={closeIcon} alt="close" className="w-full h-full" />
      </button>
    </div>
  );
};

export default Header;
