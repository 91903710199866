import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MAX_TOKENS_IN_PORTFOLIO, MIN_TOKENS_IN_PORTFOLIO } from "../../../../constants/token";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Coin } from "../../../../store/rocket/api/types";
import {
  changeRocketPortfolio,
  changeStatusModalRocket,
  isTokenInPortfolio,
} from "../../../../store/rocket/rocketReducer";
import CoinNameBlock from "../../../Coin/CoinNameBlock";
import coins from "../../../Coin/config/coins";
import BottomButton from "../../../ui/BottomButton";
import Switch from "../../../ui/Switch";
import SearchBar from "../SearchBar";

const CoinItem = ({ coin, isOn }: { coin: Coin; isOn: boolean }) => {
  const dispatch = useAppDispatch();

  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(isOn);

  const toggleSwitch = (newValue: boolean) => {
    setIsSwitchOn(newValue);
    dispatch(changeRocketPortfolio(coin));
  };

  return (
    <div className="pt-[15px] pb-[16px]  border-solid border-b-[1px] border-[#FFFFFF15] flex justify-between items-center">
      <CoinNameBlock symbol={coin.symbol} name={coin.name} />
      <Switch
        bgColor="#00000040"
        bgActiveColor="#45D700"
        circleColor="white"
        isOn={isSwitchOn}
        toggleSwitch={toggleSwitch}
      />
    </div>
  );
};

const ModalContent = () => {
  const [searchToken, setSearchToken] = useState("");
  const [coinsVisible, setCoinsVisible] = useState<Coin[]>([]);

  const dispatch = useAppDispatch();

  const rocket = useAppSelector((state) => state.rocket.rocket);
  const portfolio = useAppSelector((state) => state.rocket.rocket?.portfolio);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchToken.length > 0) {
      const filteredCoins = coins.filter((coin) => coin.symbol.toLowerCase().includes(searchToken.toLowerCase()));
      setCoinsVisible(filteredCoins);
    } else {
      setCoinsVisible(coins);
    }
  }, [searchToken]);

  const handleClick = () => {
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}/buildPortfolio`;
    navigate(newPath);
    dispatch(changeStatusModalRocket(false));
  };

  return (
    <div className="text-center">
      <h3 className="text-[#FFFFFFBF] tracking-[0.02em] text-xs font-medium mb-[17px]">
        <span className="font-bold ">
          {portfolio?.length || 0}/{MAX_TOKENS_IN_PORTFOLIO}
        </span>{" "}
        tokens selected (minimum {MIN_TOKENS_IN_PORTFOLIO})
      </h3>
      <SearchBar value={searchToken} setValue={setSearchToken} />
      <div className="mb-[100px]">
        {coinsVisible.map((coin: Coin) => (
          <CoinItem key={coin.symbol} coin={coin} isOn={isTokenInPortfolio(rocket, coin)} />
        ))}
      </div>
      <BottomButton
        text="Confirm"
        handler={handleClick}
        bgColor={
          (portfolio?.length || 0) < MIN_TOKENS_IN_PORTFOLIO || (portfolio?.length || 0) > MAX_TOKENS_IN_PORTFOLIO
            ? "#563C66"
            : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
        }
        className="disabled:text-white/50"
        attributes={{
          disabled:
            (portfolio?.length || 0) < MIN_TOKENS_IN_PORTFOLIO || (portfolio?.length || 0) > MAX_TOKENS_IN_PORTFOLIO,
        }}
      />
    </div>
  );
};

export default ModalContent;
