import { NavLink, useParams } from "react-router-dom";
import { GradientCard } from "../../ui/GradientCard";
import NoteActions, { DisplayFormat, Page } from "./NoteActions";
import NoteInfo from "./NoteInfo";

import chevronRightIcon from "../../../assets/icons/arrow-right.svg";
import { useSelector } from "react-redux";
import { lastNoteSelector } from "../../../store/token/tokensReducer";

const LastNote = () => {
  const { tokenId } = useParams();

  const lastNote = useSelector(lastNoteSelector);

  return (
    <>
      <GradientCard backgroundColor="#28202C">
        <div className={"flex flex-col relative pt-4 h-min pb-3 px-1 rounded-[16px]  leading-none"}>
          <div className="flex justify-between px-2">
            <p className="font-semibold">Community notes</p>
            <NavLink to={`/pumpadur/tokens/${tokenId}/notes`} className="flex gap-1">
              <span
                className="font-medium"
                style={{
                  background: "linear-gradient(90deg, #27beff 0%, #0075ff 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                View All
              </span>
              <img src={chevronRightIcon} className="w-2" alt="Right arrow" />
            </NavLink>
          </div>
          {lastNote && (
            <>
              <div className="bg-black/70 relative p-2 rounded-2xl mt-4">
                <NoteInfo note={lastNote} />
              </div>
              <div className="pt-1 px-2">
                <NoteActions
                  text="Is this note helpful?"
                  textStyle="text-[12px]"
                  iconStyle="size-4 "
                  displayFormat={DisplayFormat.YES_NO}
                  noteId={lastNote.id}
                  page={Page.LAST_NOTE}
                />
              </div>
            </>
          )}
        </div>
      </GradientCard>
    </>
  );
};

export default LastNote;
