import commentImage from "../../../../assets/icons/comment.svg";
import { getTimeDifference } from "../../../../helpers/getTimeDifference";
import { Token } from "../../../../store/token/api/types";
import CreatedBy from "../TokenDetails/CreatedBy";

const TokenSummary = ({ token }: { token: Token }) => {
  const { name, ticker, userName, createdAt, description } = token;

  return (
    <div className="w-full text-[12px] font-medium leading-none">
      <div className="w-full flex justify-between uppercase font-semibold pb-[10px]">
        <p className="text-[14px] leading-none">{name}</p>
        <p className=" text-[#b5b5b5]">{ticker}</p>
      </div>
      <p className="pb-3 text-[10px] text-[#95879e]">{description.slice(0, 26)}</p>
      <CreatedBy userName={userName} />
      <div className="flex gap-[5px] pb-3">
        <div>{getTimeDifference(createdAt)}</div>
        <div className="flex justify-center items-center gap-1">
          <img className="size-4" src={commentImage} alt="Cosmonaut" loading="lazy" />
          <p>47</p>
        </div>
      </div>
    </div>
  );
};

export default TokenSummary;
