import { ReactNode } from "react";

interface BlockInfoProps {
  icon?: string;
  sing?: string;
  text?: string | number | ReactNode;
  value?: string | number | ReactNode;
  textSize?: string;
}

const BlockInfo: React.FC<BlockInfoProps> = ({ icon, sing, text, value, textSize = "18" }) => {
  return (
    <div className="flex flex-col items-center gap-[1px] rounded-2xl bg-[#FFFFFF26] pt-[6px] pb-[8px] h-[66px]">
      {icon && <img src={icon} alt={icon} className="w-4 h-4" />}
      {sing && <span className=" text-[16px] leading-4">{sing}</span>}
      <div className="text-[12px] leading-5 text-[#989898] font-medium ">{text}</div>
      <div className={`font-bold leading-4`} style={{ fontSize: `${textSize}px` }}>
        {value}
      </div>
    </div>
  );
};

export default BlockInfo;
