import { useSelector } from "react-redux";
import { cardsSelector } from "../../../store/cards/cardsReducer";
import { PurchasedCards } from "../../../store/cards/api/types";
import { ExternalImage } from "../../ui/ExternalImage";

const ComboCards = ({ comboList }: { comboList: PurchasedCards[] }) => {
  const cards = useSelector(cardsSelector);

  return (
    <div className="flex justify-between gap-[4vw] pt-4">
      {Array.from({ length: 3 }).map((_, index) => {
        const comboCard = comboList[index];
        const cardDetails = comboCard ? cards.find((item) => item.key === comboCard.key) : null;

        return (
          <div
            key={index}
            className="relative w-1/3 rounded-2xl bg-white/10 p-3"
            style={{ height: "calc(29vw * 1/1)" }}
          >
            <div className="w-full h-full bg-black/20 rounded-2xl relative">
              {cardDetails ? (
                <ExternalImage
                  src={cardDetails.img}
                  alt={cardDetails.name}
                  className="size-full object-cover rounded-xl"
                />
              ) : (
                <img className="absolute inset-0 w-full h-full" src="/combo-cards.png" alt="" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ComboCards;
