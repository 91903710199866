import { GradientLine } from "./GradientLine.tsx";
import Input from "./Input.tsx";
import copyIcon from "../../assets/icons/copy.svg";
import { useRef } from "react";
import { useToastNotifications } from "../../hooks/useToastNotifications.ts";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer.ts";

interface CopyToClipboardInputProps {
  value: string;
  onCopy?: () => void;
}

const MESSAGE_DURATION = 2000;

export function CopyToClipboardInput({ value, onCopy }: CopyToClipboardInputProps) {
  const { showNotification } = useToastNotifications();

  const prevMessageHide = useRef<() => void>();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);

      if (prevMessageHide.current) prevMessageHide.current();

      prevMessageHide.current = showNotification({
        title: "Copied to clipboard",
        type: ToastNotificationType.Success,
        timeoutMs: MESSAGE_DURATION,
      });

      onCopy && onCopy();
    } catch (error) {
      showNotification({
        title: "Failed to copy to clipboard",
        type: ToastNotificationType.Error,
        timeoutMs: MESSAGE_DURATION,
      });
    }
  };

  return (
    <div className="relative">
      <Input readOnly={true} type="text" value={value} className="truncate pr-[70px] text-[12px]" />

      {/* COPY BUTTON */}
      <button
        onClick={(event) => {
          event.stopPropagation();
          handleCopy();
        }}
        className="absolute top-0 right-4 h-full px-3 text-sm w-[33px] z-20 !p-0"
      >
        <GradientLine className="absolute left-0 top-1/2 -translate-y-1/2 h-[32px] w-[1px]" />
        <img
          className="w-[14px] h-auto block ml-auto absolute left-4 top-1/2 -translate-y-1/2"
          src={copyIcon}
          alt="Copy"
        />
      </button>
    </div>
  );
}
