import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { GradientBorder } from "../ui/GradientBorder";
import { UNKNOWN_COINS_PLACEHOLDER } from "../../constants/clicker.ts";
import { Coin } from "../../store/clicker/types.ts";
import {
  activeGameCoinsSelector,
  activePortfolioIdSelector,
  coinCapacitySelector,
  coinsSelector,
  dailyQuestSelector,
  isGameActiveSelector,
} from "../../store/clicker/selectors.ts";
import { ExternalImage } from "../ui/ExternalImage.tsx";
import { useWatch } from "../../hooks/usePreviousValue.ts";
import { CircleCount, CounterRef } from "./CircleCounter.tsx";

export function PortfolioCoins() {
  const dailyQuest = useSelector(dailyQuestSelector);
  const activePortfolioId = useSelector(activePortfolioIdSelector);
  const coins = useSelector(coinsSelector);
  const coinCapacity = useSelector(coinCapacitySelector);
  const activeGameCoins = useSelector(activeGameCoinsSelector);
  const isGameActive = useSelector(isGameActiveSelector);

  const activePortfolio = useMemo(
    () => dailyQuest?.portfolios.find(({ id }) => id === activePortfolioId),
    [dailyQuest, activePortfolioId],
  );

  const [extendedActivePortfolioCoins, setExtendedActivePortfolioCoins] = useState<
    Array<
      | (Coin & {
          progress: number;
          isInActiveGame: boolean;
        })
      | null
    >
  >([]);

  useEffect(() => {
    setExtendedActivePortfolioCoins(
      (activePortfolio &&
        activePortfolio.coins.map((coin) => {
          return {
            ...coin,
            ...(coins.find(({ id }) => id === coin.originalCoinId) || {}),
            isInActiveGame: activeGameCoins.some(({ id }) => id === coin.id),
          };
        })) ||
        UNKNOWN_COINS_PLACEHOLDER,
    );
  }, [activePortfolio, coins, coinCapacity, activeGameCoins, isGameActive]);

  return (
    <>
      {extendedActivePortfolioCoins && (
        <ul className="mt-[11px] grid grid-cols-5 gap-x-[9px] h-[69px]">
          {extendedActivePortfolioCoins.map((coin, index) => {
            return (
              <PortfolioCoinItem
                key={coin?.id || index}
                {...coin}
                index={index}
                progress={coin?.progress || 0}
                isInActiveGame={coin?.isInActiveGame || false}
                id={coin?.id || index}
              />
            );
          })}
        </ul>
      )}
    </>
  );
}

interface PortfolioCoinProps extends Partial<Coin> {
  progress: number;
  isInActiveGame: boolean;
  index: number;
}

function PortfolioCoinItem({ index, progress, isInActiveGame, name, icon, id }: PortfolioCoinProps) {
  const CircleCountRef = useRef<CounterRef>(null);
  useWatch(progress, (current, prev) => {
    if (current > prev) {
      CircleCountRef.current?.applyCircle();
    }
  });

  return (
    <li id={`portfolio-coin-${index + 1}`} key={id || index} className="flex flex-col items-center portfolio-coin">
      <div className="relative size-6">
        <div className="relative z-10 block size-6 rounded-full overflow-hidden">
          {icon ? (
            <ExternalImage src={icon} alt={name} className="w-full h-full object-cover" />
          ) : (
            <span className="flex justify-center items-center leading-none font-bold bg-[#31213B] w-full h-full text-[12px]">
              ?
            </span>
          )}
        </div>

        <CircleCount
          className="absolute z-0 size-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          ref={CircleCountRef}
        />
      </div>
      <p className="text-[12px] leading-none font-bold uppercase pt-2 text-center">{name}</p>
      <GradientBorder
        backgroundColor="rgba(0,0,0,0.5)"
        roundedClass="rounded-full"
        className="mt-[9px] w-full h-[10px]"
        gradient="linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))"
      >
        {/* progress line */}
        <span
          className={`
             absolute
              top-[2px]
              left-[2px]
              h-[calc(100%-4px)]
              rounded-full
              origin-left
              ${progress === 100 ? " bg-green" : " bg-orange"}
              ${progress === 100 ? " bg-green" : " bg-orange"}
              z-10
          `}
          style={{
            width: `calc((100% - 4px) * ${progress / 100})`,
          }}
        />
      </GradientBorder>

      <strong className={`text-[12px] block mt-1 ${!isInActiveGame ? "opacity-50" : ""}`}>
        {Math.floor(progress || 0)}%
      </strong>
    </li>
  );
}
