import Button from "../../ui/Button";
import Title from "../../ui/Title";

import plusIcon from "../../../assets/icons/plus.svg";
import { useCallback, useState } from "react";
import SearchBar from "../../ui/SearchBar";
import TokensFilter, { FilterKey } from "./TokensFilter";
import { useSelector } from "react-redux";
import { filteredTokensSelector } from "../../../store/token/tokensReducer";
import TokenItem from "../Token/TokenItem/TokenItem";
import { useAppDispatch } from "../../../store/hooks";
import { fetchHotTokensListAction } from "../../../store/token/actions/fetchHotTokensListAction";
import { fetchAllNewTokensListAction } from "../../../store/token/actions/fetchAllNewTokensListAction";
import { fetchTopCapTokensListAction } from "../../../store/token/actions/fetchTopCapTokensListAction";
import { fetchMyTokensListAction } from "../../../store/token/actions/fetchMyTokensListAction";
import { NavLink } from "react-router-dom";
import { fetchSearchTokensListAction } from "../../../store/token/actions/fetchSearchTokensListAction";
import { debounce } from "lodash-es";

const TokenList = () => {
  const [searchToken, setSearchToken] = useState("");
  const [activeTab, setActiveTab] = useState<FilterKey>(FilterKey.Hot);

  const filteredTokens = useSelector(filteredTokensSelector);
  const dispatch = useAppDispatch();

  const handleSelectTab = (tab: FilterKey) => {
    if (tab === activeTab) return;
    setActiveTab(tab);
    switch (tab) {
      case FilterKey.Hot: {
        dispatch(fetchHotTokensListAction());
        break;
      }
      case FilterKey.New: {
        dispatch(fetchAllNewTokensListAction());
        break;
      }
      case FilterKey.TopMCAP: {
        dispatch(fetchTopCapTokensListAction());
        break;
      }
      case FilterKey.MyTokens: {
        dispatch(fetchMyTokensListAction());
        break;
      }
    }
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      dispatch(fetchSearchTokensListAction(value));
    }, 300),
    [],
  );

  const handleSearch = (value: string) => {
    setSearchToken(value);
    debouncedSearch(value);
  };

  return (
    <div className="pt-[21px] pb-[100px]">
      <div className="sticky top-4 z-10">
        <div className="flex justify-between items-center ">
          <Title text="Tokens" style="mb-0" />
          <NavLink to={"/pumpadur/create-token"} className="relative w-[170px] h-[42px] flex justify-center">
            <Button
              text="Create new coin"
              className="rounded-[32px] w-[162px] text-[12px] h-[38px] px-3 z-40 !py-3"
              classNameIcon="w-[14px] h-[14px] mr-1"
              icon={plusIcon}
            />
            <div
              className="absolute top-1 w-full  h-[38px] rounded-2xl z-10"
              style={{
                backgroundImage: " linear-gradient(150deg, #ffae34 0%, #f37e3b 100%)",
                filter: "blur(40px)",
              }}
            ></div>
          </NavLink>
        </div>
        <div className="pt-[10px]">
          <SearchBar value={searchToken} setValue={handleSearch} />
        </div>
        <TokensFilter onSelectTab={handleSelectTab} activeTab={activeTab} />
      </div>
      <div className="flex flex-col gap-2 pt-3">
        {filteredTokens?.map((token) => <TokenItem token={token} key={token.id} />)}
      </div>
    </div>
  );
};

export default TokenList;
