import React, { ReactNode } from "react";
import Header from "./Header";

interface ModalProps {
  children: ReactNode;
  headerText: string;
  height?: string;
  closeModal?: (state: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ children, headerText, height, closeModal }) => {
  return (
    <div className="px-4 ">
      <div className="fixed top-0 bottom-0 right-0 left-0 bg-black opacity-90 z-50"></div>
      <div
        className="fixed bottom-0 left-0 right-0 bg-[#4D315E] w-full ml-0 opacity-100 z-[60]"
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          height: `${height}`,
        }}
      >
        <div className="bg-[#00000040] px-4">
          <div>
            <Header text={headerText} closeModal={closeModal} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
