import { moneyFormat } from "../../../../helpers/moneyFormat";
import ProgressLine from "../../../Common/ProgressLine";
interface TokenMetricsProps {
  progress: string;
  marcetCap: string;
}

const TokenMetrics = ({ marcetCap, progress }: TokenMetricsProps) => {
  const goal = ((+marcetCap * 100) / +progress) | 0;

  return (
    <div>
      <div className="flex justify-between font-semibold pb-[7px] pt-5">
        <div>
          <span className="text-lavenderGray">Market Cap:</span>{" "}
          <span className="text-aquaMint">${moneyFormat(+marcetCap)}</span>
        </div>
        <div>
          <span className="text-lavenderGray">Goal:</span> <span className="text-aquaMint">${moneyFormat(goal)}</span>
        </div>
      </div>
      <ProgressLine progress={+progress} styles="bg-white/25" />
    </div>
  );
};

export default TokenMetrics;
