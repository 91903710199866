import { NavLink } from "react-router-dom";
import { Token } from "../../../../store/token/api/types";
import { GradientCard } from "../../../ui/GradientCard";
import TokenMetrics from "./TokenMetrics";
import TokenSummary from "./TokenSummary";
import TokenImage from "../TokenImage";

const TokenItem = ({ token }: { token: Token }) => {
  const { marcetCap, progress, id, logo } = token;
  return (
    <NavLink to={`/pumpadur/tokens/${id}`}>
      <GradientCard backgroundColor="#28202C">
        <div className="px-3 pt-[18px] pb-3 flex gap-3 leading-none">
          <TokenImage imageSrc={logo} classesName="rounded-2xl  w-[80px] h-[80px] min-w-[80px] min-h-[80px]" />
          <div className="flex flex-col w-full">
            <TokenSummary token={token} />
            <TokenMetrics marcetCap={marcetCap} progress={progress} />
          </div>
        </div>
      </GradientCard>
    </NavLink>
  );
};

export default TokenItem;
