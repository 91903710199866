// import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// import { userIdSelector } from "../../store/account/account";
import { useAppSelector } from "../../store/hooks";
// import { changeStatusModalRocket, isPassenger } from "../../store/rocket/rocketReducer";
// import { RootState } from "../../store/store";
import Button from "../ui/Button";
import { menuItems } from "./configs/MenuItems";
import MenuItem from "./MenuItem";

const BottomMenu = () => {
  // const dispatch = useAppDispatch();

  // const handleGetInRocket = () => {
  //   console.log("get in rocket");
  //   dispatch(changeStatusModalRocket(true));
  //   // dispatch(changeStatusBottomMenu(false));
  // };

  const handleBoostRocket = () => {
    console.log("Boost your rocket");
  };

  const bottomMenuVisible = useAppSelector((state) => state.bottomMenu.visible);

  // const rocket = useAppSelector((state) => state.rocket.rocket);
  const boostRocketVisible = useAppSelector((state) => state.bottomMenu.boostRocket);
  const getInRocketVisible = useAppSelector((state) => state.bottomMenu.getInRocket);
  const overlay = useAppSelector((state) => state.global.overlay);

  // const currentUserId = useSelector(userIdSelector);
  // const isUserPassenger = useSelector((state: RootState) => isPassenger(state, currentUserId));

  return (
    (bottomMenuVisible || getInRocketVisible || boostRocketVisible) && (
      <div
        className="p-4 fixed  left-0 right-0 bottom-0 font-medium z-40"
        style={{
          background: overlay
            ? ""
            : "linear-gradient(0deg, #482D59 0%, rgba(72, 45, 89, 0.8) 59.93%, rgba(72, 45, 89, 0) 100%)",
        }}
      >
        {/* {getInRocketVisible && !isUserPassenger && (
          <Button
            handleClick={handleGetInRocket}
            text="Get in the rocket"
            style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          />
        )} */}
        {boostRocketVisible && (
          <Button
            handleClick={handleBoostRocket}
            text="Boost your rocket"
            style="linear-gradient(93.4deg, #27BEFF -17.03%, #0075FF 103.51%)"
          />
        )}
        {bottomMenuVisible && (
          <div
            className={`relative flex  font-medium rounded-[16px] w-full  py-[9px] justify-evenly ${
              overlay ? "bg-[#513862]" : "bg-[#635070]"
            } bottom-menu`}
            style={{
              display: "flex",
              margin: "0 auto",
            }}
          >
            <div className="z-50 [&>:not(.active)>div>p]:opacity-50 w-full flex justify-evenly">
              {menuItems.map((item, _) => (
                <NavLink to={`${item.link}`} key={item.title}>
                  {({ isActive }) => (
                    <MenuItem title={item.title} icon={item.icon} emoji={item.emoji} active={isActive} />
                  )}
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  );
};
export default BottomMenu;
