import { toNumber } from "lodash-es";
import { moneyFormat } from "../../../helpers/moneyFormat";
import { Token } from "../../../store/token/api/types";
import { GradientCard } from "../../ui/GradientCard";
import CreatedBy from "../Token/TokenDetails/CreatedBy";

const TopTokenBanner = ({ token }: { token: Token }) => {
  const { marcetCap, name, ticker, userName } = token;
  return (
    <GradientCard backgroundColor="#0d0903">
      <div className="h-min w-full rounded-2xl bg-cover bg-top bg-[url('/top-token-background.png')] flex flex-col items-center pt-[2vh] leading-none gap-[14px]">
        <div className=" w-[56px] h-[56px] min-w-[56px] min-h-[56px] ">
          <img src="/image-token.png" className="w-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] pb-3">
          <div className="uppercase font-bold">
            <span>{name}</span> - <span>${ticker}</span>
          </div>
          <div>
            <span className="text-medGrey font-medium">Market Cap: </span>
            <span className="text-green font-bold">${moneyFormat(toNumber(marcetCap))}</span>
          </div>
          <CreatedBy userName={userName} generalStyles="pb-[10px]" />
        </div>
      </div>
    </GradientCard>
  );
};

export default TopTokenBanner;
