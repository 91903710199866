import { useEffect } from "react";
import ExchangeList from "../components/Exchange/ExchangeList";
import Title from "../components/ui/Title";
import { useAppDispatch } from "../store/hooks";
import { fetchExchangesListAction } from "../store/exchanges/actions/fetchExchangesListAction";

export default function SelectionExchangePage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchExchangesListAction());
  }, []);

  return (
    <div>
      <Title text="Choose staking exchange" />
      <div className="pt-8">
        <ExchangeList />
      </div>
    </div>
  );
}
