export function shortenKey(
  key: string,
  startLength: number = 6,
  endLength: number = 6,
  totalLength: number = 12,
): string {
  if (key.length <= totalLength) return key;

  const start = key.slice(0, startLength);
  const end = key.slice(-endLength);
  return `${start}...${end}`;
}
