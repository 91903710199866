import { useSelector } from "react-redux";
import UserPhoto from "../../Common/UserPhoto";
import DarkBackground from "../../ui/DarkBackground";
import UserWalletInfo from "../UserWalletInfo";
import { userIdSelector, userNameSelector } from "../../../store/account/account";
import { followersSelector, followingSelector } from "../../../store/cryptoProfile/cryptoProfileReducer";
import { GradientLine } from "../../ui/GradientLine";

const UserSummary = () => {
  const userName = useSelector(userNameSelector);
  const userId = useSelector(userIdSelector);
  const followers = useSelector(followersSelector);
  const following = useSelector(followingSelector);
  return (
    <DarkBackground>
      <div className="flex relative w-full flex-col gap-6 items-center justify-center py-4">
        <div className="flex flex-col justify-center items-center gap-[14px]">
          <UserPhoto styles="w-[88px] h-[88px] rounded-2xl pb-1" />
          <p className="text-[20px] font-semibold uppercase">{userName ? userName : `user${userId}`}</p>
          <div className="flex text-[#b5b5b5] relative justify-center items-center gap-6">
            <p>{followers} Followers</p>
            <GradientLine className=" h-[20px] w-[1px]" />
            <p>{following} Following</p>
          </div>
        </div>
        <UserWalletInfo />
      </div>
    </DarkBackground>
  );
};

export default UserSummary;
