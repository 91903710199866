import { useSelector } from "react-redux";
import balanceIcon from "../../assets/icons/balance.svg";
import tgPremiumIcon from "../../assets/img/tg-premium.png";
import ticketIcon from "../../assets/icons/ticket.svg";
import { moneyFormat } from "../../helpers/moneyFormat.ts";
import {
  premiumUserInviteBonuceSelector,
  premiumUserInviteTicketBonuceSelector,
  userInviteBonuceSelector,
  userInviteTicketBonuceSelector,
} from "../../store/friends/friendsReducer.ts";
// import { GradientCard } from "../ui/GradientCard.tsx";

export function FriendsConditions({ containerStyle }: { containerStyle?: string }) {
  const userInviteBonuce = useSelector(userInviteBonuceSelector);
  const premiumUserInviteBonuce = useSelector(premiumUserInviteBonuceSelector);
  const userInviteTicketBonuce = useSelector(userInviteTicketBonuceSelector);
  const premiumUserInviteTicketBonuce = useSelector(premiumUserInviteTicketBonuceSelector);

  const conditions = [
    {
      title: "for each fren",
      tickets: userInviteTicketBonuce,
      cost: userInviteBonuce,
    },
    {
      title: 'for <span class="text-[#77BDFF] ">premium fren</span>',
      tickets: premiumUserInviteTicketBonuce,
      cost: premiumUserInviteBonuce,
      icon: { url: tgPremiumIcon },
    },
  ];
  const referralPercentages = [
    {
      title: '<span class="text-[14px]">for direct referrals</span>',
      profit: "10%",
    },
    {
      title: "for referrals from your</br> direct referrals",
      profit: "2,5%",
    },
  ];
  return (
    // <GradientCard backgroundColor="#1D1920">
    <ul className={`${containerStyle} pb-[12px] grid grid-cols-2 gap-[10.5px]`}>
      {conditions.map(({ icon, title, cost, tickets }) => (
        <li
          className="h-[60px] flex items-center justify-center gap-x-2  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl"
          key={title}
        >
          <div className="text-[16px] flex flex-col justify-center items-center gap-1">
            <div className="flex justify-center items-center gap-[6px]">
              <div className="flex gap-[2px] justify-center items-center">
                <div className="leading-none font-bold inline-block">
                  <strong className="text-[#FEC801] font-bold">+{moneyFormat(cost)}</strong>
                </div>
                <div className="h-[12px] w-[12px] inline-block">
                  <img src={balanceIcon} alt="Balance" className="size-full object-contain object-center" />
                </div>
              </div>
              <p>and</p>
              <div className="flex gap-[2px] justify-center items-center">
                <div className="leading-none font-bold inline-block">
                  <strong className="text-lightPink font-bold">+{moneyFormat(tickets)}</strong>
                </div>
                <div className="h-[12px] w-[14px] inline-block">
                  <img src={ticketIcon} alt="Tickets" className="size-full object-contain object-center" />
                </div>
              </div>
            </div>
            <div className="flex">
              <h2
                className="text-[14px]  font-medium leading-none text-center"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {icon && <img src={icon?.url} alt={title} className="size-[13px] h-auto object-contain object-center " />}
            </div>
          </div>
        </li>
      ))}
      {referralPercentages.map(({ title, profit }) => (
        <li
          className="h-[60px] flex items-center justify-center gap-x-2  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl"
          key={title}
        >
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold inline-block">
                <strong className="text-white font-bold leading-none">+{profit}</strong>
              </div>
            </div>
            <div className="flex">
              <h2
                className="font-medium leading-[13px] text-center text-[12px]"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>

    // </GradientCard>
  );
}
