import { useSelector } from "react-redux";
import { Portfolio } from "../../store/clicker/types";
import TopPanel from "../TopPanel";
import { AppBg } from "../ui/AppBg";
import { ScreenLayout } from "../ui/ScreenLayout";
import { dailyQuestSelector } from "../../store/clicker/selectors";
import { useMemo } from "react";
import balanceIcon from "../../assets/icons/balance.svg";
import cosmonautImage from "../../assets/img/claim-cosmonaut.png";
import { moneyFormat } from "../../helpers/moneyFormat";
import Button from "../ui/Button";
// import { calculateRewards } from "../../helpers/calculateRewards.ts";

interface ClaimRewardScreenProps {
  portfolio?: Portfolio;
  portfolioIndex?: number;
  isOpened: boolean;
  isPending: boolean;
  onClaim: () => void;
}

export function ClaimRewardScreen({ isOpened, portfolioIndex, portfolio, isPending, onClaim }: ClaimRewardScreenProps) {
  const dailyQuest = useSelector(dailyQuestSelector);

  // const portfolioReward = useMemo(() => {
  //   if (!portfolio || !dailyQuest) return 0;
  //   const index = dailyQuest.portfolios.findIndex(({ id }) => id === portfolio.id);

  //   return calculateRewards(dailyQuest.portfolios)[index];
  // }, [portfolio, dailyQuest]);

  const claimSum = useMemo(() => {
    // if (isDemo) {
    return portfolio?.cost || 0;
    // }

    // const allPortfoliosCompleted = dailyQuest?.portfolios.every(({ progress }) => progress >= 100);

    // if (allPortfoliosCompleted) {
    //   return portfolioReward + (dailyQuest?.questCompleteBonus || 0);
    // }

    // return portfolioReward || 0;
  }, [portfolioIndex, portfolio, dailyQuest]);

  function handleClaim() {
    onClaim();
  }

  return isOpened ? (
    <>
      <ScreenLayout className="fixed top-0 left-0 z-[999] w-full h-full flex flex-col">
        <AppBg position="absolute" />
        <TopPanel page="claim-reward" />

        <div className="pt-[42px] relative grow flex flex-col pb-[17px]">
          <h2 className="flex flex-col items-center text-[20px]">
            <span className="font-medium leading-none">You earned</span>

            <span className="text-[1.85em] height-normal:text-[2.4em] flex items-center gap-x-[0.2em]">
              <span className="size-[0.75em]">
                <img src={balanceIcon} alt="Rocket" className="size-full object-cover" />
              </span>

              <span className="leading-none font-extrabold">{moneyFormat(claimSum)}!</span>
            </span>
          </h2>

          <div className="mt-6 height-normal:mt-8 grow flex flex-col">
            <div className="grow flex flex-col">
              <div className="grow relative w-full max-w-[259px] min-w-[140px] min-h-[130px] max-h-[262px] mx-auto">
                <img
                  rel="preload"
                  className="h-full w-full object-contain block object-top mx-auto absolute top-0 left-1/2 -translate-x-1/2"
                  src={cosmonautImage}
                  alt="Cosmonaut"
                  loading="lazy"
                />
              </div>

              <div className="text-[1rem] height-normal:text-5 shrink-0 font-bold text-center flex flex-col items-center pt-[6vh]">
                {/* <span className="leading-[1.2]">{isDemo ? `Demo-Portfolio` : `Crypto Portfolio`}</span> */}
                <span className="text-[1.5em] leading-none">Staking Unlocked!</span>

                <p className="mt-[11px] text-[0.875rem] height-normal:text-[1rem] font-normal">
                  Now you can <strong>stake</strong> your rewards to <br /> earn even more in passive mode
                </p>
              </div>
            </div>

            <div className="shrink-0 pt-8">
              <Button
                text="claim and stake"
                isPending={isPending}
                style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
                className={`text-[14px] py-[1.14em] disabled:!bg-lightGrey}`}
                handleClick={handleClaim}
              />
            </div>
          </div>
        </div>
      </ScreenLayout>
    </>
  ) : null;
}
