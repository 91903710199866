import { GradientCard } from "../../../ui/GradientCard";

import chevronRightIcon from "../../../../assets/icons/chevron-right.svg";
import { useTelegramApp } from "../../../../hooks/useTelegramApp";

export interface Guide {
  iconSrc: string;
  text: string;
  link: string;
}

const GuideItem = ({ item }: { item: Guide }) => {
  const { iconSrc, text, link } = item;

  const tgApp = useTelegramApp();

  const handler = () => {
    tgApp.openLink(link);
  };
  return (
    <GradientCard backgroundColor="#28202C">
      <button onClick={handler} type="button" className="flex-1 p-1 w-full flex gap-x-3 items-center pr-3 pl-4 py-4">
        <span className="flex grow gap-x-3 items-center">
          <span className="h-[30px] w-[30px] relative  rounded-xl overflow-hidden flex justify-center items-center">
            <img loading="lazy" className="size-[30px] object-cover object-center" src={iconSrc} alt="guide icon" />
          </span>

          <span className="flex-1">
            <span className="text-left text-[16px] font-medium block break-all capitalize">{text}</span>
          </span>
          <span className="block mr-2">
            <img src={chevronRightIcon} alt="Right arrow" />
          </span>
        </span>
      </button>
    </GradientCard>
  );
};

export default GuideItem;
