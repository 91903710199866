import DayCardItem from "./DayCardItem.tsx";
import { moneyFormat } from "../../../helpers/moneyFormat.ts";
import { DailyRewards } from "../../../store/cards/api/types.ts";

export default function DayCards({ dailyRewards, claimedRewards }: { dailyRewards: DailyRewards; claimedRewards: number }) {
  let currentDay = claimedRewards + 1;
  if (currentDay > 7) {
    currentDay = 1;
  }

  return (
    <div className="grid grid-cols-3 gap-y-[16px] mt-8">
      {dailyRewards.map((reward, index) => {
        let alignmentClass = "";

        if (index % 3 === 0) {
          alignmentClass = "justify-self-start";
        } else if (index % 3 === 2) {
          alignmentClass = "justify-self-end";
        } else {
          alignmentClass = "justify-self-center";
        }

        const isLastRow = index >= dailyRewards.length - (dailyRewards.length % 3 || 3);
        if (isLastRow) {
          alignmentClass = "col-span-3 justify-self-stretch";
        }

        return (
          <div key={reward.id} className={`${alignmentClass}`}>
            <DayCardItem 
              day={reward.dayCount} 
              rewardAmount={reward.rewardAmount > 0 ? moneyFormat(reward.rewardAmount) : null} 
              rewardTicket={reward.rewardTicket > 0 ? reward.rewardTicket : null}
              rewardBoostCard={reward.rewardBoostCard}
              isLarge={reward.dayCount === 7} 
              currentDay={currentDay}
            />
          </div>
        );
      })}
    </div>
  );
}