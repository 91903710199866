import TopPanel from "../components/TopPanel.tsx";
import Title from "../components/ui/Title.tsx";
import { FriendsConditions } from "../components/Friends/FriendsConditions.tsx";
import { ReferralLink } from "../components/Friends/ReferralLink.tsx";
import { MyReferralsBlock } from "../components/Friends/MyReferralsBlock.tsx";
// import { useAppDispatch } from "../store/hooks.ts";
// import { useEffect } from "react";
// import { fetchInvitesDataAction } from "../store/friends/actions/fetchInvitesDataAction.ts";

export function FriendsPage() {
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(fetchInvitesDataAction());
  // }, []);

  return (
    <div className="pb-[100px]">
      <TopPanel page="Friends" />

      <div className="mt-[25px]">
        <Title text="Invite friends to Get Bonuses" />
      </div>

      <FriendsConditions containerStyle="pt-1" />

      <MyReferralsBlock />

      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>

        <ReferralLink />
      </div>
      {/* PREVIOUS VERSION */}
      {/* <div>
        <InvitedFriends />
        {leftLoad > 0 ? (
          <button
            disabled={isPending}
            className="font-semibold flex justify-center items-center  gap-x-5 text-base h-[64px] text-orange w-full text-center py-5"
            onClick={() => {
              dispatch(nextPage());
            }}
          >
            <span className="relative">
              Load more friends
              {isPending ? (
                <span className="flex absolute right-0 translate-x-[calc(100%+12px)] top-1/2 -translate-y-1/2 justify-center text-[6px] items-center text-orange">
                  <PreloaderCircle />
                </span>
              ) : null}
            </span>
          </button>
        ) : null}
      </div> */}
    </div>
  );
}
