import UserPhoto from "../../Common/UserPhoto";
import { useSelector } from "react-redux";
import {
  cryptoProfilePublicKeySelector,
  isActiveWalletSelector,
  isRewardReceivedSelector,
  pendingSelector,
} from "../../../store/cryptoProfile/cryptoProfileReducer";
import { userIdSelector, userNameSelector } from "../../../store/account/account";
import { redirectToWalletManagement } from "../../../helpers/redirectToWalletManagement";

const UserProfileHeader = () => {
  const phantomWalletPublicKey = useSelector(cryptoProfilePublicKeySelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const userName = useSelector(userNameSelector);
  const userId = useSelector(userIdSelector);
  const isRewardReceived = useSelector(isRewardReceivedSelector);
  const isPending = useSelector(pendingSelector);

  return (
    <div className={`flex ${isActiveWallet ? "items-start" : "items-center"} justify-between`}>
      <div className="flex gap-2 items-center">
        <div className=" w-[50px] h-[50px] rounded-[12px] outline outline-offset-[2px] outline-[2px] outline-darkPurple">
          <UserPhoto styles="w-[50px] h-[50px] rounded-[12px]" />
        </div>

        <p className="text-[16px] font-semibold">@{userName ? userName : `user${userId}`}</p>
      </div>
      <div className="flex gap-[10px] items-center">
        {!isPending &&
          (!isActiveWallet ? (
            <div className="flex justify-center relative w-[121px] h-[28px]">
              <button
                onClick={redirectToWalletManagement}
                className=" w-[113px] h-full rounded-2xl flex justify-center items-center z-10 hover:scale-105 ease-in-out duration-300"
                style={{
                  backgroundImage: "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",
                }}
              >
                <span className="text-[12px] font-semibold">Connect Wallet</span>
              </button>{" "}
              <div
                className="absolute top-1 w-[121px] h-[28px] rounded-2xl"
                style={{
                  backgroundImage: "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",
                  filter: "blur(40px)",
                }}
              ></div>
            </div>
          ) : (
            !isRewardReceived && (
              <div className="pt-[2px]">
                <p className="text-[12px] leading-none text-white/50">Connected Wallet</p>
                <p className="font-semibold">{shortenKey(phantomWalletPublicKey)}</p>
              </div>
            )
          ))}

        {/* <NavLink to="/pumpadur/settings" className="w-[21px] h-[21px]">
          <img src={gearIcon} alt="gear" className="size-full" />
        </NavLink> */}
      </div>
    </div>
  );
};

export default UserProfileHeader;

function shortenKey(key: string): string {
  if (key.length <= 10) return key;
  const start = key.slice(0, 6);
  const end = key.slice(-6);
  return `${start}...${end}`;
}
