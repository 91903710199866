import { useSelector } from "react-redux";
import { Exchange } from "../../store/exchanges/types";
import { useAppDispatch } from "../../store/hooks";
import { exchangeSelector, setExchange } from "../../store/account/account";
import { ExternalImage } from "../ui/ExternalImage";
import { fetchChangedExchangeAction } from "../../store/account/actions/fetchChangedExchangeAction";
import { useNavigate } from "react-router-dom";
import { fetchInitialClickerDataAction } from "../../store/clicker/actions/fetchInitialClickerDataAction";
interface ExchangeItemProps {
  exchange: Exchange;
}
const ExchangeItem = ({ exchange }: ExchangeItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedExchange = useSelector(exchangeSelector);
  const handleSelectExchange = async () => {
    const resultAction = await dispatch(fetchChangedExchangeAction(exchange.id));

    if (fetchChangedExchangeAction.fulfilled.match(resultAction)) {
      dispatch(setExchange(exchange));
      navigate("/");
      dispatch(fetchInitialClickerDataAction());
    } else {
      console.error("Failed to fetch exchange:", resultAction.error);
    }
  };
  return (
    <div
      className={`w-full flex justify-center items-center pt-[17px] pb-[14px]  bg-white/10 rounded-2xl gap-2 ${selectedExchange?.title === exchange.title ? "border-solid border-[1px] border-[#FFD600]" : ""}`}
      onClick={() => handleSelectExchange()}
    >
      {exchange.iconUrl && (
        <ExternalImage src={exchange.iconUrl} className="rounded-[12px]" alt={exchange.title} width={17} height={17} />
      )}
      <span className="block font-bold">{exchange.title}</span>
      {+exchange.ratio > 0 ? <span className="block">({exchange.ratio}% Base Ratio)</span> : <></>}
    </div>
  );
};

export default ExchangeItem;
