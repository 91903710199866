interface TitleProps {
  text: string;
  style?: string;
}

const Title: React.FC<TitleProps> = ({ text, style = "text-center mb-[14px]" }) => {
  const containsHTML = (text: string) => /<\/?[a-z][\s\S]*>/i.test(text);
  return (
    <h1 className={`${style} text-[20px] font-bold  `}>
      {containsHTML(text) ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text}
    </h1>
  );
};

export default Title;
