import { useEffect } from "react";
import { useAppDispatch } from "../store/hooks";
import { changeStatusBottomMenu } from "../store/menu/bottomMenuReducer";
import { useSelector } from "react-redux";
import { dailyRewardsSelector } from "../store/cards/cardsReducer";
import { dailyStreakSelector} from "../store/account/account";
import { fetchDailyRewardAction } from "../store/account/actions/fetchDailyReward";
import ProgressBar from "../components/DailyReward/ProgressBar/ProgressBar";
import DayCards from "../components/DailyReward/DayCards/DayCards";
import Button from "../components/ui/Button";
import { useNavigate } from "react-router-dom";


export function DailyRewardPage() {
    const dailyRewards = useSelector(dailyRewardsSelector);
    const dailyStreak = useSelector(dailyStreakSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
        dispatch(changeStatusBottomMenu(false));
        return () => {
          dispatch(changeStatusBottomMenu(true));
        };
      }, []);
  

    const handleGetServiceBonus = () => {
      dispatch(fetchDailyRewardAction())
        .then((action) => {
          if (fetchDailyRewardAction.fulfilled.match(action)) {
            navigate("/");
            console.log("Modal should be closed now.");
          } else {
            console.error("Action failed:", action);
          }
        })
        .catch((error) => {
          console.error("Action threw an error:", error);
        });
    };
  
  return (
    <div className="flex flex-col relative justify-center min-h-screen leading-none">
      <div className="relative flex flex-col flex-grow select-none min-h-screen text-sm">
          <div className="text-center pt-6 z-10">
            <h1 className="text-[20px] font-bold text-white tracking-[1px] text-shadow-md">
              Daily Reward
            </h1>
            <p className="text-[16px] leading-[24px] text-white" style={{ width: '251px', margin: '16px auto 24px auto', textAlign: 'center' }}>
              Accrue coins for logging into the game daily without skipping
            </p>

            {dailyRewards && dailyRewards.length > 0 && (
              <ProgressBar dailyRewards={dailyRewards} claimedRewards={dailyStreak}/>
            )}
            {dailyRewards && dailyRewards.length > 0 && (
              <DayCards dailyRewards={dailyRewards} claimedRewards={dailyStreak} />
            )}

            <div className="mt-[16px]">
              <Button text={"Claim"} handleClick={handleGetServiceBonus} style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)" className="w-full" />
            </div>
          </div>
        </div>
    </div>
  );
}
