import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { buyPurchasedCardResponse } from "./types.ts";

export async function buyCard(key: string) {
  return (
    await axiosInstance.put<buyPurchasedCardResponse>(apiRoutes.cards.purchasedCards, {
      key,
    })
  ).data.data;
}
