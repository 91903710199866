import { useSelector, useStore } from "react-redux";
import { isDemoSelector } from "../../store/clicker/selectors";
import { RootState } from "../../store/store";
import { useAppDispatch } from "../../store/hooks";
import {
  generateGameCoins,
  revalidateActivePortfolio,
  revalidateIsGameActive,
  setOnboardingDailyQuest,
} from "../../store/clicker/clickerReducer";
import { useEffect, useState } from "react";
import OnboardingSteps, { OnboardingStep } from "../Onboarding/OnboardingSteps";
import { OnboardingTour } from "./OnboardingTour";
import { finishOnboarding } from "../../store/onboarding/onboardingReducer";
import { onboardingStepSelector } from "../../store/onboarding/selectors";
import { fetchInitialClickerDataAction } from "../../store/clicker/actions/fetchInitialClickerDataAction";
import { DataLayerEventNames, useDataLayer } from "../../hooks/useDataLayer";

const minutesAfterNow = (minutes: number) => new Date(new Date(Date.now()).getTime() + 1000 * 60 * minutes);

export function ClickerOnboarding() {
  const store = useStore<RootState>();
  const dispatch = useAppDispatch();
  const isDemo = useSelector(isDemoSelector);
  const onboardingStep = useSelector(onboardingStepSelector);
  const [isOnboardingPartStarted, setIsOnboardingPartStarted] = useState(false);

  const { trackEvent } = useDataLayer();

  useEffect(() => {
    const portfolios = store.getState().clicker.dailyQuest?.portfolios;
    if (portfolios) {
      const hasProgress = portfolios.some((portfolio) => {
        return portfolio.coins.some((coin) => coin.progress !== 0);
      });
      hasProgress && dispatch(finishOnboarding());
    }
    if (!store.getState().clicker.dailyQuest) {
      dispatch(fetchInitialClickerDataAction()).then(() => {
        const isDemo = store.getState().clicker.dailyQuest?.isDemo;

        if (isDemo && !store.getState().onboarding.isFinished) {
          setTimeout(() => {
            setOnboradingDailyQuest();
            setTimeout(() => {
              setIsOnboardingPartStarted(true);
            }, 10);
          }, 50);
        }
      });
    } else {
      if (isDemo && !store.getState().onboarding.isFinished) {
        setOnboradingDailyQuest();
        setTimeout(() => {
          setIsOnboardingPartStarted(true);
        }, 10);
      }
    }
  }, []);

  function setOnboradingDailyQuest() {
    const dailyQuest = store.getState().clicker.dailyQuest;
    const onboardingStep = store.getState().onboarding.step;

    if (!dailyQuest) return;

    const quest = {
      ...dailyQuest,
      portfolios: dailyQuest.portfolios.map((portfolio) => {
        return {
          ...portfolio,
          activeFrom: onboardingStep === 2 ? new Date() : minutesAfterNow(15),
          activeTo: minutesAfterNow(120),
        };
      }),
    };

    dispatch(setOnboardingDailyQuest(quest));
    dispatch(generateGameCoins(false));
    dispatch(revalidateActivePortfolio());
    dispatch(revalidateIsGameActive());
  }

  return (
    <>
      {isDemo && isOnboardingPartStarted && onboardingStep === 0 ? (
        <OnboardingTour
          steps={[
            {
              target: "#portfolio-coin-2",
              content: <OnboardingSteps step={OnboardingStep.CorrectToken} />,
            },
          ]}
          trackEvent={() => trackEvent(DataLayerEventNames.tapTipPassed)}
          onEnd={() => {}}
        />
      ) : null}
    </>
  );
}
