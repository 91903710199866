import { axiosAdInstance } from "../axiosAdInstance";
import { Ad } from "./types";

export async function fetchAdFeed(userId: number): Promise<Ad[]> {
    const apiKey = import.meta.env.VITE_TAP_AD_API_KEY;
    const userAgent = navigator.userAgent;
  const response = await axiosAdInstance.get<Ad[]>('/v1/feed', {
    params: {
      user_id: userId,
      apikey: apiKey,
      ua: userAgent,
    },
  });
  return response.data;
}

