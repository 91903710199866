import { motion } from "framer-motion";
import cogwheelIcon from "../../assets/icons/cogwheel.svg";

interface CogwheelRotateProps {
  width: string;
  height: string;
  duration?: number;
}

const CogwheelRotate = ({ width, height, duration = 30 }: CogwheelRotateProps) => {
  return (
    <motion.div
      className="flex justify-center"
      animate={{ rotate: 360 }}
      transition={{ duration: duration, repeat: Infinity, ease: "linear" }}
      style={{
        width: width,
        height: height,
      }}
    >
      <img src={cogwheelIcon} alt="cogwheel" className="w-full h-full object-cover object-center" />
    </motion.div>
  );
};

export default CogwheelRotate;
