import { clamp } from "lodash-es";
import { nFormatter } from "../../../helpers/moneyFormat";
import { Card, PurchasedCards, RuleCardsEnum } from "../../../store/cards/api/types";
import { ExternalImage } from "../../ui/ExternalImage";
import { calculateCardPrice, calculateIncomePerHour } from "../../../helpers/cardsCalculated";
import { useSelector } from "react-redux";
import { balanceSelector } from "../../../store/account/account";
import {
  cardsSelector,
  isCardInPurchasedCards,
  openPopUpForCard,
  purchasedCardsSelector,
} from "../../../store/cards/cardsReducer";
import { userInvitesSelector } from "../../../store/friends/friendsReducer";
import CardRule from "./CardRule";

//icons
import lockIcon from "../../../assets/icons/lock-white.svg";
import balanceIcon from "../../../assets/icons/balance.svg";
import { useAppDispatch } from "../../../store/hooks";

const MAX_LEVEL = 100;

interface CardProps {
  card: Card;
  level: number;
  isGameActive?: boolean;
}

const CardItem = ({ card, level = 0 }: CardProps) => {
  const balance = useSelector(balanceSelector);
  const cards = useSelector(cardsSelector);
  const purchasedCards = useSelector(purchasedCardsSelector);
  const userInvites = useSelector(userInvitesSelector);

  const { img, name, unlockRequirement, referalsToUnlock, referalsPerLevel } = card;

  const fontSize = Math.floor(clamp(window.innerWidth * 0.036, 10, 14));
  const lineHeight = Math.floor(clamp(window.innerWidth * 0.042, 12, 16));

  // const nextLevel = level + 1

  const cardPrice = level > 0 ? calculateCardPrice(card.startCost, level) : card.startCost;
  const incomePerHour = level > 0 ? calculateIncomePerHour(card.startProfit, level) : card.startProfit;

  const isCurrentCard = isCardInPurchasedCards(purchasedCards, card.key);
  const bonusForCurrentCard =
    isCurrentCard && isCurrentCard.level !== 0 ? incomePerHour - isCurrentCard.bonus : incomePerHour;
  const { executedRule, typeOfRule, rule } = checkRules(
    unlockRequirement,
    referalsToUnlock,
    card,
    referalsPerLevel,
    purchasedCards,
    userInvites,
    cards,
  );

  const dispatch = useAppDispatch();
  const active = balance >= cardPrice && executedRule && level < MAX_LEVEL;

  const handleClick = () => {
    if (level < MAX_LEVEL)
      dispatch(
        openPopUpForCard({
          isOpened: true,
          card: card,
          isActiveCard: active,
          typeOfRule: typeOfRule,
          executedRule: executedRule,
        }),
      );
  };
  return (
    <>
      <div
        onClick={handleClick}
        className="relative flex flex-col gap-[1px] h-full"
        style={{ fontSize: fontSize, lineHeight: `${lineHeight}px` }}
      >
        <div className="absolute top-0 left-0 bg-[#A029F5] z-[1000] py-[0.8vh] px-[2.6vw] font-bold rounded-tl-[12px] rounded-br-[12px]">
          lvl {level}
        </div>
        <div className="bg-[#FFFFFF26] flex p-2 rounded-t-2xl gap-2 font-bold ">
          <div className="w-1/2 h-full relative border-solid border-[1px] border-[#4E335E] rounded-xl">
            <ExternalImage src={img} alt={name} className="size-full object-cover rounded-xl" />
            {!active && (
              <>
                <img src={lockIcon} alt="lock" className="absolute z-10 inset-0 m-auto" />
                <div className="size-full bg-[#4B325A]/75 absolute top-0 rounded-xl"></div>
              </>
            )}
          </div>
          <div className="w-1/2 h-full flex flex-col justify-between gap-[2px] break-words">
            <p>{name}</p>
            <div className="text-yellow flex items-center  gap-[2px]">
              <p>+{nFormatter(bonusForCurrentCard)}</p>
              <img
                className=" object-cover"
                style={{ height: `${fontSize}px`, width: `${fontSize}px` }}
                loading="lazy"
                src={balanceIcon}
                alt="currency"
              />
            </div>
          </div>
        </div>
        <CardRule
          executedRule={executedRule}
          cardPrice={cardPrice}
          rule={rule}
          typeOfRule={typeOfRule}
          isMaxLevel={level >= MAX_LEVEL}
        />
      </div>
    </>
  );
};

export default CardItem;

export const checkRules = (
  unlockRequirement: Card["unlockRequirement"],
  referalsToUnlock: number,
  currentCard: Card,
  referalsPerLevel: number[],
  purchasedCards: PurchasedCards[],
  userInvites: number,
  cards: Card[],
) => {
  let executedRule = true;
  let rule = "";
  let typeOfRule = RuleCardsEnum.LEVEL;

  if (unlockRequirement && unlockRequirement.key != "add_new_friend") {
    const { key: unlockRequirementKey, level: unlockRequirementLevel } = unlockRequirement;

    executedRule =
      unlockRequirementLevel && unlockRequirementKey
        ? (purchasedCards.find((purchasedCard) => purchasedCard.key === unlockRequirementKey)?.level || 0) >=
          unlockRequirementLevel
        : true;

    rule = `${cards.find((item) => item.key === unlockRequirementKey)?.name} lvl. ${unlockRequirementLevel}`;
    typeOfRule = RuleCardsEnum.LEVEL;
    if (!executedRule) {
      return { executedRule, typeOfRule, rule };
    }
  }

  if (referalsToUnlock && referalsToUnlock > 0) {
    executedRule = userInvites >= referalsToUnlock;
    typeOfRule = RuleCardsEnum.TOTAL_REFERRALS;
    rule = `Invite ${referalsToUnlock} friends`;
  }

  const cardInPurchased = isCardInPurchasedCards(purchasedCards, currentCard.key);
  const nextLevelForCurrentCard = cardInPurchased ? cardInPurchased?.level + 1 : 1;

  if (referalsPerLevel?.includes(nextLevelForCurrentCard)) {
    //PREVIOUS LOGIC

    // if (nextLevelForCurrentCard === 1) {
    //   executedRule = dateReferralsUpdate! > timeToInvites!;
    // } else if (cardInPurchased) {
    //   executedRule = dateReferralsUpdate! > cardInPurchased.lastUpdated;
    // }
    executedRule = cardInPurchased ? cardInPurchased?.isTicketUsed : false;
    typeOfRule = RuleCardsEnum.NEW_FRIENDS;
    rule = `Invite +1`;
  }

  return { executedRule, typeOfRule, rule };
};
