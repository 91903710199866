interface ChipProps {
  text: string;
  bgColor: string;
  textSize?: string;
  height?: string;
  paddingX?: string;
  border?: string;
}

const Chip: React.FC<ChipProps> = ({
  text,
  bgColor,
  textSize = "text-[12px]",
  paddingX = "px-2",
  height = "",
  border = "",
}) => {
  return (
    <div className={`${height}`}>
      <div
        className={`${textSize} font-semibold ${paddingX} h-full rounded-2xl  ${bgColor} border-solid ${border} flex items-center text-center`}
      >
        {text}
      </div>
    </div>
  );
};
export default Chip;
