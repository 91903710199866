import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import {
  adNextAvailableTimeSelector,
  adNextAvailableTimeTwentySelecor,
  adTwentyCountSelecor,
  adTwentyRewardTakenSelector,
} from '../../../store/account/account';
import { referralsTicketSelector } from '../../../store/cards/cardsReducer';
import { useToastNotifications } from '../../../hooks/useToastNotifications';
import { useAppDispatch } from '../../../store/hooks';
import ticketsIcon from '../../../assets/img/tickets.png';
import { ClickerGameTimerRef } from '../../Clicker/GameTimer';
import { calculateTimeRemaining } from '../DailyCombo/DailyComboStatus';
import { ToastNotificationType } from '../../../store/toastNotifications/toastNotificationsReducer';
import { fetchRewardForTaskAdsAction } from '../../../store/account/actions/fetchRewardForTaskAdsAction';
import { fetchAdTicketRewardAction } from '../../../store/account/actions/fetchAdTicketReward';
import { GradientCard } from '../../ui/GradientCard';
import { Timer } from '../../ui/Timer';

export interface ShowPromiseResult {
  done: boolean; // true if user watch till the end, otherwise false
  description: string; // event description
  state: 'load' | 'render' | 'playing' | 'destroy'; // banner state
  error: boolean; // true if event was emitted due to error, otherwise false
}

const AdTicketReward = React.memo(() => {
  const adNextAvailable = useSelector(adNextAvailableTimeSelector);
  const adNextAvailableTimeTwenty = useSelector(adNextAvailableTimeTwentySelecor);
  const adTwentyCount = useSelector(adTwentyCountSelecor);
  const adTwentyRewardTaken = useSelector(adTwentyRewardTakenSelector);
  const reftickets = useSelector(referralsTicketSelector);

  const [isAdsActive, setIsAdsActive] = useState(adNextAvailable === null || new Date(adNextAvailable) < new Date());
  const [AdController, setAdController] = useState<{
    show: () => Promise<ShowPromiseResult>;
    load: () => boolean;
  } | null>(null);
  const [isBannerAdsgram, setIsBannerAdsgram] = useState<boolean>(false);
  const [isClaimTicketAvailable, setIsClaimTicketAvailable] = useState(false);

  const { showNotification } = useToastNotifications();
  const dispatch = useAppDispatch();

  const timerRef = useRef<ClickerGameTimerRef>(null);
  const [timeRemainingTwenty, setTimeRemainingTwenty] = useState(() =>
    adNextAvailableTimeTwenty ? calculateTimeRemaining(adNextAvailableTimeTwenty) : -1
  );

  useEffect(() => {
    setTimeRemainingTwenty(adNextAvailableTimeTwenty ? calculateTimeRemaining(adNextAvailableTimeTwenty) : -1);
    setIsAdsActive(adNextAvailable === null || new Date(adNextAvailable) < new Date());
  }, [adNextAvailable, adNextAvailableTimeTwenty]);

  useEffect(() => {
    const checkBannerAvailability = async () => {
      try {
        const blockId = import.meta.env.VITE_ADSGRAM_BLOCK_ID;
        const controller = window.Adsgram.init({ blockId: blockId });
        setAdController(controller);

        if (controller) {
          const loadPromise = await controller.load();
          setIsBannerAdsgram(loadPromise);
        } else {
          console.error('AdController is not initialized.');
        }
      } catch (error) {
        console.error('Error checking banner availability:', error);
      }
    };

    checkBannerAvailability();
  }, []);

  useEffect(() => {
    if (adTwentyCount > 0 && adTwentyCount % 10 === 0 && !adTwentyRewardTaken) {
      setIsClaimTicketAvailable(true);
    } else {
      setIsClaimTicketAvailable(false);
    }
  }, [adTwentyCount, adTwentyRewardTaken, reftickets]);

  const adsgramShow = () => {
    if (isClaimTicketAvailable) {
      showNotification({
        title: 'Claim your reward',
        type: ToastNotificationType.Error,
      });
      return;
    }

    if (timeRemainingTwenty > 0) {
      console.log('Ads are on cooldown, skipping ad display.');
      return;
    }

    if (isAdsActive) {
      if (AdController) {
        AdController.show()
          .then((result: ShowPromiseResult) => {
            if (result.done) {
              dispatch(fetchRewardForTaskAdsAction('special-ads'));
              showNotification({
                title: "You've been watched video",
                type: ToastNotificationType.Success,
                timeoutMs: 2000,
              });
            }
          })
          .catch((result: ShowPromiseResult) => {
            console.error('Error or user skipped the ad', result);
          });
      } else {
        console.error('AdController is not initialized');
      }
    } else {
      console.log('Ads are not active');
    }
  };
  const handleClaimTicket = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isClaimTicketAvailable && !adTwentyRewardTaken) {
      dispatch(fetchAdTicketRewardAction());
    } else {
      console.log('Claim ticket button is not active');
    }
  };

  const displayAdTwentyCount = (() => {
    if (adTwentyCount === 0) {
      return '0/10';
    }
    if (adTwentyCount === 10 && adTwentyRewardTaken) {
      return '0/10';
    }
    if (adTwentyCount === 10 || adTwentyCount === 20) {
      return '10/10';
    }
    return `${adTwentyCount % 10}/10`;
  })();

  return (
    <div className="mt-[10px] flex flex-col gap-2 mb-[16px] rounded-[12px]">
      {isBannerAdsgram && (
        <GradientCard backgroundColor="#28202C">
          <div className="flex-1 p-1 w-full flex gap-x-3 items-center pr-3 bg-[rgba(188,97,190,0.43)] rounded-[12px] h-[56px]">
            <span className="flex grow gap-x-3 items-center">
              <span className="h-[54px] w-[54px] relative flex justify-center items-center left-[18px]">
                <span className="absolute w-[54px] h-[54px] bg-[#F36BFF] blur-[10px] rounded-full"></span>
                <img loading="lazy" className="absolute w-[44px] h-[51px]" src={ticketsIcon} alt="Task icon" />
              </span>

              <div className="absolute left-[81px] leading-none">
                <span className="text-left block capitalize pb-2 font-inter font-normal font-bold text-[14px] text-white flex-none">
                  Get your ticket
                </span>

                <span className="text-left block w-[95px] h-[9px] font-inter font-normal font-medium text-[12px] text-white flex-none order-1 flex-grow-0">
                  Watch {displayAdTwentyCount} ads
                </span>
              </div>
            </span>

            {timeRemainingTwenty > 0 ? (
              <div className="relative flex flex-col items-center justify-center">
                <Timer
                  ref={timerRef}
                  remainingTime={timeRemainingTwenty}
                  style={`
                color: #FFFFFF;
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px !important;
                align-self: stretch;
              `}
                  onEnd={() => {
                    setIsAdsActive(true);
                  }}
                />
                <span className="block text-center text-[12px] text-white font-inter font-normal w-full">
                  Next videos
                </span>
              </div>
            ) : (
              <button
                type="button"
                className={`relative flex items-center justify-center w-[123px] h-[32px] ${
                  isClaimTicketAvailable ? 'bg-[#00D254]' : 'cursor-pointer'
                } rounded-[16px]`}
                style={{
                  background: isClaimTicketAvailable
                    ? undefined
                    : 'radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%), #832DBF',
                  borderRadius: '16px',
                }}
                onClick={event => {
                  if (!isClaimTicketAvailable) {
                    adsgramShow();
                  } else {
                    handleClaimTicket(event);
                  }
                }}
              >
                <span
                  className="text-center"
                  style={{
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#FFFFFF',
                  }}
                >
                  {isClaimTicketAvailable ? 'Claim ticket' : 'Watch'}
                </span>
              </button>
            )}
          </div>
        </GradientCard>
      )}
    </div>
  );
});

export default AdTicketReward;
