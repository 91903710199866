import React, { useEffect } from "react";
import { CLICKER_FOOTER_HEIGHT, CLICKER_TOOLBAR_HEIGHT } from "../../constants/clicker.ts";
import { useSelector } from "react-redux";
import { activeGameCoinsSelector } from "../../store/clicker/selectors.ts";
import { ExternalImage } from "../ui/ExternalImage.tsx";
export enum OnboardingStep {
  Skills = "skills",
  Cards = "cards",
  CorrectToken = "correct-token",
}

interface OnboardingStepsProps {
  step: OnboardingStep;
}

const OnboardingSteps: React.FC<OnboardingStepsProps> = ({ step }) => {
  const gameCanvasHeight = window.innerHeight - CLICKER_TOOLBAR_HEIGHT - CLICKER_FOOTER_HEIGHT;
  const activeGameCoins = useSelector(activeGameCoinsSelector);

  useEffect(() => {
    const targetElement = document.querySelector(".coin-index-1");
    if (step === OnboardingStep.CorrectToken) {
      if (targetElement instanceof HTMLElement) {
        targetElement.style.display = "none";
      }
    }

    return () => {
      if (targetElement instanceof HTMLElement) {
        targetElement.style.display = "block";
      }
    };
  }, [step]);

  if (step === OnboardingStep.Skills) {
    return (
      <p className="text-[24px] leading-6 absolute top-[3vh] left-[8vh] max-w-[173px]">
        Tap here to increase your Hourly Income
      </p>
    );
  }

  if (step === OnboardingStep.Cards) {
    return (
      <p className="text-[20px] font-bold leading-6 absolute top-[7vh] left-[10vh]">
        Complete this <br /> Portfolio to Get $RCT <br /> Rewards
      </p>
    );
  }

  if (step === OnboardingStep.CorrectToken) {
    return (
      <div
        className="w-full ml-auto z-[50] flex flex-col items-start relative justify-between pr-[20%]"
        style={{
          marginTop: `${CLICKER_TOOLBAR_HEIGHT}px`,
          height: `${gameCanvasHeight}px`,
        }}
      >
        <div className="mt-5 flex gap-x-3 items-center right-[13%] absolute top-[31%] -translate-y-1/2">
          <p className="text-[24px]  leading-7 text-right">
            Tap the correct token <br /> to build your portfolio
          </p>

          <ExternalImage
            src={activeGameCoins?.[1]?.icon}
            alt="ETH"
            style={{
              width: gameCanvasHeight * 0.23,
              height: gameCanvasHeight * 0.23,
            }}
            className=" object-cover bg-white rounded-full  max-w-[80px] max-h-[80px]"
          />
        </div>
      </div>
    );
  }

  return null;
};

export default OnboardingSteps;
