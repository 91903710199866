export function PreloaderCircle() {
  return (
    <span className="lds-ring">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  );
}
