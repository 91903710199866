import { moneyFormat } from "../../helpers/moneyFormat";

interface RocketMetricsProps {
  min_deposite?: number;
  buyIn: number;
  yourInvest: number;
  ownerInvest: number;
  otherInvest: number;
}

const RocketMetrics: React.FC<RocketMetricsProps> = ({ min_deposite, buyIn, yourInvest, otherInvest, ownerInvest }) => {
  const percentOfYourInvest = (100 * yourInvest) / buyIn;
  const percentOfOtherInvest = (100 * otherInvest) / buyIn;
  const percentOfOwnerInvest = (100 * ownerInvest) / buyIn;
  const fullness = percentOfYourInvest + percentOfOtherInvest + percentOfOwnerInvest;

  const ratio =
    fullness != 100
      ? `$${moneyFormat(yourInvest + otherInvest + ownerInvest)}/$${moneyFormat(buyIn)}`
      : `$${moneyFormat(buyIn)}`;
  console.log(min_deposite);

  return (
    <div className={`flex flex-col gap-6 pb-[20px]`}>
      <div className="flex flex-col gap-[6px]">
        <div className="flex justify-between items-stretch">
          <p>
            <span className="mr-1 text-[#989898]">Fullness:</span>
            <span className=" font-bold">{fullness}%</span>
          </p>
          <p className=" font-bold">{ratio}</p>
        </div>
        <div className=" flex gap-[1px] rounded-full w-full h-2 bg-[#FFFFFF40] items-center px-[1px]">
          <div
            className={`relative h-1.5 rounded-full `}
            style={{
              width: `${percentOfOwnerInvest}%`,
              background: "linear-gradient(94.55deg, #AF38D9 -1.4%, #8B18E5 100.83%)",
            }}
          ></div>{" "}
          <div
            className={`relative h-1.5  rounded-full `}
            style={{
              width: `${percentOfYourInvest}%`,
              background: "linear-gradient(83.53deg, #00FF66 5.09%, #00BE6E 94.91%)",
            }}
          ></div>
          <div
            className={`relative h-1.5  rounded-full `}
            style={{
              width: `${percentOfOtherInvest}%`,
              background: "linear-gradient(90deg, #FEBA35 51.58%, #FF7C22 100%)",
            }}
          ></div>
        </div>
        {min_deposite && (
          <div className="flex justify-center">
            <span className={`mr-1 text-[#989898] font-medium`}>Min. rate:</span>
            <span className=" font-bold">${moneyFormat(min_deposite)}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default RocketMetrics;
