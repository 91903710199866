import { nFormatter } from "../../helpers/moneyFormat";
import balanceIcon from "../../assets/icons/balance.svg";
import userPhoto from "../../assets/icons/user.svg";
import { UserInLeague } from "../../api/user/types";
import { ExternalImage } from "../ui/ExternalImage";

const LeaderItem = ({ leader, rankingPosition }: { leader: UserInLeague; rankingPosition: number }) => {
  const { first_name, last_name, photo_url, absoluteBalance, id } = leader;

  const name = first_name || last_name ? `${first_name} ${last_name}` : `User${id}`;
  const slicedName = name.length > 15 ? `${name.slice(0, 12)}...` : name;
  return (
    <div
      className={
        "relative flex gap-[12px] max-h-[56px] items-center  rounded-[16px] font-medium block_info  hover:bg-[#4A315A] ease-in duration-300 "
      }
    >
      <div className="relative rounded-[12px] m-[4px]">
        {/* <img src={photo_url!} className=" border-none rounded-[12px] w-[48px] h-[48px] bg-cover" /> */}
        {photo_url ? (
          <ExternalImage
            src={photo_url}
            className=" border-none rounded-[12px] w-[48px] h-[48px] bg-cover"
            alt="Avatar"
            width={60}
            height={60}
            loading="lazy"
          />
        ) : (
          <img src={userPhoto} className="border-none rounded-[12px] w-[48px] h-[48px] bg-cover" alt="Profile Photo" />
        )}
        <div className="absolute top-[2px] left-[2px] rounded-2xl py-[7px] px-[4px] bg-[#2F2038] text-[8px] flex justify-center font-extrabold leading-none">
          {rankingPosition}
        </div>
      </div>
      <div className="font-bold text-[14px] flex items-center flex-grow justify-between mr-3">
        <div>
          <p className=" text-justify tracking-[0.02em] text-[14px] leading-[14px]">{slicedName}</p>
        </div>
        <div className="flex text-orangeLight items-center gap-x-[2px] text-[16px]">
          <span className="size-[19px]">
            <img loading="lazy" className="size-full object-cover" src={balanceIcon} alt="currency" />
          </span>
          {nFormatter(absoluteBalance)}
        </div>
      </div>
    </div>
  );
};

export default LeaderItem;
