import { createAsyncThunk } from "@reduxjs/toolkit";
import { setStartTappAds } from "../../../api/user/setStartTappAds";



export const fetchsetStartTappAdsAction = createAsyncThunk("account/fetchsetStartTappAds", 
  async (payload: { taskId: number, taskName: string; taskDescription: string; taskUrl: string; isDone: boolean }) => {
  const { taskId, taskName, taskDescription, taskUrl, isDone } = payload;
  return setStartTappAds(taskId, taskName,taskDescription, taskUrl, isDone);
});
