import { useSelector } from "react-redux";
import { ProgressbarBox } from "../ui/ProgressbarBox.tsx";
import balanceIcon from "../../assets/icons/balance.svg";
import { moneyFormat } from "../../helpers/moneyFormat.ts";
import { useMemo } from "react";
import {
  activePortfolioSelector,
  dailyQuestSelector,
  isGameActiveSelector,
  progressStakingRewardSelector,
  rewardFromStackingSelector,
} from "../../store/clicker/selectors.ts";

export function StakedRewardProgress() {
  const dailyQuest = useSelector(dailyQuestSelector);

  const isGameActive = useSelector(isGameActiveSelector);
  const rewardFromStacking = useSelector(rewardFromStackingSelector);
  const activePortfolio = useSelector(activePortfolioSelector);
  const portfolioCost = useMemo(() => {
    if (!dailyQuest) return 0;
    return activePortfolio ? activePortfolio.cost : 0;
  }, [dailyQuest]);

  const progressStakingReward = useSelector(progressStakingRewardSelector);
  let progress = 0;
  if (isGameActive && activePortfolio) {
    progress = activePortfolio.progress;
  } else if (progressStakingReward !== null && rewardFromStacking !== undefined) {
    progress = (progressStakingReward * 100) / rewardFromStacking;
  }

  const cost = isGameActive ? portfolioCost : portfolioCost;

  return (
    <ProgressbarBox progressPercent={progress}>
      <small className="text-[12px] text-center flex flex-col">
        <span>
          <span className="text-white font-medium">{isGameActive ? "Portfolio Reward" : "Staked Amount"}</span>
        </span>

        <strong className="flex justify-center items-center gap-x-[2px] text-[14px] text-yellow">
          <span>+</span>
          {moneyFormat(cost || 0)}
          <span className="size-4">
            <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
          </span>
        </strong>
      </small>
    </ProgressbarBox>
  );
}
