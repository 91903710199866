import { createAsyncThunk } from "@reduxjs/toolkit";
import { Ad } from "../../../api/tasks/types";
import { fetchAdFeed } from "../../../api/tasks/getAdTapList";

interface FetchAdFeedResponse {
  ads: Ad[];
}

export const fetchAdFeedAction = createAsyncThunk<FetchAdFeedResponse, { userId: number }>(
  "ads/fetchAdFeed",
  async ({ userId }) => {
    const ads = await fetchAdFeed(userId);
    return { ads };
  }
);
