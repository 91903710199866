import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { GetUserRewardOKX } from "./types.ts";

export async function getRewardOKX(marketId: string) {
  const response = await axiosInstance.post<GetUserRewardOKX>(apiRoutes.user.checkRegisterOKX, {
    marketId,
  });
  return {
    data: response.data.data,
    status: response.status,
  };
}
