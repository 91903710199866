import { useState } from "react";
import FilterTrade, { TradeType } from "./FilterTrade";
import TransactionControl from "./TransactionControl";
import TradeInfo from "./TradeInfo";

const TradeActions = () => {
  const [activeTab, setActiveTab] = useState<TradeType>(TradeType.BUY);

  return (
    <div>
      <FilterTrade onSelectTab={setActiveTab} activeTab={activeTab} />
      <TransactionControl activeTradeType={activeTab} />
      <div className="pt-6">
        <TradeInfo activeTradeType={activeTab} />
      </div>
    </div>
  );
};

export default TradeActions;
