import { SettingMenuItem } from "./configs/MenuItems";

import chevronRightIcon from "../../../assets/icons/chevron-right.svg";
import { NavLink } from "react-router-dom";

interface MenuItemProps extends SettingMenuItem {
  border?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ link, icon, id, title, border }) => {
  return (
    <NavLink to={link} key={id}>
      <div>
        <div
          className={`flex items-center justify-between py-4 ${border ? "border-solid border-b-[1px]  border-[#fff]/10 pb-3" : ""}`}
        >
          <div className={`flex items-center justify-center gap-2`}>
            <div className="size-[28px] flex justify-center items-center rounded-full" id={id}>
              <img src={`/${icon}.svg`} className="size-[18px]" alt={icon} />
            </div>
            <div className="font-semibold">{title}</div>
          </div>
          <div className="block mr-2">
            <img src={chevronRightIcon} alt="Right arrow" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default MenuItem;
