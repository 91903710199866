import { createSlice } from "@reduxjs/toolkit";
import { Boost } from "./types.ts";
import { fetchBoostsListAction } from "./actions/fetchBoostsListAction.ts";
import { buyBoostAction } from "./actions/buyBoostAction.ts";
export interface BoostsState {
  pending: boolean;
  error: string | null;

  buyPending: boolean;
  buyError: string | null;

  boosts: Boost[];
}

const initialState: BoostsState = {
  pending: false,
  error: null,
  boosts: [],

  buyPending: false,
  buyError: null,
};

export const boostsSlice = createSlice({
  name: "boosts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // FETCH INITIAL CLICKER DATA
      .addCase(fetchBoostsListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })

      .addCase(fetchBoostsListAction.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.pending = false;
        state.boosts = action.payload.boosts;
      })

      .addCase(fetchBoostsListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      // BUY BOOST
      .addCase(buyBoostAction.pending, (state) => {
        state.buyPending = true;
        state.buyError = null;
      })

      .addCase(buyBoostAction.fulfilled, (state) => {
        state.buyPending = false;
      })

      .addCase(buyBoostAction.rejected, (state, action) => {
        state.buyPending = false;
        state.buyError = action.error.message || "Unknown error";
      });
  },
});

export default boostsSlice.reducer;
