import UserWalletInfo from "../UserWalletInfo";
import NftList from "./Nft/NftList";
import NftsConditions from "./Nft/NftsConditions";
import { useTelegramApp } from "../../../hooks/useTelegramApp";
import Button from "../../ui/Button";
import ReactDOM from "react-dom";
import GuidesList from "./Guide/GuidesList";

const MintAndManageNFT = () => {
  const tgApp = useTelegramApp();

  const handleOpenSite = () => {
    tgApp.openLink("https://magiceden.io/launchpad/orbitonx?preview=");
  };

  return (
    <div className="pt-6 pb-[70px]">
      <UserWalletInfo showBalance={true} />
      <NftList />
      <NftsConditions />
      {ReactDOM.createPortal(
        <div className="fixed z-[50] left-4 right-4 w-[calc(100% - 16px)] bottom-[82px]">
          <Button
            text="Mint On MAGIC EDEN"
            handleClick={handleOpenSite}
            style="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
          />
        </div>,
        document.body,
      )}
      <GuidesList />
    </div>
  );
};

export default MintAndManageNFT;
