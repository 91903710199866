import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useAppDispatch } from "../../store/hooks";
import { setStatusDarkBackground } from "../../store/onboarding/onboardingReducer";

interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const baseStep: Partial<Step> = {
  disableBeacon: true,
  placement: "top-start",
};

interface ClickerTourProps {
  steps: Array<Omit<Step, "disableBeacon" | "placement"> & Partial<Pick<Step, "disableBeacon" | "placement">>>;
  onEnd: () => void;
  maxWidth?: string;
  trackEvent?: () => void;
}

export function OnboardingTour({ steps: initialSteps, onEnd, maxWidth, trackEvent }: ClickerTourProps) {
  const dispatch = useAppDispatch();

  const [{ run, stepIndex, steps }, setState] = useState<State>({
    run: true,
    stepIndex: 0,
    steps: initialSteps.map((step) => ({
      ...baseStep,
      ...step,
    })),
  });

  useEffect(() => {
    document.addEventListener("click", handleOverlayClick);

    document.body.style.overflow = "hidden";

    return () => {
      document.removeEventListener("click", handleOverlayClick);

      document.body.style.overflow = "";
    };
  }, [dispatch]);
  useEffect(() => {
    if (trackEvent) {
      trackEvent();
    }
    const timeoutId = setTimeout(() => {
      dispatch(setStatusDarkBackground(false));
    }, 50);

    return () => {
      clearTimeout(timeoutId);
      dispatch(setStatusDarkBackground(true));
    };
  }, []);

  function handleCallback({ status }: CallBackProps) {
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      onEnd();
    }
  }

  function handleOverlayClick() {
    setState((prevState) => ({
      ...prevState,
      stepIndex: prevState.stepIndex + 1,
    }));
  }

  return ReactDOM.createPortal(
    <Joyride
      callback={handleCallback}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      disableOverlayClose
      scrollDuration={1000}
      scrollToFirstStep
      // disableScrolling
      // disableScrollParentFix
      styles={{
        options: {
          arrowColor: "#0000",
          backgroundColor: "#0000",
          primaryColor: "#0000",
          textColor: "#fff",
        },
        buttonNext: {
          display: "none",
        },

        buttonBack: {
          display: "none",
        },
        buttonSkip: {
          display: "none",
        },
        buttonClose: {
          display: "none",
        },

        tooltip: {
          fontSize: "24px",
          maxWidth: maxWidth,
          top: 0,
          left: 0,
          bottom: 0,
          textAlign: "center",
        },

        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },

        spotlight: {
          borderRadius: 12,
        },
      }}
    />,
    document.getElementById("root")!,
  );
}
