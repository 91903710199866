import userPhoto from "../../..//assets/icons/user.svg";
import { Holder } from "../../../api/holders/types";
import { ExternalImage } from "../../ui/ExternalImage";
import { GradientCard } from "../../ui/GradientCard";

const HoldersItem = ({ holder }: { holder: Holder }) => {
  const {
    percentage,
    tokenAmount,
    user: { first_name, photo_url },
    token: { ticker },
  } = holder;
  return (
    <GradientCard backgroundColor="#28202C">
      <div
        className={"flex gap-1 items-center justify-between  rounded-[16px]  text-[14px] font-bold p-1 leading-none"}
      >
        <div className="flex items-center gap-[10px]">
          <div className="relative bg-lightPurple rounded-[12px] ">
            {photo_url && photo_url.length > 10 ? (
              <ExternalImage
                src={photo_url}
                alt={first_name}
                width={56}
                height={56}
                className=" rounded-[12px] bg-cover"
              />
            ) : (
              <img src={userPhoto} className=" rounded-[12px] w-[56px] h-[56px] bg-cover" />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <p>{first_name}</p>
            <p className="text-[12px] text-medGrey">
              {tokenAmount} ${ticker}
            </p>
          </div>
        </div>
        <div className="flex items-center text-[12px]  justify-between pr-3">
          <p>{percentage}%</p>
        </div>
      </div>
    </GradientCard>
  );
};

export default HoldersItem;
