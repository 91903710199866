import { CreateNote } from "../../../api/communityNotes/types";
import { useAppDispatch } from "../../../store/hooks";
import { createNoteAction } from "../../../store/token/actions/createNoteAction";

interface NoteFormProps {
  value: string;
  handleChange: (newValue: string) => void;
  tokenId: number | string;
}

const NoteForm = ({ handleChange, value, tokenId }: NoteFormProps) => {
  const dispatch = useAppDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };

  const handleSendNote = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: CreateNote = {
      tokenId: +tokenId,
      message: value,
    };

    console.log("send note", data);

    dispatch(createNoteAction(data)).finally(() => handleChange(""));
  };

  return (
    <form
      onSubmit={handleSendNote}
      className="w-full fixed bottom-0 left-0 right-0 bg-black/50 backdrop-blur-xl flex px-4 pt-3 pb-5 gap-2"
    >
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        className="appearance-none relative z-10 w-full h-[40px] px-4 outline-none rounded-3xl truncate backdrop-blur-xl bg-[#d9d9d9]/15"
      />
      <button
        type="submit"
        className="rounded-3xl px-4"
        disabled={value.length <= 2}
        style={{
          background:
            value.length <= 2
              ? "rgba(217, 217, 217, 0.15)"
              : "radial-gradient(406.24% 212.5% at 0% -44.64%, rgb(229, 67, 255) 0%, rgb(131, 45, 191) 100%)",
        }}
      >
        Send
      </button>
    </form>
  );
};

export default NoteForm;
