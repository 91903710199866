import { Token } from "../../store/token/api/types.ts";
import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";

export async function getSearchTokens(term: string): Promise<Token[]> {
  const response = await axiosInstance.get<ApiResponse<Token[]>>(apiRoutes.tokens.searchTokens, {
    params: { term },
  });
  return response.data.data;
}
