import { UNKNOWN_COINS_PLACEHOLDER } from "../../constants/clicker.ts";
import { useSelector } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { clamp } from "lodash-es";
import { AnimatePresence, motion } from "framer-motion";
import { CoinClickArea } from "./CoinClickArea.tsx";
import unknownGameCoinIcon from "../../assets/icons/unknown-game-coin.png";
import { ClickAllowedRejectedReasonEnum, ClickAllowedStatus, PortfolioCoin } from "../../store/clicker/types.ts";
import { activeGameCoinsSelector, isGameActiveSelector } from "../../store/clicker/selectors.ts";
import { ExternalImage } from "../ui/ExternalImage.tsx";

const ids = UNKNOWN_COINS_PLACEHOLDER.map((_, index) => 1000 - index);

export const ICON_SIZE_PERCENT = 33;
const DEFAULT_PARENT_CIRCLE_HEIGHT_PX = 317;
const BASE_DURATION = 0.3;
export const MAX_GAME_CANVAS_HEIGHT = 232;
export const ENERGY_LOSS_PER_WRONG_TAP = 5;

const coinsStyle = [
  { opened: { top: 0, left: 50, transform: [-50, -30] } },
  { opened: { top: 20.06, left: 100, transform: [-38, 0] } },
  { opened: { top: 100, left: 100, transform: [-100.07, -70] } },
  { opened: { top: 100, left: 0, transform: [0.06, -70] } },
  { opened: { top: 20, left: 0, transform: [-62, 0] } },
];

interface MemoizedClickerGameProps {
  handleCoinClick: (id: PortfolioCoin["id"]) => ClickAllowedStatus;
  clickCost: number;
  isPlaying: boolean;
}

const INITIAL_ANIMATION_STATE = {
  y: "-50%",
  x: "-50%",
  scale: 0.5,
  opacity: 0,
  top: "50%",
  left: "50%",
  transition: {
    delay: BASE_DURATION / 2,
    duration: BASE_DURATION,
    type: "tween",
  },
};

const EXIT = {
  y: "-50%",
  x: "-50%",
  scale: 0.3,
  top: "50%",
  left: "50%",
  opacity: 0,
  transition: {
    duration: BASE_DURATION + BASE_DURATION * 0.2,
    type: "tween",
  },
};

const getEnter = (index: number, isInitialRender: boolean) => {
  const {
    top,
    left,
    transform: [x, y],
  } = coinsStyle[index].opened;
  return {
    y: `${y}%`,
    x: `${x}%`,
    opacity: 1,
    scale: 1,
    top: `${top}%`,
    left: `${left}%`,
    transition: {
      duration: !isInitialRender ? BASE_DURATION + BASE_DURATION * 0.4 : 0,
      delay: !isInitialRender ? BASE_DURATION + BASE_DURATION * 0.2 : 0,
      type: "tween",
    },
  };
};

export const ClickerGameCanvas = ({ clickCost, handleCoinClick, isPlaying }: MemoizedClickerGameProps) => {
  const randomCoins = useSelector(activeGameCoinsSelector);

  // const activePortfolio = useSelector(activePortfolioSelector);
  const isGameActive = useSelector(isGameActiveSelector);
  // const endDate = useSelector(nextGameMilestoneTimeSelector);

  // const [timeRemaining, setTimeRemaining] = useState(() => (endDate ? calculateTimeRemaining(endDate) : -1));

  const activePointers = useRef(new Map<number, HTMLElement>());

  const [isAnimating, setIsAnimating] = useState(false);
  const [rootElementHeight, setRootElementHeight] = useState(() => DEFAULT_PARENT_CIRCLE_HEIGHT_PX);

  const rootElementRef = useRef<HTMLDivElement>(null);
  const dangerAreaRef = useRef<HTMLDivElement>(null);
  const dangerAreaDebounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const missClickCount = useRef(0);

  const handlePointerDown = (event: React.PointerEvent<HTMLElement>, id: PortfolioCoin["id"]) => {
    event.preventDefault();
    if (isAnimating) {
      return {
        isAllowed: false,
        rejectedReason: ClickAllowedRejectedReasonEnum.UNKNOWN,
      };
    }

    activePointers.current.set(event.pointerId, event.currentTarget);

    const canBeClicked = handleCoinClick(id);

    if (!canBeClicked.isAllowed) {
      handleDangerIndicator();
      missClickCount.current += 1;
    } else {
      hideDangerIndicator();
    }

    return canBeClicked;
  };

  const handlePointerUp = (e: React.PointerEvent<HTMLElement>) => {
    activePointers.current.delete(e.pointerId);
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 1000); // Delay to match the exit animation
    }
  }, [randomCoins]);

  // Get root element height to calculate circle size
  useEffect(() => {
    const rootElement = rootElementRef.current;
    if (!rootElement) return;

    const resizeObserver = new ResizeObserver(([entry]) => {
      setRootElementHeight(entry.contentRect.height);
    });

    resizeObserver.observe(rootElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [rootElementRef]);

  // Handle unmounted

  useEffect(
    () => () => {
      // Clear danger area debounce timeout
      if (dangerAreaDebounceTimeout.current) {
        clearTimeout(dangerAreaDebounceTimeout.current);
        missClickCount.current = 0;
      }
    },
    [],
  );

  // Calculate circle size
  const axisCircleSize = useMemo(() => {
    return clamp(rootElementHeight - rootElementHeight * 0.183, 150, 232);
  }, [rootElementHeight]);

  const isInitialRender = useRef(true);

  function handleDangerIndicator() {
    if (dangerAreaDebounceTimeout.current) {
      clearTimeout(dangerAreaDebounceTimeout.current);
    }

    if (!dangerAreaRef.current) return;

    const scaleFactor = 6;
    const opacityFactor = 6;

    const scale = Math.max(0.8, Math.min(1, 0.7 + (missClickCount.current / scaleFactor) * 0.2));

    const opacity = Math.max(0.6, Math.min(1, 0.45 + (missClickCount.current / opacityFactor) * 0.4));

    dangerAreaRef.current.style.transitionDuration = "0.25s";
    dangerAreaRef.current.style.transitionTimingFunction = "cubic-bezier(0.0, 0.0, 0.2, 1)";

    dangerAreaRef.current.style.opacity = `${opacity}`;
    dangerAreaRef.current.style.transform = `translate(-50%, -50%) scale(${scale})`;

    dangerAreaDebounceTimeout.current = setTimeout(hideDangerIndicator, 800);
  }

  function hideDangerIndicator() {
    const duration = clamp(missClickCount.current / 5, 0.7, 1.3);

    dangerAreaRef.current!.style.transitionDuration = `${duration}s`;
    dangerAreaRef.current!.style.transitionTimingFunction = "cubic-bezier(0.4, 0.0, 0.2, 1)";

    dangerAreaRef.current!.style.opacity = "0";
    dangerAreaRef.current!.style.transform = "translate(-50%, -50%) scale(0.5)";
    missClickCount.current = 0;
  }

  // useEffect(() => {
  //   endDate && setTimeRemaining(calculateTimeRemaining(endDate));

  //   const timer = setInterval(() => {
  //     setTimeRemaining((prevTime) => {
  //       if (prevTime <= 0) {
  //         clearInterval(timer);
  //         return 0;
  //       }
  //       return prevTime - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, [endDate]);
  return (
    <div ref={rootElementRef} className={`w-full flex-1 min-h-[150px] relative rounded-full `}>
      {/* circle */}
      <div
        className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full ${
          !isGameActive ? "opacity-25" : ""
        }`}
        style={{
          width: `${axisCircleSize}px`,
          height: `${axisCircleSize}px`,
          maxHeight: `${MAX_GAME_CANVAS_HEIGHT}px`,
          maxWidth: `${MAX_GAME_CANVAS_HEIGHT}px`,
          minHeight: "150px",
          minWidth: "150px",
        }}
      >
        <img
          src="/clicker-circle.svg"
          alt=" "
          aria-hidden="true"
          className="w-full h-full object-cover object-center"
        />
        <span className="text-center absolute top-1/2 left-1/2  leading-snug -translate-x-1/2 -translate-y-1/2 font-bold opacity-50">
          {isPlaying ? null : (
            <span
              style={{
                fontSize: clamp(axisCircleSize * 0.08, 12, 18),
              }}
            >
              Tap To Earn
              <br /> and Activate
              <br /> Staking
            </span>
          )}
        </span>
        {/* COUNTER */}
        <div className="text-[60px] font-extrabold w-full h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        {/* DANGER AREA */}
        <div
          ref={dangerAreaRef}
          className="absolute top-1/2 left-1/2 bg-red rounded-full z-10 pointer-events-none transition-all duration-300 ease-out origin-center"
          style={{
            width: `${axisCircleSize * 1.3}px`,
            height: `${axisCircleSize * 1.3}px`,
            filter: `blur(${axisCircleSize * 0.4}px)`,
            opacity: 0,
            transform: "translate(-50%, -50%) scale(0.8)",
          }}
        />
        {/* coins */}
        <ul className="select-none">
          <AnimatePresence initial={false}>
            {(!isGameActive ? UNKNOWN_COINS_PLACEHOLDER : randomCoins).map((coin, index) => (
              <motion.li
                className="rounded-full flex absolute"
                key={coin?.id || ids[index]}
                animate="enter"
                initial="initial"
                exit="exit"
                variants={{
                  initial: INITIAL_ANIMATION_STATE,
                  enter: getEnter(index, isInitialRender.current),
                  exit: EXIT,
                }}
                onAnimationEnd={() => {
                  if (isGameActive) {
                    isInitialRender.current = false;
                  }
                }}
                style={{
                  width: `${(axisCircleSize * ICON_SIZE_PERCENT) / 100}px`,
                  height: `${(axisCircleSize * ICON_SIZE_PERCENT) / 100}px`,
                  position: "absolute",
                }}
              >
                {coin ? (
                  <CoinClickArea
                    className={`flex-1 coin-index-${index}`}
                    cost={Math.round(clickCost)}
                    isLocked={isAnimating}
                    onPointerDown={(e) => handlePointerDown(e, coin.id)}
                    onPointerUp={handlePointerUp}
                    onPointerCancel={handlePointerUp}
                  >
                    <ExternalImage
                      src={coin.icon}
                      alt={coin.name}
                      className="w-full h-full rounded-full object-cover object-center"
                    />
                  </CoinClickArea>
                ) : (
                  <div>
                    <img
                      src={unknownGameCoinIcon}
                      alt={" "}
                      loading="lazy"
                      className="w-full h-full rounded-full object-cover origin-center"
                    />
                  </div>
                )}
              </motion.li>
            ))}
          </AnimatePresence>
        </ul>
      </div>

      {/* {!isGameActive && (
        <div className="text-center flex flex-col items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <span
            aria-hidden="true"
            style={{
              height: `${axisCircleSize * 1.115}px`,
              width: `${axisCircleSize * 1.115}px`,
              filter: `blur(${80 * 0.5275}px)`,
            }}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#775889] rounded-full"
          />

          <div className="relative flex flex-col">
            {" "}
            <span className=" text-[48px] leading-none  mb-3">💼</span>
            <span className=" w-[276px]">
              <span className="left-1/2 top-1/2 font-bold -translate-y-1/2 -translate-x-1/2 text-[24px] leading-[1.33] w-full">
                <span> Next round in</span>
                <br /> <span className="text-[40px] font-bold">{formatTime(timeRemaining)}</span>
              </span>
            </span>
          </div>
        </div>
      )} */}
    </div>
  );
};
