import { type ReactNode } from "react";

interface ProgressbarBoxProps {
  progressPercent: number;
  children: ReactNode;
}

export function ProgressbarBox({ progressPercent, children }: ProgressbarBoxProps) {
  return (
    <div className="rounded-2xl bg-white/15 relative flex justify-center items-center p-1 overflow-hidden">
      {/* Progress indicator */}
      <span
        aria-hidden="false"
        className="absolute w-full h-full top-0 left-0 transition-transform duration-200 origin-left bg-[#45D700]/25"
        style={{
          transform: `scaleX(${progressPercent / 100})`,
        }}
      />

      <div className="flex-1 relative">{children}</div>
    </div>
  );
}
