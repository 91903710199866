export enum TypeCardsEnum {
  Risk = "Risk",
  Timing = "Timing",
  Scams = "Scams",
  Special = "Special",
}

export enum RuleCardsEnum {
  BALANCE = "BALANCE",
  LEVEL = "LEVEL",
  NEW_FRIENDS = "NEW_FRIENDS",
  TOTAL_REFERRALS = "TOTAL_REFERRALS",
}

export interface Card {
  id: number;
  name: string;
  key: string;
  img: string;
  updatedAt: Date;
  type: TypeCardsEnum;
  description: string;
  startCost: number;
  startProfit: number;
  referalsToUnlock: number;
  referalsPerLevel: number[];
  unlockRequirement?: {
    key: string;
    level: number;
  };
}

export interface PurchasedCards {
  key: string;
  level: number;
  lastUpdated: Date;
  isTicketUsed: boolean;
  bonus: number;
  cost: number;
}

export interface DailyCombo {
  activeDate: Date;
  comboKeys: string[];
  reward: number;
}

export interface DailyReward {
  id: number;
  dayCount: number;
  rewardAmount: number;
  rewardTicket: number;
  rewardBoostCard: boolean;
  rewardDescription: string;
  createdAt: Date;
  updatedAt: Date;
}

export type DailyRewards = DailyReward[];