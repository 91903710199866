import Button from "../ui/Button.tsx";
import { useSelector } from "react-redux";
import { inviteIdSelector, inviteUrlSelector } from "../../store/friends/friendsReducer.ts";
import { useTelegramApp } from "../../hooks/useTelegramApp.ts";
import { useToastNotifications } from "../../hooks/useToastNotifications.ts";
import { ToastNotification, ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer.ts";
import { DataLayerEventNames, useDataLayer } from "../../hooks/useDataLayer.ts";

export function InviteButton({
  text,
  placement,
  background = "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)",
}: {
  text: string;
  placement: string;
  background?: string;
}) {
  const inviteId = useSelector(inviteIdSelector);
  const inviteUrl = useSelector(inviteUrlSelector);

  const telegramApp = useTelegramApp();

  const { showNotification } = useToastNotifications();
  const { trackEvent } = useDataLayer();

  function handleInviteFriends() {
    console.log("tit");
    console.log("inviteId", inviteId);

    // if (inviteId) {
    //   try {
    //     trackEvent(DataLayerEventNames.refLinkSharedClick, {
    //       placement: placement,
    //     });
    //     const shareText = `**Play and Mine $RCT Tokens with Me!** 🎮\nJump into OrbitonX game and become a crypto guru!\n\n💸 +50 $RCT tokens as a welcome gift\n🔥 +100 $RCT tokens if you have Telegram Premium`;

    //     telegramApp.openTelegramLink(
    //       `https://t.me/share/url?url=${encodeURIComponent(inviteUrl!)}&text=${encodeURIComponent(shareText)}`,
    //     );
    //   } catch (_) {
    //     showNotification({
    //       title: "Something went wrong. Please try again later.",
    //       type: ToastNotificationType.Error,
    //     });
    //   }
    // }

    inviteNewFriend(inviteId, inviteUrl, placement, telegramApp, trackEvent, showNotification);
  }

  return (
    <Button
      text={text}
      style={background}
      className="text-[14px] py-[1.14em] disabled:!bg-lightGrey"
      handleClick={handleInviteFriends}
    />
  );
}

interface TrackEvent {
  <Properties extends object>(eventName: DataLayerEventNames, properties?: Properties): void;
}

interface ShowNotification {
  (notification: Omit<ToastNotification, "id">): () => void;
}

export const inviteNewFriend = (
  inviteId: string | null,
  inviteUrl: string | null,
  placement: string,
  telegramApp: any,
  trackEvent: TrackEvent,
  showNotification: ShowNotification,
) => {
  if (inviteId) {
    try {
      trackEvent(DataLayerEventNames.refLinkSharedClick, {
        placement: placement,
      });
      const shareText = `**Play and Mine $RCT Tokens with Me!** 🎮\nJump into OrbitonX game and become a crypto guru!\n\n💸 +50 $RCT tokens as a welcome gift\n🔥 +100 $RCT tokens if you have Telegram Premium`;

      telegramApp.openTelegramLink(
        `https://t.me/share/url?url=${encodeURIComponent(inviteUrl!)}&text=${encodeURIComponent(shareText)}`,
      );
    } catch (_) {
      showNotification({
        title: "Something went wrong. Please try again later.",
        type: ToastNotificationType.Error,
      });
    }
  }
};
