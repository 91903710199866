import hotIcon from "../../../assets/icons/hot.svg";
import newIcon from "../../../assets/icons/new.svg";
import myTokensIcon from "../../../assets/icons/my-tokens.svg";
import bagMoneyIcon from "../../../assets/icons/bag-coins.svg";

export enum FilterKey {
  Hot = "get-hot-tokens",
  TopMCAP = "get-top-cap-tokens",
  New = "get-all-new-tokens",
  MyTokens = "get-my-tokens",
}

interface FilterItem {
  icon: string;
  text: string;
  key: FilterKey;
}

interface TokensFilterProps {
  onSelectTab: (limit: FilterKey) => void;
  activeTab: FilterKey;
}

const TokensFilter = ({ onSelectTab, activeTab }: TokensFilterProps) => {
  const handleTabClick = (limit: FilterKey) => {
    onSelectTab(limit);
  };

  const filterItems: FilterItem[] = [
    { icon: hotIcon, text: "Hot", key: FilterKey.Hot },
    { icon: bagMoneyIcon, text: "Top MCAP", key: FilterKey.TopMCAP },
    { icon: newIcon, text: "New", key: FilterKey.New },
    { icon: myTokensIcon, text: "My tokens", key: FilterKey.MyTokens },
  ];

  return (
    <div className="mt-3">
      <div className="flex justify-start space-x-1 overflow-x-auto overflow-y-hidden  scrollbar-hide">
        {filterItems.map(({ icon, text, key }) => (
          <button
            key={key}
            className={`rounded-2xl flex-shrink-0 flex gap-2 text-[#b5a4bf] leading-none font-semibold    px-[17px] w-fit py-[9px] bg-white/15 ${activeTab === key ? "bg-white/30 text-white" : ""}`}
            onClick={() => handleTabClick(key)}
          >
            <img src={icon} alt={text} className="size-[14px]" />
            <p>{text}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TokensFilter;
