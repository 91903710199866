import React from "react";

interface CreatedByProps {
  userName: string;
  generalStyles?: string;
}

const CreatedBy: React.FC<CreatedByProps> = ({ userName, generalStyles = "pb-3" }) => {
  return (
    <div className={`${generalStyles}`}>
      <span className="text-medGrey font-medium">Created by:</span>
      <span className="ml-2 font-semibold">@{userName}</span>
    </div>
  );
};

export default CreatedBy;
