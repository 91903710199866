import IconWithText from "../../../ui/IconWithText";
import ProfileRocketImages from "../../ProfileRocketImages";

import checkInIcon from "../../../../assets/icons/check-in.svg";
import Button from "../../../ui/Button";

import friendsIcon from "../../../../assets/icons/users-white.svg";

const ModalContent = () => {
  return (
    <div>
      <div className="flex justify-center">
        <ProfileRocketImages />
      </div>
      <div className="pt-[22px] pb-[12px]">
        <IconWithText icon={checkInIcon} text={`Checked in`} style="text-[#00D254] font-bold" />
      </div>
      <p className="text-[14px] text-[#A98DBD] font-medium px-4 mb-[32px] text-center">
        Your rocket has successfully checked in! To join the round, your rocket needs to be fully fueled with
        contributions.
      </p>
      <Button
        text="Invite friends"
        icon={friendsIcon}
        style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
      />
    </div>
  );
};

export default ModalContent;
