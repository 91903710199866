import { ReactNode } from "react";

interface DarkBackgroundProps {
  children: ReactNode;
}

const DarkBackground: React.FC<DarkBackgroundProps> = ({ children }) => {
  return (
    <div className="w-full">
      <div className="bg-[#00000040] mx-[-16px] px-4">{children}</div>
    </div>
  );
};

export default DarkBackground;
