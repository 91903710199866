import handshakeIcon from "../assets/icons/handshake.svg";
import bagCoinsIcon from "../assets/icons/bag-coins.png";
import { useAppDispatch } from "../store/hooks.ts";
import { changeStatusBottomMenu } from "../store/menu/bottomMenuReducer.ts";
import { useEffect } from "react";
import { InviteButton } from "../components/Friends/InviteButton.tsx";
import { fetchInvitesDataAction } from "../store/friends/actions/fetchInvitesDataAction.ts";
import { FriendsConditions } from "../components/Friends/FriendsConditions.tsx";

export default function FrensEarnings() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchInvitesDataAction());
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="flex flex-1 flex-col pb-[17px]">
      <div className="grow flex flex-col items-center pt-[3vh]">
        <img src={handshakeIcon} alt="handshake" className="size-[7.5vh]" />
        <h1 className="text-[32px] font-bold leading-none flex items-center text-center gap-x-[9px] pt-4 pb-[6vh]">
          <span>
            Boost Your <span className="text-[#FEC801]">Income!</span>
          </span>
        </h1>
        <img src={bagCoinsIcon} alt="bagCoinsIcon" style={{ height: "calc((100vh - 100px) * 0.28)" }} />
        <div className="mt-[30px]">
          <p className="text-[16px] px-[34px] text-center pb-6">
            <span className="font-bold">Invite friends</span> to join and watch
            <br /> your passive earnings grow
          </p>
          <FriendsConditions />
        </div>
      </div>

      <div className="shrink-0">
        <InviteButton text="Invite" placement=" onboarding_invite" />
      </div>
    </div>
  );
}
