import React from "react";

interface RocketsStatusFilterProps {
  onSelectStatus: (limit: string) => void;
  activeStatus: string;
}

const RocketsStatusFilter: React.FC<RocketsStatusFilterProps> = ({ onSelectStatus, activeStatus }) => {
  const handleTabClick = (limit: string) => {
    onSelectStatus(limit);
  };

  return (
    <div>
      <div className="flex justify-between font-semibold px-1 pt-1 h-[40px] mb-[18px] ">
        {["pending", "ready"].map((status) => (
          <button
            key={status}
            className={` w-1/2 pb-[10px] text-[16px]  border-b border-solid ${
              activeStatus === status
                ? "text-[#FEA836]  border-[#FEA836]"
                : "text-[#FFFFFF] opacity-75  border-[#46364D]"
            }`}
            onClick={() => handleTabClick(status)}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RocketsStatusFilter;
