import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { GetRewardForTaskAdsResponse } from "./types.ts";

export async function getRewardForTaskAds(pageName: string) {
  return (
    await axiosInstance.get<GetRewardForTaskAdsResponse>(apiRoutes.user.getRewardForTaskAds, {
      headers: {
        "x-page-name": pageName,
      },
    })
  ).data.data;
}
