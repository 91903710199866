import { TimeBlockStatus } from "./types.ts";

interface TimeBlockProps {
  status: TimeBlockStatus;
  time: string;
  width?: string;
  textSize?: string;
  statusText?: string;
}

const TimeBlock: React.FC<TimeBlockProps> = ({ status, time, textSize = "14", statusText }) => {
  return (
    <div
      className={`w-full ${
        status == "running" || status == "playing"
          ? "bg-green"
          : status == "registering"
            ? "bg-[#FEA836]"
            : status == "ready"
              ? "bg-[#CAE814]"
              : status == "waiting"
                ? "bg-green"
                : "bg-[#808080]"
      } text-center rounded-2xl  font-medium h-[64px]`}
    >
      <span className={`text-xs font-bold ${status == "registering" || status == "ready" ? "text-black" : ""} `}>
        {status === "ready"
          ? status?.charAt(0).toUpperCase() + status?.slice(1) + " to Go"
          : status?.charAt(0).toUpperCase() + status?.slice(1)}
      </span>
      <div
        className={`${
          status == "planned" ? "bg-[#3F3B41]" : "bg-darkGrey"
        }  m-[1px] rounded-[15px] h-[42px] flex flex-col justify-center`}
      >
        <p
          className={`text-xs ${
            status == "running" || status == "playing"
              ? "text-[#45D700]"
              : status == "registering"
                ? "text-[#FEA836]"
                : status == "ready"
                  ? "text-[#B9D700]"
                  : status == "waiting"
                    ? "text-green"
                    : "text-[#858585]"
          }`}
        >
          {statusText
            ? statusText
            : status == "running" || status == "playing"
              ? "Closes in"
              : status == "registering" || status == "ready"
                ? "Starts in"
                : "Starts"}
        </p>
        <p className="font-bold" style={{ fontSize: `${textSize}px` }}>
          {time}
        </p>
      </div>
    </div>
  );
};
export default TimeBlock;
