import { createSlice } from "@reduxjs/toolkit";
import { Nft } from "./api/types";
import { fetchMyNftsAction } from "./actions/fetchMyNftsAction";
import { RootState } from "../store";

export interface NftsState {
  pending: boolean;
  error: string | null;
  myNfts: Nft[];
}

const initialState: NftsState = {
  pending: false,
  error: null,
  myNfts: [],
};

const nftsSlice = createSlice({
  name: "nfts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //MY NFTS
    builder
      .addCase(fetchMyNftsAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchMyNftsAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.myNfts = action.payload;
      })
      .addCase(fetchMyNftsAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const {} = nftsSlice.actions;

export default nftsSlice.reducer;

export const myNftsSelector = (state: RootState) => state.nft.myNfts;
