import checkInIcon from "../../../assets/icons/check-in.svg";
import IconWithText from "../../ui/IconWithText";

import { useEffect, useRef, useState } from "react";
import { CryptoAssetDistribution } from "../../../store/rocket/api/types";

interface PortfolioAllocationOverview {
  allocationPortfolio: number;
  portfolio: CryptoAssetDistribution[] | undefined;
}

const PortfolioAllocationOverview: React.FC<PortfolioAllocationOverview> = ({ allocationPortfolio, portfolio }) => {
  return (
    <div className="pb-[14px]">
      <SubTitle allocation={allocationPortfolio} />
      <AllocationResultBlock portfolio={portfolio} allocation={allocationPortfolio} />
    </div>
  );
};

const SubTitle = ({ allocation }: { allocation: number }) => {
  const unAllocation = 100 - allocation;
  return (
    <div className="text-center pt-[10px] pb-[12px] font-semibold text-xs">
      {allocation < 100 ? (
        <p>Unallocated area {unAllocation}%</p>
      ) : (
        <IconWithText icon={checkInIcon} text={`Portfolio occupancy ${allocation}%`} style="text-[#1EFF85]" />
      )}
    </div>
  );
};

const AllocationResultBlock = ({
  portfolio,
  allocation,
}: {
  portfolio: CryptoAssetDistribution[] | undefined;
  allocation: number;
}) => {
  const [sizes, setSizes] = useState<any>([]);

  const refs = useRef<(HTMLDivElement | null)[]>(new Array(portfolio?.length).fill(null));

  const unAllocation = 100 - allocation;

  useEffect(() => {
    const newSizes = refs.current.map((ref) => ref?.getBoundingClientRect());
    setSizes(newSizes);
  }, [portfolio]);

  return (
    <div className="w-full h-[8px] bg-[#FFFFFF40] rounded-[4px] flex items-center  ">
      {portfolio?.map((asset, index) => {
        const divSize = sizes[index]?.width;
        const shouldDisplayPercentage = divSize >= 38;
        return (
          <div
            ref={(el) => (refs.current[index] = el)}
            className="relative rounded-[3px] h-[6px] "
            key={index}
            style={{ width: `${asset.percentage}%`, background: asset.color }}
          >
            {asset.percentage > 0 && (
              <div className="absolute top-[13px] flex gap-1 items-center">
                <img
                  src={`/${asset.coin.symbol}.svg`}
                  className={`relative min-w-[12px] w-[12px] min-h-[12px] h-[12px] }`}
                  style={{
                    right: divSize < 12 && sizes[index].x > 29 ? 12 - divSize : 0,
                  }}
                  alt="coin"
                />
                {shouldDisplayPercentage && (
                  <p className="text-[10px] font-semibold leading-[10px]">{asset.percentage}%</p>
                )}
              </div>
            )}
          </div>
        );
      })}
      {unAllocation > 0 && <div className="rounded-[3px] h-[6px] bg-white" style={{ width: `${unAllocation}%` }}></div>}
    </div>
  );
};

export default PortfolioAllocationOverview;
