const ProgressLine = ({
  progress,
  styles = "border-solid border-[1px] border-[#FFFFFF40]",
}: {
  progress: number;
  styles?: string;
}) => {
  return (
    <div className={`flex gap-[1px] h-[8px] rounded-full w-full ${styles}   items-center`}>
      {progress > 0 && (
        <div
          className={`relative h-[6px] rounded-full p-[2px] ml-[2px]`}
          style={{
            width: `${progress}%`,
            background: "linear-gradient(90deg, #F37E3B 0%, #FFAE34 100%)",
          }}
        ></div>
      )}
    </div>
  );
};

export default ProgressLine;
