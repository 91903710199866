import resetIcon from "../../../assets/icons/reset.svg";
import moneyIcon from "../../../assets/icons/money.svg";
import Button from "./Button";
import { useAppDispatch } from "../../../store/hooks";
import {
  clearPercentageInPortfolio,
  randomPortfolio,
  resetPortfolioSettings,
} from "../../../store/rocket/rocketReducer";

const ButtonsBox = () => {
  const dispatch = useAppDispatch();
  const handleReset = () => {
    dispatch(resetPortfolioSettings());
  };

  const handleRandom = () => {
    dispatch(randomPortfolio());
  };

  const handleCoinSelection = () => {
    dispatch(clearPercentageInPortfolio());
  };

  return (
    <div className="flex justify-between">
      <Button text="Reset" icon={resetIcon} iconStyle="w-[14px] h-[14px]" handleClick={handleReset} />
      <Button text="Random" icon={moneyIcon} iconStyle="w-[13px] h-[13px]" handleClick={handleRandom} />
      <Button text="Coin selection" icon={moneyIcon} iconStyle="w-[13px] h-[13px]" handleClick={handleCoinSelection} />
    </div>
  );
};

export default ButtonsBox;
