import { useEffect } from "react";
import UserPhoto from "../../components/Common/UserPhoto";
import { ReferralLink } from "../../components/Friends/ReferralLink";
import MenuList from "../../components/Pumpadur/Settings/MenuList";
import TokensList from "../../components/Pumpadur/Settings/TokensList";
import UserWalletInfo from "../../components/Pumpadur/UserWalletInfo";
import DarkBackground from "../../components/ui/DarkBackground";
import { useAppDispatch } from "../../store/hooks";
import { fetchMyTokensListAction } from "../../store/token/actions/fetchMyTokensListAction";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";

const SettingsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMyTokensListAction());
    dispatch(changeStatusBottomMenu(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="relative pb-[20px]">
      <DarkBackground>
        <div className="flex relative w-full flex-col gap-6 items-center justify-center py-4">
          <UserPhoto styles="w-[88px] h-[88px] rounded-2xl" />
          <p className="text-[14px] text-[#b5b5b5]">Set New Photo (Soon) </p>
          <UserWalletInfo />
        </div>
      </DarkBackground>
      <MenuList />

      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>

        <ReferralLink buttonText="Share" />
        <p className=" text-center w-full px-[10vw] font-medium text-[12px] text-lightestPurple">
          Share this link with friends to get more GAS XP points if they join and trade.
        </p>
      </div>
      <TokensList />
    </div>
  );
};

export default SettingsPage;
