import React, { useEffect, useState } from "react";
import { Ad } from "../../../api/tasks/types";
import { useSelector } from "react-redux";
import { 
  setTapCooldown,
  tgIdSelector, 
  userTappAdsrSelector} from "../../../store/account/account";
import chevronRightIcon from "../../../assets/icons/chevron-right.svg";
import checkmarkCircleIcon from "../../../assets/icons/checkmark-circle.svg";
import { useAppDispatch } from "../../../store/hooks";
import { registerAdClickAction } from "../../../store/tasks/actions/registerAdClickAction";
import { GradientCard } from "../../ui/GradientCard";
import { fetchsetStartTappAdsAction } from "../../../store/account/actions/fetchsetStartTappAdsAction";
import { UserTappAds } from "../../../api/user/types";

interface AdItemProps {
  ad: Ad;
  isCooldown: boolean;
  setIsCooldown: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdItem: React.FC<AdItemProps> = ({ ad, 
  isCooldown, 
  setIsCooldown 
}) => {
  const userId = useSelector(tgIdSelector) as number;
  const userTappads = useSelector(userTappAdsrSelector);
  const [tapTaskCounter, setTapTaskCounter] = useState<number>(0);
  const [isTaskCompleted, setIsTaskCompleted] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userTappads) {
        console.log("userTappads is undefined or not yet loaded");
        return;
    }

  const userTask = userTappads.find((userAd: UserTappAds) =>userAd.taskId === ad.id);
  if (userTask) {
    setIsTaskCompleted(userTask.isDone );
  } 
    const tapTaskCounter = userTappads.filter((userAd: UserTappAds) => userAd.status === 'completed').length;
    setTapTaskCounter(tapTaskCounter);
  }, [userTappads]);
  useEffect(() => {
    
  }, [userTappads]);

  const handleClick = async () => {
    if (isCooldown || tapTaskCounter >= 3 || isTaskCompleted) {
      return;
    }
    const cooldownDuration = 5000; // 5 секунд
    const cooldownEndTime = Date.now() + cooldownDuration;
    setIsCooldown(true);
    dispatch(setTapCooldown(cooldownEndTime.toString()))
    try {
        window.open(ad.url, "_blank");
        const result = await dispatch(registerAdClickAction({ userId, offerId: ad.id }));
        if (registerAdClickAction.fulfilled.match(result)) {
            await dispatch(fetchsetStartTappAdsAction({ 
              taskId: ad.id,
              taskName: ad.name,  
              taskDescription: ad.description, 
              taskUrl: ad.url, 
              isDone: ad.is_done }));
        } else {
            console.error("Failed to register ad click:", result.payload);
        }
    } catch (error) {
        console.error("An error occurred:", error);
    }
};

  return (
    <li key={ad.id}>
      <GradientCard backgroundColor="#28202C">
        <button
          type="button"
          className="flex-1 p-1 w-full h-[64px] flex gap-x-3 items-center pr-3"
          onClick={handleClick}
          disabled={isCooldown}
        >
          <span className="flex grow gap-x-3 items-center">
            <img
              src={ad.icon}
              alt={ad.name}
              loading="lazy"
              className="h-12 w-12 rounded-lg ml-[5px] mr-4"
            />

            <span className="flex-1">
              <span className="text-left text-[16px] font-medium block break-all capitalize">
                {ad.name}
              </span>
              <strong className="flex mt-0.5 text-orangeLight items-center gap-x-[0.286em] text-[14px] pr-4">
                {ad.description && (
                  <p className="text-gray-400 text-sm">{ad.description}</p>
                )}
              </strong>
            </span>
          </span>
          <span className="block w-5 h-5 flex items-center justify-center">
            {isTaskCompleted ? (
              <img className="w-full h-full" src={checkmarkCircleIcon} alt="Checkmark" />
            ) : (
              <img className="w-full h-full" src={chevronRightIcon} loading="lazy" alt="Right arrow" />
            )}
          </span>
 
        </button>
      </GradientCard>
    </li>
  );
};

export default AdItem;