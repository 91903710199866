import SkillsIcon from "../../assets/icons/skills.svg?react";
import TasksIcon from "../../assets/icons/tasks.svg?react";
import EarnIcon from "../../assets/icons/earn.svg?react";
import FriendsIcon from "../../assets/icons/friends.svg?react";
import PumpadurIcon from "../../assets/icons/pumpadur.svg?react";

interface MenuItemProps {
  title: string;
  icon: string;
  emoji?: string;
  border?: boolean;
  active?: boolean;
  width?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, icon, active = false, width }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center h-full gap-[2px]`}
      id={`${title.toLowerCase()}`}
      style={{ width: width ? width : "17.5vw" }}
    >
      <div className="relative  size-6 rounded-full">
        <div className="relative z-10">
          <IconComponent icon={icon} active={active} />
        </div>
      </div>
      <p className="text-[11px] leading-[20px] transition-opacity duration-200 text-center">{title}</p>
    </div>
  );
};

export default MenuItem;

const IconComponent: React.FC<{ icon: string; active: boolean }> = ({ icon, active }) => {
  const icons: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
    tasks: TasksIcon,
    earn: EarnIcon,
    friends: FriendsIcon,
    skills: SkillsIcon,
    pumpadur: PumpadurIcon,
  };

  const Icon = icons[icon];

  return Icon ? (
    <div
      className="rounded-full"
      style={
        active
          ? {
              background: "linear-gradient(180deg, #d325ff 0%, #7737ff 100%)",
              boxShadow: `
                0 0 5px #d325ff,
                0 0 10px #7737ff
              `,
            }
          : {}
      }
    >
      <Icon className={`size-6 rounded-full ${active ? "fill-white" : "fill-white/50"}`} />
    </div>
  ) : null;
};
