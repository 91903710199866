import { useEffect, useRef, useState } from "react";
import balanceIcon from "../../assets/icons/balance.svg";
import { moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";
import { StakingTimer } from "./StakingTimer";
import { ClickerGameTimerRef } from "./GameTimer";
import { calculateTimeRemaining } from "../../helpers/calculateTimeRemaining";
import { useSelector } from "react-redux";
import { clamp } from "lodash-es";
import Button from "../ui/Button";
import CogwheelRotate from "../ui/CogwheelRotate";
import ExchangeSummary from "../ExchangesSummery";
import AnimatedCounter from "../ui/AnimatedCounter";
import {
  finishStakingSelector,
  isDemoSelector,
  rewardFromStackingSelector,
  startStakingSelector,
} from "../../store/clicker/selectors";
import { useAppDispatch } from "../../store/hooks";
import { fetchBalanceAction } from "../../store/account/actions/fetchBalanceAction";
import { saveStakingRewardAction } from "../../store/clicker/actions/saveStakingRewardAction";
import { setProgressStakingReward } from "../../store/clicker/clickerReducer";
import { adNextAvailableTimeClickerSelector, exchangeSelector } from "../../store/account/account";
import { ExternalImage } from "../ui/ExternalImage";
import TadsWidgetTask from "../Tasks/TadsWidgetTask";
import { resetDailyQuestDataAction } from "../../store/clicker/actions/resetDailyQuestDataAction";
import { OnboardingTour } from "./OnboardingTour";
import OnboardingSteps, { OnboardingStep } from "../Onboarding/OnboardingSteps";
import { setOnboardingStep } from "../../store/onboarding/onboardingReducer";
import { useNavigate } from "react-router-dom";
import { isOnboardingFinishedSelector } from "../../store/onboarding/selectors";
import { completeDemoPortfolio } from "../../api/clicker/completeDemoPortfolio";
import { DataLayerEventNames, useDataLayer } from "../../hooks/useDataLayer";

const ADSGRAM_BLOCK_ID = import.meta.env.VITE_TADSWIDGET_BLOCK_ID;
const ADSGRAM_BLOCK_ID2 = import.meta.env.VITE_TADSWIDGET_BLOCK_ID2;

const StakingComponent = () => {
  const finishStaking = useSelector(finishStakingSelector);
  const exchange = useSelector(exchangeSelector);
  const isDemo = useSelector(isDemoSelector);

  const [isPending, setIsPending] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const adNextAvailable = useSelector(adNextAvailableTimeClickerSelector);

  const [isAdsActive, setIsAdsActive] = useState<boolean>(
    adNextAvailable === null || new Date(adNextAvailable) < new Date(),
  );

  useEffect(() => {
    setIsAdsActive(adNextAvailable === null || new Date(adNextAvailable) < new Date());
  }, [adNextAvailable]);

  const summaryRewardForStaking = useSelector(rewardFromStackingSelector) || 0;

  const [isStakingActivated, setIsStakingActivated] = useState(finishStaking && finishStaking > new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setIsStakingActivated(finishStaking && finishStaking > new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [finishStaking]);

  const handleClaim = async () => {
    setIsPending(true);

    try {
      if (isDemo) {
        await completeDemoPortfolio();
      }

      let result = await dispatch(saveStakingRewardAction());

      if (saveStakingRewardAction.fulfilled.match(result)) {
        if (result.payload.quest.portfolios[0].coins.length < 5) {
          let resultResetDailyQuest;
          do {
            resultResetDailyQuest = await dispatch(resetDailyQuestDataAction());
          } while (
            resetDailyQuestDataAction.fulfilled.match(resultResetDailyQuest) &&
            resultResetDailyQuest.payload.quest.portfolios[0].coins.length < 5
          );
        }
      }

      await new Promise((resolve, reject) => {
        dispatch(fetchBalanceAction())
          .then((result) => {
            fetchBalanceAction.fulfilled.match(result) ? resolve(result) : reject(result);
          })
          .catch(reject);
      });
    } catch (error) {
      console.error("Error in handleClaim:", error);
    } finally {
      setIsPending(false);
    }
  };
  const randomShow = Math.random() >= 0.5;

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="w-full text-center">
        <p className="text-center font-bold text-[20px]  pt-[1vh] pb-2">
          Staking{" "}
          {isStakingActivated ? (
            <span className="text-green">In progress </span>
          ) : (
            <span className="text-[#FFD600]">finished </span>
          )}
        </p>
        <div className="flex justify-center  pb-[2vh]">
          <ExchangeSummary />
        </div>
        {isStakingActivated ? (
          <div>
            <StakingActivated />
            {isAdsActive && randomShow ? (
              <div className="absolute bottom-[100px] w-full">
                <TadsWidgetTask pageName="clicker" blockId={ADSGRAM_BLOCK_ID} reward={true} />
              </div>
            ) : (
              <div className="absolute bottom-[100px] w-full">
                <TadsWidgetTask pageName="clicker" blockId={ADSGRAM_BLOCK_ID2} reward={false} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div className="relative flex gap-[2vw] justify-center items-start">
              <CogwheelRotate width="14.5vh" height="14.5vh" />
              <div className="flex flex-col items-start gap-[7vh] pt-1">
                <CogwheelRotate width="8.5vh" height="8.5vh" />
              </div>
              {/* <div className="absolute left-1/2 transform -translate-x-1/2 h-[12vh] w-[12vh] ">
                <img className="h-full w-full object-contain" src={stackCoinsIcon} alt="stack-coins" />
              </div> */}
              {exchange?.iconUrl3 && (
                <div className="absolute inset-0 flex justify-center items-center">
                  <ExternalImage
                    src={exchange.iconUrl3}
                    alt={exchange?.title}
                    className="w-[12vh] h-[12vh] object-contain rounded-full "
                    style={{ boxShadow: "0 0 32px 0 #d16eff" }}
                  />
                </div>
              )}
            </div>
            <div className="relative z-10 flex flex-col gap-3 pt-[12px] w-full">
              <p className="font-bold text-[20px] text-white/75 leading-[24px]">
                The exchange has started
                <br /> working for you
              </p>
              <strong
                className="flex justify-center items-center gap-x-[0.1em] text-[#FFD600] "
                style={{
                  fontSize: clamp(window.innerHeight * 0.043, 20, 36),
                }}
              >
                <span className="size-[26px]">
                  <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
                </span>
                <AnimatedCounter from={0} to={summaryRewardForStaking} toFixed={2} duration={1} />
              </strong>
              <p className="pb-[0.5vh] text-white/70 text-[16px] font-bold">00h 00m</p>
              <Button
                text={`Thank you, ${exchange?.title} ❤️`}
                isPending={isPending}
                style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
                className={`text-[14px] py-[1.14em] disabled:!bg-lightGrey}`}
                handleClick={handleClaim}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const StakingActivated = () => {
  const dispatch = useAppDispatch();

  const startDate = useSelector(startStakingSelector);
  const endDate = useSelector(finishStakingSelector);

  // const profitPerHour = useSelector(profitPerHourSelector);
  const startTimestamp = startDate ? startDate.getTime() : 0;
  const endTimestamp = endDate ? endDate.getTime() : 0;

  const durationInMilliseconds = endTimestamp - startTimestamp;

  const durationInHours = durationInMilliseconds / 3600000;
  const summaryRewardForStaking = useSelector(rewardFromStackingSelector) || 0;
  // const rewardFromStacking = useSelector(rewardFromStackingSelector);

  const navigate = useNavigate();
  const isDemo = useSelector(isDemoSelector);
  const isOnboardingFinished = useSelector(isOnboardingFinishedSelector);

  const { trackEvent } = useDataLayer();

  const resultPerSecond = summaryRewardForStaking / (durationInHours * 3600);

  const [timeRemaining, setTimeRemaining] = useState(() => (endDate ? calculateTimeRemaining(endDate) : -1));

  useEffect(() => {
    endDate && setTimeRemaining(calculateTimeRemaining(endDate));
  }, [endDate]);

  const [totalEarned, setTotalEarned] = useState(((new Date().getTime() - startTimestamp) / 1000) * resultPerSecond);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalEarned((prevEarning) => {
        const result = prevEarning + resultPerSecond;
        dispatch(setProgressStakingReward(result));
        return result;
      });
      console.log("totalEarned + resultPerSecond", totalEarned + resultPerSecond);
    }, 1000);

    return () => clearInterval(interval);
  }, [resultPerSecond]);

  const timerRef = useRef<ClickerGameTimerRef>(null);

  return (
    <div className="relative grow flex justify-center">
      <CogwheelRotate width="40vh" height="40vh" />
      <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col items-center">
        <p
          className=" font-bold text-white/70 pb-[1.5vh]"
          style={{
            fontSize: clamp(window.innerHeight * 0.029, 12, 20),
          }}
        >
          Earning
        </p>
        <strong
          className="flex justify-center items-center gap-x-[0.1em] text-[#FFD600] pb-[2vh]"
          style={{
            fontSize: clamp(window.innerHeight * 0.035, 12, 20),
          }}
        >
          +
          <span className="size-[19px]">
            <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
          </span>
          {moneyFormatWithTwoDigits(totalEarned)}
        </strong>
        <StakingTimer ref={timerRef} remainingTime={timeRemaining} />
      </div>
      {isDemo && !isOnboardingFinished ? (
        <OnboardingTour
          steps={[
            {
              target: "#skills",
              content: <OnboardingSteps step={OnboardingStep.Skills} />,
              placement: "top-start",
            },
          ]}
          trackEvent={() => trackEvent(DataLayerEventNames.skillsTipPassed)}
          onEnd={() => {
            dispatch(setOnboardingStep(2));
            navigate("/cards");
          }}
        />
      ) : null}
    </div>
  );
};

export default StakingComponent;
