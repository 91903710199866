import { payoutsTable } from "../../mock/payoutsTable";
import { Round } from "../../store/round/api/types";

const PayoutsTable = ({ round }: { round: Round | undefined }) => {
  const numberOfRockets: number = round?.rockets.length || 0;
  return (
    <div className="payouts-table">
      <h3 className="text-center text-xs tracking-[0.02em] font-bold mb-[11px]">
        There are now {numberOfRockets} rockets in the round
      </h3>
      <table className="w-full border-collapse text-center">
        <thead className="text-[11px] leading-[11px] font-semibold text-[#FFFFFF61] border-red">
          <tr>
            <th className="border-solid w-[50%] font-semibold border-white/5 border-b  text-center p-[6px]">Rockets</th>
            <th className="border-solid w-[50%] font-semibold border-white/5 border-b  p-[6px]">Number of Prizes</th>
          </tr>
        </thead>
        <tbody className={`text-[14px] tracking-[0.02em] font-bold`}>
          {payoutsTable.map((payout, index) => (
            <tr
              key={index}
              className={`${payout.min <= numberOfRockets && numberOfRockets <= payout.max ? "text-[#FFAE34]" : ""}`}
            >
              <td className="border-solid w-[50%] border-white/5 border-r border-b p-2">
                {payout.min} ~ {payout.max}
              </td>
              <td className="border-solid w-[50%] border-white/5 border-b p-2">{payout.numberOFPrizes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PayoutsTable;
