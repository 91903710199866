import { RootState } from "../store.ts";

export const dailyQuestSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest;
export const activePortfolioIdSelector = ({ clicker: { activePortfolio } }: RootState) => activePortfolio?.portfolio.id;
export const activePortfolioSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.portfolios[0];
export const portfoliosCountSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.portfolios.length;
export const portfolioCapacitySelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.portfolioCapacity || 0;
export const coinCapacitySelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.coinCapacity || 0;
export const activeGameCoinsSelector = ({ clicker: { activeGameCoins } }: RootState) => activeGameCoins;
export const isGameActiveSelector = ({ clicker: { isGameActive } }: RootState) => isGameActive;
export const activeGameSelector = ({ clicker: { activeGame } }: RootState) => activeGame;
export const nextGameMilestoneTimeSelector = ({ clicker }: RootState) => {
  return clicker.isGameActive
    ? clicker.activePortfolio?.portfolio?.activeTo
    : clicker.activePortfolio?.portfolio?.activeFrom || clicker.nextQuestStart;
};

export const pendingSelector = ({ clicker: { pending } }: RootState) => pending;

export const isDemoSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.isDemo;
// export const profitPerHourSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.profitPerHour;
export const rewardFromStackingSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.rewardFromStacking;
export const progressStakingRewardSelector = ({ clicker: { progressStakingReward } }: RootState) =>
  progressStakingReward;

export const clickCostSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.clickCost;
export const coinsSelector = ({ clicker: { coins } }: RootState) => coins;
export const startStakingSelector = ({ clicker: { dailyQuest } }: RootState) => dailyQuest?.portfolios[0].startStaking;
export const finishStakingSelector = ({ clicker: { dailyQuest } }: RootState) =>
  dailyQuest?.portfolios[0].finishStaking;
