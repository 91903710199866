import { BottomSheet } from "../../ui/BottomSheet";
import purchaseCoinsIcon from "../../../assets/icons/purchase-coins.png";
import arrowIcon from "../../../assets/icons/arrow-purchase-card.svg";
import arrowUpIcon from "../../../assets/icons/arrow-up-purchase-card.svg";

import CurrencyDisplay from "../../Common/CurrencyDisplay";
import { ExternalImage } from "../../ui/ExternalImage";
import { useSelector } from "react-redux";
import {
  closePopUpForCard,
  isCardInPurchasedCards,
  popUpForCardSelector,
  purchasedCardsSelector,
} from "../../../store/cards/cardsReducer";
import PurchaseButtonGroup from "./PurchaseButtonGroup";
import { calculateCardPrice, calculateIncomePerHour } from "../../../helpers/cardsCalculated";
import { useAppDispatch } from "../../../store/hooks";
import { memo } from "react";

const PurchaseCard = memo(() => {
  const popUpForCard = useSelector(popUpForCardSelector);
  const purchasedCards = useSelector(purchasedCardsSelector);

  const dispatch = useAppDispatch();

  const { card, isOpened, executedRule, isActiveCard, typeOfRule } = popUpForCard!;
  if (!popUpForCard || !popUpForCard.card) {
    return null;
  }

  const { name, description, img } = card!;

  const isCurrentCard = isCardInPurchasedCards(purchasedCards, card!.key);

  const level = purchasedCards.find((purchasedCard) => purchasedCard.key === card!.key)?.level || 0;
  const price = level > 0 ? calculateCardPrice(card!.startCost, level) : card!.startCost;
  const incomePerHour = level > 0 ? calculateIncomePerHour(card!.startProfit, level) : card!.startProfit;

  const bonusForCurrentCard = isCurrentCard ? incomePerHour - isCurrentCard.bonus : incomePerHour;
  return (
    <div className="flex flex-col justify-center leading-none">
      <BottomSheet isOpened={isOpened} onClose={() => dispatch(closePopUpForCard())}>
        <div className="absolute top-[-6vh]  left-1/2 -translate-x-1/2 ">
          <div className="w-[24vw] aspect-square relative  border-solid border-[1px] border-[#4E335E] rounded-xl">
            <ExternalImage src={img} className="size-full object-cover  rounded-xl" alt={name} />
            {/* <img src="/combo-card.png" alt="" className="size-full object-cover  rounded-xl" /> */}
          </div>
        </div>
        <div className="text-center pt-[2vh] leading-none">
          <div className="mb-6  px-[10vw] flex flex-col justify-center items-center">
            <h1 className="text-[24px] font-bold mb-3 leading-none">{name}</h1>
            <p className="text-[16px]  mb-5 px-[6vw] leading-[20px]">{description}</p>
            <div className=" w-full h-[64px] relative flex justify-center items-center">
              <img
                className="size-full object-cover w-[130px] h-[50px] z-10"
                loading="lazy"
                src={purchaseCoinsIcon}
                alt="purchaseCoinsIcon"
              />
              <div className="absolute h-[64px] w-[64px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#eece2b] blur-[30px]"></div>
            </div>
            <p className="text-[16px] pt-3 pb-2">Profit per hour</p>
            {isCurrentCard ? (
              <>
                <div className="flex gap-2 items-center justify-center py-2 px-[22px] bg-[#6c3f88] rounded-xl mb-2">
                  <div className="w-[22px] h-[20px]">
                    <img
                      className="size-full  object-contain "
                      loading="lazy"
                      src={arrowUpIcon}
                      alt="purchaseCoinsIcon"
                    />
                  </div>
                  <CurrencyDisplay
                    value={bonusForCurrentCard}
                    textStyle="text-[32px] text-[#FFD600] gap-2"
                    iconStyle="size-[26px]"
                    gap="gap-2"
                  />
                </div>
                <div className="flex items-center justify-center gap-2">
                  <CurrencyDisplay
                    value={isCurrentCard.bonus}
                    textStyle="text-[18px] opacity-50"
                    iconStyle="size-[17px] opacity-50"
                  />
                  <img
                    className="size-full object-cover w-[38px] "
                    loading="lazy"
                    src={arrowIcon}
                    alt="purchaseCoinsIcon"
                  />
                  <CurrencyDisplay value={incomePerHour} textStyle="text-[24px] text-white" iconStyle="size-[20px]" />
                </div>
              </>
            ) : (
              <CurrencyDisplay
                value={bonusForCurrentCard}
                textStyle="text-[32px] text-[#FFD600]"
                iconStyle="size-[26px]"
              />
            )}
          </div>
          <PurchaseButtonGroup
            card={card!}
            executedRule={executedRule!}
            incomePerHour={incomePerHour}
            isActiveCard={isActiveCard!}
            price={price}
            typeOfRule={typeOfRule!}
          />
        </div>
      </BottomSheet>
    </div>
  );
});

export default PurchaseCard;
