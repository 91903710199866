function isIsoDateString<T>(value: T): boolean {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/;
  return typeof value === "string" && isoDateRegex.test(value);
}

export function recursiveConvertDates<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(recursiveConvertDates) as unknown as T;
  } else if (obj !== null && typeof obj === "object") {
    const newObj: Record<string, unknown> = {};
    for (const key in obj) {
      const value = (obj as Record<string, unknown>)[key];
      if (isIsoDateString(value)) {
        newObj[key] = new Date(value as string | number | Date);
      } else {
        newObj[key] = recursiveConvertDates(value);
      }
    }
    return newObj as T;
  }
  return obj;
}
