import { Coin } from "../../store/rocket/api/types";
import CoinItem from "./CoinItem";
import CoinSubTitle from "./CoinSubTitle";
import coins from "./config/coins";

const CoinList: React.FC = () => {
  return (
    <div>
      <CoinSubTitle />
      {coins.map((coin: Coin, index: number) => (
        <CoinItem key={index} coin={coin} />
      ))}
    </div>
  );
};

export default CoinList;
