import { useEffect } from "react";
import Title from "../../../ui/Title";
import TransactionItem from "./TransactionItem";
import { useAppDispatch } from "../../../../store/hooks";
import { fetchMyTransactionListAction } from "../../../../store/token/actions/fetchMyTransactionListAction";
import { useSelector } from "react-redux";
import { myTransactionSelector } from "../../../../store/token/tokensReducer";

const TransactionList = () => {
  const dispatch = useAppDispatch();
  const myTransactions = useSelector(myTransactionSelector);

  useEffect(() => {
    dispatch(fetchMyTransactionListAction());
  }, []);

  return (
    <>
      {myTransactions && (
        <div className="pt-8">
          <Title text="Last Transactions" />
          <div className="flex flex-col gap-2">
            {myTransactions.map((transaction) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionList;
