import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { fetchBalanceAction } from "./actions/fetchBalanceAction.ts";
import { UserAnalyticsData, UserTappAds } from "../../api/user/types.ts";
import { fetchUserAnalyticsDataAction } from "./actions/fetchUserAnalyticsDataAction.ts";
import { fetchUserInfoAction } from "./actions/fetchUserInfoAction.ts";
import { fetchRewardForReferralsAction } from "./actions/fetchRewardForReferralsAction.ts";
import { Exchange } from "../exchanges/types.ts";
import { League } from "../leagues/api/types.ts";
import { fetchChangedExchangeAction } from "./actions/fetchChangedExchangeAction.ts";
import { fetchRewardForTaskAdsAction } from "./actions/fetchRewardForTaskAdsAction.ts";
import { putPurchasedCardAction } from "../cards/actions/putPurchasedCardAction.ts";
import { fetchInitialClickerDataAction } from "../clicker/actions/fetchInitialClickerDataAction.ts";
import { fetchCombosRewardAction } from "./actions/fetchCombosRewardAction.ts";
import { fetchServiceBonusAction } from "./actions/fetchServiceBonusAction.ts";
import { fetchRewardForOnboardingAction } from "./actions/fetchRewardForOnboardingAction.ts";
import { fetchAdTicketRewardAction } from "./actions/fetchAdTicketReward.ts";
import { fetchDailyRewardAction } from "./actions/fetchDailyReward.ts";
import { fetchUserProfileAction } from "./actions/fetchUserProfileAction.ts";
import { fetchTapTaskRewardAction } from "../cards/actions/fetchTapTaskReward.ts";
import { fetchUserTappAdsAction } from "./actions/fetchUserTappAdsAction.ts";
import { fetchRewardForNftAction } from "../cryptoProfile/actions/fetchRewardForNftAction.ts";

export interface AccountState {
  userId: number | null | string;
  tgId: number | null | string;
  balance: number;
  username: string | null;
  firstName: string | null;
  absoluteBalance: number;
  serviceBonus: number;
  profitPerHour: number;
  profitPerHourToStaking: number;
  totalBonusForCardsToStaking: number;
  rewardForReferrals: number;
  pending: boolean;
  exchange: Exchange | null;
  league: League | null;
  error: string | null;
  analyticsData: null | UserAnalyticsData;
  photo: string;
  adNextAvailableTimeTasks: Date | null;
  adNextAvailableTimeClicker: Date | null;
  dailyStreak: number;
  lastDailyRewardDate: Date | null;
  adNextAvailableTimeTwenty: Date | null;
  adTwentyCount: number;
  adTwentyRewardTaken: boolean;
  userTappAds: UserTappAds[];
  tapCooldown: string;
  adTapNextAvailableTimeDate: Date | null;
  curentDate: string;
}

const initialState: AccountState = {
  userId: null,
  tgId: null,
  balance: 0,
  username: null,
  firstName: null,
  absoluteBalance: 0,
  serviceBonus: 0,
  rewardForReferrals: 0,
  profitPerHour: 0,
  profitPerHourToStaking: 0,
  totalBonusForCardsToStaking: 0,
  photo: "",
  pending: false,
  exchange: null,
  league: null,
  error: null,
  analyticsData: null,
  adNextAvailableTimeTasks: null,
  adNextAvailableTimeClicker: null,
  dailyStreak: 0,
  lastDailyRewardDate: null,
  adNextAvailableTimeTwenty: null,
  adTwentyCount: 0,
  adTwentyRewardTaken: false,
  userTappAds: [],
  tapCooldown: "",
  adTapNextAvailableTimeDate: null,
  curentDate: "",
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setExchange(state, action: PayloadAction<Exchange>) {
      state.exchange = action.payload;
    },
    changeProfitPerHour(state, action: PayloadAction<number>) {
      state.profitPerHour += action.payload;
    },
    setTapCooldown(state, action: PayloadAction<string>) {
      state.tapCooldown = action.payload;
    },
    resetDailyStreak(state) {
      state.dailyStreak = 0;
    },
  },
  extraReducers(builder) {
    // Balance
    builder
      .addCase(fetchBalanceAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchBalanceAction.fulfilled, (state, action) => {
        state.pending = false;
        state.balance = action.payload.balance;
        state.absoluteBalance = action.payload.absoluteBalance;
        state.error = null;
      })
      //SERVISE BONUS
      .addCase(fetchServiceBonusAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchServiceBonusAction.fulfilled, (state, action) => {
        state.pending = false;
        state.balance = action.payload.balance;
        state.absoluteBalance = action.payload.absoluteBalance;
        state.error = null;
      })

      .addCase(fetchRewardForTaskAdsAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchRewardForTaskAdsAction.fulfilled, (state, action) => {
        state.pending = false;
        state.balance = action.payload.balance;
        state.absoluteBalance = action.payload.absoluteBalance;
        state.adNextAvailableTimeTasks = action.payload.adNextAvailableTime;
        state.adNextAvailableTimeClicker = action.payload.adNextAvailableTimeSecondary;
        state.adNextAvailableTimeTwenty = action.payload.adNextAvailableTimeTwenty;
        state.adTwentyCount = action.payload.adTwentyCount;
        state.adTwentyRewardTaken = action.payload.adTwentyRewardTaken;
        state.error = null;
      })

      .addCase(fetchRewardForReferralsAction.fulfilled, (state, action) => {
        state.pending = false;
        state.rewardForReferrals = action.payload.reward;
        state.error = null;
      })
      .addCase(fetchRewardForReferralsAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(fetchUserInfoAction.fulfilled, (state, action) => {
        state.pending = false;
        state.photo = action.payload.photo_url;
        state.exchange = action.payload.stoke;
        state.league = action.payload.league;
        state.adNextAvailableTimeTasks = action.payload.adNextAvailableTime;
        state.adNextAvailableTimeClicker = action.payload.adNextAvailableTimeSecondary;
        state.userId = action.payload.id;
        state.tgId = action.payload.tgChatId;
        state.balance = action.payload.balance;
        state.username = action.payload.username;
        state.firstName = action.payload.first_name;
        state.absoluteBalance = action.payload.absoluteBalance;
        state.serviceBonus = action.payload.serviceBonus;
        state.dailyStreak = action.payload.dailyStreak;
        state.lastDailyRewardDate = action.payload.lastDailyRewardDate;
        state.adNextAvailableTimeTwenty = action.payload.adNextAvailableTimeTwenty;
        state.adTwentyCount = action.payload.adTwentyCount;
        state.adTwentyRewardTaken = action.payload.adTwentyRewardTaken;
        state.curentDate = action.payload.curentDate;
        state.error = null;
      })

      .addCase(fetchUserInfoAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(fetchUserProfileAction.fulfilled, (state, action) => {
        state.pending = false;
        state.adTapNextAvailableTimeDate = action.payload.adTapNextAvailableTimeDate;
      })

      .addCase(fetchUserProfileAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchTapTaskRewardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.userTappAds = action.payload.updatedTappAds;
        state.adTapNextAvailableTimeDate = action.payload.adTapNextAvailableTimeDate;
      })

      .addCase(fetchTapTaskRewardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(fetchUserTappAdsAction.fulfilled, (state, action) => {
        state.pending = false;
        state.userTappAds = action.payload.tasks;
      })

      .addCase(fetchUserTappAdsAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(fetchChangedExchangeAction.fulfilled, (state) => {
        state.pending = false;
        state.error = null;
      })

      .addCase(fetchChangedExchangeAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(putPurchasedCardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.balance = action.payload.balance;
        state.absoluteBalance = action.payload.absoluteBalance;
      })

      .addCase(putPurchasedCardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchAdTicketRewardAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchAdTicketRewardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.adTwentyRewardTaken = action.payload.adTwentyRewardTaken;
        state.adTwentyCount = action.payload.adTwentyCount;
        state.adNextAvailableTimeTwenty = action.payload.adNextAvailableTimeTwenty;
        state.error = null;
      })
      .addCase(fetchDailyRewardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(fetchDailyRewardAction.fulfilled, (state, action) => {
        state.balance = action.payload.updatedBalance;
        state.absoluteBalance = action.payload.updatedAbsoluteBalance;
        state.error = null;
      });

    //COMBOS
    builder
      .addCase(fetchCombosRewardAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.balance = action.payload.balance ? action.payload.balance : state.balance;
        state.absoluteBalance = action.payload.absoluteBalance ? action.payload.absoluteBalance : state.absoluteBalance;
      })

      .addCase(fetchCombosRewardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });

    // Daily Quest
    builder.addCase(fetchInitialClickerDataAction.fulfilled, (state, action) => {
      if (action.payload.dailyQuest) {
        state.profitPerHour = action.payload.dailyQuest.quest.profitPerHour;
        state.profitPerHourToStaking = action.payload.dailyQuest.quest.profitPerHourToStaking;
        state.totalBonusForCardsToStaking = action.payload.dailyQuest.quest.totalBonusForCardsToStaking;
      }
    });

    // Analytics
    builder.addCase(fetchUserAnalyticsDataAction.fulfilled, (state, action) => {
      state.analyticsData = action.payload;
      state.error = null;
    });

    //ONBOARDING
    builder
      .addCase(fetchRewardForOnboardingAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchRewardForOnboardingAction.fulfilled, (state, action) => {
        state.pending = false;
        state.balance = action.payload.balance;
        state.absoluteBalance = action.payload.absoluteBalance;
        state.error = null;
      });

    //claim reward for nft
    builder
      .addCase(fetchRewardForNftAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchRewardForNftAction.fulfilled, (state, action) => {
        state.pending = false;

        const { coins } = action.payload;
        state.absoluteBalance += coins;
        state.balance += coins;
      });
  },
});

export const totalBonusForPurchasedCardsToStakingSelector = (state: RootState) =>
  state.account.totalBonusForCardsToStaking;
export const balanceSelector = (state: RootState) => state.account.balance;
export const absoluteBalanceSelector = (state: RootState) => state.account.absoluteBalance;
export const serviceBonusSelector = (state: RootState) => state.account.serviceBonus;
export const pendingSelectorAccount = (state: RootState) => state.account.pending;
export const userIdSelector = (state: RootState) => state.account.userId;
export const userNameSelector = (state: RootState) => state.account.username;
export const photoSelector = (state: RootState) => state.account.photo;
export const rewardForReferralsSelector = (state: RootState) => state.account.rewardForReferrals;
export const accountErrorsSelector = (state: RootState) => state.account.error;
export const exchangeSelector = (state: RootState) => state.account.exchange;
export const currentLeagueSelector = (state: RootState) => state.account.league;
export const adNextAvailableTimeSelector = (state: RootState) => state.account.adNextAvailableTimeTasks;
export const adNextAvailableTimeTwentySelecor = (state: RootState) => state.account.adNextAvailableTimeTwenty;
export const adTwentyCountSelecor = (state: RootState) => state.account.adTwentyCount;
export const adNextAvailableTimeClickerSelector = (state: RootState) => state.account.adNextAvailableTimeClicker;
export const profitPerHourSelector = (state: RootState) => state.account.profitPerHour;
export const profitPerHourToStakingSelector = (state: RootState) => state.account.profitPerHourToStaking;
export const adTwentyRewardTakenSelector = (state: RootState) => state.account.adTwentyRewardTaken;
export const dailyStreakSelector = (state: RootState) => state.account.dailyStreak;
export const lastDailyRewardDateSelector = (state: RootState) => state.account.lastDailyRewardDate;
export const tgIdSelector = (state: RootState) => state.account.tgId;
export const userTappAdsrSelector = (state: RootState) => state.account.userTappAds;
export const tapCooldownSelector = (state: RootState) => state.account.tapCooldown;
export const adTapDateSelector = (state: RootState) => state.account.adTapNextAvailableTimeDate;
export const curentDateSelector = (state: RootState) => state.account.curentDate;

export default accountSlice.reducer;

export const { setExchange, changeProfitPerHour, resetDailyStreak, setTapCooldown } = accountSlice.actions;
