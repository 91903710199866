import rocketIcon from "../../assets/icons/big-rocket.svg";
import userIcon from "../../assets/icons/user3.svg";
import chainIcon from "../../assets/icons/chain.svg";

const ProfileRocketImages = () => {
  return (
    <div className="relative flex items-center">
      <div className="bg-lightPurple rounded-[50%] w-[40px] h-[40px]  outline outline-2 outline-offset-2 outline-[#9531D0] gap-[15px] z-0">
        <img src={userIcon} alt="rocket" className="rounded-[50%]" />
      </div>
      <div className="relative w-[88px] h-[88px] flex justify-center items-center ml-2">
        <div className="absolute flex w-full h-full  bg-[#D9ABE1] opacity-25   rounded-[50%] justify-center items-center "></div>
        <img src={rocketIcon} alt="rocket" className="w-[64px] h-[64px] z-10" />
      </div>
      <img src={chainIcon} alt="chain" className="absolute w-6 h-6 left-[32px] opacity-100" />
    </div>
  );
};

export default ProfileRocketImages;
