import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveGameCoin,
  ClickAllowedRejectedReasonEnum,
  ClickAllowedStatus,
  Coin,
  DailyPortfolioQuest,
  Portfolio,
  PortfolioCoin,
} from "./types.ts";
import { cloneDeep } from "lodash-es";
import { PORTFOLIO_COINS_COUNT } from "../../constants/clicker.ts";
import { fetchInitialClickerDataAction } from "./actions/fetchInitialClickerDataAction.ts";
import { recursiveConvertDates } from "../../helpers/recursiveConvertDate.ts";
import { calculatePercent } from "../../helpers/calculatePercent.ts";
import Decimal from "decimal.js";
import { resetDailyQuestDataAction } from "./actions/resetDailyQuestDataAction.ts";
import { saveStakingRewardAction } from "./actions/saveStakingRewardAction.ts";

export interface ClickerState {
  pending: boolean;
  error: string | null;
  coins: Coin[];
  dailyQuest: DailyPortfolioQuest | null;
  session: {
    /**
     * Registered clicks
     */
    clicks: PortfolioCoin["id"][];
  };
  activePortfolio: { index: number; portfolio: Portfolio } | null;
  activeGameCoins: ActiveGameCoin[];
  isGameActive: boolean;
  activeGame: { portfolioId: Portfolio["id"]; untilDate: Date | null } | null;
  nextQuestStart: Date | null;
  progressStakingReward: number | null;
}

const initialState: ClickerState = {
  pending: false,
  error: null,

  /**
   * @description List of all available coins
   */
  coins: [],
  dailyQuest: null,
  session: {
    clicks: [],
  },
  activePortfolio: null,
  activeGameCoins: [],
  isGameActive: false,
  activeGame: null,
  nextQuestStart: null,
  progressStakingReward: null,
};

export const clickerSlice = createSlice({
  name: "clicker",
  initialState,
  reducers: {
    setProgressStakingReward(state, action: PayloadAction<number>) {
      state.progressStakingReward = action.payload;
    },
    registerCoinClick(state, action: PayloadAction<{ id: PortfolioCoin["id"] }>) {
      if (!state.dailyQuest) return;

      if (!state.activePortfolio?.portfolio) {
        console.log("registerCoinClick: Active portfolio is not found. Active portfolio index:");
      }

      const isCanBeClicked = isCoinCanBeClicked(action.payload.id, state.activePortfolio?.portfolio ?? null);

      // If the coin is in the active portfolio, save the click
      if (isCanBeClicked.isAllowed) {
        state.session.clicks.push(action.payload.id);

        const activePortfolio = state.activePortfolio;

        if (activePortfolio) {
          state.dailyQuest.portfolios.forEach((portfolio) => {
            if (portfolio.id === activePortfolio.portfolio.id) {
              const coins = portfolio.coins.map((coin) => {
                const coinProgress = new Decimal(coin.progress);

                const coinStep = new Decimal(
                  calculatePercent(state.dailyQuest!.clickCost, state.dailyQuest!.coinCapacity),
                );

                return {
                  ...coin,
                  progress:
                    coin.id === action.payload.id
                      ? coinProgress.plus(coinStep).clamp(0, 100).toNumber()
                      : coin.progress,
                };
              });

              const portfolioProgress = new Decimal(
                coins.reduce((acc, { progress }) => {
                  acc = acc.plus(new Decimal(progress));
                  return acc;
                }, new Decimal(0)),
              );

              portfolio.coins = coins;
              portfolio.progress = portfolioProgress.dividedBy(coins.length).toNumber();
            }
          });
        }
      }
    },

    revalidateActivePortfolio(state) {
      if (!state.dailyQuest) return;
      state.activePortfolio = getActivePortfolio(state.dailyQuest!);
    },

    setActiveGameCoins(state, action: PayloadAction<ActiveGameCoin[]>) {
      state.activeGameCoins = action.payload;
    },

    revalidateIsGameActive(state) {
      const activePortfolio = state.dailyQuest?.portfolios.find(({ id }) => id === state.activePortfolio?.portfolio.id);
      if (!activePortfolio) {
        state.isGameActive = false;
        return;
      }

      state.isGameActive = activePortfolio.progress < 100;
    },

    generateGameCoins(
      state,

      /**
       * Is onboarding mode finished
       */
      action: PayloadAction<boolean>,
    ) {
      // TODO: Reactor duplicate code
      const activePortfolioCoins = state.dailyQuest?.portfolios.find((portfolio) => {
        return portfolio.id === state.activePortfolio?.portfolio.id;
      })?.coins;
      if (!activePortfolioCoins?.length) {
        return;
      }

      const startingProces =
        state.dailyQuest?.portfolios.filter((portfolio) => {
          return portfolio.coins.filter((coin) => coin.progress != 0).length > 0;
        }) ?? [];

      if (state.dailyQuest?.isDemo && !action.payload && !(startingProces?.length > 0)) {
        const randomCoins = [];
        const portfolioCoin = activePortfolioCoins[1];

        for (let i = 0; i < PORTFOLIO_COINS_COUNT; i++) {
          const otherCoins = state.coins.filter(({ id }) => id !== portfolioCoin.originalCoinId);
          const randomCoinIndex = Math.floor(Math.random() * otherCoins.length);
          const draftFoundCoin = otherCoins[randomCoinIndex];
          randomCoins.push({
            ...draftFoundCoin,
            id:
              activePortfolioCoins.find(({ originalCoinId }) => originalCoinId === draftFoundCoin.id)?.id || `${i - 5}`,
            originalCoinId: draftFoundCoin.id,
          });
        }

        randomCoins.sort(() => Math.random() - 0.5);
        randomCoins[1] = {
          ...state.coins.find(({ id }) => id === portfolioCoin.originalCoinId)!,
          id: portfolioCoin.id,
          originalCoinId: portfolioCoin.originalCoinId,
        };

        state.activeGameCoins = randomCoins;
        return;
      }

      const notFilledCoins = activePortfolioCoins.filter(({ progress }) => progress < 100);

      if (!notFilledCoins.length) {
        state.activeGameCoins = [];
        return;
      }

      const allCoins = cloneDeep(state.coins);

      const randomActiveCoin = notFilledCoins[Math.floor(Math.random() * notFilledCoins.length)];

      const notSelectedCoins = cloneDeep(allCoins).filter(({ id }) => id !== randomActiveCoin?.originalCoinId);

      const randomCoins: ActiveGameCoin[] = [
        {
          ...allCoins.find(({ id }) => id === randomActiveCoin.originalCoinId)!,
          id: randomActiveCoin.id,
          originalCoinId: randomActiveCoin.originalCoinId,
        }, // At least one coin from active portfolio
      ];

      for (let i = 0; i < PORTFOLIO_COINS_COUNT - 1; i++) {
        const randomCoinIndex = Math.floor(Math.random() * notSelectedCoins.length);
        const draftFoundCoin = notSelectedCoins[randomCoinIndex];

        randomCoins.push({
          ...draftFoundCoin,
          id: activePortfolioCoins.find(({ originalCoinId }) => originalCoinId === draftFoundCoin.id)?.id || `${i - 5}`,
          originalCoinId: draftFoundCoin.id,
        });

        notSelectedCoins.splice(randomCoinIndex, 1); // Remove selected coin from the list to avoid duplicates
      }

      state.activeGameCoins = randomCoins.sort(() => Math.random() - 0.5);
    },

    setOnboardingDailyQuest(state, action: PayloadAction<DailyPortfolioQuest>) {
      state.dailyQuest = action.payload;
    },

    setOnboardingCoins(state, action: PayloadAction<Coin[]>) {
      state.coins = action.payload;
    },

    setActiveGame(state, action: PayloadAction<{ portfolioId: Portfolio["id"]; untilDate: Date | null }>) {
      const { portfolioId, untilDate } = action.payload;
      state.activeGame = { portfolioId: portfolioId, untilDate: untilDate };
    },
  },
  extraReducers(builder) {
    builder
      // FETCH INITIAL CLICKER DATA
      .addCase(fetchInitialClickerDataAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })

      .addCase(fetchInitialClickerDataAction.fulfilled, (state, action) => {
        if (!action.payload) return;
        const dailyQuest = action.payload.dailyQuest;

        if (dailyQuest) {
          const { quest, nextQuestStart } = recursiveConvertDates(dailyQuest);
          state.dailyQuest = processQuestData(quest);
          let activePortfolio = getActivePortfolio(state.dailyQuest!);

          state.activePortfolio = activePortfolio;
          state.isGameActive = activePortfolio ? activePortfolio?.portfolio.progress < 100 : false;

          state.nextQuestStart = nextQuestStart;
          state.coins = action.payload.coins;

          state.pending = false;
        } else {
          console.error("dailyQuest is undefined");
        }
      })

      .addCase(fetchInitialClickerDataAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      // RESET DAILY QUESTS
      .addCase(resetDailyQuestDataAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })

      .addCase(resetDailyQuestDataAction.fulfilled, (state, action) => {
        if (!action.payload) return;

        const { quest, nextQuestStart } = recursiveConvertDates(action.payload);

        state.dailyQuest = processQuestData(quest);
        let activePortfolio = getActivePortfolio(state.dailyQuest!);

        state.activePortfolio = activePortfolio;
        state.isGameActive = activePortfolio ? activePortfolio?.portfolio.progress < 100 : false;

        state.nextQuestStart = nextQuestStart;

        state.pending = false;
      })

      .addCase(resetDailyQuestDataAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      })

      //AFTER SAVE STAKING REWARD
      .addCase(saveStakingRewardAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })

      .addCase(saveStakingRewardAction.fulfilled, (state, action) => {
        if (!action.payload) return;

        const { quest, nextQuestStart } = recursiveConvertDates(action.payload);

        state.dailyQuest = processQuestData(quest);
        let activePortfolio = getActivePortfolio(state.dailyQuest!);

        state.activePortfolio = activePortfolio;
        state.isGameActive = activePortfolio ? activePortfolio?.portfolio.progress < 100 : false;

        state.nextQuestStart = nextQuestStart;

        state.pending = false;
      })

      .addCase(saveStakingRewardAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const {
  registerCoinClick,
  revalidateActivePortfolio,
  setActiveGameCoins,
  revalidateIsGameActive,
  generateGameCoins,
  setOnboardingDailyQuest,
  setOnboardingCoins,
  setActiveGame,
} = clickerSlice.actions;

export const { setProgressStakingReward } = clickerSlice.actions;

export default clickerSlice.reducer;

export function getActivePortfolio(dailyQuest: DailyPortfolioQuest) {
  const foundIndex = dailyQuest.portfolios.findIndex(({ progress }) => {
    return progress < 100;
  });

  return foundIndex !== -1
    ? {
        index: foundIndex,
        portfolio: dailyQuest.portfolios[foundIndex],
      }
    : null;
}

export function isCoinCanBeClicked(id: PortfolioCoin["id"], activePortfolio: Portfolio | null): ClickAllowedStatus {
  if (!activePortfolio?.coins) {
    console.log("isCoinCanBeClicked: Active portfolio coins are not found");

    return {
      isAllowed: false,
      rejectedReason: ClickAllowedRejectedReasonEnum.NO_ACTIVE_PORTFOLIO,
    };
  }

  const activePortfolioCoinsId = activePortfolio.coins.map((coin) => coin.id);
  const isInActivePortfolio = activePortfolioCoinsId.includes(id);

  if (!isInActivePortfolio) {
    return {
      isAllowed: false,
      rejectedReason: ClickAllowedRejectedReasonEnum.COIN_NOT_IN_PORTFOLIO,
    };
  }

  const isCoinFilled = (activePortfolio?.coins.find((coin) => coin.id === id)?.progress || 0) >= 100;

  if (isCoinFilled) {
    return {
      isAllowed: false,
      rejectedReason: ClickAllowedRejectedReasonEnum.COIN_PROGRESS_MAX,
    };
  }

  return {
    isAllowed: true,
    rejectedReason: null,
  };
}

export const processQuestData = (quest: DailyPortfolioQuest) => {
  let draftPortfolios = quest.portfolios;

  if (quest.isDemo) {
    draftPortfolios = draftPortfolios.map((portfolio) => ({
      ...portfolio,
      activeTo: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 2),
      activeFrom: new Date(),
      isDemoPortfolio: true,
    }));
  }

  return {
    ...quest,
    portfolios: draftPortfolios,
  };
};
