import { SetCoinsProgress } from "../../../api/clicker/types.ts";
import { patchClickProgress } from "../../../api/clicker/patchClickProgress.ts";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const saveClickProgressAction = createAsyncThunk(
  "clicker/saveClickProgress",
  async (progress: SetCoinsProgress) => {
    return patchClickProgress(progress);
  },
);
