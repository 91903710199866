import { createAsyncThunk } from "@reduxjs/toolkit";
import { createNote } from "../../../api/communityNotes/createNote";
import { CreateNote } from "../../../api/communityNotes/types";
import { Note } from "../api/types";
import { RootState } from "../../store";

export const createNoteAction = createAsyncThunk<Note, CreateNote, { state: RootState }>(
  "tokens/createNote",
  async (data: CreateNote, thunkAPI) => {
    const state = thunkAPI.getState();
    const first_name = state.account.firstName;
    const photo_url = state.account.photo;

    return createNote(data, first_name!, photo_url);
  },
);
