import { useEffect } from "react";
import { ReferralLink } from "../../components/Friends/ReferralLink";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import UserSummary from "../../components/Pumpadur/Profile/UserSummary";
import CreatedTokensList from "../../components/Pumpadur/Profile/CreatedTokensList";
import TransactionList from "../../components/Pumpadur/Profile/Transaction/TransactionList";
import { fetchMyNftsAction } from "../../store/nft/actions/fetchMyNftsAction";
import NftList from "../../components/Pumpadur/Profile/Nft/NftList";

const ProfilePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchMyNftsAction());
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="relative pb-[20px]">
      <UserSummary />
      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>

        <ReferralLink buttonText="Share" />
        <p className=" text-center w-full px-[10vw] font-medium text-[12px] text-lightestPurple">
          Share this link with friends to get more GAS XP points if they join and trade.
        </p>
      </div>
      <NftList />
      <CreatedTokensList />
      <TransactionList />
    </div>
  );
};

export default ProfilePage;
