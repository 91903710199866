import { Link, useParams } from "react-router-dom";
import { RocketCard } from "../../components/Rocket/Card";
import TopPanel from "../../components/TopPanel";
import Title from "../../components/ui/Title";

const PickRocketPage = () => {
  interface Rocket {
    name: string;
    rarity: string;
    status: string;
    time?: string;
    active?: boolean;
  }

  const rockets: Rocket[] = [
    {
      name: "Name of the rocket",
      rarity: "Legendary",
      status: "Playing",
      time: "15:23",
      active: false,
    },
    {
      name: "Name of the rocket",
      rarity: "Legendary",
      status: "Ready to Go",
      time: "15:23",
      active: false,
    },
    {
      name: "Name of the rocket",
      rarity: "Rare",
      status: "Registering",
      time: "15:23",
      active: false,
    },
    {
      name: "Name of the rocket",
      rarity: "Legendary",
      status: "Unpublished",
    },
    {
      name: "Name of the rocket",
      rarity: "Legendary",
      status: "Playing",
      time: "15:23",
    },
    {
      name: "Name of the rocket",
      rarity: "Legendary",
      status: "Ready to Go",
      time: "15:23",
    },
    {
      name: "Name of the rocket",
      rarity: "Rare",
      status: "Registering",
      time: "15:23",
    },
    {
      name: "Name of the rocket",
      rarity: "Legendary",
      status: "Unpublished",
    },
  ];

  const { roundId } = useParams<{ roundId: string }>();
  return (
    <div>
      <TopPanel page="buy" />
      <div className="pt-[25px] ">
        <Title text="Pick a rocket" />
        <div className="flex flex-wrap gap-2 pt-[18px] mb-[100px]">
          {rockets.map((rocket, index) =>
            rocket.active === false ? (
              <div className="w-[48.8%]" key={index}>
                <RocketCard key={index} rocket={rocket} />
              </div>
            ) : (
              <Link to={`/rounds/${roundId}/customRocket`} className="w-[48.8%]" key={index}>
                <RocketCard key={index} rocket={rocket} />
              </Link>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
export default PickRocketPage;
