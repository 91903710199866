import { useSelector } from "react-redux";
import { moneyFormat } from "../../helpers/moneyFormat";
import { GradientCard } from "../ui/GradientCard";
import { userInvitesSelector, userInvitesTier2Selector } from "../../store/friends/friendsReducer";

export function MyReferralsBlock() {
  const userInvites = useSelector(userInvitesSelector);
  const userInvitesTier2 = useSelector(userInvitesTier2Selector);

  const items = [
    {
      value: userInvites,
      subtitle: "Direct Referrals",
    },
    {
      value: userInvitesTier2,
      subtitle: "Lvl.2 Referrals",
    },
  ];
  return (
    <div>
      <h2 className="text-center text-[16px] font-semibold opacity-50 leading-[24px] mt-[23px] mb-4">My referrals</h2>
      <ul className={` grid grid-cols-2 gap-2`}>
        {items.map((item) => (
          <li key={item.subtitle}>
            <GradientCard backgroundColor="#28202C">
              <div className="flex-1 px-2 py-6 w-full flex flex-col gap-y-1 justify-center items-center leading-none">
                <p className="text-yellow text-[18px] font-bold">{moneyFormat(item.value | 0)}</p>
                <p className="text-[14px] font-medium">{item.subtitle}</p>
              </div>
            </GradientCard>
          </li>
        ))}
      </ul>
    </div>
  );
}
