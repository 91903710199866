import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button.tsx";
import { BottomSheet } from "../../ui/BottomSheet.tsx";
import { cardsSelector, dailyRewardCardKeySelector } from "../../../store/cards/cardsReducer.ts";
import { Card } from "../../../store/cards/api/types.ts";
import { ExternalImage } from "../../ui/ExternalImage.tsx";

export function PopupCardReward() {
  const rewardedCardKey = useSelector(dailyRewardCardKeySelector);
  const cards = useSelector(cardsSelector);
  const navigate = useNavigate();
  
  const [isOpened, setIsOpened] = useState(false);
  const [serviceBonusCard, setServiceBonusCard] = useState<Card | undefined>();

  const handleGetServiceBonus = () => {
    setIsOpened(false);
    navigate("/cards?type=Special");
  };

  useEffect(() => {
    const card = cards.find(card => card.key === rewardedCardKey);
    setServiceBonusCard(card);
    if (card) {
      setIsOpened(true);
    }
  }, [cards, rewardedCardKey]);

  if (!isOpened || !serviceBonusCard) {
    return null;
  }
  const { img, name } = serviceBonusCard;

  return (
    <div className="flex flex-col justify-center leading-none">
      <BottomSheet isOpened={isOpened} onClose={() => setIsOpened(false)} className="h-[308px] rounded-[24px]">
        <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-0">
          <ExternalImage src={img} alt={name} className="size-[93px] object-cover rounded-xl" />
        </div>
        <div className="text-center pt-3 leading-none mt-[16px]">
          <div className="mb-6">
            <h1 className="text-[24px] font-bold mb-6 leading-none">Special Card {name} Unlocked!</h1>
            <p className="text-[16px] leading-none mb-5">Check Skills menu to buy it</p>
          </div>
          <Button
            text={"CHECK skills menu"}
            handleClick={handleGetServiceBonus}
            style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          />
          <button
            onClick={() => setIsOpened(false)}
            className="mt-4 font-[var(--font-family)] font-bold text-[14px] leading-[114%] tracking-[0.02em] uppercase text-center text-[var(--hamstercard.pro-white-mine-shaft)] cursor-pointer"
          >
            Skip
          </button>
        </div>
      </BottomSheet>
    </div>
  );
}
