import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GlobalState {
  overlay: boolean;
}

const initialState: GlobalState = {
  overlay: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    isVisibleOverlay: (state, action: PayloadAction<boolean>) => {
      state.overlay = action.payload;
    },
  },
});

export const { isVisibleOverlay } = globalSlice.actions;

export default globalSlice.reducer;
