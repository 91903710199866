import { useSelector } from "react-redux";
import { GradientCard } from "../ui/GradientCard";
import {
  cryptoProfileBalanceSelector,
  cryptoProfilePublicKeySelector,
  isActiveWalletSelector,
} from "../../store/cryptoProfile/cryptoProfileReducer";
import { userIdSelector, userNameSelector } from "../../store/account/account";

import chevronRightIcon from "../../assets/icons/chevron-right.svg";
import { redirectToWalletManagement } from "../../helpers/redirectToWalletManagement";
import { FC } from "react";
import { moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";

interface UserWalletInfoProprs {
  showBalance?: boolean;
}

const UserWalletInfo: FC<UserWalletInfoProprs> = ({ showBalance = false }) => {
  const phantomWalletPublicKey = useSelector(cryptoProfilePublicKeySelector);
  const phantomWalletBalance = useSelector(cryptoProfileBalanceSelector);
  const userName = useSelector(userNameSelector);
  const userId = useSelector(userIdSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);

  return (
    <div className="w-full">
      <GradientCard backgroundColor="#28202C">
        <div className="px-4" onClick={redirectToWalletManagement}>
          <div className={` ${isActiveWallet ? "border-solid border-b-[1px]  border-[#fff]/10 pb-3" : ""} py-3`}>
            <p className="text-[12px] text-white/50">{showBalance ? "Balance" : "Username"}</p>
            <p className="font-semibold">
              {showBalance
                ? `${moneyFormatWithTwoDigits(+phantomWalletBalance)} SOL`
                : `@${userName ? userName : `user${userId}`}`}
            </p>
          </div>
          {isActiveWallet && (
            <div className="flex justify-between items-center py-3">
              <div>
                <p className="text-[12px] text-white/50">Connected Wallet</p>
                <p className="font-semibold">{shortenKey(phantomWalletPublicKey)}</p>
              </div>
              <div className="block mr-1">
                <img src={chevronRightIcon} alt="Right arrow" />
              </div>
            </div>
          )}
        </div>
      </GradientCard>
    </div>
  );
};

export default UserWalletInfo;

function shortenKey(key: string): string {
  if (key.length <= 10) return key;
  const start = key.slice(0, 6);
  const end = key.slice(-6);
  return `${start}...${end}`;
}
