import { useSelector } from "react-redux";
import { leaguesSelector } from "../store/leaders/leaguesReducer.ts";
import balanceIcon from "../assets/icons/balance.svg";
import { nFormatter } from "../helpers/moneyFormat.ts";
import { absoluteBalanceSelector, currentLeagueSelector } from "../store/account/account.tsx";
import ProgressLine from "../components/League/ProgressLine.tsx";
import LeadersList from "../components/League/LeadersList.tsx";
import { useEffect, useState } from "react";
import { League } from "../store/leagues/api/types.ts";
import { useAppDispatch } from "../store/hooks.ts";
import { fetchLeaguesListAction } from "../store/leagues/actions/fetchLeaguesListAction.ts";
import { fetchUsersInLeagueAction } from "../store/leagues/actions/fetchUsersInLeagueAction.ts";
// import {  isPendingSelector } from "../store/leagues/leaguesReducer.ts";
import LeagueHeader from "../components/League/LeagueHeader.tsx";
import { clearUsers } from "../store/leagues/leaguesReducer.ts";
// import { PreloaderCircle } from "../components/ui/PreloaderCircle.tsx";

export function LeadersPage() {
  const leagues = useSelector(leaguesSelector);
  const balance = useSelector(absoluteBalanceSelector);
  // const isPending = useSelector(isPendingSelector);

  const currentLeague = useSelector(currentLeagueSelector);
  const dispatch = useAppDispatch();

  const [league, setLeague] = useState<League | null>(
    leagues.find((league) => league.id === currentLeague?.id) || null,
  );

  const currentLeagueProgress = league ? (balance * 100) / (league.threshold || 0) : 0;
  const prevLastLeague = leagues[leagues.length - 2];
  const lastLeague = leagues[leagues.length - 1];
  const isLastLeague = league?.id === lastLeague.id;

  const handleChangeLeague = (id: number) => {
    const newLeague = leagues.find((league) => league.id === id);
    if (newLeague) {
      setLeague(newLeague);
    }
  };

  useEffect(() => {
    setLeague(leagues.find((league) => league.id === currentLeague?.id) || null);
  }, [leagues]);

  useEffect(() => {
    dispatch(fetchLeaguesListAction());

    dispatch(fetchUsersInLeagueAction({ count: 20, offset: 0 }));
  }, []);

  useEffect(() => {
    dispatch(clearUsers());
    dispatch(fetchUsersInLeagueAction({ count: 20, offset: 0, league: league?.id }));
  }, [league]);

  return (
    <>
      {" "}
      {league && (
        <>
          <div className="px-[20px]">
            <div className="flex gap-2 justify-center">
              <LeagueHeader league={league} handleChangeLeague={handleChangeLeague} />
            </div>

            <span className="font-bold text-[20px] block text-center mt-2 mb-[10px]">{league.title} League</span>
            <div className="flex gap-[2px] justify-center">
              <img src={balanceIcon} className="w-[19px] h-[19px]" />
              <span className="text-[16px] text-yellow">
                {nFormatter(balance)}/
                {isLastLeague && lastLeague.title === "Billion"
                  ? `${nFormatter(prevLastLeague.threshold)}+`
                  : nFormatter(league.threshold)}
              </span>
            </div>

            <ProgressLine progress={currentLeagueProgress} />
          </div>

          <LeadersList league={league} />
        </>
      )}
    </>
  );
}
