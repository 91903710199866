interface GradientBorderProps {
  children: React.ReactNode;
  gradient: string;
  backgroundColor?: string;
  className?: string;
  roundedClass?: string;
}

export function GradientBorder({
  children,
  roundedClass,
  backgroundColor = "#000",
  gradient,
  className,
}: GradientBorderProps) {
  return (
    <div
      className={`bg-origin-padding relative ${className} ${roundedClass}`}
      style={{
        background: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundImage: gradient,
      }}
    >
      <div
        className="bg-clip-text"
        style={{
          backgroundImage: gradient,
          backgroundRepeat: "no-repeat",
        }}
      />

      <span
        className={`
                absolute
                top-[1px]
                left-[1px]
                w-[calc(100%-2px)]
                h-[calc(100%-2px)]
                ${roundedClass}
            `}
        style={{
          backgroundColor,
        }}
      />

      <div className="relative h-full w-full">{children}</div>
    </div>
  );
}
