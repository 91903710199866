// import { useSelector } from "react-redux";
// import { nftsConditionsSelector } from "../../../../store/cryptoProfile/cryptoProfileReducer";
// import { moneyFormat } from "../../../../helpers/moneyFormat";

import ticketIcon from "../../../../assets/icons/ticket.svg";
import CurrencyDisplay from "../../../Common/CurrencyDisplay";

const NftsConditions = () => {
  // const nftsConditions = useSelector(nftsConditionsSelector);
  // const { discountForMainNFTSale, discountOnNFTPresale, forInGameBalance, forUnlockPassiveInGameIncome } =
  //   nftsConditions!;
  return (
    <div className="pt-[3vh] px-[6vw]">
      <ul className="grid grid-cols-2 gap-[10.5px]">
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold inline-block">
                <strong className="font-bold leading-none text-yellow">50%</strong>
              </div>
            </div>
            <p className="font-medium text-center text-sm leading-4">discount on NFT presale</p>
          </div>
        </li>
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold inline-block">
                <strong className="font-bold leading-none text-yellow">50%</strong>
              </div>
            </div>
            <p className="font-medium text-center text-sm leading-4">discount for main NFT sale</p>
          </div>
        </li>
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              {/* <div className="leading-none font-bold inline-block">
                <strong className="font-bold leading-none text-yellow">{moneyFormat(100000)}</strong>
              </div> */}
              <CurrencyDisplay textStyle="text-[16px] text-yellow" value={100000} />
            </div>
            <p className="font-medium text-center text-sm leading-4">for in-game balance</p>
          </div>
        </li>
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold flex gap-[2px]">
                <strong className="text-[#FF80F7] font-bold leading-none">+15</strong>
                <img src={ticketIcon} className="w-[14px] h-auto  inline-block" />
              </div>
            </div>
            <p className="font-medium leading-[13px] text-center text-[12px]">
              for unlock passive
              <br /> in-game income
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NftsConditions;
