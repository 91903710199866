import { useAppDispatch } from "../../store/hooks.ts";
import { useSelector } from "react-redux";
// import { revalidateActivePortfolio, revalidateIsGameActive } from "../../store/clicker/clickerReducer.tsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  activePortfolioIdSelector,
  dailyQuestSelector,
  // isDemoSelector,
  // nextGameMilestoneTimeSelector,
} from "../../store/clicker/selectors.ts";
// import { useWatch } from "../../hooks/usePreviousValue.ts";
import ReactDOM from "react-dom";
import { ClaimRewardScreen } from "./ClaimRewardScreen.tsx";
import { Portfolio } from "../../store/clicker/types.ts";
import { fetchBalanceAction } from "../../store/account/actions/fetchBalanceAction.ts";
import { fetchInitialClickerDataAction } from "../../store/clicker/actions/fetchInitialClickerDataAction.ts";
import { DataLayerEventNames, useDataLayer } from "../../hooks/useDataLayer.ts";
// import { completeDemoPortfolio } from "../../api/clicker/completeDemoPortfolio.ts";
import * as Sentry from "@sentry/react";
import { useToastNotifications } from "../../hooks/useToastNotifications.ts";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer.ts";
import { setOnboardingStep } from "../../store/onboarding/onboardingReducer.ts";
import { fetchRewardForOnboardingAction } from "../../store/account/actions/fetchRewardForOnboardingAction.ts";
// import { useNavigate } from "react-router-dom";

export function ActivePortfolioRevalidateBackground() {
  const dispatch = useAppDispatch();
  const { trackEvent } = useDataLayer();

  // const navigate = useNavigate();
  const dailyQuest = useSelector(dailyQuestSelector);
  // const isDemo = useSelector(isDemoSelector);
  const activePortfolioId = useSelector(activePortfolioIdSelector);
  // const nextMilestoneStartAt = useSelector(nextGameMilestoneTimeSelector);

  const [isClaimPending, setIsClaimPending] = useState(() => false);

  const { showNotification } = useToastNotifications();

  const activePortfolio = useMemo(() => {
    return dailyQuest?.portfolios.find(({ id }) => id === activePortfolioId);
  }, [activePortfolioId, dailyQuest?.portfolios]);

  // const isActivePortfolioCompleted = useMemo(() => {
  //   return (activePortfolio?.progress || 0) >= 100;
  // }, [activePortfolio?.progress]);

  const [portfolioToClaim, setPortfolioToClaim] = useState<{
    portfolio: Portfolio;
    index: number;
  } | null>(null);

  useEffect(() => {
    const isPortfolioCompleted = activePortfolio && activePortfolio.progress >= 100;

    if (isPortfolioCompleted) {
      setPortfolioToClaim({
        portfolio: activePortfolio,
        index: dailyQuest!.portfolios.findIndex(({ id }) => id === activePortfolio.id),
      });
    }
  }, [activePortfolio?.progress]);

  // console.log("portfolioToClaim", portfolioToClaim);

  // useWatch(activePortfolio, (_, prevPortfolio) => {
  //   const isPrevPortfolioCompleted = prevPortfolio && prevPortfolio.progress >= 100;

  //   if (isPrevPortfolioCompleted) {
  //     setPortfolioToClaim({
  //       portfolio: prevPortfolio,
  //       index: dailyQuest!.portfolios.findIndex(({ id }) => id === prevPortfolio.id),
  //     });
  //   }
  // });

  // const store = useStore<RootState>();

  // const isInitial = useRef(true);

  // const nextGameTimeout = useRef(null as ReturnType<typeof setTimeout> | null);

  // useEffect(() => {
  //   dispatch(revalidateIsGameActive());

  //   if (!isInitial.current) {
  //     dispatch(revalidateActivePortfolio());
  //   }

  //   if (nextMilestoneStartAt) {
  //     if (nextGameTimeout.current) {
  //       clearTimeout(nextGameTimeout.current);
  //     }

  //     nextGameTimeout.current = setTimeout(
  //       () => {
  //         dispatch(revalidateIsGameActive());
  //       },
  //       new Date(nextMilestoneStartAt).getTime() - new Date().getTime(),
  //     );
  //   }

  //   isInitial.current = false;
  // }, [nextMilestoneStartAt, activePortfolioId, isActivePortfolioCompleted, dispatch]);

  // useEffect(() => {
  // let timeout: ReturnType<typeof setTimeout> | null = null;
  // function revalidateActivePortfolioRecursively() {
  //   dispatch(revalidateActivePortfolio());
  //   const {
  //     clicker: { activePortfolio },
  //   } = store.getState();
  //   if (activePortfolio) {
  //     const diff = activePortfolio.portfolio.activeTo.getTime() - new Date().getTime();
  //     timeout = setTimeout(() => {
  //       revalidateActivePortfolioRecursively();
  //       clearTimeout(timeout!);
  //     }, diff);
  //   }
  // }
  // if (!isDemo) {
  //   revalidateActivePortfolioRecursively();
  // }
  // return () => {
  // if (timeout) clearTimeout(timeout);
  // if (nextGameTimeout.current) {
  //   clearTimeout(nextGameTimeout.current);
  // }
  // };
  // }, [activePortfolioId, isDemo]);

  const isDemoAlreadyCompletedSuccessfully = useRef(false);

  const handlePortfolioClaim = useCallback(async () => {
    setIsClaimPending(true);

    if (portfolioToClaim?.portfolio.isDemoPortfolio) {
      try {
        if (!isDemoAlreadyCompletedSuccessfully.current) {
          // await completeDemoPortfolio();
          dispatch(fetchRewardForOnboardingAction());
          dispatch(setOnboardingStep(1));

          // Save to avoid multiple requests after user retry claim on server error on one of the next requests
          isDemoAlreadyCompletedSuccessfully.current = true;

          // Track event only if the demo portfolio was completed successfully
          trackEvent(DataLayerEventNames.demoPortfolioCompleted);
        }

        await Promise.all([
          new Promise((resolve, reject) => {
            dispatch(fetchBalanceAction())
              .then((result) => {
                fetchBalanceAction.fulfilled.match(result) ? resolve(result) : reject(result);
              })
              .catch(reject);
          }),
          new Promise((resolve, reject) => {
            dispatch(fetchInitialClickerDataAction())
              .then((result) =>
                fetchInitialClickerDataAction.fulfilled.match(result) ? resolve(result) : reject(result),
              )
              .catch(reject);
          }),
        ]);

        setPortfolioToClaim(null);
      } catch (e) {
        Sentry.setTag("DEMO COMPLETION ERROR", true);
        Sentry.captureException(e);

        showNotification({
          type: ToastNotificationType.Error,
          title: "Something went wrong. Please, try again",
          timeoutMs: 5000,
        });

        setIsClaimPending(false);

        return;
      }
    } else {
      trackEvent(DataLayerEventNames.portfolioCompleted);

      await Promise.all([
        new Promise((resolve, reject) => {
          dispatch(fetchBalanceAction())
            .then((result) => {
              fetchBalanceAction.fulfilled.match(result) ? resolve(result) : reject(result);
            })
            .catch(reject);
        }),
        new Promise((resolve, reject) => {
          dispatch(fetchInitialClickerDataAction())
            .then((result) =>
              fetchInitialClickerDataAction.fulfilled.match(result) ? resolve(result) : reject(result),
            )
            .catch(reject);
        }),
      ]);

      setPortfolioToClaim(null);
    }

    // await dispatch(fetchBalanceAction());
    setIsClaimPending(false);

    setPortfolioToClaim(null);
    // if (isDemo) navigate("/frens-earning");
  }, [portfolioToClaim]);

  return portfolioToClaim
    ? ReactDOM.createPortal(
        <ClaimRewardScreen
          isOpened={!!portfolioToClaim}
          portfolio={portfolioToClaim?.portfolio}
          portfolioIndex={portfolioToClaim?.index}
          isPending={isClaimPending}
          onClaim={handlePortfolioClaim}
        />,
        document.getElementById("root")!,
      )
    : null;
}
