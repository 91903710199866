import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    step: 0,
    isStarted: false,
    isFinished: false,
    darkBackground: true,
  },
  reducers: {
    setOnboardingStep(state, action: PayloadAction<number>) {
      state.isStarted = true;
      state.step = action.payload;
    },
    setStatusDarkBackground(state, action: PayloadAction<boolean>) {
      state.darkBackground = action.payload;
    },

    finishOnboarding(state) {
      state.isFinished = true;
      state.isStarted = false;
      state.step = -1;
    },
  },
});

export const { setOnboardingStep, finishOnboarding, setStatusDarkBackground } = onboardingSlice.actions;

export default onboardingSlice.reducer;
