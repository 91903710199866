import likeIcon from "../../../assets/icons/like.svg";
import dislikeIcon from "../../../assets/icons/dislike.svg";
import { NoteAction, UpdateNote } from "../../../api/communityNotes/types";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import { patchNoteForTokenAction } from "../../../store/token/actions/patchNoteForTokenAction";
import React, { useCallback } from "react";
import { patchLastNoteForTokenAction } from "../../../store/token/actions/patchLastNoteForTokenAction";

export enum DisplayFormat {
  COUNT = "count",
  YES_NO = "yes_no",
}
export enum Page {
  ALL_NOTES = "all_notes",
  LAST_NOTE = "last_note",
}

interface NoteActionsProps {
  text?: string;
  textStyle?: string;
  iconStyle?: string;
  displayFormat?: DisplayFormat;
  likes?: number;
  dislikes?: number;
  noteId: number;
  page?: Page;
}

const NoteActions = React.memo(
  ({
    text = "Is this note helpful?",
    textStyle = "text-[10px]",
    iconStyle = "size-[12px] ",
    dislikes,
    displayFormat = DisplayFormat.COUNT,
    likes,
    noteId,
    page = Page.ALL_NOTES,
  }: NoteActionsProps) => {
    const { tokenId } = useParams();
    const dispatch = useAppDispatch();

    const handleActionNote = useCallback(
      (action: NoteAction) => {
        const data: UpdateNote = {
          tokenId: +tokenId!,
          noteId: noteId,
          like: action,
        };

        if (page === Page.ALL_NOTES) dispatch(patchNoteForTokenAction(data));
        else dispatch(patchLastNoteForTokenAction(data));
      },
      [dispatch, tokenId, noteId],
    );

    return (
      <div className="flex justify-between items-center pt-2">
        <p className={`font-medium  text-lavenderGray ${textStyle}`}>{text}</p>
        <div className="flex gap-4 font-semibold text-[12px]">
          <button
            type="button"
            onClick={() => {
              handleActionNote(NoteAction.LIKE);
            }}
            className="flex gap-1 items-center"
          >
            <img src={likeIcon} className={`${iconStyle}`} alt="like" />
            <p>{displayFormat === DisplayFormat.COUNT ? likes : "Yes"}</p>
          </button>
          <button
            type="button"
            onClick={() => {
              handleActionNote(NoteAction.DISLIKE);
            }}
            className="flex gap-1 items-center"
          >
            <img src={dislikeIcon} className={` ${iconStyle}`} alt="dislike" />
            <p>{displayFormat === DisplayFormat.COUNT ? dislikes : "No"}</p>
          </button>
        </div>
      </div>
    );
  },
);

export default NoteActions;
