import { useSelector } from "react-redux";
import { userIdSelector } from "../../store/account/account";
import { Rocket } from "../../store/rocket/api/types";
import RocketItem from "./RocketItem/RocketItem";

const RocketList: React.FC<{
  rockets: Rocket[] | undefined;
  status: string;
}> = ({ rockets, status }) => {
  const currentUserId = useSelector(userIdSelector);

  const promoRockets = [
    ...(rockets?.filter((rocket) => rocket?.passengers?.every((passenger) => passenger.id !== currentUserId)) || []),
  ];

  const myRockets = [
    ...(rockets?.filter((rocket) => rocket?.passengers?.some((passenger) => passenger.id === currentUserId)) || []),
  ];

  return (
    <div className={`${status === "registering" ? "mb-[170px]" : "mb-[100px]"} `}>
      {myRockets.length > 0 && (
        <div
          className="rounded-[20px]"
          style={{
            background: "linear-gradient(93.06deg, #FF34F7 -7.81%, #A23BF3 111.82%)",
          }}
        >
          <p className=" py-[4px] font-extrabold leading-3 text-[12px] italic">Your rockets</p>
          <div
            className={` p-1 rounded-[20px]`}
            style={{
              background: "#673582",
              border: "1px solid #AE3AF3",
            }}
          >
            {myRockets.map((rocket, index) => (
              <div className={`${index == 0 ? "" : "mt-[8px]"}`} key={index}>
                <RocketItem rocket={rocket} />
              </div>
            ))}
          </div>
        </div>
      )}
      {promoRockets.length > 0 && (
        <div
          className="rounded-[20px] mt-[12px]"
          style={{
            background: "linear-gradient(93.06deg, #FFAE34 -7.81%, #F37E3B 111.82%)",
          }}
        >
          <p className=" py-[4px] italic font-extrabold text-[12px] leading-3 text-black">Promo rockets</p>
          <div
            className={` p-1 rounded-[20px]`}
            style={{
              background: " #764752",
              border: "1px solid #F5853A",
            }}
          >
            {promoRockets.map((rocket, index) => (
              <div className={`${index == 0 ? "" : "mt-[8px]"}`} key={index}>
                <RocketItem rocket={rocket} />
              </div>
            ))}
          </div>
        </div>
      )}

      {rockets?.map((rocket, index) => (
        <div className="mt-[8px]" key={index}>
          <RocketItem rocket={rocket} />
        </div>
      ))}
    </div>
  );
};
export default RocketList;
