import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BottomMenuInitialState {
  visible: boolean;
  boostRocket: boolean;
  getInRocket: boolean;
  buildPortfolio: boolean;
}
const initialState: BottomMenuInitialState = {
  visible: true,
  boostRocket: false,
  getInRocket: false,
  buildPortfolio: false,
};

export const bottomMenuSlice = createSlice({
  name: "bottomMenu",
  initialState,
  reducers: {
    changeStatusBottomMenu: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    boostRocket: (state, action: PayloadAction<boolean>) => {
      state.boostRocket = action.payload;
    },
    setStatusGetInRocket: (state, action: PayloadAction<boolean>) => {
      state.getInRocket = action.payload;
    },
    setStatusBuildPortfolio: (state, action: PayloadAction<boolean>) => {
      state.buildPortfolio = action.payload;
    },
  },
});

export const { boostRocket, setStatusGetInRocket, changeStatusBottomMenu, setStatusBuildPortfolio } =
  bottomMenuSlice.actions;
export default bottomMenuSlice.reducer;
