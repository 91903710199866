import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";
import { Holder } from "./types.ts";

export async function getHoldersForToken(tokenId: number) {
  return (
    await axiosInstance.get<ApiResponse<Holder[]>>(apiRoutes.holders.getHoldersForToken, {
      params: { tokenId },
    })
  ).data.data;
}
