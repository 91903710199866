export enum TokensBuyStatus {
  CREATED = "Created",
  BUEYD = "Buyed",
}

export interface Token {
  id: number;
  logo: string;
  name: string;
  ticker: string;
  createdBy: number;
  userName: string;
  progress: string;
  marcetCap: string;
  description: string;
  tgChatLink: string;
  tgChannelLink: string;
  xTwitter: string;
  status: TokensBuyStatus;
  createdAt: string;
  updatedAt: string;
}

export interface TokenInfo extends Token {
  transactions: Transaction[];
  user: {
    photo_url: null;
  };
}

export interface CreateToken {
  logo: string | File | null;
  name: string;
  ticker: string;
  description: string;
  tgChatLink: string;
  tgChannelLink: string;
  xTwitter: string;
}

export enum TransactionsStatus {
  SELL = "Sell",
  BUY = "Buy",
  DEPLOY = "Deploy",
}

export interface TransactionToken {
  logo: string;
  name: string;
  ticker: string;
  userName: string;
}

export interface Transaction {
  id: number;
  userId: number;
  tokenId: number;
  status: TransactionsStatus;
  coinQuantity: number | string;
  createdAt: string;
  updatedAt: string;
  token?: TransactionToken;
  user?: {
    first_name: string;
  };
}

export interface Note {
  id: number;
  user?: {
    first_name: string;
    photo_url: string;
  };
  message: string;
  like: number;
  disLike: number;
  createdAt: string;
}
