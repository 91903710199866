import balanceIcon from "../assets/icons/balance.svg";
import bagCoinsIcon from "../assets/icons/bag-coins.png";
import { useAppDispatch } from "../store/hooks.ts";
import { changeStatusBottomMenu } from "../store/menu/bottomMenuReducer.ts";
import { useEffect, useState } from "react";

// import { InviteButton } from "../components/Friends/InviteButton.tsx";
import { moneyFormat } from "../helpers/moneyFormat.ts";
import AnimatedCounter from "../components/ui/AnimatedCounter.tsx";
import { useSelector } from "react-redux";
import { fetchInvitesDataAction } from "../store/friends/actions/fetchInvitesDataAction.ts";
import { rewardForReferralsSelector } from "../store/account/account.tsx";
import Button from "../components/ui/Button.tsx";
import { useNavigate } from "react-router-dom";
import { userInvitesSelector } from "../store/friends/friendsReducer.ts";

export default function DailyEarnings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const total = useSelector(userInvitesSelector);

  const rewardForReferrals = useSelector(rewardForReferralsSelector);
  const [isPending, setIsPending] = useState(() => false);

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    setIsPending(true);
    dispatch(fetchInvitesDataAction());
    setIsPending(false);
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="flex flex-1 flex-col  text-[16px] text-center pb-[17px]">
      <div className=" grow flex flex-col items-center pt-[8vh] ">
        <h1 className="text-[24px] font-bold leading-none flex items-center text-center gap-x-[9px] pb-[4vh]">
          <span>Daily Earnings Update</span>
        </h1>
        <div className="flex flex-col items-center gap-[19px] pb-[6vh]">
          <p>You've earned </p>
          <div className="text-[36px] font-bold text-yellow flex gap-1 items-center">
            +<AnimatedCounter from={0} to={rewardForReferrals} duration={1} />
            <img src={balanceIcon} alt="Balance" className="size-[26px] object-contain object-center" />
          </div>
          <p>
            from your <span className="font-bold text-yellow">{moneyFormat(total)} referrals</span> today!
          </p>
        </div>
        <img
          src={bagCoinsIcon}
          alt="bagCoinsIcon"
          style={{ height: "calc((100vh - 100px) * 0.28)" }}
          className="object-contain object-center"
        />
        <p className="pt-[4vh] pb-[2.4vh]">
          Keep sharing and watch
          <br /> your income grow!
        </p>
      </div>

      {!isPending && (
        <div className="shrink-0 ">
          {/* <InviteButton text="Invite" placement="daily_ref_rewards" /> */}
          <Button
            text="Claim"
            handleClick={() => navigate("/")}
            style={"linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"}
          />
        </div>
      )}
    </div>
  );
}
