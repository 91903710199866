import { forwardRef, useImperativeHandle } from "react";
import { useCountDown } from "../../hooks/useCountDown.ts";
import { clamp } from "lodash-es";
import { formatMsToSeconds } from "../../helpers/formatMs.ts";

interface ClickerGameTimerProps {
  remainingTime: number;
  immediate?: boolean;
  onStart?: () => void;
  onEnd?: () => void;
}

export interface ClickerGameTimerRef {
  start: () => void;
  clear: () => void;
  isPlaying: () => boolean;
}

export const ClickerGameTimer = forwardRef<ClickerGameTimerRef, ClickerGameTimerProps>(
  ({ onStart, onEnd, remainingTime, immediate }, ref) => {
    const {
      start,
      time: timeRemaining,
      isPlaying,
      clear,
    } = useCountDown({
      duration: remainingTime,
      onEnd,
    });

    useImperativeHandle(ref, () => ({
      start: () => {
        start();
        onStart && onStart();
      },
      clear: () => {
        clear();
      },
      isPlaying: () => isPlaying,
    }));

    if (immediate) {
      clear();
      start();
      onStart && onStart();
    }

    const fontSize = clamp(window.innerHeight * 0.035, 24, 30);

    return isPlaying ? (
      <span
        className="leading-[1.1] block text-center font-bold opacity-50"
        style={{
          fontSize: fontSize,
        }}
      >
        <small
          className="whitespace-nowrap"
          style={{
            fontSize: clamp(fontSize * 0.3, 10, 20),
          }}
        >
          Tokens change in
        </small>

        <br />

        {formatMsToSeconds(timeRemaining)}
      </span>
    ) : null;
  },
);
