import { useTelegramApp } from "../../../hooks/useTelegramApp";
import { BottomSheet } from "../../ui/BottomSheet";
import NftsConditions from "./Nft/NftsConditions";
import Button from "../../ui/Button";

import nftPopUpImage from "../../../assets/img/nft-magic-eden-pop-up.png";
import Title from "../../ui/Title";

interface NftPopUpProps {
  isOpened: boolean;
  onClose: () => void;
}

const NftPopUp = ({ isOpened, onClose }: NftPopUpProps) => {
  const tgApp = useTelegramApp();

  const handleOpenSite = () => {
    tgApp.openLink("https://magiceden.io/launchpad/orbitonx?preview=");
  };
  return (
    <BottomSheet isOpened={isOpened} onClose={onClose} canBeClosedOnTouchMove={true} canBeClosedOnClickInside={true}>
      <div className="w-full flex flex-col justify-center items-center object-contain -mt-8 mb-4">
        <img src={nftPopUpImage} alt="nftPopUpImage" className=" w-[58vw]" />
      </div>
      <div>
        <Title style="!mb-1 text-center" text="Mint Free NFT and Get Advantages  " />
        <p className="text-[16px] font-medium text-center">
          Don’t miss your chance to own
          <br /> a piece of OrbitonX!
        </p>
      </div>
      <NftsConditions />

      <Button
        text="Mint On MAGIC EDEN"
        className="mt-[5vh]"
        handleClick={handleOpenSite}
        style="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
      />
    </BottomSheet>
  );
};

export default NftPopUp;
