import solanaIcon from "../../../../assets/icons/solana-with-background.svg";
import magicEdenIcon from "../../../../assets/icons/magic-eden.svg";
import GuideItem, { Guide } from "./GuideItem";

const GuidesList = () => {
  const guidesList: Guide[] = [
    {
      iconSrc: magicEdenIcon,
      text: "How to Mint NFT on Magic Eden",
      link: "https://www.notion.so/orbitonx/Connecting-a-Phantom-wallet-to-Magic-Eden-and-minting-a-collection-ecdd6692459f471a9ccd0b0415b7f396",
    },
    {
      iconSrc: solanaIcon,
      text: "How to Get SOL (Solana)",
      link: "https://www.notion.so/orbitonx/Funding-Your-Phantom-Wallet-SOL-via-BestChange-eac8a12e8ab54c6d8edc62dac269da29",
    },
  ];
  return (
    <div className="pt-6  space-y-2">
      <h2 className="text-[16px] font-bold pb-2">Guides</h2>
      {guidesList.map((item, index) => (
        <GuideItem item={item} key={index} />
      ))}
    </div>
  );
};

export default GuidesList;
