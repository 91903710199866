import userAvatar from "../../../assets/icons/user.svg";

export default function UserIcon() {
  return (
    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[32px] h-[32px] rounded-full flex justify-center items-center" style={{ border: '2px solid #C869FF' }}>
      <div className="w-[28px] h-[28px] bg-white rounded-full flex justify-center items-center">
        <img src={userAvatar} alt="User Avatar" className="w-[28px] h-[28px] rounded-full object-cover" />
      </div>
    </div>
  );
}
