import { useSelector } from "react-redux";
import balanceIcon from "../assets/icons/balance.svg";
// import plusIcon from "../assets/icons/plus.svg";
import userPhoto from "../assets/icons/user.svg";
import ticketIcon from "../assets/icons/ticket.svg";
import { balanceSelector, photoSelector } from "../store/account/account";
import { moneyFormat } from "../helpers/moneyFormat";
import { ExternalImage } from "./ui/ExternalImage";
import { referralsTicketSelector } from "../store/cards/cardsReducer";

export default function UserBalanceBlock({ width = "w-max" }: { width?: string }) {
  const balance = useSelector(balanceSelector);
  const photo = useSelector(photoSelector);
  const referralsTicket = useSelector(referralsTicketSelector);

  const title = "Avatar";

  const photo_url = photo.endsWith(".svg") || photo.endsWith(".jpeg") ? photo : `${photo}.jpeg`;

  return (
    <div className="flex-1 ">
      <div
        className={`w-full min-w-[45vw] container p-1 flex  justify-between items-end ${width} pr-3 rounded-[16px] bg-[#1F1F1F]`}
      >
        <div className="flex items-center gap-2">
          <div className="bg-lightPurple rounded-[12px]">
            {!photo ? (
              <img src={userPhoto} className="rounded-[12px]" alt={title} />
            ) : (
              <ExternalImage src={photo_url} className="rounded-[12px]" alt={title} width={50} height={50} />
            )}
          </div>
          <div className="flex flex-col ">
            <div className="flex gap-1">
              <img src={balanceIcon} className="w-[14px] h-auto" />
              <span className="tracking-[1px] text-[12px] font-semibold">{moneyFormat(balance)}</span>
            </div>
            <div className="flex gap-1">
              <img src={ticketIcon} className="w-[14px] h-auto" />
              <span className="tracking-[1px] text-[12px] font-semibold">{moneyFormat(referralsTicket || 0)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    // {/*   VERSION WITH PLUS BUTTON   */}
    // {/*<div className="container p-1 pr-[3px] flex justify-between items-end w-[179px] rounded-[16px] bg-[#1F1F1F]">*/}
    // {/*  <div className="flex items-center gap-2">*/}
    // {/*    <div className="bg-lightPurple rounded-[12px]">*/}
    // {/*      <img src={userPhoto} className=" rounded-[12px]" />*/}
    // {/*    </div>*/}
    // {/*    <div className="flex gap-1">*/}
    // {/*      <img src={balanceIcon} />*/}
    // {/*      <span className="tracking-[1px] font-semibold">*/}
    // {/*        {moneyFormat(balance)}*/}
    // {/*      </span>*/}
    // {/*    </div>*/}
    // {/*  </div>*/}
    // {/*  <div className="w-[40px] h-[36px] flex justify-center relative ">*/}
    // {/*    <button*/}
    // {/*      className="absolute w-[32px] h-[32px] rounded-lg flex justify-center items-center z-10 hover:scale-125 ease-in-out duration-300"*/}
    // {/*      style={{*/}
    // {/*        backgroundImage:*/}
    // {/*          "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",*/}
    // {/*      }}*/}
    // {/*    >*/}
    // {/*      <img src={plusIcon} />*/}
    // {/*    </button>*/}
    // {/*    <div*/}
    // {/*      className="absolute bottom-0 w-10 h-8 rounded-lg"*/}
    // {/*      style={{*/}
    // {/*        backgroundImage:*/}
    // {/*          "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",*/}
    // {/*        filter: "blur(40px)",*/}
    // {/*      }}*/}
    // {/*    ></div>*/}
    // {/*  </div>*/}
    // {/*</div>*/}
  );
}
