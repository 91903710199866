import { ACCESS_TOKEN_KEY } from "../constants/auth";
import { useTelegramApp } from "../hooks/useTelegramApp";

export const redirectToWalletManagement = () => {
  const tgApp = useTelegramApp();

  const token = localStorage.getItem(ACCESS_TOKEN_KEY) || "";

  const url = new URL(import.meta.env.VITE_PHANTOM_SITE_URL);
  url.searchParams.append("token", token);

  tgApp.openLink(url.toString() || "");
  tgApp.close();
};
