interface ButtonProps {
  text: string;
  icon: string;
  handleClick?: () => void;
  iconStyle?: string;
}

const Button: React.FC<ButtonProps> = ({ text, icon, handleClick, iconStyle }) => {
  return (
    <button onClick={handleClick} className="bg-[#22273A] py-2 px-4 flex items-center gap-[6px] rounded-[32px]">
      <img src={icon} className={iconStyle} alt="Reset" />
      <span className="block text-[12px] leading-4 tracking-[0.04em] font-medium">{text}</span>
    </button>
  );
};

export default Button;
