import { Exchange } from "../../store/exchanges/types";

const ExchangeTasksBlock = ({ exchange }: { exchange: Exchange | null }) => {
  return (
    <div className="text-[16px] leading-6  px-3">
      <h2 className="text-white/75  font-semibold pb-2">Your tasks: </h2>
      <ol className="font-medium flex flex-col gap-2 list-decimal list-inside">
        <li className="marker:font-black">
          <span className="pl-2 ">Register with {exchange?.title} </span>
        </li>
        <li className=" marker:font-black">
          <span className="pl-2">Paste your {exchange?.title} ID (BUID)</span>
        </li>
      </ol>
    </div>
  );
};

export default ExchangeTasksBlock;
