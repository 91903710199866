import { useSelector } from "react-redux";
import { adTwentyCountSelecor, adTwentyRewardTakenSelector } from "../store/account/account.tsx";
import ticketsIcon from "../assets/img/tickets.png";
import friendsIcon from "../assets/icons/hands.svg";
import tasksIcon from "../assets/icons/strong.svg";
import successIcon from "../assets/icons/succes.svg";
import videoIcon from "../assets/icons/camera.svg";
import ticketIcon from "../assets/icons/ticket.svg";
import motorIcon from "../assets/icons/motor.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/ui/Button.tsx";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../store/hooks.ts";
import { changeStatusBottomMenu } from "../store/menu/bottomMenuReducer.ts";

export function HowToGetTicketPage() {
  const adTwentyCount = useSelector(adTwentyCountSelecor);
  const adTwentyRewardTaken = useSelector(adTwentyRewardTakenSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [closePath, setClosePath] = useState<string>("/");

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  useEffect(() => {
    const getQueryParams = (url: string) => {
      const params = new URLSearchParams(url);
      return {
        closePath: params.get("closePath"),
      };
    };

    const { closePath } = getQueryParams(location.search);

    if (closePath) {
      setClosePath(closePath);
    }
  }, [location.search, navigate]);

  const displayAdTwentyCount = (() => {
    if (adTwentyCount === 0) {
      return "0/10";
    }
    if (adTwentyCount === 10 && adTwentyRewardTaken) {
      return "0/10";
    }
    if (adTwentyCount === 10 || adTwentyCount === 20) {
      return "10/10";
    }
    return `${adTwentyCount % 10}/10`;
  })();

  return (
    <div className="text-center pt-6 z-10">
      <h1 className="text-[20px] font-bold text-white tracking-[1px] text-shadow-md">How to get Tickets?</h1>
      <span className="relative flex justify-center items-center mt-[30px]">
        <span className="absolute w-[120px] h-[120px] bg-[#F36BFF] blur-[10px] rounded-full"></span>
        <img loading="lazy" className="relative w-[103px] h-[120px]" src={ticketsIcon} alt="Task icon" />
      </span>

      <div className="mt-[30px] space-y-2">
        {[
          {
            title: "Invite more frens",
            icon: friendsIcon,
            buttonText: "INVITE",
            action: () => {
              handleButtonClick("/friends");
            },
            content: (
              <div className="flex flex-col justify-between h-[28.5px]">
                <div className="flex items-center">
                  <span className="font-semibold text-[14px] text-[#f36bff]">+1</span>
                  <img src={ticketIcon} alt="Ticket Icon" className="w-[14px] h-[11.2px] ml-1" />
                  <span className="ml-1 font-medium text-[14px] text-[var(--hamstercard.pro-white-mine-shaft)] leading-none m-0">
                    for each fren
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="font-semibold text-[14px] text-[#f36bff]">+3</span>
                  <img src={ticketIcon} alt="Ticket Icon" className="w-[14px] h-[11.2px] ml-1" />
                  <span className="ml-1 font-medium text-[14px] text-[var(--hamstercard.pro-white-mine-shaft)] leading-none m-0">
                    for premium fren
                  </span>
                </div>
              </div>
            ),
          },
          {
            title: "Complete Tasks",
            icon: tasksIcon,
            buttonText: "GO TO TASKS",
            action: () => {
              handleButtonClick("/tasks");
            },
          },
          {
            title: "Check in on the app Daily",
            icon: successIcon,
          },
          {
            title: "Watch 10 Ads",
            icon: videoIcon,
            buttonText: (
              <>
                <img src={motorIcon} alt="Motor Icon" className="inline-block mr-2 w-[16px] h-[16px]" />
                WATCH
              </>
            ),
            action: () => {
              handleButtonClick("/tasks");
            },
            content: (
              <span className="text-left block font-medium text-[14px] text-[var(--hamstercard.pro-white-mine-shaft)]">
                Watch {displayAdTwentyCount} ads
              </span>
            ),
          },
        ].map(({ title, icon, buttonText, content, action }, index) => (
          <div
            key={index}
            className="flex items-center rounded-xl px-4 py-4"
            style={{
              width: "100%",
              height: "80px",
              backgroundColor: "rgba(255, 255, 255, 0.15)",
              position: "relative",
            }}
          >
            <img src={icon} alt={title} className="w-[29px] h-[29px]" />
            <div className="ml-[8px]">
              <p className="text-white text-[16px] font-bold text-start m-0 p-0">{title}</p>
              {content && <p className="text-sm m-0 p-0 text-start">{content}</p>}
            </div>

            {buttonText && (
              <button
                onClick={action}
                className="rounded-[32px] py-[12px] px-[15px] bg-gradient-to-r from-[#ffae34] to-[#f37e3b] text-white font-bold absolute right-4"
                style={{
                  maxWidth: "114px",
                  maxHeight: "41px",
                  width: "100%",
                  height: "auto",
                  fontSize: "12px",
                  lineHeight: "133%",
                  letterSpacing: "0.02em",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {buttonText}
              </button>
            )}
          </div>
        ))}
      </div>

      <div className="mt-[70px] px-4 left-0 right-0">
        <Button
          text={"OK"}
          handleClick={() => handleButtonClick(closePath)}
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          className="w-full"
        />
      </div>
    </div>
  );
}
