import { moneyFormat } from "../../helpers/moneyFormat";
import balanceIcon from "../../assets/icons/balance.svg";

interface CurrencyDisplayProps {
  value: number;
  textStyle?: string;
  iconStyle?: string;
  withPlus?: boolean;
  gap?: string;
}

const CurrencyDisplay = ({
  value,
  textStyle,
  iconStyle,
  withPlus = true,
  gap = "gap-x-[2px]",
}: CurrencyDisplayProps) => {
  return (
    <strong className={`flex justify-center items-center  text-[14px] ${textStyle} ${gap}`}>
      <div>
        {withPlus && <span>+</span>}
        {moneyFormat(value || 0)}{" "}
      </div>
      <span className={`size-3  ${iconStyle}`}>
        <img className={`size-full object-cover`} loading="lazy" src={balanceIcon} alt="currency" />
      </span>
    </strong>
  );
};

export default CurrencyDisplay;
