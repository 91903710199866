import { CopyToClipboardInput } from "../ui/CopyToClipboardInput.tsx";
import { useSelector } from "react-redux";
import { inviteUrlSelector } from "../../store/friends/friendsReducer.ts";
import { InviteButton } from "./InviteButton.tsx";

export function ReferralLink({ buttonText = "Invite Friends" }: { buttonText?: string }) {
  const inviteUrl = useSelector(inviteUrlSelector);

  return (
    <div>
      <CopyToClipboardInput value={inviteUrl || ""} />

      <div className="mt-4">
        <InviteButton text={buttonText} placement="friends_screen" />
      </div>
    </div>
  );
}
