import { useSelector } from "react-redux";
import WarningBlock from "../../../Common/WarningBlock";
import Title from "../../../ui/Title";
import { myNftsSelector } from "../../../../store/nft/nftsReducer";
import NftItem from "./NftItem";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const NftList = () => {
  const myNfts = useSelector(myNftsSelector);

  return (
    <div className="pt-8">
      <Title text="Your NFTs" style="text-left" />
      {myNfts?.length > 0 ? (
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {myNfts.map((nft) => (
            <SwiperSlide key={nft.id}>
              <NftItem nft={nft} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <WarningBlock>
          <div className="p-1 flex justify-between items-center w-full h-full text-[12px] text-orangeLight">
            <p>You do not possess any NFTs.</p>
            <button className="bg-white h-full rounded-xl px-[27px]">
              <span className="text-black font-bold">Purchase</span>
            </button>
          </div>
        </WarningBlock>
      )}
    </div>
  );
};

export default NftList;
