import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { GetUserTappAdsResponse } from "./types.ts";

export async function setStartTappAds(
  taskId: number,
  taskName: string,
  taskDescription: string,
  taskUrl: string,
  isDone: boolean) {
  return (await axiosInstance.post<GetUserTappAdsResponse>(apiRoutes.user.setStartTappAds, {
    taskId,
    taskName,
    taskDescription,
    taskUrl,
    isDone
  })).data.data;
}
