import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshToken } from "./auth/refresh-token.ts";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants/auth.ts";
import { apiRoutes } from "./routes.ts";
import * as Sentry from "@sentry/react";
import axiosRetry from "axios-retry";

export const axiosInstance = Axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL}/api`,
});

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1500;
  },
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

function onRefreshed(token: string): void {
  refreshSubscribers.forEach((callback) => callback(token));
}

function addRefreshSubscriber(callback: (token: string) => void): void {
  refreshSubscribers.push(callback);
}

const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

axiosInstance.interceptors.request.use((config) => {
  const isRefreshTokenRequest = config.url?.includes(apiRoutes.auth.refreshToken);
  const tokenKey = isRefreshTokenRequest ? REFRESH_TOKEN_KEY : ACCESS_TOKEN_KEY;

  config.headers["Authorization"] = `Bearer ${localStorage.getItem(tokenKey)}`;
  config.headers["x-local-time"] = new Date().toISOString();
  config.headers["X-Timezone"] = getTimezone();
  config.headers["X-Request-id"] = Math.random().toString(36).substring(7);

  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    Sentry.setTag("RequestId", error?.config?.headers["X-Request-id"] || "UNKNOWN");

    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (!accessToken) {
      Sentry.setContext("No access token", {});
    }

    if (!["get", "delete"].includes(error?.config?.method || "")) {
      Sentry.setContext("Request data", {
        data: error.config.data,
      });
    }

    Sentry.setContext("User Timezone", {
      timezone: getTimezone(),
    });

    Sentry.setContext("headers", error.config.headers);
    Sentry.setContext("Error data", error.response?.data);

    Sentry.captureException(error);

    const { config, response } = error;

    const originalRequest: AxiosRequestConfig = config;

    if (response?.status === 401) {
      const refreshTokenValue = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (!refreshTokenValue) {
        Sentry.captureMessage("Unauthorized request. No refresh token");
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);

        return Promise.reject(new Error("No refresh token available"));
      }

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          Sentry.captureMessage("Unauthorized request. Token refreshed successfully");
          const refreshTokenResponse = await refreshToken();
          localStorage.setItem(ACCESS_TOKEN_KEY, refreshTokenResponse.token);
          localStorage.setItem(REFRESH_TOKEN_KEY, refreshTokenResponse.refreshToken);

          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${refreshTokenResponse.token}`;
          isRefreshing = false;
          onRefreshed(refreshTokenResponse.token);
          refreshSubscribers = [];
        } catch (refreshError) {
          Sentry.captureException(refreshError);
          isRefreshing = false;
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          localStorage.removeItem(REFRESH_TOKEN_KEY);
          return Promise.reject(refreshError);
        }
      }

      return new Promise<AxiosResponse>((resolve) => {
        addRefreshSubscriber((newToken: string) => {
          Sentry.captureMessage("Unauthorized request. Token refreshed successfully and request is repeated.");
          if (!originalRequest.headers) {
            originalRequest.headers = {};
          }

          originalRequest.headers["Authorization"] = "Bearer " + newToken;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  },
);
