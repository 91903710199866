export interface Energy {
  clickCost: number;
  count: number;
  capacity: number;
  recoveryPerSecond: number;
}

export interface Coin {
  /**
   * DB coin id
   */
  id: number;
  name: string;
  capacity: number;
  icon: string;
}

export interface PortfolioCoin {
  /**
   * User coins ID
   */
  id: string;

  /**
   * Coin ID DB
   */
  originalCoinId: Coin["id"];
  progress: number;
}

export type ActiveGameCoin = Omit<Coin, "id"> & Pick<PortfolioCoin, "originalCoinId" | "id">;

export interface Portfolio {
  id: string;
  activeFrom: Date;
  activeTo: Date;
  finishStaking: Date | null;
  startStaking: Date | null;
  progress: number;
  cost: number;
  coins: PortfolioCoin[];
  isDemoPortfolio?: boolean;
}

export interface DailyPortfolioQuest {
  id: number;
  step: number;
  portfolioCapacity: number;
  clickCost: number;
  coinCapacity: number;
  isDemo: boolean;
  questCompleteBonus: number;
  portfolios: Portfolio[];
  rewardFromStacking: number;
  profitPerHour: number;
  profitPerHourToStaking: number;
  totalBonusForCardsToStaking: number;
}

export enum ClickAllowedRejectedReasonEnum {
  COIN_NOT_IN_PORTFOLIO = "Coin not in portfolio",
  COIN_PROGRESS_MAX = "Coin progress max",
  UNKNOWN = "Unknown",
  NO_ACTIVE_PORTFOLIO = "No active portfolio",
}

export type ClickAllowedStatus = {
  isAllowed: boolean;
  rejectedReason: ClickAllowedRejectedReasonEnum | null;
};
