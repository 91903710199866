import IconWithText from "../ui/IconWithText";
import TimeBlock from "./StatusRound";

import style from "./Round.module.css";

import buyInIcon from "../../assets/icons/buy-in.svg";
import rocketIcon from "../../assets/icons/rocket-white.svg";
import prizeIcon from "../../assets/icons/prize.svg";
import ownerBadgeIcon from "../../assets/icons/owner-badge.svg";
import investedBadgeIcon from "../../assets/icons/invested-badge.svg";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../store/account/account";
import { Rocket } from "../../store/rocket/api/types";

interface RoundItemProps {
  status: "running" | "registering" | "planned";
  title: string;
  buyIn: number;
  rockets: Rocket[];
  prize: string;
  time: string;
}

const RoundItem: React.FC<RoundItemProps> = ({ status, title, buyIn, rockets, prize, time }) => {
  const currentUserId = useSelector(userIdSelector);

  const isCurrentUserOwner =
    rockets !== undefined && rockets?.findIndex((rocket) => rocket.ownerId === currentUserId) >= 0;

  const isCurrentInvestedInRocket =
    rockets !== undefined &&
    rockets?.findIndex((rocket) =>
      rocket?.passengers?.some((passenger) => passenger.id === currentUserId && rocket.ownerId !== currentUserId),
    ) >= 0;

  const bgGradient: string =
    isCurrentUserOwner && isCurrentInvestedInRocket
      ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(1, 225, 255, 0.2) 0%, rgba(1, 225, 255, 0) 100%)"
      : isCurrentUserOwner
        ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(1, 133, 255, 0.2) 0%, rgba(1, 133, 255, 0) 100%)"
        : isCurrentInvestedInRocket
          ? "radial-gradient(47.51% 214.38% at 100% 50%, rgba(1, 166, 97, 0.15) 0%, rgba(1, 166, 97, 0) 100%)"
          : "";

  return (
    <div
      className={
        "relative flex gap-[11px] items-center h-[64px] rounded-2xl font-medium  hover:scale-105 ease-in duration-500 " +
        style["round-item"]
      }
      style={{
        backgroundImage: bgGradient,
      }}
    >
      <div className=" w-[88px]">
        <TimeBlock time={time} status={status} />
      </div>
      <div className="w-[230px]">
        <p className="pb-[8px] tracking-[0.02em]">{title}</p>
        <div className="flex w-full justify-between">
          <IconWithText icon={buyInIcon} text={buyIn} style="font-bold" />
          <IconWithText icon={rocketIcon} text={rockets?.length} style="font-bold" />
          <IconWithText icon={prizeIcon} text={prize} style="font-bold" />
        </div>
      </div>
      <div className="absolute top-0 right-[19px] flex gap-1">
        {isCurrentUserOwner && <img src={ownerBadgeIcon} alt="owner" className="w-6 h-8" />}
        {isCurrentInvestedInRocket && <img src={investedBadgeIcon} alt="owner" className="w-6 h-8 " />}
      </div>
    </div>
  );
};
export default RoundItem;
