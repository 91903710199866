import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import roundData from "../../mock/rounds";
import { Passenger, Rocket } from "../rocket/api/types";
import { Round } from "./api/types";

export interface RoundInitialState {
  round: Round | undefined;
  rounds: Round[];
}
const initialState: RoundInitialState = {
  round: undefined,
  rounds: [...roundData],
};

export const rocketSlice = createSlice({
  name: "rocket",
  initialState,
  reducers: {
    setRound: (state, action: PayloadAction<string | undefined>) => {
      state.round = state.rounds.find((round) => round.id === action.payload);
    },
    addRocketToRound: (state, action: PayloadAction<Rocket | undefined>) => {
      if (action.payload) {
        const roundIndex = state.rounds.findIndex((round) => round.id === state.round?.id);
        if (roundIndex !== -1) {
          state.rounds[roundIndex].rockets.push(action.payload);
          if (state.round) {
            state.round.rockets.push(action.payload);
          }
        }
      }
    },
    investedInRocket: (
      state,
      action: PayloadAction<{
        passenger: Passenger;
        rocketId: string | undefined;
      }>,
    ) => {
      const { passenger, rocketId } = action.payload;
      const rocket = state.round?.rockets.find((rocket) => rocket.id === rocketId);
      if (rocket) {
        rocket?.passengers?.push(passenger);
      }

      const roundIndex = state.rounds.findIndex((round) => round.id === state.round?.id);
      if (roundIndex !== -1 && state.round) {
        state.rounds[roundIndex] = { ...state.round };
      }
    },
  },
});

export const { setRound, addRocketToRound, investedInRocket } = rocketSlice.actions;
export default rocketSlice.reducer;
