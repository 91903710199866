import Button from "../../ui/Button.tsx";
import { useSelector } from "react-redux";
import { tapAdsSelector } from "../../../store/tasks/tasksReducer.ts";
import AdItem from "./TapAddsItem.tsx";
import { useEffect, useState, useRef } from "react";
import { tapCooldownSelector,userTappAdsrSelector } from "../../../store/account/account.tsx";
import { BottomSheet } from "../../ui/BottomSheet.tsx";
import { UserTappAds } from "../../../api/user/types.ts";

interface PopupTapAddsListProps {
  isOpened: boolean;
  onClose: () => void;
}

export function PopupTapAddsList({ isOpened, onClose }: PopupTapAddsListProps) {
  const adsList = useSelector(tapAdsSelector);
  const tapCooldown = useSelector(tapCooldownSelector);
  const userTappads = useSelector(userTappAdsrSelector);
  const [isCooldown, setIsCooldown] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

 useEffect(() => {
  if (!userTappads) {
      console.log("userTappads is undefined or not yet loaded");
      return;
  }
  const tapTaskCounter = userTappads.filter((ad: UserTappAds) => ad.status === 'completed').length;
  if (tapTaskCounter >= 3) {
      onClose();
  }
}, [userTappads]);

  useEffect(() => {
    if (tapCooldown) {
      const timeLeft = parseInt(tapCooldown, 10) - Date.now();
      if (timeLeft > 0) {
        setIsCooldown(true);
        timerRef.current = setTimeout(() => {
          setIsCooldown(false);
        }, timeLeft);
      }
    }
  
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [tapCooldown]);

  if (!isOpened) {
    return null;
  }

  return (
    <div className="relative flex flex-col justify-center leading-none h-screen">
      <BottomSheet
        isOpened={isOpened}
        onClose={onClose}
        closeButton={false}
        className="h-full w-full bg-transparent"
      >
        <div className="relative flex flex-col select-none min-h-full text-sm">
          <div className="text-center z-10 flex flex-col h-full">
            <h1 className="text-[20px] font-bold text-white tracking-[1px] text-shadow-md">
            TappAds List
            </h1>
            <ul className="space-y-2 mt-[14px] flex-grow overflow-auto">
              {adsList.map((ad) => (
                <AdItem
                  key={ad.id}
                  ad={ad}
                  isCooldown={isCooldown}
                  setIsCooldown={setIsCooldown}
                />
              ))}
            </ul>
            <div className="mt-[16px]">
              <Button
                text={"Close"}
                handleClick={onClose}
                style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
}
