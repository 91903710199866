import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { GetUsersInLeague } from "./types.ts";
export async function getUsersInLeague(count: number = 10, offset: number = 0, league?: number) {
  return (
    await axiosInstance.get<GetUsersInLeague>(apiRoutes.user.usersInLeague, {
      params: {
        count: count,
        offset: offset,
        league: league,
      },
    })
  ).data.data;
}
