import { League, User } from "../api/types";

const generateUsers = (count: number): User[] => {
  return Array.from({ length: count }, (_, index) => {
    const name = `User ${Math.floor(Math.random() * 1000)}`;
    const avatar = `https://randomuser.me/api/portraits/men/${index}.jpg`;

    return {
      id: parseFloat((Math.random() * 10000).toFixed(0)),
      name: name,
      avatar: avatar,
      balance: parseFloat((Math.random() * 1000).toFixed(0)),
    };
  });
};

export const LeaguesMock: League[] = [
  {
    id: 1,
    name: "Bronze",
    description: null,
    icon: "bronze.svg",
    pointsForNextLeague: 1500,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
  {
    id: 2,
    name: "Silver",
    description: null,
    icon: "silver.svg",
    pointsForNextLeague: 4500,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
  {
    id: 3,
    name: "Gold",
    description: null,
    icon: "gold.svg",
    pointsForNextLeague: 10000,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
  {
    id: 4,
    name: "Platinum",
    description: null,
    icon: "platinum.svg",
    pointsForNextLeague: 20000,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
  {
    id: 5,
    name: "Diamond",
    description: null,
    icon: "dimond.svg",
    pointsForNextLeague: 35000,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
  {
    id: 6,
    name: "Master",
    description: null,
    icon: "master.svg",
    pointsForNextLeague: 60000,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
  {
    id: 7,
    name: "Legendary",
    description: null,
    icon: "legendary.svg",
    pointsForNextLeague: 90000,
    users: generateUsers(25),
    rankingPositionForCurrentUser: 100500,
  },
];
