import { useSelector } from "react-redux";
import { BottomSheet } from "../../../ui/BottomSheet";
import Button from "../../../ui/Button";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import { moneyFormatWithTwoDigits } from "../../../../helpers/moneyFormat";
interface ConfirmPurchaseProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  solanaAmount: number;
}

const ConfirmPurchase = ({ isOpened, setIsOpened, solanaAmount }: ConfirmPurchaseProps) => {
  const token = useSelector(currentTokenSelector);

  const { name, ticker } = token!;
  return (
    <BottomSheet
      isOpened={isOpened}
      onClose={() => setIsOpened(false)}
      title="Confirm purchase"
      classNameForChildrenContainer="px-4 py-4"
    >
      <div className="mt-3 flex flex-col gap-2 text-[14px]">
        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 ">
          <div className="flex justify-between px-2   border-solid border-b-[1px] border-white/10 pb-3">
            <p className=" font-semibold text-lavenderGray">Name</p>
            <div className="font-bold flex gap-2">
              <img src="/image-token.png" className="size-5 object-cover" /> <p>{name}</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2 ">
            <p className="font-semibold text-lavenderGray">Ticker</p>
            <div className="font-bold">
              <p>${ticker}</p>
            </div>
          </div>
        </div>

        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 mb-[14px]">
          <div className="flex justify-between  px-2  border-solid border-b-[1px] border-white/10 pb-3">
            <p className=" font-semibold text-lavenderGray">Send SOL</p>
            <div className=" font-bold flex gap-2 items-center">
              <img src="/solana-sol-logo.svg" className="size-4 object-cover" alt="solana-icon" />{" "}
              <p>{moneyFormatWithTwoDigits(solanaAmount)}</p>
            </div>
          </div>
          <div className="flex justify-between px-2  py-3 border-solid border-b-[1px] border-white/10">
            <p className=" font-semibold text-lavenderGray">Receive ${ticker}</p>
            <div className=" font-bold flex gap-2">
              <img src="/image-token.png" className="size-5 object-cover" /> <p>1,276,259.49</p>
            </div>
          </div>
          <div className="flex justify-between py-3 px-2  border-solid border-b-[1px] border-white/10">
            <p className="font-semibold text-lavenderGray">Share of the supply</p>
            <div className=" font-bold">
              <p>0.13%</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2 ">
            <p className=" font-semibold text-lavenderGray">Vesting period</p>
            <div className="font-bold">
              <p>22h 15m</p>
            </div>
          </div>
        </div>
        <p className="text-center pb-2 font-medium">
          <span className="pr-2 text-lavenderGray">Transaction fee:</span>
          <span>0.1 SOL</span>
        </p>
        <Button
          text="Confirm"
          handleClick={() => setIsOpened(false)}
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
        />
      </div>
    </BottomSheet>
  );
};

export default ConfirmPurchase;
