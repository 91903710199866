import upIcon from "../../../assets/icons/up.svg";
import { useAppSelector } from "../../../store/hooks";

interface StatisticsBlockProps {
  fullness?: number;
  min_deposite?: number;
  profit?: string;
  percentage_profit?: string;
}

const StatisticsBlock: React.FC<StatisticsBlockProps> = ({ fullness, min_deposite, profit, percentage_profit }) => {
  const defaultFullness = fullness !== undefined ? fullness : 0;

  const round = useAppSelector((state) => state.round.round);

  return (
    <div>
      {round?.status === "registering" && (
        <div className={`flex gap-6 font-bold ${+defaultFullness < 25 ? "text-[#95879E]" : ""}`}>
          <div className="flex items-center gap-[6px]">
            <div className="flex  w-[84px] rounded-full h-2.5 bg-[#00000080]">
              <div
                className={`max-w-[80px] ${
                  +defaultFullness < 25 ? "bg-[#8E8E8E]" : "bg-[#FC9F37]"
                }  h-1.5 mt-[2px] ml-[2px] rounded-full`}
                style={{ width: `${defaultFullness}%` }}
              ></div>
            </div>
            <p>{defaultFullness}%</p>
          </div>
          <div>
            <span className={`mr-1   ${+defaultFullness < 25 ? "text-[#95879E]" : "text-[#989898] font-medium"}`}>
              Min.:
            </span>
            <span>{min_deposite}</span>
          </div>
        </div>
      )}
      {profit && percentage_profit && (
        <div className="flex gap-[12px] text-green leading-3 font-bold">
          <p>+{profit}</p>
          <div className="flex gap-1">
            <img src={upIcon} className={"w-[7px] inline"} />
            <p>+{percentage_profit}</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default StatisticsBlock;
