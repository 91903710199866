import { ReferralLink } from "../../Friends/ReferralLink";

const TokenLink = () => {
  return (
    <div>
      <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Token link</h2>

      <ReferralLink buttonText="Share" />
      <p className=" text-center w-full  font-medium text-[12px] text-lightestPurple">
        Share this link with friends to get more <br />
        GAS XP points if they join and trade.
      </p>
    </div>
  );
};

export default TokenLink;
